import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    marginBottom: '150px'
  },
  pleaseConfirmText: {
    marginTop: theme.spacing(10)
  },
  propertyViewsCard: {
    margin: theme.spacing(4, 'auto', 2, 'auto'),
    width: '100%'
  },
  propertyViewsContainer: {
    padding: '25px 50px 15px 25px',
    display: 'flex',
    flexDirection: 'column'
  },
  propertyViewButton: {
    margin: theme.spacing(4, 5, 1, 'auto'),
    padding: theme.spacing('auto', 5)
  },
  propertyFormCard: {
    width: '100%'
  },
  propertyFormQuestionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  readMoreLink: {
    color: theme.colorPalette.orange_10
  },
  propertyFormQuestion: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  propertyFormExplanation: {
    fontSize: 14
  },
  propertyFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 50px 25px 35px',
    width: '100%'
  },
  formTitle: {
    fontSize: 20,
    marginBottom: theme.spacing(4)
  },
  formItem: {
    marginLeft: 10
  },
  formLabel: {
    margin: '10px 0px 5px'
  },
  attestationContainer: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column'
  },
  attestationCheckbox: {
    margin: theme.spacing(2, 2)
  },
  icon: {
    transform: 'rotate(90deg)'
  },
  floatingContainer: {
    marginTop: 'auto',
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 99
  },
  footerContainer: {
    height: 98,
    width: '100%',
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  footerBtn: {
    marginRight: '100px',
    padding: theme.spacing('auto', 5)
  },
  priorQuote: {
    marginLeft: theme.spacing(1),
    color: theme.colorPalette.orange_10,
    borderBottom: `1px solid ${theme.colorPalette.orange_10}`
  },
  sectionTitle: {
    margin: theme.spacing(10, 0, 3, 0),
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export default makeStyles(styles);
