import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import { IconTextLink } from 'core/components/icon-text-link';
import useStyles from './advanced-windstorm-info-modal.styles';

const titles = {
  roofDeck: 'Roof Deck',
  roofCover: 'Roof Cover',
  roofDeckAttachment: 'Roof Deck Attachment',
  roofToWallConnection: 'Roof to Wall Connection',
  openingProtection: 'Window Opening Protection',
  roofSWR: 'Secondary Water Resistance',
  windstormMitigationLevel: 'Windstorm Loss Retrofit Level'
};

const AdvancedWindstormInfoModal = ({ open, onClose, name, options, definitionsLink }) => {
  const classes = useStyles();

  return (
    <BaseDialog open={open} onClose={onClose}>
      <Label type="greenBig" className={classes.modalHeader}>
        {titles[name]}
      </Label>
      <Label type="darkGreenSmall" className={classes.modalContent}>
        {options.map((item) => {
          return (
            <Grid item className={classes.infoContainer} key={item.id}>
              <Label type="infoLabel" xs={12}>
                {item.value} {item.isDefault && '(Default)'}
              </Label>
              <Label type="infoValue" className={classes.modalContent} xs={12}>
                {item.hint}
              </Label>
            </Grid>
          );
        })}
      </Label>
      {definitionsLink && (
        <IconTextLink href={definitionsLink} text="Go to more details on retrofit levels" altIcon="More details" />
      )}
      <NotificationCard type="light">
        The selected level must be verified after purchase through a licensed inspector or licensed general contractor.
      </NotificationCard>
    </BaseDialog>
  );
};

AdvancedWindstormInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  definitionsLink: PropTypes.string
};

AdvancedWindstormInfoModal.defaultProps = {
  definitionsLink: null
};

export default AdvancedWindstormInfoModal;
