import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as Sentry from '@sentry/browser';

import { useStore } from 'core/store';
import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import ErrorDialog from 'core/components/error-dialog/error-dialog';
import LeavePageDialog from 'core/components/leave-page-dialog';
import Section from 'core/components/section';
import { useToast } from 'core/components/toast/context';
import { getPolicyStatus, PolicyStatus, getPolicyChange } from 'core/helpers/policy-status';
import { haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';
import { DisableProvider } from 'common/disabled-context';
import PolicyDetails from './details';
import Incidents from './incidents';
import { PaymentTab } from './payment-tab';
import PolicySettings from './policy-settings';
import Preview from './preview';
import Documents from '../documents/documents';
import { PolicyNotifications } from './policy-notifications';
import UnsignedBixConversionWarning from './unsigned-bix-conversion-warning';
import RenewalNotice from './renewal-notice';
import CancelledPolicyFooter from './footer/cancelled-policy-footer';
import useStyles from './policy.styles';

const PolicyTabs = {
  SETTINGS: 'SETTINGS',
  PAYMENT: 'PAYMENT',
  DETAILS: 'DETAILS',
  DOCUMENTS: 'DOCUMENTS',
  INCIDENTS: 'INCIDENTS'
};

const ModalType = {
  PREVIEW: 'PREVIEW',
  RENEWAL: 'RENEWAL'
};

const formatAdditionalParties = ({ additionalParties }) => {
  if (!Array.isArray(additionalParties)) {
    return additionalParties;
  }
  return additionalParties.map((party) => {
    if (party.address && !party.address.address) {
      return {
        ...party,
        address: {
          address: '',
          address2: party.address?.address2 || '',
          city: party.address?.city || '',
          state: party.address?.state || '',
          zip: party.address?.zip || ''
        }
      };
    }
    return party;
  });
};

const Policy = ({ history }) => {
  const classes = useStyles();
  const toast = useToast();
  const session = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState(PolicyTabs.SETTINGS);
  const [promptTab, setPromptTab] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [modal, setModal] = useState(null);
  const [error, setError] = useState({});
  const [preview, setPreview] = useState(null);
  const [action, setAction] = useState('');
  const [isBixConversionUnsigned, setIsBixConversionUnsigned] = useState(false);
  const [reasons, setReasons] = useState({});
  const [dataDescriptions, setDataDescriptions] = useState({});
  const [renewalPolicyId, setRenewalPolicyId] = useState(null);

  const {
    account: {
      fetchFullAccountAndPolicy,
      id: accountId,
      policies: {
        getUnsignedBixConversion,
        notifications,
        policy: {
          policy,
          loading,
          setChanged,
          changed,
          changePolicy,
          changeSegment,
          addSegment,
          previewPolicyChangeScrubIncidents,
          getRenewalPolicyId,
          confirm
        }
      }
    }
  } = useStore();

  const isLoading = loading || !policy;
  const policyStatus = isLoading ? null : getPolicyStatus(policy);
  const cancelled = policyStatus === PolicyStatus.InCancellation || policyStatus === PolicyStatus.Cancelled;

  const warnIfUnsignedConversion = () => {
    if (!getUnsignedBixConversion(policy?.id).signedDocUploadedInS3) {
      setIsBixConversionUnsigned(!getUnsignedBixConversion(policy?.id).signedDocUploadedInS3);
    }
  };

  const setLoadingState = () => {
    setLoadingPreview(true);
    setModal(ModalType.PREVIEW);
    setError({});
    setPreview(null);
    warnIfUnsignedConversion();
  };

  const closeModal = () => {
    setModal(null);
  };

  const handleError = ({ error }, dataToLog, defaultError) => {
    Sentry.captureException(
      JSON.stringify({
        res: error,
        ...dataToLog
      })
    );
    const errorToDisplay = error.networkError
      ? {
          messages: [
            'Our servers are not responding.',
            'You might have lost your internet connection, please check it.',
            'If not, just refresh this page.'
          ],
          label: 'Refresh',
          title: 'Whoops!',
          tryAgain: window.location.reload.bind(window.location)
        }
      : defaultError;
    setError(errorToDisplay);
    setModal(null);
    setAction(null);
  };

  const handleChangePolicy = useCallback(
    async (values) => {
      const currentPolicy = getPolicyStatus(policy);
      const newPolicy = getPolicyStatus(values);
      const action = getPolicyChange(currentPolicy, newPolicy);
      setLoadingState();
      const res = await changePolicy({
        ...values,
        additionalParties: formatAdditionalParties(values)
      });
      if (res.ok) {
        const { preview, reasons } = res;
        setPreview(preview);
        setReasons(reasons);
        setAction(action);
      } else {
        handleError(
          res,
          { values },
          {
            messages: [
              'Sorry about that. It looks like our systems are not able process a preview for the changes.',
              'Please try again'
            ],
            title: 'Settings preview error',
            label: 'Try Again',
            tryAgain: handleChangePolicy.bind(this, values)
          }
        );
        closeModal();
        setAction(null);
      }
      setLoadingPreview(false);
    },

    [policy, changePolicy]
  );

  const handleSegmentChange = useCallback(
    async (segment, changeType, initialValues, automatedFixesDescription, mismatchedDataDescription) => {
      setLoadingState();
      setAction(null);
      warnIfUnsignedConversion();
      const segmentChangeFunction = changeType === 'edit_change' ? changeSegment : addSegment;
      const policyId = policy.id;
      const hasSymbols = (car) => haveAnyOfThisCarsSymbolsChanged(car, initialValues.cars);
      if (segment.cars?.some((car) => hasSymbols(car))) {
        segment.cars.forEach((car) => {
          if (hasSymbols(car)) {
            const aux = car.symbolAux?.toUpperCase() || '  ';
            car.manuallyAddedSymbols = true;
            car.symbolMake = car.symbolMake?.toUpperCase();
            car.symbolModel = car.symbolModel?.toUpperCase();
            car.symbolStyle = car.symbolStyle?.toUpperCase();
            car.symbolAux = String(aux).length !== 2 ? aux : '  ';
          }
        });
      }
      const res = await segmentChangeFunction(policyId, segment);
      if (res.ok) {
        const { preview } = res;
        setPreview(preview);
        setDataDescriptions({
          automatedFixesDescription,
          mismatchedDataDescription
        });
      } else {
        handleError(
          res,
          { segment },
          {
            messages: [res.error.message],
            title: 'Something went wrong.',
            label: 'Try Again',
            tryAgain: handleSegmentChange.bind(this, segment, changeType, initialValues)
          }
        );
        setPreview(null);
        setModal(null);
      }
      setLoadingPreview(false);
    },
    [policy]
  );

  const handleChangeTab = useCallback(
    (e, tab) => {
      if (changed) {
        e.preventDefault();
        setPromptTab(tab);
      } else {
        setCurrentTab(tab);
      }
    },
    [changed, currentTab, promptTab]
  );

  const handlePreviewPolicyChangeScrubIncidents = useCallback(
    async (policyValue, incidents) => {
      setLoadingState();
      const res = await previewPolicyChangeScrubIncidents(policyValue, incidents);
      if (res.ok) {
        const { preview } = res;
        setPreview(preview);
      } else {
        handleError(
          res,
          { policyValue },
          {
            tryAgain: handlePreviewPolicyChangeScrubIncidents.bind(this, policyValue, incidents)
          }
        );
      }
      setLoadingPreview(false);
    },
    [previewPolicyChangeScrubIncidents]
  );

  const handleConfirm = useCallback(
    async (id, notes) => {
      setLoadingState();
      let res = await confirm(policy.id, id, notes);
      let hasError = !res.ok;
      if (res.ok) {
        res = await getRenewalPolicyId(policy.id);
        hasError = !res.ok;
        if (res.ok) {
          const { renewalPolicyId } = res;
          setModal(renewalPolicyId ? ModalType.RENEWAL : null);
          setRenewalPolicyId(renewalPolicyId);
          if (!getUnsignedBixConversion(policy.id).signedDocUploadedInS3) {
            toast.notify({
              type: 'success',
              message: 'Renewal policy document is successfully endorsed'
            });
          } else {
            toast.notify({
              type: 'success',
              message: 'The policy was updated successfully',
              label: "Go to Customer's Details",
              action: () => {
                history.push(`/customer/${accountId}`);
              }
            });
          }

          fetchFullAccountAndPolicy(policy.id, accountId);
        }
      }
      if (hasError) {
        handleError(
          res,
          { id, notes },
          {
            messages: [
              'Sorry about that. It looks like our systems are not able process the policy update.',
              res.error.message
            ],
            title: 'Confirmation error',
            label: 'Refresh page',
            tryAgain: window.location.reload.bind(window.location)
          }
        );
      }
      setLoadingPreview(false);
    },
    [accountId, policy, history]
  );

  return (
    <DisableProvider>
      {!isLoading && notifications && <PolicyNotifications onClick={() => setCurrentTab(PolicyTabs.DETAILS)} />}
      {(session.canEdit || session.viewOnly) && (
        <Tabs value={currentTab} variant="fullWidth" onChange={handleChangeTab}>
          <Tab label="Settings" className={classes.tab} value={PolicyTabs.SETTINGS} />
          <Tab label="Payment & Price" className={classes.tab} value={PolicyTabs.PAYMENT} />
          <Tab label="Details" className={classes.tab} value={PolicyTabs.DETAILS} />
          <Tab label="Documents" className={classes.tab} value={PolicyTabs.DOCUMENTS} />
          <Tab label="Incidents" className={classes.tab} value={PolicyTabs.INCIDENTS} />
        </Tabs>
      )}
      {/* Check styles for Section */}
      <Section className={classNames([classes.details, { [classes.withFooter]: changed }])}>
        {isLoading ? (
          <Loading type="secondary" />
        ) : (
          <>
            {currentTab === PolicyTabs.SETTINGS && (session.canEdit || session.viewOnly) && (
              <PolicySettings
                key={`version-${policy.version}`}
                policy={policy}
                accountId={accountId}
                loadingPreview={loadingPreview}
                onPreviewPolicy={handleChangePolicy}
                goToPaymentTab={() => setCurrentTab(PolicyTabs.PAYMENT)}
              />
            )}
            {currentTab === PolicyTabs.PAYMENT && (
              <PaymentTab loadingPreview={loadingPreview} handleChangePolicy={handleChangePolicy} />
            )}
            {currentTab === PolicyTabs.DETAILS && (
              <PolicyDetails
                key={`version-${policy.version}`}
                loadingPreview={loadingPreview}
                onSegmentPreview={handleSegmentChange}
                repEmail={session.user.email}
              />
            )}
            {(currentTab === PolicyTabs.DOCUMENTS || (!session.canEdit && !session.viewOnly)) && (
              <Documents showRecreateApplication={session.canEdit} />
            )}
            {currentTab === PolicyTabs.INCIDENTS && (
              <Incidents loadingPreview={loadingPreview} handleChangePolicy={previewPolicyChangeScrubIncidents} />
            )}
          </>
        )}
      </Section>

      {isBixConversionUnsigned && (
        <UnsignedBixConversionWarning
          open={isBixConversionUnsigned}
          onClose={() => {
            setIsBixConversionUnsigned(false);
          }}
        />
      )}

      {modal === ModalType.PREVIEW && (
        <Preview
          open={modal === ModalType.PREVIEW}
          preview={preview}
          loading={loadingPreview}
          onClose={closeModal}
          onConfirm={handleConfirm}
          action={action}
          policyType={policy.policyType}
          cancelReason={reasons.cancelReason}
          nonRenewReason={reasons.nonRenewReason}
          automatedFixesDescription={dataDescriptions.automatedFixesDescription}
          mismatchedDataDescription={dataDescriptions.mismatchedDataDescription}
        />
      )}
      {modal === ModalType.RENEWAL && (
        <RenewalNotice open={modal === ModalType.RENEWAL} onClose={closeModal} renewalPolicyId={renewalPolicyId} />
      )}
      <LeavePageDialog
        onClose={() => setPromptTab(null)}
        open={!!promptTab}
        cb={() => {
          setCurrentTab(promptTab);
          setPromptTab(null);
          setChanged(false);
        }}
      />
      <ErrorDialog
        open={!!error.messages}
        errors={error.messages}
        onClose={() => setError({})}
        label={error.label}
        onClick={error.tryAgain}
        title={error.title}
      />
      {currentTab !== PolicyTabs.SETTINGS && cancelled && (
        <CancelledPolicyFooter
          title={
            policyStatus === PolicyStatus.InCancellation
              ? 'This policy is pending cancellation.'
              : 'This policy is cancelled.'
          }
          isAgency={session.isAgency}
        />
      )}
    </DisableProvider>
  );
};

Policy.propTypes = {
  history: PropTypes.object.isRequired
};

export default observer(Policy);
