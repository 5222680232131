import { nodePathToFriendlyName, cancelCodeInformationalArray } from '@ourbranch/lookups';
import up from 'core/assets/svg/up.svg';
import down from 'core/assets/svg/down.svg';
import { toCapitalized } from 'core/helpers/formatters';

export function getPriceDetails({ newTotalPrice }, total) {
  if (newTotalPrice > total) {
    return {
      arrow: up,
      label: 'increase'
    };
  }
  if (newTotalPrice < total) {
    return {
      arrow: down,
      label: 'decrease'
    };
  }
  return {
    arrow: null,
    label: 'not change'
  };
}

export function parsePreviewChanges(changes) {
  const changedNodePaths = changes.split(',');
  return changedNodePaths.map((nodePath) => nodePathToFriendlyName[nodePath] ?? toCapitalized(nodePath)).join(', ');
}

export function determineboxTwoText(cancelReason) {
  return cancelCodeInformationalArray.find((cancelCodeObject) => cancelCodeObject.cancelCode === cancelReason)
    .boxTwoText;
}

export function canAddToInternalNote(cancelReason) {
  return !!cancelCodeInformationalArray.find((cancelCodeObject) => cancelCodeObject.cancelCode === cancelReason)
    .allowFreeFormNote;
}

export const getButtonLabel = (action) => {
  if (action === 'rescind') {
    return 'Rescind cancellation';
  }
  if (action === 'reinstate') {
    return 'Reinstate policy';
  }
  if (action === 'cancel') {
    return 'Cancel policy';
  }
  return 'Create a new version';
};
