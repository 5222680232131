import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(4, 0),
    gap: theme.spacing(2),
    fontWeight: 'normal'
  }
});

export default makeStyles(styles);
