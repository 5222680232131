import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import noop from 'lodash-es/noop';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import caret from '../../assets/svg/caretOrange.svg';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import { Card } from '../card';
import { CollapsibleCardNotification } from './collapsible-card-notification';

const noBorderClasses = ['noBorders', 'noBordersTop'];

class CollapsibleCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    className: PropTypes.string,
    contentRight: PropTypes.node,
    dataCy: PropTypes.string,
    initialOpen: PropTypes.bool,
    forceOpen: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'noBorders', 'noBordersTop'])
  };

  static defaultProps = {
    type: 'primary',
    dataCy: 'Collapsible',
    contentRight: null,
    initialOpen: false,
    onChange: noop,
    forceOpen: false,
    className: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.initialOpen
    };
  }

  toggle = () =>
    this.setState(
      ({ open }) => ({ open: !open }),
      () => this.props.onChange && this.props.onChange(this.state.open)
    );

  // this function can open a collapsible card "remotely" - added for New Construction checkbox on QuoteForms
  componentDidUpdate(prevProps) {
    if (this.props.forceOpen !== prevProps.forceOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: this.props.forceOpen });
    }
  }

  render() {
    const { state, toggle } = this;
    const { open } = state;
    const {
      classes,
      content,
      contentRight,
      children,
      dataCy,
      type,
      initialOpen,
      forceOpen,
      collapsibleCardNotification,
      className,
      ...props
    } = this.props;
    const noBorder = noBorderClasses.includes(type);

    return (
      <Collapse
        in={open}
        collapsedHeight={noBorder ? 70 : collapsibleCardNotification ? 105 : 80}
        className={classNames(classes.collapse, { [classes.noBorders]: noBorder })}
      >
        <Card
          className={classNames(classes.container, className, { [classes.containerOpen]: open })}
          type={type}
          {...props}
        >
          <IconButton
            classes={MapClassesToElem(ElemType.IconButton, classes)}
            onClick={toggle}
            data-cy={`${dataCy}-toggle`}
          >
            <img className={open ? classes.icon : classes.iconFold} src={caret} alt={open ? 'fold' : 'unfold'} />
          </IconButton>
          <Grid>
            <Grid className={classes.header}>
              {content && <Grid className={classes.content}>{content}</Grid>}
              {contentRight && <Grid className={classes.contentRight}>{contentRight}</Grid>}
            </Grid>
            {collapsibleCardNotification && (
              <CollapsibleCardNotification open={open}>{collapsibleCardNotification}</CollapsibleCardNotification>
            )}
          </Grid>
          {open && children}
        </Card>
      </Collapse>
    );
  }
}

export default CollapsibleCard;
