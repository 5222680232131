import { basementTypes } from '@ourbranch/lookups';

export const getUWPreBindInitialValues = (quote, selectedOption, state) => {
  if (!quote?.home) {
    return {};
  }
  const propertyUWValues = {
    initialValues: {
      signedPropertyUWAttestation: false,
      home: {},
      auto: {}
    },
    conditions: {}
  };

  if (state === 'FL') {
    // this should check for home and then add the values
    // materialUI expects strings for radio buttons
    if (selectedOption.includes('H')) {
      propertyUWValues.initialValues.home.isPrimaryHome =
        quote?.preBindUWCheck?.homeVerification?.isPrimaryHome?.toString() || 'true';
      propertyUWValues.initialValues.home.hasTrampolinesOrSkateBoardRampEtc =
        quote?.preBindUWCheck?.homeVerification?.hasTrampolinesOrSkateBoardRampEtc?.toString() || '';
      propertyUWValues.initialValues.home.hadRentedMultTimesOrFullTimeOneTenant =
        quote?.preBindUWCheck?.homeVerification?.hadRentedMultTimesOrFullTimeOneTenant?.toString() || '';
      propertyUWValues.initialValues.home.isOwnedByCorpOrPartnershipEtc =
        quote?.preBindUWCheck?.homeVerification?.isOwnedByCorpOrPartnershipEtc?.toString() || '';
      propertyUWValues.initialValues.home.isInForeclosure =
        quote?.preBindUWCheck?.homeVerification?.isInForeclosure?.toString() || '';
      propertyUWValues.initialValues.home.hasMoreThan2Mortgages =
        quote?.preBindUWCheck?.homeVerification?.hasMoreThan2Mortgages?.toString() || '';
      propertyUWValues.initialValues.home.hadPriorLossIn5Years =
        quote?.preBindUWCheck?.homeVerification?.hadPriorLossIn5Years?.toString() || '';
      propertyUWValues.initialValues.home.isPriorPolicyCancelledOrNonRenewed =
        quote?.preBindUWCheck?.homeVerification?.isPriorPolicyCancelledOrNonRenewed?.toString() || '';
      propertyUWValues.initialValues.home.isIneligibleDogBreed =
        quote?.preBindUWCheck?.homeVerification?.isIneligibleDogBreed?.toString() || '';
      propertyUWValues.initialValues.home.hasExoticOrNonDomesticatedEtcAnimals =
        quote?.preBindUWCheck?.homeVerification?.hasExoticOrNonDomesticatedEtcAnimals?.toString() || '';
      propertyUWValues.initialValues.home.hasPetBiteHistoryOrViciousBehavior =
        quote?.preBindUWCheck?.homeVerification?.hasPetBiteHistoryOrViciousBehavior?.toString() || '';
      propertyUWValues.initialValues.home.excludeWindCoverage =
        quote?.preBindUWCheck?.homeVerification?.excludeWindCoverage?.toString() || 'false';
      propertyUWValues.initialValues.home.excludeContentCoverage =
        quote?.preBindUWCheck?.homeVerification?.excludeContentCoverage?.toString() || 'false';
      propertyUWValues.initialValues.home.includeSinkholeCoverage =
        quote?.preBindUWCheck?.homeVerification?.includeSinkholeCoverage?.toString() || 'false';
      propertyUWValues.initialValues.home.hadSinkholeActivityOrIsAdjacentToSinkholes =
        quote?.preBindUWCheck?.homeVerification?.hadSinkholeActivityOrIsAdjacentToSinkholes?.toString() || '';
      propertyUWValues.initialValues.home.hasFlatRoofs =
        quote?.preBindUWCheck?.homeVerification?.hasFlatRoofs?.toString() || '';
      propertyUWValues.initialValues.home.isMobileOrPrefabricatedEtc =
        quote?.preBindUWCheck?.homeVerification?.isMobileOrPrefabricatedEtc?.toString() || '';
      propertyUWValues.initialValues.home.hasRecalledConstructionMaterials =
        quote?.preBindUWCheck?.homeVerification?.hasRecalledConstructionMaterials?.toString() || '';
      propertyUWValues.initialValues.home.isWaterHeaterAgeGreaterThan15Years =
        quote?.preBindUWCheck?.homeVerification?.isWaterHeaterAgeGreaterThan15Years?.toString() || '';

      propertyUWValues.initialValues.home.runDayCareOutOfMyHome =
        quote?.preBindUWCheck?.homeVerification?.runDayCareOutOfMyHome?.toString() || '';

      propertyUWValues.initialValues.home.hasCommercialOccupation =
        quote?.preBindUWCheck?.homeVerification?.hasCommercialOccupation?.toString() || '';

      propertyUWValues.initialValues.home.hasInsuranceCurrently =
        quote?.preBindUWCheck?.homeVerification?.hasInsuranceCurrently?.toString() || '';
    }

    if (selectedOption.includes('A')) {
      propertyUWValues.initialValues.auto.needSR22 =
        quote?.preBindUWCheck?.autoVerification?.needSR22?.toString() || 'false';
      propertyUWValues.initialValues.auto.excludeBICoverage =
        quote?.preBindUWCheck?.autoVerification?.excludeBICoverage?.toString() || 'false';
    }
    propertyUWValues.conditions.florida = true;

    return propertyUWValues;
  }

  //  siding conditions
  if (quote?.home.yearBuilt < 1960) {
    propertyUWValues.initialValues.home.noAsbestosCheck = 'No';
    propertyUWValues.conditions.noAsbestosCheck = true;
  }

  // electric and plumbing
  if (quote?.home?.yearBuilt < 1940) {
    // electric conditions
    propertyUWValues.initialValues.home.homeWiringCheck = 'Yes';
    propertyUWValues.conditions.homeWiringCheck = true;
    // plumbing conditions
    propertyUWValues.initialValues.home.homePlumbingCheck = 'Yes';
    propertyUWValues.conditions.homePlumbingCheck = true;
  }

  // raised foundation conditions
  if (quote?.home?.basementType === basementTypes.Raised) {
    propertyUWValues.initialValues.home.noOpenFoundationCheck = 'Yes';
    propertyUWValues.conditions.noOpenFoundationCheck = true;
  }

  return propertyUWValues;
};

export default getUWPreBindInitialValues;
