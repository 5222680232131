import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import caretOrange from '../../assets/svg/caretOrange.svg';
import caretGray from '../../assets/svg/caretGray.svg';
import calendar from '../../assets/svg/calendar.svg';
import styles from './select.styles';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';

const nativeIcons = {
  caretOrange,
  caretGray,
  calendar
};

const emptyLabelClasses = {
  light: 'empty'
};

const StyledSelect = memo(({ classes, mode, icon, options, onChange, showDefaultValue, ...props }) => {
  const expandIcon = ({ className }) => (
    <img
      src={icon ? nativeIcons[icon] : mode === 'dark' ? nativeIcons.caretOrange : nativeIcons.caretGray}
      alt="select"
      className={classNames(className, classes.caret)}
    />
  );
  const selectOptions = options || [];
  const { icons, ...fieldProps } = props;
  const { value } = fieldProps;

  return (
    <TextField
      select
      SelectProps={{
        disableUnderline: true,
        displayEmpty: true,
        classes: mode ? MapClassesToElem(`${mode}-${ElemType.TextField}`, classes) : null,
        IconComponent: expandIcon
      }}
      InputLabelProps={{
        classes: mode ? MapClassesToElem(`${mode}-${ElemType.Label}`, classes) : null,
        className: classNames({
          [classes[emptyLabelClasses[mode]]]: value === null && emptyLabelClasses[mode]
        }),
        shrink: value !== null
      }}
      InputProps={{
        classes: mode ? MapClassesToElem(`${mode}-${ElemType.Input}`, classes) : null
      }}
      onChange={(e) => onChange(e.target.name, e.target.value)}
      {...fieldProps}
    >
      {selectOptions.map((option) => (
        <MenuItem
          classes={{ selected: classes.selected, root: classes.select }}
          key={option.id}
          value={option.id}
          disabled={option.disabled}
        >
          {icons && icons[option.id] ? (
            <>
              <img className={classes.selectIcon} alt={option.id} src={icons[option.id]} />
              {option.value} {showDefaultValue && option.isDefault && '(Default)'}
            </>
          ) : (
            `${option.value} ${showDefaultValue && option.isDefault ? '(Default)' : ''}`
          )}
        </MenuItem>
      ))}
    </TextField>
  );
});

StyledSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.oneOf([null, 'light', 'dark']),
  className: PropTypes.string,
  icons: PropTypes.object,
  icon: PropTypes.oneOf([null, 'calendar', 'caretOrange', 'caretGray']),
  showDefaultValue: PropTypes.bool
};

StyledSelect.defaultProps = {
  mode: null,
  className: 'home',
  icons: undefined,
  icon: null,
  value: null,
  showDefaultValue: false
};

export default withStyles(styles)(StyledSelect);
