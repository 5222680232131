import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { paymentMethod as PaymentMethods, standaloneRentersStates, policyType as PolicyType } from '@ourbranch/lookups';

import { Label } from 'core';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import useDetailedPrice from 'offer/hooks/use-detailed-price';
import { getPolicyType } from 'core/helpers/policy-type';
import { nthDayFormatter, currencyFormatter, awsDateToStringDate } from 'core/helpers/formatters';
import useStyles from './payments-disclosures.styles';
import usePlanPrice from 'offer/hooks/use-plan-price';

const MortgageDisclosure = ({ mortgageDetails, amount }) => (
  <>
    invoice your mortgage lender <strong>{mortgageDetails.mortgageHolderName}</strong> in the amount of{' '}
    <strong>{amount}</strong> for your first policy term premium, and for the full premium of each future renewal term.
    You also authorize Branch Insurance to share information about your policy with your mortgage company as needed.{' '}
    <br />
    <br />
    We will contact you if your mortgage company fails to pay, and we may require you to provide further authorization
    as needed.
  </>
);

const CreditCardDisclosure = ({
  isMonthly,
  cardLast4,
  effectiveDate,
  amount,
  billingDayOfMonth,
  remainingPaymentsAmount,
  megaDownPay,
  schedule
}) => {
  return (
    <>
      charge your{' '}
      <strong>{cardLast4 ? `credit card ending in ${cardLast4} ` : '(Please fill credit card info) '}</strong>
      for the amount of{' '}
      <strong>
        {amount} on {effectiveDate}
      </strong>{' '}
      {isMonthly && (
        <>
          for the initial payment of your policy and{' '}
          <strong>
            {remainingPaymentsAmount} on the {nthDayFormatter(billingDayOfMonth)} of each month
            {megaDownPay ? ` starting on ${awsDateToStringDate(schedule[1].date)}` : ''}
          </strong>{' '}
          for your current policy term. You also authorize Branch Insurance to charge this method of payment on the{' '}
          <strong>{nthDayFormatter(billingDayOfMonth)} of each month</strong> for each subsequent policy renewal. You
          will receive an email reminder 48-hours in advance of your payments and you can change your method of payment
          at any time through your Branch account or on our mobile app.
        </>
      )}
      {!isMonthly && (
        <>
          for the full amount of your current policy term and for the full amount of each subsequent policy renewal. You
          will receive an email reminder 48-hours in advance of your payments, and you can change your method of payment
          at any time through your Branch account or on our mobile app.
        </>
      )}
    </>
  );
};

const ACHDisclosure = ({
  isMonthly,
  effectiveDate,
  amount,
  billingDayOfMonth,
  remainingPaymentsAmount,
  megaDownPay,
  schedule
}) => (
  <>
    debit your account for the amount of{' '}
    <strong>
      {amount} on {effectiveDate}
    </strong>{' '}
    {isMonthly && (
      <>
        for the initial payment of your policy and{' '}
        <strong>
          {remainingPaymentsAmount} on the {nthDayFormatter(billingDayOfMonth)} of each month
          {megaDownPay ? ` starting on ${awsDateToStringDate(schedule[1].date)}` : ''}
        </strong>{' '}
        for your current policy term. You also authorize Branch Insurance to charge this method of payment on the{' '}
        <strong>{nthDayFormatter(billingDayOfMonth)} of each month</strong> for each subsequent policy renewal. You will
        receive an email reminder 48-hours in advance of your payments, and you can change your method of payment at any
        time through your Branch account or on our mobile app.
      </>
    )}
    {!isMonthly && (
      <>
        for the full amount of your current policy term and for the full amount of each subsequent policy renewal. You
        will receive an email reminder 48-hours in advance of your payments, and you can change your method of payment
        at any time through your Branch account or on our mobile app.
      </>
    )}
  </>
);

const Disclosure = ({ paymentMethod, ...props }) => (
  <>
    you authorize Branch Insurance to {paymentMethod === PaymentMethods.Escrow && <MortgageDisclosure {...props} />}
    {paymentMethod === PaymentMethods.CreditCard && <CreditCardDisclosure {...props} />}
    {paymentMethod === PaymentMethods.ACH && <ACHDisclosure {...props} />}
  </>
);

const PaymentsDisclosures = ({ policyType, offer }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { hasHome, hasAuto, hasCondo } = getPolicyType(policyType, offer?.quote?.noBindHome, offer?.quote?.noBindAuto);

  const {
    homeownersPaymentMethod,
    homeEffectiveDate,
    homeBillingDayOfMonth,
    autoPaymentMethod,
    autoBillingDayOfMonth,
    autoEffectiveDate,
    rentersPaymentMethod,
    rentersEffectiveDate,
    rentersBillingDayOfMonth,
    mortgageDetails,
    cardLast4,
    recoveredPaymentData,
    condoPaymentMethod,
    condoBillingDayOfMonth,
    condoEffectiveDate,
    autoMegaDownPay,
    homeMegaDownPay,
    rentersMegaDownPay,
    condoMegaDownPay
  } = values;

  const prices = usePlanPrice(offer, policyType, useDetailedPrice);

  const commonProps = {
    mortgageDetails,
    cardLast4: cardLast4 || recoveredPaymentData?.creditCard?.card.last4
  };

  const homeProps = {
    ...commonProps,
    paymentMethod: homeownersPaymentMethod,
    isMonthly: prices.isHomeMonthly,
    effectiveDate: homeEffectiveDate,
    billingDayOfMonth: homeBillingDayOfMonth,
    amount: currencyFormatter(prices.homePrice),
    remainingPaymentsAmount: prices.isHomeMonthly ? currencyFormatter(prices.homeRemainingPaymentsAmount) : 0,
    megaDownPay: homeMegaDownPay,
    schedule: prices.homeNextPayments
  };

  const autoProps = {
    ...commonProps,
    paymentMethod: autoPaymentMethod,
    isMonthly: prices.isAutoMonthly,
    billingDayOfMonth: autoBillingDayOfMonth,
    effectiveDate: autoEffectiveDate,
    amount: currencyFormatter(prices.autoPrice),
    remainingPaymentsAmount: prices.isAutoMonthly ? currencyFormatter(prices.autoRemainingPaymentsAmount) : 0,
    megaDownPay: autoMegaDownPay,
    schedule: prices.autoNextPayments
  };

  const rentersProps = {
    ...commonProps,
    paymentMethod: rentersPaymentMethod,
    isMonthly: prices.isRentersMonthly,
    billingDayOfMonth: rentersBillingDayOfMonth,
    effectiveDate: rentersEffectiveDate,
    amount: currencyFormatter(prices.rentersPrice),
    remainingPaymentsAmount: prices.isRentersMonthly ? currencyFormatter(prices.rentersRemainingPaymentsAmount) : 0,
    megaDownPay: rentersMegaDownPay,
    schedule: prices.rentersNextPayments
  };

  const condoProps = {
    ...commonProps,
    paymentMethod: condoPaymentMethod,
    isMonthly: prices.isCondoMonthly,
    billingDayOfMonth: condoBillingDayOfMonth,
    effectiveDate: condoEffectiveDate,
    amount: currencyFormatter(prices.condoPrice),
    remainingPaymentsAmount: prices.isCondoMonthly ? currencyFormatter(prices.condoRemainingPaymentsAmount) : 0,
    condoMegaDownPay,
    schedule: prices.condoNextPayments
  };

  const disclosureMapper = (type) => {
    switch (type) {
      case PolicyType.Home: {
        if (hasHome) {
          return (
            <li key={type}>
              <>For your home policy, </>
              <Disclosure {...homeProps} />
            </li>
          );
        }
        break;
      }
      case PolicyType.Auto: {
        if (hasAuto) {
          return (
            <li key={type}>
              <>For your auto policy, </>
              <Disclosure {...autoProps} />
            </li>
          );
        }
        break;
      }
      case PolicyType.Renters: {
        if (standaloneRentersStates[offer.quote.correctedAddress.state]) {
          return (
            <li key={type}>
              <>For your renters policy, </>
              <Disclosure {...rentersProps} />
            </li>
          );
        }
        break;
      }
      case PolicyType.Condo: {
        if (hasCondo) {
          return (
            <li key={type}>
              <>For your condo policy, </>
              <Disclosure {...condoProps} />
            </li>
          );
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Section title="Payments Disclosures" type="SubSection">
      <Card type="primary">
        <Grid container item xs={12} className={classes.container}>
          <Label type="greenSmall" className={classes.title}>
            Please go through these carefully with the potential member
          </Label>
          <ul className={classes.list}>{policyType.split('').map(disclosureMapper)}</ul>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <FormField
            name="authorizePayments"
            type="checkbox"
            label="Insured member authorizes these payments"
            mode="light"
            fast={false}
          />
        </Grid>
      </Card>
    </Section>
  );
};

PaymentsDisclosures.props = {
  policyType: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired
};

export default PaymentsDisclosures;
