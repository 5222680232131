import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  getOptions,
  HighestEducation,
  windstormMitigationDiscountStates,
  stormShuttersStates,
  enhancedRoofWindstormStates,
  hideHighestEducationStates,
  windstormMitigationDiscountYearBuilt,
  stormShuttersInAdvancedWindstormSectionStates
} from '@ourbranch/lookups';

import CollapsibleCard from 'core/components/collapsible-card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Section from 'core/components/section';
import FormList from 'core/components/form-list';
import withDatePicker from 'core/components/with-date-picker';
import { useCurrentState } from 'common/hooks/useCurrentState';
import { AddMortgage, MortgageItem } from '../../mortgage-details';
import { DwellingAddress } from 'common/components/dwelling-address';
import AdvancedWindstormDetails from './advanced-windstorm-details/advanced-windstorm-details';
import { StormShutters } from './storm-shutters';
import useStyles from './detail.styles';

const id = 'home';

const Detail = memo(({ disabled, fromPolicy }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { home } = values;
  const state = useCurrentState(values);
  const showStormShutters = stormShuttersStates.includes(home.homeLocation.state);
  const showEnhancedRoofWindstorm = enhancedRoofWindstormStates[state];
  const showWindstormMitigationLevel =
    windstormMitigationDiscountStates[state] &&
    (!home.yearBuilt || home.yearBuilt < windstormMitigationDiscountYearBuilt);
  const showAdvancedWindstormDetails = showEnhancedRoofWindstorm || showWindstormMitigationLevel || showStormShutters;
  const showStormShuttersInAdvancedSection =
    stormShuttersStates.includes(state) && stormShuttersInAdvancedWindstormSectionStates.includes(state);
  const showStormShuttersInRoofSection = stormShuttersStates.includes(state) && !showStormShuttersInAdvancedSection;

  useEffect(() => {
    if (home.mortgageDetails.length > 0) {
      if (!home.mortgageDetails.find((item) => item.primary)) {
        setFieldValue(`${id}.mortgageDetails.0`, { ...home.mortgageDetails[0], primary: true });
      }
    } else {
      setFieldValue('primaryMortgageDetail.mortgageHolderAddress', {
        city: '',
        state: '',
        zip: '',
        address: '',
        address2: ''
      });
      setFieldValue('primaryMortgageDetail.mortgageHolderName', '');
      setFieldValue('primaryMortgageDetail.mortgageHolderId', '');
      setFieldValue('primaryMortgageDetail.loanNumber', '');
    }
  }, [home.mortgageDetails, home.mortgageDetails.length, setFieldValue]);

  if (!home) {
    return null;
  }

  return (
    <Section title="Home detail" type="SubSection">
      <CollapsibleCard
        initialOpen
        content={
          <DwellingAddress
            dwelling="Home"
            fieldName="home.homeLocation"
            location={home.homeLocation}
            latitude={home.latitude}
            longitude={home.longitude}
          />
        }
      >
        <Grid container justify="space-around" className={classes.containerDark}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Residents</Label>
            </Grid>
            <FormField
              name={`${id}.ownershipStatus`}
              type="select"
              label="Ownership"
              mode="light"
              options={getOptions('ownershipStatus')}
              xs={6}
            />
            <FormField
              name={`${id}.purchaseDate`}
              type="date"
              label="House Purchased"
              mode="light"
              xs={3}
              disableFuture={false}
              maxDate={addDays(new Date(), 60)}
            />
            <FormField
              name={`${id}.numOccupants`}
              type="numeric"
              label="Residents"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />

            {!hideHighestEducationStates.includes(state) && (
              <FormField
                name="highestEducation"
                type="value"
                label="Highest Education of Residents (non-editable)"
                value={HighestEducation[values.highestEducation] || 'N/A'}
                xs={6}
              />
            )}
            <FormField
              name="oldestResident"
              type="value"
              label="Age of Oldest resident (non-editable)"
              value={values.oldestResident || 'N/A'}
              xs={hideHighestEducationStates.includes(state) ? 12 : 6}
            />
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Property</Label>
            </Grid>
            <FormField
              name={`${id}.yearBuilt`}
              type="numeric"
              label="Built in"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.typeOfHome`}
              type="select"
              label="Home Type"
              mode="light"
              options={getOptions('typeOfHome')}
              xs={3}
            />
            <FormField
              name={`${id}.numFamiliesInDwelling`}
              type="numeric"
              label="Families in Dwelling"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.homeQuality`}
              type="select"
              label="Home Quality"
              mode="light"
              options={getOptions('homeQuality')}
              xs={3}
            />
            <FormField
              name={`${id}.sqFt`}
              type="numeric"
              label="Above-Ground Square Footage"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={6}
            />
            <FormField
              name={`${id}.numStories`}
              type="numeric"
              label="Number of Stories"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.constructionType`}
              type="select"
              label="Construction Type"
              mode="light"
              options={getOptions('constructionType')}
              xs={3}
            />
            <FormField
              name={`${id}.numHalfBathrooms`}
              type="numeric"
              label="Number of Half Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numFullBathrooms`}
              type="numeric"
              label="Number of Full Bathrooms"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numFireplaces`}
              type="numeric"
              label="Number of Fireplaces"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.numWoodBurningStoves`}
              type="numeric"
              label="Number of Wood Stoves"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.basementType`}
              type="select"
              label="Basement Type"
              mode="light"
              options={getOptions('basementType')}
              xs={3}
            />
            <FormField
              name={`${id}.basementSqFt`}
              type="numeric"
              label="Basement Square Footage"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={6}
            />
            <FormField
              name={`${id}.exteriorWallType`}
              type="select"
              label="Exterior Wall Type"
              mode="light"
              options={getOptions('exteriorWallType')}
              xs={6}
            />
            <FormField
              name={`${id}.garageType`}
              type="select"
              label="Garage Type"
              mode="light"
              options={getOptions('garageType')}
              xs={3}
            />
            <FormField
              name={`${id}.garageCarCapacity`}
              type="numeric"
              label="Garage's car space"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={3}
            />
            <FormField
              name={`${id}.roofYear`}
              type="numeric"
              label="Roof Year"
              mode="light"
              format={{
                allowNegative: false
              }}
              xs={4}
            />
            <FormField
              name={`${id}.roofType`}
              type="select"
              label="Roof Type"
              mode="light"
              options={getOptions('roofType')}
              xs={4}
            />
            <FormField
              name={`${id}.roofShape`}
              type="select"
              label="Roof Shape"
              mode="light"
              options={getOptions('roofShape')}
              xs={4}
            />
            {showStormShuttersInRoofSection && <StormShutters />}
            {showStormShuttersInRoofSection && showAdvancedWindstormDetails && (
              <AdvancedWindstormDetails fromPolicy={fromPolicy} />
            )}
            {home.fpcFromLocation?.includes('/') && (
              <FormField
                name={`${id}.fireHydrantWithin1000ft`}
                id={`${id}.fireHydrantWithin1000ft`}
                type="checkbox"
                label="Fire hydrant within 1,000 feet?"
                mode="light"
                xs={12}
              />
            )}
            <FormField
              name={`${id}.fpc`}
              type="value"
              label="Fire Protection Class (non-editable)"
              value={home.fpc || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.wildfireHazardScore`}
              type="value"
              label="Wildfire Hazard Score (non-editable)"
              value={home.wildfireHazardScore || 'N/A'}
              xs={6}
            />
            <FormField
              name={`${id}.dtoName`}
              type="value"
              label="Distance to Ocean (non-editable)"
              value={home.dtoName || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowLabel`}
              type="value"
              label="Nearest BOW (non-editable)"
              value={home.dtbowLabel || 'N/A'}
              xs={4}
            />
            <FormField
              name={`${id}.dtbowName`}
              type="value"
              label="Distance to BOW (non-editable)"
              value={home.dtbowName || 'N/A'}
              xs={4}
            />
            {!showStormShuttersInRoofSection && showAdvancedWindstormDetails && (
              <AdvancedWindstormDetails fromPolicy={fromPolicy} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.mortgagesContainer}>
          <FormList
            classes={{ container: classes.container, rightValue: classes.sectionRightValue }}
            disabled={disabled}
            id={`${id}.mortgageDetails`}
            item={MortgageItem}
            type="InnerSection"
            addForm={AddMortgage}
            title="Mortgage Details"
            singular="Mortgage"
            plural="Mortgages"
          />
        </Grid>
      </CollapsibleCard>
    </Section>
  );
});

Detail.propTypes = {
  disabled: PropTypes.bool,
  fromPolicy: PropTypes.bool
};

Detail.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default withDatePicker(Detail);
