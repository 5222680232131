import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    padding: theme.spacing(6, 8),
    borderRadius: theme.sizes.roundCorners
  },
  checkboxContainer: {
    margin: 'auto'
  }
});

export default makeStyles(styles);
