import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  excluded: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default makeStyles(styles);
