/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter, isBefore, isToday } from 'date-fns';
import { standaloneRentersStates } from '@ourbranch/lookups';

// Effective date for SA Renters policies must be after October 1st, 2022
const standaloneRentersLimitDate = new Date(2022, 9, 1);
const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const schema = Yup.object().shape({
  global: Yup.object()
    .shape({
      homeEffectiveDate: Yup.date()
        .test('min', 'Policy start date cannot be in the past or before the home purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('H') && !this.options.context?.noBindHome) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateHome =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateHome, date);
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('H') && !this.options.context?.noBindHome) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      autoEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('min', 'Policy start date cannot be in the past', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const now = new Date();
            const date = new Date(obj);
            return isToday(date) || isBefore(now, date);
          }
          return true;
        }),
      rentersEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (
            this.options.context?.selectedOption?.includes('R') &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('standalone-renters-min', 'Policy start date must be after 10/1/2022', function (obj) {
          if (
            this.options.context?.selectedOption?.includes('R') &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date >= standaloneRentersLimitDate;
          }
          return true;
        })
        .test('min', 'Policy start date cannot be in the past', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const now = new Date();
            const date = new Date(obj);
            return isToday(date) || isBefore(now, date);
          }
          return true;
        }),
      condoEffectiveDate: Yup.date()
        .nullable()
        .test('min', 'Policy start date cannot be in the past or before the condo purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const purchaseDate = this.options.context?.condo?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateCondo =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateCondo, date);
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
    })
    .nullable()
});

export default schema;
