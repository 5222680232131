import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  item: {
    alignItems: 'center',
    backgroundColor: 'unset',
    borderTop: `solid 1px ${theme.colorPalette.white_30_op_30}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4)
  }
});

export default makeStyles(styles);
