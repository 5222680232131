/* eslint-disable func-names */
import * as Yup from 'yup';
import {
  aopDeductibleIsAlwaysPercent,
  noWindHailDeductibleStates,
  paymentMethod,
  windHailDeductibleCanVaryByCounty
} from '@ourbranch/lookups';

import { validAffinityCode } from 'common/helpers/yup-helpers';
import { umbrellaValidationSchema } from './umbrella.validation-schema';
import { connectedHomeValidationSchema } from './connected-home.validation-schema';

Yup.addMethod(Yup.mixed, 'validAffinityCode', validAffinityCode);

const min = (minVal) => (val) => val >= minVal;

export const homeValidationSchema = ({
  policies,
  policy,
  isAdvancedConnectedHome,
  savedSegment,
  session,
  affinityLookups,
  autoPolicyDetails
}) => {
  const baseSchema = Yup.object().shape({
    home: Yup.object().shape({
      roofYear: Yup.number().test('roofYearMin', 'Roof year must be higher than 1899', min(1900)),
      yearBuilt: Yup.number().test('yearBuiltMin', 'Year built must be higher than 1899', min(1900)),
      mortgageDetails: Yup.array(
        Yup.object().shape({
          mortgageHolderName: Yup.string()
            .trim()
            .required('Required. If trying to remove this mortgage, use the Trash button.'),
          mortgageHolderAddress: Yup.object().shape({
            address: Yup.string().trim().required('Required'),
            address2: Yup.string().nullable(),
            city: Yup.string().trim().required('Required'),
            state: Yup.string().trim().required('Required'),
            zip: Yup.string().trim().required('Required')
          }),
          loanNumber: Yup.string().trim().required('Required')
        })
      )
        .nullable()
        .test(
          'atLeastOneRequired',
          'At least one mortgage details required because payment method is mortgage',
          function (mortgageList) {
            if (policy.paymentMethod === paymentMethod.Escrow) {
              return mortgageList?.length > 0;
            }
            return true; // force pass because we don't care about length of mortgage list if not paying by mortgage
          }
        )
    }),
    homeCoverage: Yup.object().shape({
      coverageA: Yup.number().test(
        'coverageA',
        'Error, dwelling limit value cannot be less than estimated cost to rebuild home.',
        function (
          currentCoverageA,
          {
            options: {
              context: { home }
            }
          }
        ) {
          return home.replacementCostEstimate.total <= currentCoverageA;
        }
      ),
      coverageCourseOfConstruction: Yup.boolean(),
      coverageFairRental: Yup.boolean().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.boolean().test('coverageFairRental', 'Active construction. Set to inactive', (val) => !val),
        otherwise: Yup.boolean().required()
      }),
      coverageBCPctOfA: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageBCPctOfA', 'Active construction. Set to 0', (val) => val === 0),
        otherwise: Yup.number().required()
      }),
      coverageIBSC: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageIBSC', 'Active construction. Must be < 20%', (val) => val < 20),
        otherwise: Yup.number()
      }),
      coverageDC: Yup.boolean().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.boolean().test('coverageDC', 'Active construction. Set to inactive', (val) => !val),
        otherwise: Yup.boolean()
      }),
      coverageWaterBackup: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageWaterBackup', 'Active construction. Set to 0', (val) => val < 20),
        otherwise: Yup.number()
      }),
      deductibleWindHail: Yup.number()
        .nullable()
        .test(
          'CompareToAllOtherPerils',
          'Wind & Hail Deductible must be greater than or equal to all other perils deductible',
          function (value) {
            const state = this.options.context.state;
            const county = this.options.context.home.county.toUpperCase();
            const windHailInState =
              (!windHailDeductibleCanVaryByCounty[state] ||
                (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][county])) &&
              !noWindHailDeductibleStates[state];

            if (windHailInState) {
              const windHailIsPercent = value < 1;
              const coverageA = this.options.context.homeCoverage?.coverageA;
              const allOtherPerilsIsPercent = this.options.context.homeCoverage?.deductibleAllOther < 1;

              // if value is a %, we need to multiply it by coverage A to see the deductible amount
              const allOtherPerilsAmount = allOtherPerilsIsPercent
                ? this.options.context.homeCoverage?.deductibleAllOther * coverageA
                : this.options.context.homeCoverage?.deductibleAllOther;
              const windHailAmount = windHailIsPercent ? value * coverageA : value;

              return windHailAmount >= allOtherPerilsAmount;
            }
            return true;
          }
        ),
      deductibleAllOther: Yup.number()
        .nullable()
        .test(
          'WindHailPercentageMin',
          'All Other Perils deductible must be a percentage for this state',
          function (value) {
            if (aopDeductibleIsAlwaysPercent[this.options.context?.state] && value > 1) {
              return false;
            }
            return true;
          }
        )
    }),
    scheduledPersonalProperty: Yup.object()
      .shape({
        items: Yup.array(Yup.object()),
        deductible: Yup.mixed().test('deductible', 'SPP Deductible is required', function (value) {
          return !(this.parent.items && this.parent.items.length && !value);
        })
      })
      .nullable(),
    connectedHome: Yup.object().when('homeCoverage', {
      is: (val) => val.coverageCourseOfConstruction,
      then: Yup.object().test(
        'connectedHome',
        'Active construction. Cannot add Connected Home Discount',
        (val) => val.monitored === 'N'
      ),
      otherwise: connectedHomeValidationSchema(isAdvancedConnectedHome)
    }),
    global: Yup.object().shape({
      affinity: Yup.string()
        .validAffinityCode('global.affinity', { affinityLookups, isAgency: session.isAgency })
        .nullable()
    })
  });

  return baseSchema.concat(umbrellaValidationSchema({ policy, savedSegment, autoPolicyDetails }));
};
