import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3)
  },
  mortgages: {
    padding: theme.spacing(0, 6, 3, 8),
    backgroundColor: theme.colors.card_background_primary_editable,
    borderTop: `1px solid ${theme.colors.white}`
  }
});

export default makeStyles(styles);
