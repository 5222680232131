import React from 'react';
import { observer } from 'mobx-react';
import { lookupsJson } from '@ourbranch/lookups';

import { useStore } from 'core/store/store.mobx';
import Driver from 'common/components/people/driver';
import DriverActions from 'common/components/people/driver-actions';

const OfferDriver = (props) => {
  const { item } = props;
  const { offer: offerStore } = useStore();
  const { state } = offerStore;

  const maritalStatusOptions = lookupsJson.maritalStatus.filter((option) => {
    return option.id !== 'W';
  });

  const onRecheckDrivingRecord = (e) => {
    // eslint-disable-next-line no-unused-expressions
    offerStore.drivers.get(item.id)?.toggleWillRecheckDrivingRecord();
  };

  // everyone who is allowed to edit an offer should be able to exclude
  // however once a driver is saved as excluded, then we don't allow un-excluding
  const driverSavedAsExcluded = offerStore.offer?.quote?.drivers.find((d) => d.id === item.id)?.excludeDriver;
  const hasPermissionToExcludeDriver = !driverSavedAsExcluded;

  return (
    <Driver
      {...props}
      state={state}
      onRecheckDrivingRecord={onRecheckDrivingRecord}
      maritalStatusOptions={maritalStatusOptions}
      actions={
        <DriverActions
          driver={item}
          formikFieldPath={props.fieldName}
          state={state}
          hasPermissionToExcludeDriver={hasPermissionToExcludeDriver}
          {...props}
        />
      }
    />
  );
};

export default observer(OfferDriver);
