import React from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext, yupToFormErrors, Form } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { useConnectedHomeSecurityProviders } from 'common/components/discounts/hooks/connected-home.hooks';
import { ConnectedHomeFormFields } from 'common/components/discounts/connected-home-form-fields';
import { connectedHomeSchema } from './connected-home.validation-schema';
import useStyles from './connected-home-form.styles';

/*
Read about the business logic behind Connected Home here:
https://www.notion.so/branch/All-About-Affinity-and-Connected-Home-Discounts-c4f5abad218049e0a37991cd60157f41
*/

const ConnectedHomeFormContent = observer(({ mode, classes }) => {
  const { offer: offerStore, affinityLookups: affinityLookupsStore } = useStore();

  const isAdvancedConnectedHome = offerStore?.isAdvancedConnectedHome;

  // Get offer specific providers and pass it as prop to avoid offer logic in common component
  const securityProviderOptions = useConnectedHomeSecurityProviders({ isAdvancedConnectedHome }, affinityLookupsStore);

  return (
    <Form>
      <ConnectedHomeFormFields mode={mode} securityProviderOptions={securityProviderOptions} />
      <Grid container justify="flex-start" alignItems="center">
        <Button className={classes.applyDiscountBtn} mode="big" variant="contained" color="secondary" type="submit">
          Apply Discount
        </Button>
      </Grid>
    </Form>
  );
});

const ConnectedHomeForm = observer(({ mode }) => {
  const { offer: offerStore } = useStore();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const classes = useStyles();

  const isAdvancedConnectedHome = offerStore.isAdvancedConnectedHome;

  const initialValues = {
    global: {
      homeSecurityPartnerCustomerType: values?.global?.homeSecurityPartnerCustomerType,
      affinity: values?.global?.affinity
    },
    connectedHome: {
      autoWaterShutoffDevices: values?.connectedHome?.autoWaterShutoffDevices ?? false,
      moistureDevices: values?.connectedHome?.moistureDevices,
      monitored: values?.connectedHome?.monitored,
      motionDetectingDevices: values?.connectedHome?.motionDetectingDevices,
      providerName: values?.connectedHome?.providerName,
      smokeDetectingDevices: values?.connectedHome?.smokeDetectingDevices,
      theftPreventionDevices: values?.connectedHome?.theftPreventionDevices,
      agreeToTerms: values?.connectedHome?.agreeToTerms ?? false
    }
  };

  const handleSubmit = (modalValues) => {
    offerStore.setShowConnectedHomeModal(false);
    Object.keys(modalValues.global).forEach((key) => {
      setFieldValue(`global.${key}`, modalValues.global[`${key}`]);
      setFieldTouched(`global.${key}`);
    });
    Object.keys(modalValues.connectedHome).forEach((key) => {
      setFieldValue(`connectedHome.${key}`, modalValues.connectedHome[`${key}`]);
      setFieldTouched(`connectedHome.${key}`);
    });
  };

  const validate = async (modalValues) => {
    try {
      await connectedHomeSchema(isAdvancedConnectedHome).validate(modalValues, {
        context: modalValues,
        abortEarly: false
      });
    } catch (error) {
      const formErrors = yupToFormErrors(error);

      // disabling eslint for the console log so we can monitor validation errors in prod
      // eslint-disable-next-line
      console.log({ formErrors });
      return formErrors;
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      <ConnectedHomeFormContent mode={mode} classes={classes} />
    </Formik>
  );
});

ConnectedHomeForm.propTypes = {
  mode: PropTypes.string.isRequired
};

export default ConnectedHomeForm;
