export const policyTypes = {
  // monolines
  Home: 'H',
  Auto: 'A',
  Renters: 'R',
  Condo: 'C',
  // bundles
  ARBundle: 'AR',
  HABundle: 'HA',
  CABundle: 'CA'
};
