interface InsuranceProviderContact {
  priorCompany: string;
  email1?: string;
  email2?: string;
  fax1?: string;
  fax2?: string;
  fax3?: string;
  fax4?: string;
  needsAdditionalZendeskTicket: boolean;
  MailingAddress?: string;
  Phone?: string;
  specialInstructions?: string;

  /**  @description  used in the search rankings. 1 is default, higher weight means it will score higher in search */
  weight?: number;

  /**
   * @description to help with matching, we remove the "insurance"
   */
  shortName?: string;
}

export const insuranceProviderContact: InsuranceProviderContact[] = [
  {
    priorCompany: ' ALLSTATE INDEMNITY C',
    email1: 'support@allstate.com',
    email2: '',
    fax1: '866-546-9509',
    fax2: '847-326-5199',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: '21st Century Insurance',
    email1: 'service@21st.com',
    email2: '',
    fax1: '800-842-3057',
    fax2: '866-447-2611',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AAA',
    email1: 'correspondence@acg.aaa.com',
    email2: 'support@aaa.com',
    fax1: '877-489-5696',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AAA Texas',
    email1: '',
    email2: '',
    fax1: '714-754-4759',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AAA/CSAA',
    email1: 'correspondence@acg.aaa.com',
    email2: '',
    fax1: '877-489-5696',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AARP - Republic',
    email1: '',
    email2: '',
    fax1: '833-259-2277',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Absolute Insurance Solutions',
    email1: 'info@aisiteam.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Acadia Insurance',
    email1: '',
    email2: '',
    fax1: '207-772-6104',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ACCC Insurance Co.',
    email1: '',
    email2: '',
    fax1: '888-823-0888',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Acceptance Casualty Insurance',
    email1: '',
    email2: '',
    fax1: '402-342-0096',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Access General',
    email1: '',
    email2: '',
    fax1: '770-234-3738',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Access Home Insurance Co.',
    email1: 'marketing@accesshomeinsurance.com',
    email2: '',
    fax1: '225-246-2167',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Accident Fund Insurance',
    email1: '',
    email2: '',
    fax1: '517-367-2925',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Accident Insurance Co.',
    email1: 'PolicySupport@AccidentFund.com',
    email2: '',
    fax1: '866-206-5851',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ACE',
    email1: '',
    email2: '',
    fax1: '215-640-1000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Acuity A Mutual Insurance Company',
    email1: '',
    email2: '',
    fax1: '920-458-1618',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Addison Insurance Company',
    email1: 'doingbusiness@unitedfiregroup.com',
    email2: '',
    fax1: '319-286-2512',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Admiral Insurance',
    email1: '',
    email2: '',
    fax1: '480-281-0910',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Adriatic Insurance',
    email1: '',
    email2: '',
    fax1: '504-832-0605',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aegis Security Insurance Co.',
    email1: '',
    email2: '',
    fax1: '717-657-0340',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Affiliates Insurance Company',
    email1: '',
    email2: '',
    fax1: '617-796-8359',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Affinity Mutual Insurance',
    email1: '',
    email2: '',
    fax1: '419-224-4874',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Agency Insurance Co. of MD Inc',
    email1: 'customerservice@aiconline.com',
    email2: '',
    fax1: '800-492-5629',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Agents Mutual Insurance Co.',
    email1: 'agentsmutualins@cablelynx.com ',
    email2: '',
    fax1: '870-535-2690',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aggressive Insurance',
    email1: 'contact.uw@aggressiveusa.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Agri General Insurance',
    email1: '',
    email2: '',
    fax1: '515-559-1001',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AgWorkers Insurance',
    email1: 'info@agworkers.com',
    email2: '',
    fax1: '800-772-7424',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AIC Co of MD',
    email1: 'customerservice@aiconline.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AIG Property and Casualty Company',
    email1: '',
    email2: '',
    fax1: '212-770-0764',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AIG Property Casualty U.S. Inc',
    email1: '',
    email2: '',
    fax1: '212-770-7000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AIMCO Mutual Insurance',
    email1: '',
    email2: '',
    fax1: '336-605-7518',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aioi Nissay Dowa Ins Co. Ltd.',
    email1: '',
    email2: '',
    fax1: '09-394-9000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AIX Speciality Insurance',
    email1: '',
    email2: '',
    fax1: '508-853-6332',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: "Alamance Farmers' Mutl Ins Co.",
    email1: 'info@afmic.net',
    email2: '',
    fax1: '336-226-7872',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Alfa Mutual',
    email1: '',
    email2: '',
    fax1: '334-613-4427',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Align General',
    email1: '',
    email2: '',
    fax1: '619-702-7077',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Alinsco Insurance Co.',
    email1: 'policyservice@alinsco.com',
    email2: '',
    fax1: '817-231-4301',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'All America Insurance',
    email1: '',
    email2: '',
    fax1: '419-238-7626',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Allegany Insurance',
    email1: '',
    email2: '',
    fax1: '585-968-6021',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ALLEGIANT',
    email1: '',
    email2: '',
    fax1: '808-585-3513',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Alliance United',
    email1: '',
    email2: '',
    fax1: '620-241-3157',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Allianz Global Risks US Ins Co',
    email1: '',
    email2: '',
    fax1: '312-641-3797',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Allied World Assurance Co.',
    email1: 'info@awac.com',
    email2: '',
    fax1: '866-315-1430',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Allstate',
    email1: 'support@allstate.com',
    email2: '',
    fax1: '866-546-9509',
    fax2: '847-326-5199',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ALLSTATE FIRE & CASUALTY INC',
    email1: 'support@allstate.com',
    email2: '',
    fax1: '866-546-9509',
    fax2: '847-326-5199',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ALLSTATE FIRE&CAS IN',
    email1: 'support@allstate.com',
    email2: '',
    fax1: '866-546-9509',
    fax2: '847-326-5199',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ambac Assurance',
    email1: '',
    email2: '',
    fax1: '212-208-3558',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amco',
    email1: '',
    email2: '',
    fax1: '866-315-1430',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amer Integrity Ins Co FL',
    email1: 'contact@aiiflorida.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amer Modern',
    email1: '',
    email2: '',
    fax1: '800-217-5150',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'America First',
    email1: '',
    email2: '',
    fax1: '603-357-4889',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'America National',
    email1: '',
    email2: '',
    fax1: '417-887-1801',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Access Casualty Co.',
    email1: '',
    email2: '',
    fax1: '630-645-7788',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American European',
    email1: '',
    email2: '',
    fax1: '800-222-3058',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Family',
    email1: 'lifedocuments@amfam.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Family Connect',
    email1: 'servicepromise@connectbyamfam.com ',
    email2: '',
    fax1: '920-330-5607',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Farmers & Ranchers',
    email1: '',
    email2: '',
    fax1: '405-218-5400',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Modern Insurance Group',
    email1: '',
    email2: '',
    fax1: '800-217-5150',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American National Property & Casualty',
    email1: 'ServiceCenter@AmericanNational.com',
    email2: '',
    fax1: '518-533-4589',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Risk Insurance Co.',
    email1: 'customerservice@americanriskins.com',
    email2: '',
    fax1: '713-559-0704',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Sentinel Insurance Co',
    email1: '',
    email2: '',
    fax1: '877-408-9498',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Strategic Insurance',
    email1: 'txendorsements@asicorp.org',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'American Traditions Ins Co.',
    email1: 'contactus@jergermga.com',
    email2: '',
    fax1: '866-725-5051',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Americas Insurance Company',
    email1: 'AmericasLIGA@legionclaims.com',
    email2: '',
    fax1: '844-481-2460',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ameriprise',
    email1: '',
    email2: '',
    fax1: '888-269-8408',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ameritrust',
    email1: '',
    email2: '',
    fax1: '248-358-1614',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amex Assurance Company',
    email1: '',
    email2: '',
    fax1: '623-492-3355',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amfed Casualty Insurance',
    email1: '',
    email2: '',
    fax1: '601-427-1544',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amgaurd Insurance',
    email1: '',
    email2: '',
    fax1: '570-823-5930',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amica Mutual Ins',
    email1: 'docs@amica.com',
    email2: '',
    fax1: '866-602-8825',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Amtrust Financial',
    email1: '',
    email2: '',
    fax1: '212-220-7130',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Anchor General Insurance Agency',
    email1: 'anchor@anchorins.com',
    email2: '',
    fax1: '763-473-4090',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Andover Companies',
    email1: '',
    email2: '',
    fax1: '978-475-3300',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Antilles Insurance Co.',
    email1: '',
    email2: '',
    fax1: '787-474-4900',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AON-AICPA',
    email1: 'aicpa.umbrella@aon.com',
    email2: '',
    fax1: '800-953-4561',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Arbella Insurance',
    email1: 'arbellacusotmerservice@arbella.com',
    email2: '',
    fax1: '800-272-3552',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Arch Insurance Co.',
    email1: 'kschneider@archinsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Arizona Home Insurance',
    email1: 'service@arizonahomeinsurance.com',
    email2: '',
    fax1: '949-838-0019',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ark Insurance Services (agent) ',
    email1: '',
    email2: '',
    fax1: '419-425-5190',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Armed Forces Insurance',
    email1: 'service@afi.org',
    email2: '',
    fax1: '800-828-7731',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Arrowhead Insurance',
    email1: '',
    email2: '',
    fax1: '619-881-8694',
    fax2: '800-566-7650',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ASI',
    email1: 'upload@email.progressive.com ',
    email2: '',
    fax1: '866-274-8765',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ASI / Progressive',
    email1: 'upload@email.progressive.com ',
    email2: '',
    fax1: '727-374-0464',
    fax2: '800-229-1590',
    fax3: '866-274-8765',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ASI Assurance',
    email1: '',
    email2: '',
    fax1: '866-274-8765',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ASI Preferred',
    email1: '',
    email2: '',
    fax1: '727-374-0464',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aspen',
    email1: 'martha@aspeninsurancegroup.net',
    email2: '',
    fax1: '616-458-8681',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aspire General Insurance Co.',
    email1: 'customerservice@agicins.com',
    email2: '',
    fax1: '877-789-4742',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ASR',
    email1: '',
    email2: '',
    fax1: '616-464-4458',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Associated Mutual Ins Coop',
    email1: 'Underwriting@associatedmutual.com',
    email2: '',
    fax1: '845-434-5430',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AssuranceAmerica',
    email1: 'customer_service@aainsco.com',
    email2: '',
    fax1: '770-952-0258',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Assurant',
    email1: 'mhmail@assurant.com',
    email2: 'renterscancel@assurant.com',
    fax1: '305-969-4171',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Atlantic American / Delta Group',
    email1: 'bflphs@bflic.com',
    email2: 'bfluw@bflic.com',
    fax1: '404-926-4030',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Austin Mutual Insurance Co.',
    email1: '',
    email2: '',
    fax1: '888-341-0071',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Auto Club',
    email1: 'correspondence@acg.aaa.com',
    email2: '',
    fax1: '877-489-5696',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Auto-Owners',
    email1: '',
    email2: '',
    fax1: '517-323-8796',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Avatar P&C Insurance Co.',
    email1: '',
    email2: '',
    fax1: '813-514-0255',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Aventus Insurance Co.',
    email1: '',
    email2: '',
    fax1: '817-732-7226',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AXA Insurance',
    email1: '',
    email2: '',
    fax1: '212-658-8636',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'AXIS Specialty',
    email1: '',
    email2: '',
    fax1: '678-746-9320',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Badger Mutual Insurance Co.',
    email1: '',
    email2: '',
    fax1: '414-383-1234',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Balboa Insurance',
    email1: '',
    email2: '',
    fax1: '949-222-8000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Baldwin Mutual Insurance Co.',
    email1: '',
    email2: '',
    fax1: '251-943-6335',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bank of America Corporation',
    email1: 'customerservice@bankofamerica.com',
    email2: '',
    fax1: '800-432-1000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bankers Insurance',
    email1: '',
    email2: '',
    fax1: ' 800-899-0146',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Banner Life Insurance',
    email1: '',
    email2: '',
    fax1: '301-279-4178',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Barnstable',
    email1: '',
    email2: '',
    fax1: '508-362-8025',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Battle Creek Mutual',
    email1: '',
    email2: '',
    fax1: '402-675-2825',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Baxter International',
    email1: '',
    email2: '',
    fax1: '224-948-2000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bear River Mutl Ins Co Inc',
    email1: 'damon@cninsurance.com',
    email2: '',
    fax1: '801-267-5033',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bedford Grange Mutual Ins Co',
    email1: '',
    email2: '',
    fax1: '800-707-2246',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Berkley Insurance Co.',
    email1: 'service@berkleyone.com',
    email2: '',
    fax1: '855-663-8551',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Berkshire Hathaway ',
    email1: 'bhservices@bhhc.com',
    email2: 'csr@guard.com',
    fax1: '870-820-7968',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'BIC Holdings LLC',
    email1: '',
    email2: '',
    fax1: '480-543-1516',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'BlueShore Insurance Co.',
    email1: 'BICInfo@blueshoreins.com',
    email2: '',
    fax1: '866-221-4362',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Branch Insurance Exchange',
    email1: 'support@ourbranch.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bremen Farmers Mutual Ins Co.',
    email1: '',
    email2: '',
    fax1: '785-337-2414',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Brethren Mutl Ins Co The',
    email1: 'phs@bmic.com',
    email2: '',
    fax1: '800-621-4264',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Briar Creek Mutual Ins Co.',
    email1: 'bcmic@briarcreekmutual.com',
    email2: '',
    fax1: '570-683-5937',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bridgefield Casualty Insurance',
    email1: '',
    email2: '',
    fax1: '863-667-2738',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bristol West',
    email1: '',
    email2: '',
    fax1: '888-888-0070',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Broome Co-operative Ins Co.',
    email1: 'info@bcicny.com',
    email2: '',
    fax1: '607-321-2644',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Buckeye Insurance',
    email1: '',
    email2: '',
    fax1: '937-778-5000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Builders Mutual',
    email1: '',
    email2: '',
    fax1: '919-845-1976',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Bunker Hill Insurance Co.',
    email1: 'rockcare@plymouthrock.com',
    email2: 'policychanges@plymouthrock.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'California Casualty ',
    email1: '',
    email2: '',
    fax1: '888-206-3083',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'California Mutual Insurance Co',
    email1: 'frontdesk@calmutual.com',
    email2: '',
    fax1: '831-637-5824',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Callicoon Co-operative Ins Co.',
    email1: 'info@callicooncoop.com',
    email2: '',
    fax1: '845-482-5114',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cameron Mutual',
    email1: 'info@cameron-insurance.com',
    email2: 'accounting@cameron-insurance.com',
    fax1: '866-454-5051',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Canopius US Insurance Inc.',
    email1: '',
    email2: '',
    fax1: '630-994-5600',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Capital Insurance',
    email1: 'info@capitalins.com',
    email2: '',
    fax1: '800-780-3100',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Capitol Preferred Insurance Co',
    email1: 'CSR@capitol-preferred.com',
    email2: '',
    fax1: '850-521-0077',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Carnegie General',
    email1: '',
    email2: '',
    fax1: '805-445-1480',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Carolina Farmers Mutual Ins Co',
    email1: 'bfarlow@cfmic.net',
    email2: '',
    fax1: '336-625-8417',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Carolina Motor Club Inc.',
    email1: '',
    email2: '',
    fax1: '704-532-1908',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Castle Key Insurance Co.',
    email1: 'service@allstate.com',
    email2: 'support@allstate.com',
    fax1: '866-546-9509',
    fax2: '847-326-5199',
    fax3: '847-326-7524',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Casualty Corp. of America Inc.',
    email1: 'info@casualtycorp.com',
    email2: '',
    fax1: '888-835-3326 ',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Casualty Underwriters Ins Co.',
    email1: 'iai@iai-online.com',
    email2: '',
    fax1: '316-794-8470',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Catalina Holdings (Bermuda) Ltd.',
    email1: 'enquiries@catalinare.com',
    email2: '',
    fax1: '441-494-6390',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Celina Insurance',
    email1: '',
    email2: '',
    fax1: '419-586-8997',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'CEM Insurance Co.',
    email1: 'info@cemic.com',
    email2: '',
    fax1: '847-307-6300',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Centauri Insurance',
    email1: 'customerservice@centauri-ins.com',
    email2: '',
    fax1: '866-235-1620',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Center Mutual Insurance Co.',
    email1: '',
    email2: '',
    fax1: '701-776-5513',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Central Co-operative Ins Co.',
    email1: '',
    email2: '',
    fax1: '315-635-1462',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Central Insurance Companies',
    email1: 'billing@central-insurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Centre County Mutl Fire Insurance Co',
    email1: 'info@centrecountymutual.com',
    email2: '',
    fax1: '814-355-4835',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Centruy Ins Co. (Guam) Ltd.',
    email1: 'nel_matanguihan@centuryinsurancegroup.net',
    email2: '',
    fax1: '671-648-0608',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Century Mutual Insurance Co.',
    email1: 'support@centurymutual.com',
    email2: '',
    fax1: '336-855-0442',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Chautauqua Patrons Ins Co.',
    email1: 'info@cpins.com',
    email2: '',
    fax1: '716-484-2310',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Chibueze Insurance',
    email1: '',
    email2: '',
    fax1: '214-461-0487',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Chubb',
    email1: 'customercare@chubb.com',
    email2: '',
    fax1: '866-324-8222',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Church Mutual',
    email1: 'customerservice@churchmutual.com',
    email2: '',
    fax1: '855-264-2329',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cincinnati Financial Insurance Company',
    email1: 'policy_service@cinfin.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cities & Villages Mutl Ins Co.',
    email1: '',
    email2: '',
    fax1: '262-784-5599',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Citizens Property Ins Corp.',
    email1: '',
    email2: '',
    fax1: '866-411-2742',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Citizens United Recpl Exchange',
    email1: '',
    email2: '',
    fax1: '800-535-2873',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'City National Insurance/Velocity',
    email1: 'info@velocityrisk.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Clarendon National Ins Co.',
    email1: '',
    email2: '',
    fax1: '212-790-9700',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Clear Blue Insurance',
    email1: '',
    email2: '',
    fax1: '980-299-9520',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Clearcover',
    email1: 'support@clearcover.com',
    email2: '',
    fax1: '312-873-3741',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'CN insurance ',
    email1: 'cna_help@cna.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'CO Farm Bureau Mutual Ins Co.',
    email1: '',
    email2: '',
    fax1: '303-749-7500',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Coastal American Insurance Co.',
    email1: 'cs@coastalamerican.com',
    email2: '',
    fax1: '228-222-2959',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Colonial',
    email1: '',
    email2: '',
    fax1: '800-880-9325',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Columbia Insurance',
    email1: '',
    email2: '',
    fax1: '866-356-3225',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Commonwealth Casualty Co.',
    email1: '',
    email2: '',
    fax1: '877-635-1095',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Community Ins Co. (PA)',
    email1: 'info@communitysure.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Concord Group Insurance',
    email1: '',
    email2: '',
    fax1: '603-224-2614',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Conifer Holdings Inc.',
    email1: '',
    email2: '',
    fax1: '248-559-0840',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Consumers County Mutal/Travelers',
    email1: 'piservice@travelers.com',
    email2: '',
    fax1: '877-872-8737',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Consumers County Mutual',
    email1: '',
    email2: '',
    fax1: '877-872-8737',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Continental Corp.',
    email1: '',
    email2: '',
    fax1: '973-542-0300',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cooperativa de Seguros',
    email1: '',
    email2: '',
    fax1: '787-758-0101',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cornerstone National Ins Co.',
    email1: ' info@cornerstonenational.com',
    email2: '',
    fax1: '573-449-6256',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Costco Connect',
    email1: 'servicepromise@connectbyamfam.com',
    email2: '',
    fax1: '920-330-5607',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'COUNTRY Financial ',
    email1: 'request@countryfinancial.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Country-Wide Insurance Co.',
    email1: '',
    email2: '',
    fax1: '877-940-2167',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cover Financial',
    email1: 'coverclaims@littleongroup.com',
    email2: '',
    fax1: '512-708-9486',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Crestbrook Insurance Co.',
    email1: '',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Crum & Forster Holdings Corp.',
    email1: '',
    email2: '',
    fax1: '800-392-1970',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'CSAA',
    email1: 'iibhomeownersmortgage@csaa.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'CSE Insurance',
    email1: 'customersupport@cseinsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cumberland Group',
    email1: 'info@cumberland.com',
    email2: '',
    fax1: '770-771-5077',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Cypress Group Holdings, Inc.',
    email1: 'policyinfo@cypressig.com',
    email2: '',
    fax1: '904-438-3866',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Dairyland Insurance',
    email1: 'plpcdocuments@sentry.com',
    email2: '',
    fax1: '800-943-3373',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'DB Insurance Co. Ltd.',
    email1: '',
    email2: '',
    fax1: '808-942-5377',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'De Smet Farm Mutl Ins Co of SD',
    email1: 'underwriting@dsfm.net',
    email2: '',
    fax1: '605-854-3704',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Delaware Grange Mutual Ins Co.',
    email1: '',
    email2: '',
    fax1: '302-736-5159',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Delta Lloyds Ins Co of Houston',
    email1: '',
    email2: '',
    fax1: '713-570-2700',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Direct Auto',
    email1: '',
    email2: '',
    fax1: '877-622-7338',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Discovery Insurance Co.',
    email1: '',
    email2: '',
    fax1: '800-876-1492',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Donegal Mutual Insurance Co.',
    email1: '',
    email2: '',
    fax1: '800-877-0600',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Dryden Mutual Insurance Co.',
    email1: 'billing@drydenmutual.com',
    email2: '',
    fax1: '607-257-0312',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'DTRIC Insurance Co. Ltd.',
    email1: 'info@dtric.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Eagle Point Mutual Ins Co.',
    email1: 'info@eaglepointmutual.com',
    email2: '',
    fax1: '715-723-6454',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Economy Premier Assurance Company',
    email1: '',
    email2: '',
    fax1: '401-827-2315',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'ELECTRIC INSURANCE',
    email1: 'service@ElectricInsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Elephant Insurance Co.',
    email1: 'customerservice@elephant.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ellington Mutual Insurance Co.',
    email1: 'office@ellingtonmutual.com',
    email2: '',
    fax1: '920-779-0388',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Employers Mutual Casualty Co.',
    email1: '',
    email2: '',
    fax1: '515-280-2513',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Encompass',
    email1: '',
    email2: '',
    fax1: '800-924-0273',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Encova Insurance',
    email1: 'agp1@encova.com ',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Epic Insurance Midwest',
    email1: '',
    email2: '',
    fax1: '260-625-7525',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Equity Insurance Company',
    email1: 'uw@equityins.net',
    email2: '',
    fax1: '855-686-9457',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Erie',
    email1: 'customercare@erieinsurance.com',
    email2: '',
    fax1: '814-870-3126',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Erie and Niagara Insurance',
    email1: '',
    email2: '',
    fax1: '716-632-5433',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Essentia Insurance Co.',
    email1: '',
    email2: '',
    fax1: '781-332-7000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Esurance',
    email1: 'customerservice@esurance.com',
    email2: 'underwriting@esurance.com',
    fax1: '800-926-2869',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Evanston Insurance Co.',
    email1: '',
    email2: '',
    fax1: '847-572-6000',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Everest Re',
    email1: '',
    email2: '',
    fax1: '908-604-3322',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Excalibur National Ins Co.',
    email1: '',
    email2: '',
    fax1: '866-606-0323',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Explorer Insurance',
    email1: '',
    email2: '',
    fax1: '800-775-7210',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Falcon Insurance Co.',
    email1: 'TXUnderwriting@FalconInsGroup.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farm Bureau',
    email1: 'OPCChelpdesk@optum.com',
    email2: '',
    fax1: '785-587-6011',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farm Bureau Financial Service',
    email1: 'OPCChelpdesk@optum.com',
    email2: '',
    fax1: '785-587-6011',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farm Bureau Mutl Ins Co Idaho',
    email1: '',
    email2: '',
    fax1: '800-574-8875',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farm Bureau Mutl Ins Co. of AR',
    email1: '',
    email2: '',
    fax1: '866-275-7322',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farmers',
    email1: 'serviceoperations@farmersinsurance.com',
    email2: '',
    fax1: '877-217-1389',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: ' FARMERS INS EXCHANGE',
    email1: 'serviceoperations@farmersinsurance.com',
    email2: '',
    fax1: '877-217-1389',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Farmers Mutual Fire Insurance Company of Salem County',
    email1: '',
    email2: '',
    fax1: '856-935-7955',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'FedNat Insurance',
    email1: '',
    email2: '',
    fax1: '954-733-7071',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'FIC Unitrin',
    email1: '',
    email2: '',
    fax1: '800-218-8553',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Fidelity National',
    email1: '',
    email2: '',
    fax1: '800-850-3299',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'First Acceptance',
    email1: 'cs@acceptanceinsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'First American Insurance ',
    email1: 'endorsements.fapci@firstam.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'First Chicago Insurance',
    email1: '',
    email2: '',
    fax1: '708-552-4499',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'First Choice Insurance Group / Travelers',
    email1: 'piservice@travelers.com',
    email2: '',
    fax1: '800-943-3373',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Foremost',
    email1: '',
    email2: '',
    fax1: '800-325-1507',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Founders Insurance ',
    email1: '',
    email2: '',
    fax1: '847-795-0080',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Frankenmuth Insurance',
    email1: 'mark.ball@fmins.com',
    email2: '',
    fax1: '800-234-1133',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Fred Loya',
    email1: 'customerservice@fredloya.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Freeway Insurance ',
    email1: 'customercare@confie.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Fremont Insurance (Nustar Insurance Agency in MI)',
    email1: '',
    email2: '',
    fax1: '231-544-6099',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'GAINSCO INSURANCE ',
    email1: 'service@gainscoagency.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'GEICO ADVANTAGE INS',
    email1: 'r9@geico.com',
    email2: 'R5TechFax@geico.com',
    fax1: '855-814-4058',
    fax2: '866-238-5106',
    fax3: '855-810-0642',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'GEICO INSURANCE',
    email1: 'r9@geico.com',
    email2: 'R5TechFax@geico.com',
    fax1: '855-814-4058',
    fax2: '866-238-5106',
    fax3: '855-810-0642',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'GEICO TOP FIVE',
    email1: 'r9@geico.com',
    email2: 'R5TechFax@geico.com',
    fax1: '855-814-4058',
    fax2: '866-238-5106',
    fax3: '855-810-0642',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Germania Farm Mutual Insurance',
    email1: 'underwriting@germaniainsurance.com',
    email2: '',
    fax1: '979-836-1977',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Goodville Mutual Casualty Company',
    email1: '',
    email2: '',
    fax1: '717-354-5158',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Grange',
    email1: '',
    email2: '',
    fax1: '866-550-9222',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Guard Insurance Companies',
    email1: 'csr@guard.com',
    email2: '',
    fax1: '570-820-7968',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hallmark',
    email1: 'service@hallmarkgrp.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hanover',
    email1: 'clprocessla@hanover.com',
    email2: '',
    fax1: '517-546-1848',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hartford',
    email1: 'agency.services@thehartford.com',
    email2: '',
    fax1: '877-293-2072',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hartford Insurance-AARP',
    email1: 'agency.services@thehartford.com',
    email2: '',
    fax1: '877-293-2072',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hastings Mutual Insurance Company',
    email1: 'perprop@hastingsmutual.com ',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'HCC Surity',
    email1: '',
    email2: '',
    fax1: '721-994-6001',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hillstar',
    email1: '',
    email2: '',
    fax1: '866-994-4557',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Hippo Home Insurance',
    email1: 'support@myhippo.com',
    email2: 'lendersupport@hippo.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Home by QBE',
    email1: 'GC-AgencySupportDesk.US@us.qbe.com',
    email2: '',
    fax1: '888-268-1554',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Home Site',
    email1: 'customerservice@homesite.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Home State Insurance',
    email1: '',
    email2: '',
    fax1: '336-435-0918',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'HOME STATE CNTY MUTL',
    email1: '',
    email2: '',
    fax1: '336-435-0918',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Homeowners of America Insurance',
    email1: 'customerservice@hoaic.com',
    email2: '',
    fax1: '469-417-0490',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Horace Mann',
    email1: 'corporatescanning@horacemann.com',
    email2: '',
    fax1: '217-788-5161',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Infinity',
    email1: '',
    email2: '',
    fax1: '800-782-2218',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Kemper',
    email1: 'customer.service@ipacc.com',
    email2: '',
    fax1: '800-782-2218',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Kemper Preferred',
    email1: 'customer.service@ipacc.com',
    email2: '',
    fax1: '800-782-2219',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'La Familia Insurance',
    email1: '',
    email2: '',
    fax1: '866-786-4991',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Legacy',
    email1: '',
    email2: '',
    fax1: '480-413-9163',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Lemonade',
    email1: 'help@lemonade.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Liberty Mutual Insurance',
    email1: 'LMDOCUMENTS@LIBERTYMUTUAL.COM',
    email2: '',
    fax1: '866-241-0482',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Liberty Mutual Personal',
    email1: 'LMDOCUMENTS@LIBERTYMUTUAL.COM',
    email2: '',
    fax1: '866-241-0482',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'LM GENERAL INSURANCE',
    email1: 'LMDOCUMENTS@LIBERTYMUTUAL.COM',
    email2: '',
    fax1: '866-241-0482',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Lighthouse Property Insurance',
    email1: '',
    email2: '',
    fax1: '833-966-1918',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Love Insurance',
    email1: '',
    email2: '',
    fax1: '214-824-7262',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Maison Insurance',
    email1: '',
    email2: '',
    fax1: '877-864-4936',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Maison Insurance Company ',
    email1: '',
    email2: '',
    fax1: '954-733-7071',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mapfre Insurance',
    email1: '',
    email2: '',
    fax1: '508-671-4632',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mendota Insurance Company',
    email1: 'mendotauw@mendota-insurance.com',
    email2: '',
    fax1: '800-843-9970',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mercury Insurance',
    email1: '',
    email2: '',
    fax1: '888-513-9529',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mercury Insurance Company',
    email1: '',
    email2: '',
    fax1: '888-513-9530',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Metlife',
    email1: 'policyupdate@metlife.com',
    email2: '',
    fax1: '877-217-1389',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Metromile',
    email1: 'admin@metromile.com',
    email2: 'customerservice@metromile.com',
    fax1: '888-244-1702',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Midvale',
    email1: 'contactus@midvaleinsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mission Select ',
    email1: 'customerservice@missionselect.com ',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Motorists Mutual',
    email1: 'agp1@encova.com ',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mountain Valley Indemnity Company',
    email1: 'service@ngic.com',
    email2: '',
    fax1: '877-849-9022',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mountain West Farm Bureau Insurance Company',
    email1: '',
    email2: '',
    fax1: '307-686-2332',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Mutual of Enumclaw',
    email1: '',
    email2: '',
    fax1: '360-825-6885',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'National General',
    email1: 'service@ngic.com',
    email2: '',
    fax1: '877-849-9022',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'National Indemnity',
    email1: '',
    email2: '',
    fax1: '402-536-3030',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'National Mutual Insurance Company ',
    email1: '',
    email2: '',
    fax1: '877-423-5462',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Nationwide',
    email1: 'plpc@nationwide.com',
    email2: '',
    fax1: '800-811-5386',
    fax2: '866-902-0407',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'NATIONWIDE GENERAL',
    email1: 'plpc@nationwide.com',
    email2: '',
    fax1: '800-811-5386',
    fax2: '866-902-0407',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Noblr',
    email1: 'support@noblr.com',
    email2: 'service@noblr.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Noblr Reciprocal Exchange',
    email1: 'support@noblr.com',
    email2: 'service@noblr.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Ohio Mutual Group',
    email1: '',
    email2: '',
    fax1: '419-562-0995',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'OLD AMERICAN CAPITAL INSURANCE ',
    email1: '',
    email2: '',
    fax1: '214-561-1990',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Openly',
    email1: 'support@openly.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Pact Insurance ',
    email1: 'help@pactinsurance.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'PEKIN INSURANCE',
    email1: 'plsupport@pekininsurance.com',
    email2: 'ptalkington@pekininsurance.com',
    fax1: '309-346-0836',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Pharmacists Mutual',
    email1: '',
    email2: '',
    fax1: '515-295-4355',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'PLYMOUTH ROCK ASSURANCE',
    email1: 'propertyservicing@plymouthrock.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Praetorian Insurance Company',
    email1: '',
    email2: '',
    fax1: '608-825-5053',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Proctor Financial Inc',
    email1: '',
    email2: '',
    fax1: '248-269-5735',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Progressive',
    email1: 'upload@email.progressive.com ',
    email2: 'customerservice@email.progressive.com',
    fax1: '800-229-1590',
    fax2: '800-556-0014',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'PROGRESSIVE GROUPS',
    email1: 'upload@email.progressive.com ',
    email2: 'customerservice@email.progressive.com',
    fax1: '800-229-1590',
    fax2: '800-556-0014',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Progressive Home by Homesite',
    email1: 'upload@email.progressive.com ',
    email2: 'customerservice@email.progressive.com',
    fax1: '800-229-1590',
    fax2: '800-556-0014',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'QBE',
    email1: 'GC-AgencySupportDesk.US@us.qbe.com',
    email2: '',
    fax1: '888-268-1554',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'RAM insurance',
    email1: '',
    email2: '',
    fax1: '218-879-7097',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Redpoint Mutual',
    email1: 'coverclaims@littleongroup.com',
    email2: '',
    fax1: '512-708-9486',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'REDPOINT CNTY MUTUAL',
    email1: 'coverclaims@littleongroup.com',
    email2: '',
    fax1: '512-708-9486',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Redwood Fire and Casualty',
    email1: '',
    email2: '',
    fax1: '415-675-5542',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Reliant General',
    email1: '',
    email2: '',
    fax1: '858-592-0992',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Republic Group',
    email1: '',
    email2: '',
    fax1: '888-841-8372',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Root',
    email1: 'support@joinroot.com',
    email2: 'help@joinroot.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Safe Auto',
    email1: 'customerservice@safeauto.com',
    email2: '',
    fax1: '614-456-2940',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Safe Guard Insurance Co',
    email1: 'custserve@sgintl.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'SAFECO',
    email1: 'documents@safeco.com',
    email2: 'irisat@safeco.com',
    fax1: '877-344-5107',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Safeco / Liberty Mutual',
    email1: 'documents@safeco.com',
    email2: 'LMDOCUMENTS@LIBERTYMUTUAL.COM',
    fax1: '866-241-0482',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Safeport Insurance',
    email1: ' PolicyServices@SageSure.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'SAGESURE',
    email1: 'policyservices@sagesure.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Security National Insurance',
    email1: '',
    email2: '',
    fax1: '954-316-5218',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Sentry',
    email1: '',
    email2: '',
    fax1: '888-533-7828',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Sentry Insurance',
    email1: '',
    email2: '',
    fax1: '888-533-7827',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Shelter Mutual Insurance',
    email1: '',
    email2: '',
    fax1: '888-651-4255',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Southern Trust Insurance ',
    email1: 'info@stins.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Spinnaker Insurance',
    email1: '',
    email2: '',
    fax1: '908-274-1990',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Spinnaker Insurance Company',
    email1: '',
    email2: '',
    fax1: '908-274-1989 ',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Standard Casualty Insurance Co',
    email1: 'cancel@stdins.com',
    email2: 'cancel@stdins.com',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Star Casualty Insurance Company',
    email1: '',
    email2: '',
    fax1: '786-347-5535',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'State Auto',
    email1: 'sahelps@stateauto.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'State Auto Insurance Companies',
    email1: 'sahelps@stateauto.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'State Farm',
    email1: 'Info@statefarm.com',
    email2: 'underwriting@statefarm.com',
    fax1: '309-763-0307',
    fax2: '844-218-1140',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'State Farm Home Policy',
    email1: 'Info@statefarm.com',
    email2: 'underwriting@statefarm.com',
    fax1: '309-763-0307',
    fax2: '844-218-1140',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'State National',
    email1: '',
    email2: '',
    fax1: '877-400-8686',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Stillwater Insurance',
    email1: 'ins@stillwater.com',
    email2: '',
    fax1: '866-877-6355',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Swyfft',
    email1: '',
    email2: '',
    fax1: '973-206-2394',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Tapco General Liability',
    email1: '',
    email2: '',
    fax1: '336-584-8880',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'TEXAS COUNTY MUTUAL',
    email1: '',
    email2: '',
    fax1: '573-674-4037',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Texas Farm Bureau Mutual Insurance',
    email1: '',
    email2: '',
    fax1: '254-751-8712',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'The General (Permanent General)',
    email1: '',
    email2: '',
    fax1: '800-467-8767 ',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'The General Insurance',
    email1: '',
    email2: '',
    fax1: '800-467-8767 ',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'The Hartford',
    email1: 'agency.services@thehartford.com',
    email2: '',
    fax1: '877-293-2072',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'The Woodlands Insurance',
    email1: '',
    email2: '',
    fax1: '281-298-8626',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Tower Hill',
    email1: '',
    email2: '',
    fax1: '352-332-9999',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Traders Insurance ',
    email1: '',
    email2: 'underwriting@tradersauto.com',
    fax1: '800-943-3371',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Travelers',
    email1: 'piservice@travelers.com',
    email2: '',
    fax1: '800-943-3373',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Travelers/Consumers County Mutual',
    email1: 'piservice@travelers.com',
    email2: '',
    fax1: '877-872-8737',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Trexis',
    email1: 'customerservice@trexis.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Trisura Specialty Insurance Company',
    email1: 'TSIC.Underwriting@Trisura.com',
    email2: 'TIC.Underwriting@Trisura.com',
    fax1: '405-594-4969',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Twico The Woodlands  Ins',
    email1: '',
    email2: '',
    fax1: '281-298-8626',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'United Heritage Insurance ',
    email1: '',
    email2: '',
    fax1: '208-493-6290',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'United Insurance Company',
    email1: 'Info@UnitedInsurance.net',
    email2: '',
    fax1: '207-523-8098',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Unitrin Safeguard Company',
    email1: '',
    email2: '',
    fax1: '904-245-5601',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Universal Property & Casualty Insurance',
    email1: 'endorsements@universalproperty.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'USAA',
    email1: '',
    email2: '',
    fax1: '800-531-8877',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'USLI',
    email1: '',
    email2: '',
    fax1: '610-688-4391',
    fax2: '512-712-1659',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Victoria Insurance',
    email1: '',
    email2: '',
    fax1: '800-955-0958',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'West Bend Mutual Insurance',
    email1: '',
    email2: '',
    fax1: '262-334-9109',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Western Mutual Insurance ',
    email1: 'service@westernmutual.com',
    email2: '',
    fax1: '949-838-0019',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Western National Legacy',
    email1: 'info@wnins.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Western Reserve',
    email1: '',
    email2: '',
    fax1: '800-392-7093',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Western United',
    email1: '',
    email2: '',
    fax1: '949-852-5085',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Westfield',
    email1: 'westfieldccc@westfieldgrp.com',
    email2: 'plprocessing@westfieldgrp.com',
    fax1: '800-243-0210',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Weston Specialty Insurance',
    email1: 'PolicyServices@weston-ins.com',
    email2: '',
    fax1: '888.862.7390',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'WESTWOOD  INSURANCE',
    email1: 'cancellationgroup@insuranceemail.com',
    email2: '',
    fax1: '',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  },
  {
    priorCompany: 'Workmens Auto',
    email1: '',
    email2: '',
    fax1: '800-761-2305',
    fax2: '',
    fax3: '',
    needsAdditionalZendeskTicket: false,
    MailingAddress: '',
    Phone: '',
    specialInstructions: ''
  }
];
