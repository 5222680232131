/* eslint-disable func-names */
import * as Yup from 'yup';
import { paymentMethod } from '@ourbranch/lookups';

import { umbrellaValidationSchema } from './umbrella.validation-schema';
import { connectedHomeValidationSchema } from './connected-home.validation-schema';

const min = (minVal) => (val) => val >= minVal;

export const condoValidationSchema = ({ policy, savedSegment, autoPolicyDetails, isAdvancedConnectedHome }) => {
  const baseSchema = Yup.object().shape({
    condo: Yup.object().shape({
      yearBuilt: Yup.number().test('yearBuiltMin', 'Year built must be higher than 1899', min(1900)),
      condoLocation: Yup.object().shape({
        address2: Yup.string()
          .nullable()
          .test('required-in-condo', `Condo's apartment or unit number is required`, (value, { options }) => {
            if (!options.context.condo.noUnitNumber) {
              return !!value;
            }
            return true;
          })
      }),
      mortgageDetails: Yup.array(
        Yup.object().shape({
          mortgageHolderName: Yup.string()
            .trim()
            .required('Required. If trying to remove this mortgage, use the Trash button.'),
          mortgageHolderAddress: Yup.object().shape({
            address: Yup.string().trim().required('Required'),
            address2: Yup.string().nullable(),
            city: Yup.string().trim().required('Required'),
            state: Yup.string().trim().required('Required'),
            zip: Yup.string().trim().required('Required')
          }),
          loanNumber: Yup.string().trim().required('Required')
        })
      )
        .nullable()
        .test(
          'atLeastOneRequired',
          'At least one mortgage details required because payment method is mortgage',
          function (mortgageList) {
            if (policy.paymentMethod === paymentMethod.Escrow) {
              return mortgageList?.length > 0;
            }
            return true;
          }
        )
    }),
    scheduledPersonalProperty: Yup.object()
      .shape({
        items: Yup.array(Yup.object()),
        deductible: Yup.mixed().test('deductible', 'SPP Deductible is required', function (value) {
          return !(this.parent.items && this.parent.items.length && !value);
        })
      })
      .nullable(),
    connectedHome: connectedHomeValidationSchema(isAdvancedConnectedHome)
  });

  return baseSchema.concat(umbrellaValidationSchema({ policy, savedSegment, autoPolicyDetails }));
};
