import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

import * as carAssignmentResolvers from './by-state';

function CarAssignment({ state, disabled }) {
  const CarAssignmentByState = useMemo(
    () => lazy(carAssignmentResolvers[`carAssignment${state.toUpperCase()}`]),
    [state]
  );

  return (
    <Suspense fallback={null}>
      <CarAssignmentByState disabled={disabled} />
    </Suspense>
  );
}

CarAssignment.propTypes = {
  disabled: PropTypes.bool,
  state: PropTypes.string.isRequired
};

CarAssignment.defaultProps = {
  disabled: false
};

export default CarAssignment;
