/* eslint-disable func-names */
import * as Yup from 'yup';
import {
  aopDeductibleIsAlwaysPercent,
  noWindHailDeductibleStates,
  windHailDeductibleCanVaryByCounty
} from '@ourbranch/lookups';

const schema = (state) =>
  Yup.object().shape({
    homeCoverage: Yup.object().shape({
      deductibleWindHail: Yup.number()
        .nullable()
        .test(
          'CompareToAllOtherPerils',
          'Wind & Hail Deductible must be greater than or equal to all other perils deductible',
          function (value) {
            const windHailExclusion = this.options.context?.homeCoverage?.windHailExclusion;
            if (windHailExclusion) {
              // force pass since no need to validiate this if excluded
              return true;
            }
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            const county = this.options.context.home.county.toUpperCase();
            const windHailInState =
              (!windHailDeductibleCanVaryByCounty[state] ||
                (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][county])) &&
              !noWindHailDeductibleStates[state];

            if (hasHomeSelected && windHailInState) {
              const coverageA = this.options.context.homeCoverage?.coverageA;
              const windHailIsPercent = value < 1;
              const allOtherPerilsIsPercent = this.options.context.homeCoverage?.deductibleAllOther < 1;

              // since value is a %, we need to multiply it by coverage A to see the deductible amount
              const allOtherPerilsAmount = allOtherPerilsIsPercent
                ? this.options.context.homeCoverage?.deductibleAllOther * coverageA
                : this.options.context.homeCoverage?.deductibleAllOther;
              const windHailAmount = windHailIsPercent ? value * coverageA : value;

              return windHailAmount >= allOtherPerilsAmount;
            }
            return true;
          }
        ),
      deductibleAllOther: Yup.number()
        .nullable()
        .test(
          'WindHailPercentageMin',
          'All Other Perils deductible must be a percentage for this state',
          function (value) {
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            if (hasHomeSelected && aopDeductibleIsAlwaysPercent[state] && value > 1) {
              return false;
            }
            return true;
          }
        )
    })
  });

export default schema;
