import { useMemo } from 'react';
import { lookupsJson } from '@ourbranch/lookups';
import { useNewMonitoringServiceHelpers } from 'common/components/discounts/hooks/connected-home.hooks';

// security providers allowing new monitoring sign up
const providersAllowingSignUp = lookupsJson.securityProviders.filter((provider) => provider.signUpThroughBranch);

// all of our security provider options but with a key of id and value that is needed for an autocomplete field type
const advancedConnectedHomeOptions = lookupsJson.securityProviders.map((option) => ({
  id: option.id,
  text: option.value
}));

export const usePolicyConnectedHomeSecurityProviders = ({ isAdvancedConnectedHome }, affinityLookupsStore) => {
  const { isSigningUpForHomeSecurity } = useNewMonitoringServiceHelpers(affinityLookupsStore);

  const securityProviderOptions = useMemo(() => {
    // need to filter some providers on new sign-up in Policy
    if (isSigningUpForHomeSecurity) {
      return providersAllowingSignUp.filter((provider) => provider.signUpInPolicy);
    }

    if (isAdvancedConnectedHome) {
      return advancedConnectedHomeOptions;
    }

    return affinityLookupsStore.securityProviders;
  }, [isSigningUpForHomeSecurity, isAdvancedConnectedHome, affinityLookupsStore]);
  return securityProviderOptions;
};
