import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: `2px solid ${theme.colorPalette.white_10}`,
    padding: '18px 32px 24px 32px',
    marginBottom: theme.spacing(2)
  },
  addButton: {
    marginTop: theme.spacing(4)
  }
}));

export default useStyles;
