import React from 'react';
import PropTypes from 'prop-types';
import { standaloneRentersStates } from '@ourbranch/lookups';

import { Label } from 'core';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import { CardLine } from '../../card-line';
import { PriceBreakdown } from '../price-breakdown';
import useStyles from './policy-type-option.styles';

const PolicyTypeOption = ({ option, type, label, fieldOptions, tooltip, noHome, onClick, state, disabled }) => {
  const classes = useStyles();

  const hasHome = option.homePrice && option.homeFrequency && !noHome;
  const hasAuto = option.autoPrice && option.autoFrequency;
  const hasCondo = option.condoPrice && option.condoFrequency;
  const hasStandaloneRenters = option.rentersPrice && option.rentersFrequency && standaloneRentersStates[state];

  return (
    <CardLine>
      <div className={classes.section}>
        <FormField
          name="selectedOption"
          label={
            <Label className={classes.option} type="status">
              {label}
            </Label>
          }
          onClick={() => onClick(type, disabled)}
          options={fieldOptions}
          type="radio"
          mode="dark"
          disabled={disabled}
        />
      </div>
      <div className={classes.section}>
        {hasHome && <PriceBreakdown price={option.homePrice} frequency={option.homeFrequency} isDisabled={disabled} />}
        {hasCondo && (
          <PriceBreakdown price={option.condoPrice} frequency={option.condoFrequency} isDisabled={disabled} />
        )}
        {(hasHome || hasCondo) && hasAuto && (
          <Label className={classes.plus} type="toast">
            +
          </Label>
        )}
        {hasAuto &&
          ((tooltip && (
            // not eligible for monoline auto (both standalone & w/bundle)
            <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltip }} />
          )) ||
            (!tooltip && !noHome && (
              <PriceBreakdown price={option.autoPrice} frequency={option.autoFrequency} isDisabled={disabled} />
            )))}

        {hasStandaloneRenters && hasAuto && (
          <Label className={classes.plus} type="toast">
            +
          </Label>
        )}
        {hasStandaloneRenters && <PriceBreakdown price={option.rentersPrice} frequency={option.rentersFrequency} />}
      </div>
    </CardLine>
  );
};

PolicyTypeOption.propTypes = {
  option: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fieldOptions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  noHome: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

PolicyTypeOption.defaultProps = {
  tooltip: ''
};

export default PolicyTypeOption;
