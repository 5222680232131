import { fonts } from '../theme/variables';

export default (theme) => ({
  dialog: {
    background: theme.colorPalette.red_20,
    width: 600,
    minHeight: 365,
    borderRadius: 6,
    display: 'flex',
    position: 'relative'
  },
  body: {
    flex: 1,
    padding: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 42,
    marginBottom: theme.spacing(6),
    fontWeight: 200,
    display: 'block',
    color: theme.colorPalette.white_10
  },
  message: {
    display: 'block',
    color: theme.colorPalette.beige_10,
    fontSize: theme.spacing(5),
    lineHeight: 1.5,
    fontFamily: fonts.FrankRuhlLibre
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(2)
  },
  button: {
    width: 288,
    alignSelf: 'flex-end'
  }
});
