import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../common/verify-property-container.styles.js';

const Options = {
  Yes: {
    value: 'Yes',
    label: 'Yes'
  },
  IDontKnow: {
    value: 'IDontKnow',
    label: "I don't know"
  },
  No: {
    value: 'No',
    label: 'No'
  }
};

const VerifyPropertyForm = ({ noAsbestosCheck, homeWiringCheck, homePlumbingCheck, noOpenFoundationCheck }) => {
  const classes = useStyles();

  // check conditions to see if we need to display anything
  const displayForm = noAsbestosCheck || homeWiringCheck || homePlumbingCheck || noOpenFoundationCheck;

  const options = [{ ...Options.Yes }, { ...Options.IDontKnow }, { ...Options.No }];

  if (!displayForm) {
    return null;
  }
  return (
    <Grid>
      {noAsbestosCheck && (
        <Card className={classes.propertyFormQuestionCard}>
          <Label className={classes.propertyFormQuestion}>Does the home have asbestos siding?</Label>
          <Label type="formTitle" className={classes.propertyFormExplanation}>
            Homes built before 1960 may have asbestos siding, and Branch does not cover homes with asbestos siding.
            Homeowners with asbestos siding are generally aware of it.{' '}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={'https://branch.notion.site/Asbestos-Siding-2f28926b8acd4bef8d37927498457d4e'}
              className={classes.readMoreLink}
            >
              Read More
            </a>
          </Label>

          <FormField
            className={classes.formItem}
            id="home.noAsbestosCheck"
            name="home.noAsbestosCheck"
            type="radio"
            mode="light"
            options={options}
          />
        </Card>
      )}
      {homeWiringCheck && (
        <Card className={classes.propertyFormQuestionCard}>
          <Label className={classes.propertyFormQuestion}>Has the wiring been replaced in the home?</Label>
          <Label type="formTitle" className={classes.propertyFormExplanation}>
            Homes built before 1940 had knob and tube wiring, aluminum wiring, and/or glass fuses, and Branch does not
            cover homes with these types of wiring. Please confirm that the home&apos;s wiring has been replaced with
            modern wiring.{' '}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={'https://branch.notion.site/Ineligible-Electrical-Panels-Sytems-4816b002466f434ea3cf7b1f53793927'}
              className={classes.readMoreLink}
            >
              Read More
            </a>
          </Label>

          <FormField
            className={classes.formItem}
            id="home.homeWiringCheck"
            name="home.homeWiringCheck"
            type="radio"
            mode="light"
            options={options}
          />
        </Card>
      )}
      {homePlumbingCheck && (
        <Card className={classes.propertyFormQuestionCard}>
          <Label className={classes.propertyFormQuestion}>Does the home have copper or PEX plumbing?</Label>
          <Label type="formTitle" className={classes.propertyFormExplanation}>
            Homes built before 1940 had galvanized plumbing, and Branch does not cover galvanized or polybutylene
            plumbing. Please confirm that the home has copper or PEX plumbing.{' '}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={'https://branch.notion.site/Ineligible-Plumbing-e62375c514d4425f91d3e32bc12de9fa'}
              className={classes.readMoreLink}
            >
              Read More
            </a>
          </Label>

          <FormField
            className={classes.formItem}
            id="home.homePlumbingCheck"
            name="home.homePlumbingCheck"
            type="radio"
            mode="light"
            options={options}
          />
        </Card>
      )}
      {noOpenFoundationCheck && (
        <Card className={classes.propertyFormQuestionCard}>
          <Label className={classes.propertyFormQuestion}>Is the foundation of the home fully closed?</Label>
          <Label type="formTitle" className={classes.propertyFormExplanation}>
            This home has a raised foundation, and Branch does not cover homes with certain types of raised foundations
            such as stilts, pier, and crawl spaces without skirting. If the foundation is not raised, please update the
            offer under home details to reflect the appropriate foundation.{' '}
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={'https://branch.notion.site/Ineligible-Foundation-Types-756d08eaa3bb4b3198835c6f7232c182'}
              className={classes.readMoreLink}
            >
              Read More
            </a>
          </Label>

          <FormField
            className={classes.formItem}
            id="home.noOpenFoundationCheck"
            name="home.noOpenFoundationCheck"
            type="radio"
            mode="light"
            options={options}
          />
        </Card>
      )}
    </Grid>
  );
};
export default VerifyPropertyForm;
