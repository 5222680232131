import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'core/components/radio';
import useStyles from './segment-form.styles';

const radioId = 'change_option';

const SegmentForm = memo(({ onRadioChange, value, options }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Radio
        options={options}
        id={radioId}
        name={radioId}
        defaultValue={value}
        value={value}
        onChange={onRadioChange}
        classes={{ formControl: classes.formControl, controlLabel: classes.controlLabel }}
      />
    </div>
  );
});

SegmentForm.propTypes = {
  options: PropTypes.array.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default SegmentForm;
