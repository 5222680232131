import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLHomeAnimalQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Animals</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Do you have any of the following dog breeds on the property? Akita, American Bulldog, Chow Chow, Doberman
          Pinscher, Mastiff, Pit Bull, Rottweiler, Staffordshire Terrier, Wolf hybrid, or any mixed-breed thereof.
        </Label>
        <FormField
          className={classes.formItem}
          id="home.isIneligibleDogBreed"
          name="home.isIneligibleDogBreed"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Do you have one or more of the following types of animals on the property? Exotic, wild, non-domesticated,
          farm, or saddle animals.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.hasExoticOrNonDomesticatedEtcAnimals"
          name="home.hasExoticOrNonDomesticatedEtcAnimals"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Do you have a pet that has a prior bite history or has exhibited vicious behavior?
        </Label>

        <FormField
          className={classes.formItem}
          id="home.hasPetBiteHistoryOrViciousBehavior"
          name="home.hasPetBiteHistoryOrViciousBehavior"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
    </Grid>
  );
};
export default FLHomeAnimalQuestions;
