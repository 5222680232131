import * as Yup from 'yup';
import { validUSState } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

export const mortgageValidation = Yup.object().shape({
  loanNumber: Yup.number().required('Loan number is required').typeError('Loan Number must be a number'),
  mortgageHolderName: Yup.string().required('Mortgage holder name is required'),
  mortgageHolderAddress: Yup.object().shape({
    address: Yup.string().required('Address is required'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('City is required'),
    state: Yup.string().validUSState('offer.mortgageHolderAddress.state').required('State is required'),
    zip: Yup.string().required('Zip code is required')
  })
});
