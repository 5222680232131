export const messages = {
  // Only rejections
  10000: {
    title: 'Catch-all Verisk.',
    message:
      'This is the catch-all "Verisk FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  10009: {
    title: 'Total Property Claims',
    message:
      'We do not allow 3 or more total property claims on a home that is less than 50 years old in the past 5 years.'
  },
  10010: {
    title: 'Same-Peril Property Claims',
    message:
      'We do not allow 2 or more claims of the same type of peril (e.g., Fire, Wind, Water) on a home in the past 5 years.'
  },
  10011: {
    title: 'Older Home Property Claims',
    message:
      'We do not allow 2 or more losses in the last 5 years, as named insured, for homes greater than 50 years old.'
  },
  10012: {
    title: 'Bite Claims',
    message: 'We do not allow any animal bite claims.'
  },
  10013: {
    title: 'Suspended License (MVR)',
    message: 'We do not allow any record of a suspended license in the past 59 months; this one came from a Verisk MVR.'
  },
  10014: {
    title: 'Disqualifying Charge (MVR)',
    message:
      'We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from a Verisk MVR.'
  },
  10015: {
    title: 'Total Auto Incidents (MVR, Claims)',
    message:
      "We do not allow 3 or more total incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
  },
  10016: {
    title: 'Driver Auto Incidents (MVR, Claims)',
    message:
      "We have a limit on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
  },
  10017: {
    title: 'PIP Claims',
    message: "We have a limit on the number of pip claims that we found on the driver's claims history."
  },
  10018: {
    title: 'Felony (MVR)',
    message:
      'We do not allow any record of any motor-vehicle felony in the past 59 months; this one came from a Verisk MVR.'
  },
  20000: {
    title: 'Catch-all Transunion FCRA Issue',
    message:
      'This is the catch-all "Transunion FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  20001: {
    title: 'Suspended License (DR)',
    message:
      'We do not allow any record of a suspended license in the past 59 months; this one came from Transunion DriverRisk.'
  },
  20002: {
    title: 'Disqualifying Charge (DR)',
    message:
      'We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from Transunion DriverRisk.'
  },
  20003: {
    title: 'Total Auto Incidents (DR)',
    message:
      "We do not allow 3 or more total incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
  },
  20004: {
    title: 'Driver Auto Incidents (MVR)',
    message:
      "We have a limit on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
  },
  20005: {
    title: 'Felony (MVR)',
    message:
      'We do not allow any record of any motor-vehicle felony in the past 59 months; this one came from a Transunion DriverRisk.'
  },
  50000: {
    title: 'Catch-all Issue',
    message:
      'This is the catch-all "Non-FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  50001: {
    title: 'No Cars',
    message:
      "Couldn't find any cars in either Coverage Verifier or the Vehicle Registration database, and so we need to have one VIN supplied so that we can provide a price for auto. This may be a result of asking only for homeowners insurance in a state that does not pull auto information unless explicitly asked (e.g., New Hampshire)."
  },
  50002: {
    title: 'Too Many Square Feet',
    message:
      'We allow up to 6,000 square feet in homes that we insure, and this home exceeds that. If you submit a smaller value, we will use it.'
  },
  50003: {
    title: 'Too Many Occupants',
    message:
      'We allow up to 12 occupants in homes that we insure, and this home exceeds that. If you submit a smaller value, we will use it.'
  },
  50004: {
    // deprecated
    title: 'Car Ratio',
    message: 'We allow up to 2.5 cars per driver on a policy.'
  },
  50005: {
    title: 'Home Too Old',
    message: 'We do not allow homes built prior to 1900, although we can grant underwriting exceptions.'
  },
  50006: {
    title: 'Could Not Calculate Home Replacement Cost',
    message:
      'We tried to pull data on this home from three different sources (Verisk tax assessor data, Attom tax assessor data, and MLS data) and did not receive enough information to calculate a replacement cost. If you resubmit with sufficient home characteristics to calculate a replacement cost, we will do so.'
  },
  50007: {
    title: 'No Prior Auto Insurance',
    message:
      'We do not allow customers with no auto insurance for the past 31 days, unless there was no need, which needs to be granted as an underwriting exception.'
  },
  50008: {
    title: 'Exterior Insulation and Finish System',
    message:
      'We do not allow homes with Exterior Insulation and Finish System (EIFS) as their primary exterior wall type; we identify this from Verisk 360Value returning an exterior wall type of SSTU_BL.'
  },
  50009: {
    title: 'Stilts',
    message:
      'We do not allow homes built on stilts; we identify this from Verisk 360Value returning a foundation type of either E or ES.'
  },
  50010: {
    title: 'Wood Roof',
    message: 'We do not allow homes with wood roofs; if this is incorrect, you can resubmit with the proper roof type.'
  },
  50011: {
    title: 'Replacement Cost Too Low',
    message:
      'Our minimum replacement cost for coverage is $50,000. If you think it is being calculated incorrectly, specify all of the home characteristics and resubmit.'
  },
  50012: {
    title: 'Replacement Cost Too High',
    message:
      'Our maximum replacement cost for coverage is $800,000 for self-serve online, and $1,000,000 for origination within the staff system. If you think it is being calculated incorrectly, specify all of the home characteristics and resubmit.'
  },
  50013: {
    title: 'Fire Protection Class 10',
    message:
      'We do not allow homes with a fire protection class of 10. If you think this is in error, we will need proof of proximity to fire department and fire hydrant. If you are in KY or MI, talk to underwriting to see if this qualifies for an exception.'
  },
  50014: {
    title: 'No Fire Protection Class',
    message:
      'We did not receive a fire protection class for this home. This usually means that all of the home characteristics need to be entered from the New Offer screen.'
  },
  50015: {
    title: 'Too Many Drivers',
    message: 'We allow a maximum of ten drivers per policy.'
  },
  50016: {
    title: 'Too Many Cars',
    message: 'We allow a maximum of ten cars per policy.'
  },
  50017: {
    title: 'Test Case',
    message:
      'This is a test case rejection and should never been seen in production. If you do see this, please let joe@ourbranch.com know how to replicate.'
  },
  50018: {
    title: 'Ineligible Coastal Location',
    message:
      "This error is a fatal and non-recoverable error because the home is in a location that we aren't writing insurance for."
  },
  50019: {
    title: 'No Fireline Score Retrieved',
    message: "We didn't get a Fireline score from Verisk; re-run offer from start to try and get one."
  },
  50020: {
    title: 'Fireline Score is Too High',
    message: 'We received a Fireline score (wildfire hazard risk) that is too high for us to write.'
  },
  50021: {
    title: 'Fireline Score with Fire Protection Class is Too High',
    message:
      'We received a fireline score (wildfire hazard risk) that is too high for us to write with the fire protection class on this property.'
  },
  50022: {
    title: 'No Distance-to-Coast value found',
    message: 'We do not have a distance-to-coast value for this property, and we need one to run underwriting rules.'
  },
  50023: {
    title: 'Ineligible Distance-to-Coast value found',
    message: 'This property is too close to tidal water for us to underwrite it.'
  },
  50024: {
    title: 'Scheduled Personal Property too expensive',
    message: 'SPP is more than ten percent of Coverage C, which is not allowed.'
  },
  50025: {
    title: 'Scheduled Personal Property too expensive',
    message: 'SPP is more than ten percent of Coverage A, which is not allowed.'
  },
  50026: {
    title: 'Scheduled Personal Property too expensive',
    message: 'SPP is more than fifty percent of Coverage C, which is not allowed.'
  },
  50027: {
    title: 'No Home Wind/Hail Exclusion',
    message: 'We do not allow home with a wind/hail exclusion here'
  },
  50028: {
    title: 'Fire Protection CLass Too High',
    message:
      'In some states (prone to wildfires) we have a lower-than-10 FPC limit, and this home exceeds that lower limit. If the homeowner is within 1000ft of a fire hydrant, this can be sent to underwriting for override.'
  },
  50029: {
    title: 'Did Not Ask for Auto',
    message:
      'In some states we do not offer auto insurance if it is not asked for, and that was the case with this offer.'
  },
  50030: {
    title: 'Roof Condition from Imagery',
    message:
      'In the latest aerial imagery we have of this home, the roof appears to be in poor condition. We will need a recent photo of the roof to underwrite this policy (escalate to service/developers after underwriting has approved).'
  },
  50031: {
    title: 'Yard Debris from Imagery',
    message:
      'In the latest aerial imagery we have of this home, there appears to be a significant amount of yard debris. We will need a recent photo of the yard(s) to underwrite this policy (escalate to service/developers after underwriting has approved).'
  },
  50032: {
    title: 'Coastal County',
    message: 'This property is in a coastal county in a state in which we restrict sales based upon coastal location.'
  },
  50033: {
    title: 'Less than One Year Continuous Coverage',
    message:
      'Less than one year of prior continuous auto coverage for monoline direct purchase (based on Coverage Verifier). Re-run offer from scratch in staff to clear.'
  },
  50034: {
    title: 'Invalid Prior Carrier',
    message:
      'Invalid prior carrier for monoline direct purchase (based on Coverage Verifier). Re-run offer from scratch in staff to clear.'
  },
  50035: {
    title: 'Some incidents and/or violations',
    message:
      'Invalid 35-month violation/incident history for monoline direct purchase. Re-run offer from scratch in staff to clear.'
  },
  50036: {
    title: 'More cars than drivers',
    message: 'Invalid more cars than drivers for monoline direct purchase. Re-run offer from scratch in staff to clear.'
  },
  50037: {
    title: 'Bodily Injury Limit is Too Low',
    message:
      'Invalid bodily injury limit (based on Coverage Verifier) for monoline direct purchase. Re-run offer from scratch in staff to clear.'
  },
  50038: {
    title: 'CV No Hit',
    message: 'We do not allow CV no hit for monoline direct purchase. Re-run offer from scratch in staff to clear.'
  },
  50039: {
    title: 'Some Drivers Excluded',
    message:
      'We do not allow excluded drivers in monoline direct purchase. Re-run offer from scratch in staff to clear.'
  },
  50040: {
    title: 'Insurance Score Too Low/No Hit',
    message:
      'Review risk for any additional adverse factors and if present, "we are unable to offer a home policy due to risk management"'
  },
  // the following are all UW errors
  50041: {
    title: 'Self Reported Home Underwriting',
    message:
      'During this or a prior sales underwriting review, this member reported a home characteristic that does not meet Branch underwriting guidelines. If you believe this is an error, please contact a Team Lead to solve'
  },
  50042: {
    title: 'Self Reported Auto Underwriting',
    message:
      'During this or a prior sales underwriting review, this member reported a auto characteristic that does not meet Branch underwriting guidelines. If you believe this is an error, please contact a Team Lead to solve'
  },
  // the following are UW errors specific to FL
  50043: {
    title: 'Water Heater Older Than 15 Years',
    agentScript:
      'Is your water heater tankless? If not, we ask that you have it professionally replaced prior to getting a policy with Branch. You may submit proof of water heater replacement (permit, paid receipt, copy of completed contract) to us to allow the home policy to be bound'
  },
  50044: {
    title: 'Coverage A Is Less Than 100K',
    agentScript:
      'We need photos of the outside of your home to continue binding the policy. You may submit them to us for review',
    agentAction:
      'Review property and confirm the RCE is correct. Also review property appraiser and Zillow to confirm this is a Single-Family Residence'
  },
  50045: {
    title: 'Trampolines Present',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50046: {
    title: 'Property Greater Than 5 Acres Or Is PC 9 - 10',
    agentScript: `The fire protection class on your property returned a high score, meaning that there is no fire hydrant or fire department in close proximity to your property. If you feel this is a mistake, we will reach out to our vendor to confirm protection class. If they advise that you are in not an ineligible protection class, we will correct the protection class. \n If ineligible - this property does not meet our underwriting guidelines and we are unable to offer you a home policy.`
  },
  50047: {
    title: 'Unconventional Home',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50048: {
    title: 'Ineligible Dog Or Aggression History',
    message: 'Certain dog breeds and aggressive dogs, are ineligible for our home program.'
  },
  50049: {
    title: 'Prior Sinkhole Activity Or Adjacent To Sinkhole',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50050: {
    title: 'Saddle Animals, Farm, Or Non-Domesticated Animals',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50051: {
    title: 'Open Foundation',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50052: {
    title: 'In Foreclosure Property',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50053: {
    title: 'No Polybutylene Plumbing',
    message:
      'Homes with polybutylene plumbing are ineligible for our home program. If you wish to proceed with the offer, we must receive proof of professional replacement prior to binding. Acceptable proof includes permits, completed contract and a signed statement from a licensed plumber that all polybutylene plumbing has been removed from your property.'
  },
  50054: {
    title: 'Skateboard/Bike Ramp, Diving Board, Or Pool Slide Present',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50055: {
    title: 'Property Is Owned By Corporation, LLC, LP, LLP, Estate, Or Partnership',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50056: {
    title: 'Excluded Content Coverage',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50057: {
    title: 'Day Care Operating In Home',
    agentScript: `What type of business is conducted at your home? \n
- If anything other than day care this business exposure does not meet our underwriting guidelines and we are unable to offer you a policy\n
- If daycare, please submit a copy of your current commercial liability policy for our review`,
    agentAction:
      'If commercial liability policy received and there are no other hazards noted from your review, we are required to allow policy binding. We can reach out every year for confirmation that the daycare is still in business and ask for a copy of the commercial renewal policy. If not received, we can non-renew for failure to provide required documentation'
  },
  50058: {
    title: 'Seasonal Or Secondary Home',
    agentScript: 'Secondary or seasonal homes are sold as an accommodation to Branch policyholders.',
    agentAction:
      'Confirm if we have primary home. If not, advise we are unable to offer a price due to underwriting guidelines'
  },
  50059: {
    title: 'More Than 2 Mortgages',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50060: {
    title: 'Commercial Occupation',
    agentScript: `What type of business is conducted at your home? \n
- If anything other than day care this business exposure does not meet our underwriting guidelines and we are unable to offer you a policy\n
- If daycare, please submit a copy of your current commercial liability policy for our review`,
    agentAction:
      'If commercial liability policy received and there are no other hazards noted from your review, we are required to allow policy binding. We can reach out every year for confirmation that the daycare is still in business and ask for a copy of the commercial renewal policy. If not received, we can non-renew for failure to provide required documentation'
  },
  50061: {
    title: 'Home Age Is Greater Than 40 Years',
    agentScript:
      'Based on the age of your home, we require a 4-POINT inspection to be completed and submitted prior to binding. The 4-POINT must have been completed within the past 12 months, and documents the age and condition of electrical, roof, heating and cooling system and plumbing systems. The inspection must be completed by a licensed general or building contractor, building inspector, architect, engineer or a member of the American Society of Home Inspectors (ASHI), the Florida Association of Building Inspectors (FABI) or the National Association of Certified Home Inspectors (NACHI).',
    agentAction:
      'If any of the systems are ineligible (age, condition, etc.) the offer is rejected. "Unfortunately, due to the (age, condition, type) of (electrical, plumbing, heating and cooling or roof) we are unable to offer you a policy at this time as it does not meet our underwriting guidelines.'
  },
  50062: {
    title: 'Shingle Or Wood Roof',
    agentScript:
      'Review risk for any defects - if multiple adverse factors exist on risk, "we are unable to offer a home policy due to risk management"'
  },
  50063: {
    title: 'Ineligible Occupancy',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50064: {
    title: 'Flat Roof',
    agentScript:
      'Properties with flat roofs over living area are ineligible with Branch, unless it is reinforced concrete. Is your roof reinforced concrete and can you submit proof? Acceptable proof would be a completed wind mitigation inspection or building permits. If not eligible -  If "this roofing system does not meet our underwriting guidelines and we are unable to offer you a policy"',
    agentAction:
      'There are a lot of enclosed porches in Florida. If the flat roof is not over living area, it is acceptable to allow the home policy'
  },
  50065: {
    title: 'Tile Or Metal Roof over 25 Years Old',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50066: {
    title: 'Prior Loss Reported In Last 5 Years',
    agentScript:
      'In order to continue with a home policy, we need details of your prior loss and proof that the repairs were completed by a professional. Proof of repairs include paid receipts for work done that match up to the amount paid in your loss.',
    agentAction:
      "If can't provide or the proof of repairs does not match up to the amount of prior loss - decline due to failure to provide required documentation"
  },
  50067: {
    title: 'Two Property Or Liability Losses',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },
  50068: {
    title: 'No Prior Insurance',
    agentScript: `Please provide the reason why you have had a lapse of home insurance. \n If we are not allowing an exception for the lapse "we are unable to provide you a policy due to risk management"`,
    agentAction: 'If exception to be made, we must have a solid reason and no other adverse factors present'
  },
  50069: {
    title: 'Prior Policy Cancelled or Non-Renewed',
    agentScript: `Please provide the reason your prior home policy was canceled or non-renewed. \nIf we are not allowing an exception for the prior policy being cancelled or non-renewed "we are unable to provide you a policy due to risk management"`
  },
  50070: {
    title: 'Coverage A Greater Than 950K',
    agentScript: "Unfortunately, the dwelling coverage quoted exceeds Branch's maximum allowable limit",
    agentAction: 'Decline the offer - do not offer to lower dwelling coverage to make the risk eligible'
  },
  50071: {
    title: 'Sinkhole Coverage Requested',
    agentScript:
      'In order to continue to bind home coverage with the sinkhole endorsement, we need a sinkhole inspection completed by a qualified inspection company. Please note, these inspections are typically $300 paid by you. Your policy does cover you for catastrophic ground collapse coverage',
    agentAction: 'Must have UW manager AND product approval prior to allowing this coverage. '
  },
  50072: {
    title: 'Unconventional Construction Or Defective Materials',
    message: 'Properties with these characteristics are ineligible for our home program.'
  },

  50073: {
    title: 'Wind/Hail Exclusion Requested',
    agentScript:
      'Please completely fill out the wind exclusion form and submit so that we may be able to allow home policy binding',
    agentAction: 'Form has to be hand-written, not typed to be acceptable'
  },

  50074: {
    title: 'Requested No BI Coverage',
    message: 'This requires dev escalation if requested in order to offer auto.'
  },

  50075: {
    title: 'Lapse In Coverage',
    agentScript:
      'Please provide the reason why you have had a lapse in auto insurance. \nIf we are not allowing an exception for the lapse "this does not meet our underwriting guidelines and we are unable to offer you a policy"',
    agentAction: 'If exception to be made, we must have a solid reason and no other adverse factors present'
  },

  50076: {
    title: 'Too Many PIP Claims In Last 3 years',
    message: 'Drivers with these characteristics are ineligible in our program.'
  },

  50077: {
    title: 'Too Many At Fault Accidents In Last 3 years',
    message: 'Drivers with these characteristics are ineligible in our program.'
  },

  50078: {
    title: 'Too Many Accidents(Regardless of Fault) In Last 3 years',
    message: 'Drivers with these characteristics are ineligible in our program.'
  },

  50079: {
    title: 'More Than 2 Infractions In Last 3 years',
    message: 'Drivers with these characteristics are ineligible for our home program.'
  },

  50080: {
    title: 'Insurance Score Too Low/No Hit',
    message:
      'Try to rerun insurance score once confirmed spelling of name, DOB, etc. If still no hit and other adverse factors are present advise "we are unable to provide an auto policy at this time due to risk management"'
  },

  50081: {
    title: 'MVR No Hit Or UDR',
    message:
      'Try to rerun MVR once confirmed spelling of name, DOB, driver\'s license number, etc. If still no hit and other adverse factors are present advise "we are unable to provide an auto policy at this time due to risk management"'
  },

  50082: {
    title: 'SR22 Or FR44 Required',
    message: 'Potential members with these characteristics are ineligible in our program.'
  },
  50083: {
    title: "We don't cover condos that are rented out",
    message: 'If you believe this encompasses an extenuating circumstance, reach out to an Underwriter representative.'
  },
  // These errors prevent from quoting
  4000: {
    message: 'We have received an error. Here are the error details: [error_details]'
  },
  4001: {
    message: 'We have received an error. Here are the error details: [error_details]'
  },
  4002: {
    message:
      "Unfortunately, we are experiencing an issue with one of our data vendors and cannot provide you an offer at this time. Please check back later, or provide your email address and we'll let you know when your offer is ready"
  },
  4003: {
    message: 'Unfortunately, we are not able to write insurance policies in that state yet'
  },
  5001: {
    message:
      "We couldn't find you at that address. Please provide your most recent address prior to the one you already entered."
  },
  5002: {
    message: 'We will need your date of birth to properly identify you and complete your offer'
  },
  5003: {
    message:
      'We were not able to find information on your current Auto insurance provider. Please enter your current coverage limits and continuous years that you have had auto insurance.'
  },
  5004: {
    message: 'We were not able to find some information on your home. Please enter the details here.'
  },
  5005: {
    message: 'Please enter a VIN to receive an auto offer.'
  },
  5006: {
    message: 'Unfortunately, we cannot offer you insurance for this vehicle'
  },
  5007: {
    message: 'Unfortunately, we cannot offer you a price for insurance at this time.'
  },
  5008: {
    message: 'Unfortunately, we are not able to write insurance policies in that state yet.'
  },
  5009: {
    message:
      'Unfortunately, we are not able to write insurance on that vehicle. Please enter a different VIN, or continue to the next step.'
  },
  5010: {
    message:
      "That VIN doesn't match any vehicle in our database. Please check for typos and try again, or try to add a different vehicle."
  },
  5011: {
    message: 'The address is in a zip code in which we currently have a moratorium.'
  },
  5012: {
    message: "We need a driver's license number to proceed."
  },
  5013: {
    message: 'We do not allow PO Box addresses.'
  },
  5014: {
    message: 'Returned minimum premium; likely a rater error. Cannot proceed.'
  },
  5015: {
    message: "The primary driver has a UDR and no driver's license; please put in a driver's license."
  },
  5016: {
    message: 'We need more information on the primary insured.'
  },
  5017: {
    message: 'This state is restricted to only certain originators of offers.'
  },
  5018: {
    message:
      'We had an error in setting fees; for example, in Kentucky, finding the right local premium taxing authority.'
  },
  5019: {
    message: 'Our systems thought this quote looked suspicious and so did not let it complete.'
  },
  5020: {
    message: 'The offer details as submitted appeared blank to the system.'
  },
  5021: {
    message: 'This person has been quoted too many times in a row.'
  },
  5022: {
    message:
      'This person has cancelled for non-payment too many times and will no longer be allowed to purchase a new policy.'
  },
  5023: {
    message:
      'We did not find latitude and longitude for this address. Please re-generate the offer with the correct latitude and longitude to see a price.'
  },
  5024: {
    message: `We do not have a rate for this zip code in this state. This is most likely because of a typo in the zipcode; please check whether the zip code exists in the state. The second-most common reason for failure is that the zip code doesn't have anyone living in it, and is only used for PO Boxes. If neither of these two are the case, please contact rating support.`
  },
  0: {
    message: 'We have received an error. Here are the error details: [error_details]'
  }
};
