import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, getIn } from 'formik';
import { Grid } from '@material-ui/core';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import useStyles from './edit-location-details-modal.styles';

const EditLocationDetailsModal = ({ open, onClose, dwelling, dwellingLocation, fieldName, includeApt }) => {
  const classes = useStyles();
  const { setFieldValue, values, validateForm, setErrors, setTouched } = useFormikContext();

  const startingAddress = useRef(dwellingLocation);
  const initialNoUnitNumber = useRef(getIn(values, `${dwelling.toLowerCase()}.noUnitNumber`));

  const onSubmit = async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length) {
      setErrors(errors);
      setTouched(errors);
    } else {
      setFieldValue(`${fieldName}.address`, dwellingLocation.address.toUpperCase());
      setFieldValue(`${fieldName}.city`, dwellingLocation.city.toUpperCase());
      setFieldValue('correctedAddress.address', dwellingLocation.address.toUpperCase());
      setFieldValue('correctedAddress.city', dwellingLocation.city.toUpperCase());
      if (includeApt) {
        const noUnitNumber = getIn(values, `${dwelling.toLowerCase()}.noUnitNumber`);
        setFieldValue(`${fieldName}.address2`, noUnitNumber ? '' : dwellingLocation.address2?.toUpperCase());
      }
      onClose();
    }
  };

  const onExit = () => {
    setFieldValue(`${fieldName}.address`, startingAddress.current.address);
    setFieldValue(`${fieldName}.city`, startingAddress.current.city);
    if (includeApt) {
      setFieldValue(`${fieldName}.address2`, startingAddress.current.address2);
      setFieldValue(`${dwelling.toLowerCase()}.noUnitNumber`, initialNoUnitNumber.current);
    }
    onClose();
  };

  return (
    <BaseDialog open={open} onClose={onExit} size="md">
      <Label type="greenBig" className={classes.modalHeader}>
        Modify Address
      </Label>
      <Label type="greenSmall" className={classes.titleSecondary}>
        Change the address of the insured property as it will appear on documents
      </Label>
      <Grid container direction="column" className={classes.addressContainer}>
        <FormField name={`${fieldName}.address`} type="string" label="street address" mode="light" xs={12} />
        {includeApt && (
          <Grid container spacing={4}>
            <FormField
              name={`${fieldName}.address2`}
              type="string"
              label="Floor, unit, building, etc."
              mode="light"
              xs={6}
              className={classes.addressField}
            />
            <Grid item className={classes.noUnitNumber} xs={6}>
              <FormField
                type="checkbox"
                name={`${dwelling.toLowerCase()}.noUnitNumber`}
                mode="light"
                label={`${dwelling} does not have an apt/unit number`}
                fast={false}
                item
              />
            </Grid>
          </Grid>
        )}
        <FormField name={`${fieldName}.city`} type="string" label="city" mode="light" xs={12} />
        <FormField
          name="stateAndZip"
          type="string"
          label="state/zip"
          mode="light"
          xs={12}
          value={`${dwellingLocation.state}/${dwellingLocation.zip}`}
          disabled
        />
      </Grid>
      <Button className={classes.saveBtn} variant="contained" color="primary" onClick={onSubmit}>
        Save Changes
      </Button>
    </BaseDialog>
  );
};

EditLocationDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dwelling: PropTypes.string,
  dwellingLocation: PropTypes.object.isRequired,
  fieldName: PropTypes.string,
  includeApt: PropTypes.bool
};

EditLocationDetailsModal.defaultProps = {
  dwelling: 'Home',
  fieldName: 'home.homeLocation',
  includeApt: false
};

export default EditLocationDetailsModal;
