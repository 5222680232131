import { makeStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    marginTop: theme.spacing(8)
  },
  personLabel: {
    display: 'inline',
    marginRight: theme.spacing(2)
  },
  collapsibleContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(8),
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners,
    paddingBottom: theme.spacing(7)
  },
  cardFooter: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(4, 8),
    borderTop: `1px solid ${theme.colorPalette.beige_10}`,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  }
});

export default makeStyles(useStyles);
