import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  linkButton: {
    height: 36,
    width: 36
  },
  rightLabel: {
    fontSize: 14,
    display: 'inline',
    marginRight: theme.spacing(2)
  },
  rightValue: {
    fontSize: 14,
    display: 'inline'
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

export default makeStyles(styles);
