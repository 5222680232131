import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { Label } from 'core';
import { Button } from 'core/components/button';
import { useStore } from 'core/store';
import Field from 'core/components/form/form.v2';
import { validationSchema } from './footer.validation';
import useStyles from './footer.styles';

const FooterRight = ({ disabled, handleSubmit, loadingEmail }) => {
  const classes = useStyles();
  const {
    quote: { offer, loading }
  } = useStore();
  const staffHref = useMemo(() => {
    if (offer) {
      return offer.id;
    }
  }, [offer]);
  return (
    <Formik
      initialValues={{
        ...offer.quote,
        email: offer.quote.email || ''
      }}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <div className={classes.footerRightContainer}>
        <Form className={classes.form}>
          <Field mode="light" type="string" name="email" id="email" label="Email" />
          <Button
            loading={loadingEmail}
            disabled={disabled || loadingEmail || loading}
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.emailBtn}
          >
            Email Link
          </Button>
        </Form>
        <Button
          target="_blank"
          href={`/offer/${staffHref}`}
          disabled={disabled || loading || loadingEmail}
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.btn}
        >
          <Label className={classes.btnLabel}>Go to offer</Label>
        </Button>
      </div>
    </Formik>
  );
};

FooterRight.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loadingEmail: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default FooterRight;
