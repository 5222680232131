import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  modal: {
    width: 580
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  title: {
    lineHeight: 1.4
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    marginTop: theme.spacing(4)
  },
  cancel: {
    fontSize: 16,
    width: 85
  },
  newQuote: {
    fontSize: 16,
    width: 180
  }
});

export default makeStyles(styles);
