import { paymentType as PaymentType } from '@ourbranch/lookups';

/**
 * Given and offer a policy type returns a formatted string with
 * the calculated monthly and total prices with all their variations
 * @param {object} offer
 * @param {string} policyType
 * @param {function} usePriceCalculator
 */
const usePlanPrice = (offer, policyType, usePriceCalculator) => {
  const isHomeMonthly = offer ? offer.quote.global.homeownersPaymentType === PaymentType.Monthly : undefined;
  const isAutoMonthly = offer ? offer.quote.global.autoPaymentType === PaymentType.Monthly : undefined;
  const isRentersMonthly = offer ? offer.quote.global.rentersPaymentType === PaymentType.Monthly : undefined;
  const isCondoMonthly = offer ? offer.quote.global.condoPaymentType === PaymentType.Monthly : undefined;

  const priceData = usePriceCalculator(offer, policyType);

  return { isHomeMonthly, isAutoMonthly, isRentersMonthly, isCondoMonthly, ...priceData };
};

export default usePlanPrice;
