import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(10)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(10)
  },
  form: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
    flexDirection: 'column-reverse',
    padding: theme.spacing(8, 4, 42)
  },
  purchaseButton: {
    width: 260,
    height: 36,
    margin: theme.spacing(0, 'auto'),
    display: 'block',
    marginTop: theme.spacing(14)
  }
});

export default makeStyles(styles);
