import React, { useRef, useMemo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Form, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';

import { lookupsJson } from '@ourbranch/lookups';
import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { withStore } from 'core/store';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { useErrors } from '../../helpers/error-hooks';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';
import { MoreInfoNeededFlagItem } from '../new-construction-flag-item';
import { priorAddressCode } from '../quote-forms/quote-forms.constants';

import styles from './prior-address.styles';

const priorAddressCondition = (code) => priorAddressCode.includes(code);

const PriorAddress = ({ classes, store, showPriorAddressCallout, sectionsToShow }) => {
  const {
    quote: { errors: quoteErrors }
  } = store;
  const errors = useErrors(getSnapshot(quoteErrors), priorAddressCondition);
  const priorAddressErrors = useRef(null);
  const { values } = useFormikContext();
  const [shouldShow, setShouldShow] = useState(false);

  const { stateOptions } = useMemo(() => {
    return {
      stateOptions: lookupsJson.usStates
    };
  }, []);

  useEffect(() => {
    if (priorAddressErrors.length && priorAddressErrors.current) {
      priorAddressErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    const shouldBeShown = priorAddressCode.every((element) => sectionsToShow.includes(element));
    setShouldShow(shouldBeShown);
  }, [sectionsToShow]);

  return (
    <Form>
      {(values.isNewConstruction || values.isNewPurchase) && <MoreInfoNeededFlagItem />}
      {(errors.length > 0 || values.isNewConstruction || shouldShow || values.isNewPurchase) && (
        <>
          <Errors errors={errors} ref={priorAddressErrors} />
          <Collapsible title="Prior Address" forceOpen={showPriorAddressCallout}>
            <>
              <Grid item xs={8}>
                <AddressAutocomplete id="priorAddress.address" name="priorAddress" mode="light" label="Address*" />
              </Grid>
              <Grid item xs={4}>
                <Field
                  type="string"
                  id="priorAddress.unit"
                  name="priorAddress.unit"
                  mode="light"
                  label="Floor, unit, building, etc."
                />
              </Grid>
              <Grid item xs={4}>
                <Field type="string" id="priorAddress.city" name="priorAddress.city" mode="light" label="City*" />
              </Grid>
              <Grid item xs={4}>
                <Field
                  type="autocomplete"
                  options={stateOptions}
                  id="priorAddress.state"
                  name="priorAddress.state"
                  mode="light"
                  label="State*"
                  stateSelectForm
                />
              </Grid>
              <Grid item xs={4}>
                <Field type="string" id="priorAddress.zip" name="priorAddress.zip" mode="light" label="Zip Code*" />
              </Grid>
            </>
          </Collapsible>
        </>
      )}
    </Form>
  );
};

PriorAddress.propTypes = {
  showPriorAddressCallout: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withDatePicker, withStyles(styles), withStore)(PriorAddress);
