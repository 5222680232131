import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { Tooltip } from 'core/components/tooltip';
import { Button } from 'core/components/button';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { useSwapAppCoApp } from 'common/components/people/hooks';
import { useDisabledState } from 'common/disabled-context';
import { ReactComponent as SwapIcon } from './swap.svg';
import useStyles from './swap-button.styles';

function SwapButton({ allowLicensedActions, formikPath }) {
  const classes = useStyles();
  const { onSwap, disableSwapButton } = useSwapAppCoApp({ formikPath, allowLicensedActions });
  const { disabled: globalDisabled } = useDisabledState();

  const disabled = disableSwapButton || globalDisabled;

  return (
    <Grid item>
      <Button
        className={classNames(classes.swapButton, {
          [classes.swapButtonDisabled]: disabled
        })}
        onClick={onSwap}
        disabled={disabled}
        color="secondary"
      >
        Swap Main and Co-Applicant
        <SwapIcon className={classes.swapIcon} />
      </Button>
      {disableSwapButton && (
        <Tooltip
          text={
            !allowLicensedActions
              ? tooltipHoverTexts.swapButtonDisabledForLicenseOrPermissions
              : tooltipHoverTexts.swapButtonDisabledNeedsSaved
          }
          label="More Info"
          placement="bottom"
          className={classes.swapTooltip}
        />
      )}
    </Grid>
  );
}

export default SwapButton;
