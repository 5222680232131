import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  option: {
    color: theme.colorPalette.white_10
  },
  plus: {
    color: theme.colorPalette.beige_10_op_75
  },
  disabled: {
    color: theme.colorPalette.beige_10_op_13
  }
});

export default makeStyles(styles);
