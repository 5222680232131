import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: theme.spacing(4),
    backgroundColor: theme.colorPalette.orange_50,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: theme.spacing(4),
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  },
  altContainer: {
    width: '100%',
    marginTop: theme.spacing(4),
    backgroundColor: theme.colorPalette.green_40,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: theme.spacing(4),
    padding: 15,
    color: theme.colorPalette.beige_10
  },
  icon: {
    marginRight: theme.spacing(4),
    width: 50,
    height: 30,
    color: theme.colorPalette.white_10
  },
  styledText: {
    display: 'block',
    lineHeight: 1.3
  },
  button: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colorPalette.white_10
  }
}));

export default useStyles;
