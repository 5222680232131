import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerDark: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(6, 8)
  },
  container: {
    width: '100%',
    marginBottom: 0
  },
  mortgagesContainer: {
    padding: theme.spacing(0, 6, 3, 8),
    paddingTop: 0,
    backgroundColor: theme.colors.card_background_primary_editable,
    borderTop: `1px solid ${theme.colorPalette.white_10}`
  },
  sectionRightValue: {
    fontWeight: 'normal'
  },
  leftItem: {
    paddingRight: theme.spacing(5)
  },
  rightItem: {
    paddingLeft: theme.spacing(5)
  },
  streetMapText: {
    color: `${theme.colorPalette.orange_10} !important`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 12
  }
}));

export default useStyles;
