import { getOptions } from '@ourbranch/lookups';

import { types } from 'core/helpers/sanitize';

export const categories = getOptions('scheduledPersonalPropertyCategory');
export const nesting = getOptions('scheduledPersonalPropertyNesting');
const exclude = nesting.reduce((prev, option) => prev.concat(option.options), []); // Concat every option to exclude from main dropdown
export const options = [...categories, ...nesting].filter((item) => !exclude.includes(item.id)); // Build main dropdown with parent categories

export const Sports = options.find((item) => item.id === 'Sports');

const customName = (name, fieldName = '') => (fieldName ? `${fieldName}.${name}` : name);

export const checkboxes = (classes, id = '', name = '') => ({
  Musical_Instrument: {
    id: id || 'professional',
    name: customName('professional', name),
    label: 'Professional?'
  },
  Jewelry_Items: {
    id: id || 'jewelry',
    name: customName('jewelry', name),
    label: 'Gemprint?'
  },
  Sports_Guns: {
    id: id || 'gunsUsaWorld',
    name: customName('gunsUsaWorld', name),
    type: 'radio',
    options: [
      { value: 'Sports_USA_Guns', label: 'US' },
      { value: 'Sports_World_Guns', label: 'Worldwide' }
    ],
    props: {
      classes: {
        container: classes.checkbox,
        controlLabel: classes.controlLabel
      }
    }
  },
  Sports_Fishing: {
    id: id || 'deepSea',
    name: customName('deepSea', name),
    label: 'Deep Sea?'
  },
  Coin: {
    id: id || 'exTheftOption',
    name: customName('exTheftOption', name),
    label: 'Add coverage for an unattended automobile theft'
  },
  Stamp: {
    id: id || 'exTheftOption',
    name: customName('exTheftOption', name),
    label: 'Add coverage for an unattended automobile theft'
  }
});

export function getLabel(category) {
  if (category === 'Fine_Arts') {
    return 'Breakage coverage included.';
  }
}

export function getCategory(val) {
  const { category, subCategory, professional, gunsUsaWorld, jewelry, deepSea } = val;
  if (category === 'Sports') {
    if (subCategory === 'Sports_Guns') {
      return gunsUsaWorld || 'Sports_USA_Guns';
    }

    if (subCategory === 'Sports_Fishing') {
      if (typeof deepSea !== 'boolean') {
        return subCategory;
      }
      return deepSea ? 'Sports_Deep_Sea' : 'Sports_Fish_Other';
    }

    return subCategory || category;
  }
  if (category === 'Musical_Instrument') {
    return professional ? 'Musical_Pro' : 'Musical_NonPro';
  }

  if (category === 'Jewelry_Items') {
    return jewelry ? 'Gemprint_Jewelry' : 'Jewelry';
  }

  if (category === 'Sports_Fishing') {
    return deepSea ? 'Sports_Deep_Sea' : 'Sports_Fish_Other';
  }

  return category;
}

const getParent = (subCategory) => options.find((item) => item.options && item.options.includes(subCategory));

export function parseValues(val) {
  const { category, subCategory, deepSea, gunsUsaWorld } = val;
  if (subCategory && category === 'Sports') {
    if (subCategory === 'Sports_Guns') {
      return {
        ...val,
        gunsUsaWorld: gunsUsaWorld || 'Sports_USA_Guns'
      };
    }
    if (subCategory === 'Sports_Fishing') {
      return {
        ...val,
        gunsUsaWorld: undefined
      };
    }
    return {
      ...val,
      gunsUsaWorld: undefined,
      deepSea: undefined
    };
  }
  const SportsGuns = nesting.find((item) => item.id === 'Sports_Guns');
  if (SportsGuns && SportsGuns.options && SportsGuns.options.includes(category)) {
    const gunsCat = getParent('Sports_Guns');
    return {
      ...val,
      subCategory: SportsGuns.id,
      category: gunsCat ? gunsCat.id : undefined,
      gunsUsaWorld: category
    };
  }
  const found = nesting.find(({ options: itemOpts }) => (itemOpts ? itemOpts.includes(category) : undefined));
  if (found) {
    const parent = getParent(found.id);
    if (parent) {
      return {
        ...val,
        category: parent.id,
        subCategory: parent.id === 'Sports' ? found.id : undefined,
        deepSea: found.id === 'Sports_Fishing' ? category === 'Sports_Deep_Sea' : undefined
      };
    }
    return {
      ...val,
      category: found.id,
      subCategory: found.id === 'Sports' ? category : undefined,
      deepSea: category === 'Sports_Fishing' ? category === 'Sports_Deep_Sea' || deepSea : undefined,
      professional: found.id === 'Musical_Instrument' ? category === 'Musical_Pro' : undefined,
      jewelry: found.id === 'Jewelry_Items' ? category === 'Gemprint_Jewelry' : undefined
    };
  }
  // clean subcategory when not sport
  return {
    ...val,
    professional: category === 'Musical_Instrument' ? val.professional : undefined,
    jewelry: category === 'Jewelry_Items' ? !!val.jewelry : undefined,
    subCategory: undefined,
    gunsUsaWorld: undefined,
    deepSea: undefined
  };
}

export function getValues(values) {
  const { subCategory, category, gunsUsaWorld, ...vals } = parseValues(values);
  return {
    ...vals,
    subCategory,
    category,
    gunsUsaWorld
  };
}

export const formatSPP = (scheduledPP) => {
  if (!scheduledPP) {
    return {
      deductible: 0,
      items: []
    };
  }
  if (!scheduledPP.items || !scheduledPP.items.length) {
    return {
      deductible: 0,
      items: []
    };
  }
  return {
    ...scheduledPP,
    items: scheduledPP.items.map((val) =>
      types.scheduledPersonalPropertyItemDetailsInput({
        ...val,
        category: getCategory(val),
        breakageOption: true,
        exTheftOption: val.exTheftOption || false
      })
    )
  };
};

export const sortItems = (items) => {
  /* sort items ascending according to
  1. non-stamps and non-coins first
  2. then sort by oldest added
  */
  const coinsAndStamps = ['Stamp', 'Coin'];
  const compare = (a, b) => {
    // non-coins/stamp and non-coin/stamp are equal
    if (!coinsAndStamps.includes(getCategory(a)) && !coinsAndStamps.includes(getCategory(b))) {
      return 0;
    }
    // if item a is a coin or stamp move it right
    if (coinsAndStamps.includes(getCategory(a))) {
      return 1;
    }
    // otherwise move it left
    return -1;
  };
  return items.sort(compare);
};
