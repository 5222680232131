import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { lookupsJson, standaloneRentersStates } from '@ourbranch/lookups';

import { getPolicyType } from 'core/helpers/policy-type';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { numberFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Loading } from 'core/components/loading';
import { Button } from 'core/components/button';
import usePlanPrice from '../../hooks/use-plan-price';
import useDetailedPrice from '../../hooks/use-detailed-price';
import { PriceDetail } from './components/price-details-card/price-detail';
import { Additionals } from './components/additionals';
import { Downloads } from './components/downloads';
import useStyles from './sidebar.styles';

const CheckoutSidebar = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { offer: offerStore } = useStore();

  const { offer, selectedOption: selectedOptionFromStore } = offerStore;

  const selectedOption = getQuoteFriendlySelectedOption(selectedOptionFromStore);
  const prices = usePlanPrice(offer, selectedOption, useDetailedPrice);

  const handleModifyPaymentOptionsClick = useCallback(() => {
    offerStore.setSelectedOption(selectedOption);
    history.push(`/offer/${offer.id}`);
  }, [selectedOption, offer?.id]);

  if (!offerStore?.offer) {
    return <Loading />;
  }

  const { hasHome, hasAuto, hasCondo, isRenters } = getPolicyType(
    selectedOption,
    offer.quote?.noBindHome,
    offer.quote?.noBindAuto
  );

  const option = offer.options.find((x) => x.type === selectedOption);

  const isStandaloneRenters = isRenters && standaloneRentersStates[offerStore.state];

  return (
    <Grid>
      <Card className={classes.checkoutContainer}>
        {hasHome && hasAuto && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Home & Auto
          </Label>
        )}
        {hasCondo && hasAuto && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Condo & Auto
          </Label>
        )}
        {hasAuto && isRenters && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Auto & Renters
          </Label>
        )}
        {hasHome && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Home
            </Label>
            <PriceDetail
              name="Home"
              type="Home"
              isMonthly={prices.isHomeMonthly}
              downPaymentDate={prices.homeDownPaymentDate}
              remainingPayments={prices.homeRemainingPayments}
              remainingPaymentsAmount={prices.homeRemainingPaymentsAmount}
              payment={prices.homePrice}
              paymentMethod={
                lookupsJson.homeownersPaymentMethod.find((t) => t.id === offer.quote.global.homeownersPaymentMethod)
                  .value
              }
              paymentType={lookupsJson.paymentType.find((t) => t.id === offer.quote.global.homeownersPaymentType).value}
              termTotal={option?.homeTotal}
              policyType={option?.type}
              nextPayments={prices.homeNextPayments}
            />
          </div>
        )}
        {hasCondo && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Condo
            </Label>
            <PriceDetail
              name="Condo"
              type="Condo"
              isMonthly={prices.isCondoMonthly}
              downPaymentDate={prices.condoDownPaymentDate}
              remainingPayments={prices.condoRemainingPayments}
              remainingPaymentsAmount={prices.condoRemainingPaymentsAmount}
              payment={prices.condoPrice}
              paymentMethod={
                lookupsJson.condoPaymentMethod.find((t) => t.id === offer?.quote?.global?.condoPaymentMethod).value
              }
              paymentType={lookupsJson.paymentType.find((t) => t.id === offer?.quote?.global?.condoPaymentType).value}
              termTotal={option?.condoTotal}
              policyType={option?.type}
              nextPayments={prices.condoNextPayments}
            />
          </div>
        )}
        {hasAuto && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Auto
            </Label>
            <PriceDetail
              name="Auto"
              type="Auto"
              isMonthly={prices.isAutoMonthly}
              downPaymentDate={prices.autoDownPaymentDate}
              remainingPayments={prices.autoRemainingPayments}
              remainingPaymentsAmount={prices.autoRemainingPaymentsAmount}
              payment={prices.autoPrice}
              paymentMethod={
                lookupsJson.autoPaymentMethod.find((t) => t.id === offer.quote.global.autoPaymentMethod).value
              }
              paymentType={lookupsJson.paymentType.find((t) => t.id === offer.quote.global.autoPaymentType).value}
              termTotal={option?.autoTotal}
              policyType={option?.type}
              autoTotalAR={isRenters ? numberFormatter(prices.autoTotalAR, 2) : null}
              rentersTotalAR={isRenters ? numberFormatter(prices.rentersTotalAR, 2) : null}
              nextPayments={prices.autoNextPayments}
              standaloneRenters={isStandaloneRenters}
            />
          </div>
        )}
        {isStandaloneRenters && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Renters
            </Label>
            <PriceDetail
              name="Renters"
              type="Renters"
              isMonthly={prices.isRentersMonthly}
              downPaymentDate={prices.rentersDownPaymentDate}
              remainingPayments={prices.rentersRemainingPayments}
              remainingPaymentsAmount={prices.rentersRemainingPaymentsAmount}
              payment={prices.rentersPrice}
              paymentMethod={
                lookupsJson.rentersPaymentMethod.find((t) => t.id === offer.quote.global.rentersPaymentMethod).value
              }
              paymentType={
                lookupsJson.rentersPaymentType.find((t) => t.id === offer.quote.global.rentersPaymentType).value
              }
              termTotal={option?.rentersTotal}
              policyType={option?.type}
              nextPayments={prices.rentersNextPayments}
              standaloneRenters
            />
          </div>
        )}
        <Button className={classes.backLink} onClick={handleModifyPaymentOptionsClick}>
          Back to modify payment options
        </Button>
      </Card>
      {offerStore.shouldShowAdditionalPaymentsSection && <Additionals />}
      <Downloads />
    </Grid>
  );
});

export default CheckoutSidebar;
