import React from 'react';
import { getOptions } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import useStyles from './residence-insurance-type.styles';

const ResidenceInsuranceType = () => {
  const classes = useStyles();
  const residenceInsuranceTypeOptions = getOptions('residenceInsuranceType');

  return (
    <Section title="Residence Insurance" type="SubSection">
      <Card className={classes.container}>
        <LabelTooltip
          mode="light"
          label="Current Residence Insurance"
          tooltip={{
            label: 'More Info',
            onHoverText: 'This information is required for accurate Auto policy pricing.'
          }}
        >
          <FormField
            name="auto.residenceInsuranceType"
            type="select"
            mode="light"
            options={residenceInsuranceTypeOptions}
          />
        </LabelTooltip>
      </Card>
    </Section>
  );
};

export default ResidenceInsuranceType;
