import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  swapButton: {
    color: theme.colors.button__background_secondary,
    width: 250
  },
  swapButtonDisabled: {
    color: theme.colorPalette.orange_50,
    opacity: '50%'
  },
  swapIcon: {
    marginLeft: 10
  },
  swapTooltip: {
    width: 50,
    marginLeft: 15,
    top: -10,
    position: 'relative'
  }
}));

export default useStyles;
