import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateToJs } from 'core/helpers/formatters';
import useSession from 'core/hooks/use-session';
import useStyles from './cancellation-form.styles';

const FlatCancelReasons = ['CNDP', 'CNMS', 'CNRW'];
const EndDateOfTodayReasons = ['CNDB', 'CNAP', 'CNDC', 'CNDE', 'CNMI', 'CNMV', 'CNNO', 'CNDA', 'CNPR', 'CNSO'];
const PermissionGatedReasonsDetails = [
  { code: 'CNMS', permission: 'canCancelReasonCNMS' },
  { code: 'CNIP', permission: 'canCancelReasonCNIP' },
  { code: 'CNRW', permission: 'canRewritePolicy' }
];
const PermissionGatedReasons = PermissionGatedReasonsDetails.map((detail) => detail.code);

const CancellationForm = () => {
  const classes = useStyles();
  const [disableEndDate, setDisableEndDate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const { endDate, effectiveDate } = values;
  const session = useSession();
  const cancelReasons = lookupsJson.cancelReason
    .filter((cancelReasonOption) => !cancelReasonOption.deprecated)
    .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0))
    .map((cancelReasonOption) => {
      if (PermissionGatedReasons.includes(cancelReasonOption.id)) {
        const { permission } = PermissionGatedReasonsDetails.find((detail) => detail.code === cancelReasonOption.id);
        return {
          ...cancelReasonOption,
          disabled: !session[permission]
        };
      }
      return cancelReasonOption;
    });

  const original = awsDateToJs(values.fullTermPolicyEndDate);
  const maxDate = new Date(new Date(original).setDate(original.getUTCDate() - 1));
  const minDate = new Date(effectiveDate);
  const today = new Date().toISOString().split('T')[0];
  minDate.setDate(new Date(effectiveDate).getDate() + 1);

  useEffect(() => {
    if (endDate !== effectiveDate && values.term === 1) {
      setFieldValue('applyFee', true);
    } else if (endDate === effectiveDate) {
      setFieldValue('applyFee', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, effectiveDate, values.term]);

  const updateSuggestedEndDate = (cancelReason) => {
    if (FlatCancelReasons.includes(cancelReason)) {
      setFieldValue('endDate', effectiveDate);
      setDisableEndDate(true);
    } else if (EndDateOfTodayReasons.includes(cancelReason)) {
      setFieldValue('endDate', today);
      setDisableEndDate(false);
    } else {
      setFieldValue('endDate', undefined);
      setDisableEndDate(false);
    }
  };

  return (
    <Grid container key="details" className={classes.form}>
      <FormField
        name="cancelReason"
        type="select"
        label="Cancel Reason"
        mode="light"
        options={cancelReasons}
        xs={5}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        onChange={updateSuggestedEndDate}
      />
      <FormField
        name="endDate"
        type="date"
        label="End Date"
        mode="light"
        disableFuture={false}
        maxDate={maxDate}
        minDate={minDate}
        initialFocusedDate={maxDate}
        className={classes.endDate}
        xs={4}
        disabled={disableEndDate}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        defaultToEmpty
      />
      <FormField
        type="checkbox"
        id="applyFee"
        name="applyFee"
        mode="light"
        label="Apply Fee"
        xs={2}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
      />
    </Grid>
  );
};

export default withDatePicker(CancellationForm);
