import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  checkboxContainer: {
    margin: 'auto'
  },
  fullWidthCheckbox: {
    display: 'flex',
    margin: 'auto',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export default makeStyles(styles);
