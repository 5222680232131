import { useMemo } from 'react';
import isToday from 'date-fns/isToday';
import { paymentType as PaymentType } from '@ourbranch/lookups';

import { awsDateToStringDate, dateFormatter } from 'core/helpers/formatters';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const AWS_DATE_FORMAT = 'yyyy-MM-dd';

function getDownPaymentDate(effectiveDateStr) {
  const effectiveDate = new Date(effectiveDateStr);
  return isToday(new Date(effectiveDate)) ? 'TODAY' : dateFormatter(effectiveDate);
}

const useDetailedPrice = (offer, policyType) => {
  return useMemo(() => {
    if (!offer || !offer.options) {
      return {};
    }

    const { homeownersPaymentType, autoPaymentType, autoEffectiveDate, rentersPaymentType, condoPaymentType } =
      offer.quote.global;

    const isHomeMonthly = homeownersPaymentType === PaymentType.Monthly;
    const isAutoMonthly = autoPaymentType === PaymentType.Monthly;
    const isRentersMonthly = rentersPaymentType === PaymentType.Monthly;
    const isCondoMonthly = condoPaymentType === PaymentType.Monthly;

    let {
      homeBillingDayOfMonth,
      autoBillingDayOfMonth,
      rentersBillingDayOfMonth,
      rentersEffectiveDate,
      condoBillingDayOfMonth
    } = offer.quote.global;

    const {
      homeTotal,
      autoTotal,
      autoCoverages,
      rentersTotal,
      condoTotal,
      homeSchedule,
      autoSchedule,
      rentersSchedule,
      condoSchedule
    } = offer.options.find((x) => x.type === policyType) || {};

    // we need a billing day to figure out the payment amount in the case of monhtly down payment, so we choose today or at latest
    if (!homeBillingDayOfMonth) {
      homeBillingDayOfMonth = Math.min(new Date().getDate(), 28);
    }

    if (!autoBillingDayOfMonth) {
      autoBillingDayOfMonth = Math.min(new Date().getDate(), 28);
    }

    if (!rentersBillingDayOfMonth) {
      rentersBillingDayOfMonth = Math.min(new Date().getDate(), 28);
    }

    if (!condoBillingDayOfMonth) {
      condoBillingDayOfMonth = Math.min(new Date().getDate(), 28);
    }

    // older offers may not have this
    if (!rentersEffectiveDate) {
      rentersEffectiveDate = autoEffectiveDate;
    }

    const prices = {};
    if (isHomeMonthly && homeSchedule) {
      const [firstPayment, ...remainingPayments] = homeSchedule;

      prices.homeDownPaymentDate = firstPayment.date;
      prices.homeRemainingPayments = remainingPayments.length;
      prices.homePrice = firstPayment.remainingMonthly;
      prices.homeRemainingPaymentsAmount = remainingPayments[0].remainingMonthly;
      prices.homeNextPayments = homeSchedule;
    } else {
      prices.homePrice = homeTotal;
    }

    if (isAutoMonthly && autoSchedule) {
      const [firstPayment, ...remainingPayments] = autoSchedule;

      prices.autoDownPaymentDate = firstPayment.date;
      prices.autoRemainingPayments = remainingPayments.length;
      prices.autoPrice = firstPayment.remainingMonthly;
      prices.autoRemainingPaymentsAmount = remainingPayments[0].remainingMonthly;
      prices.autoNextPayments = autoSchedule;
    } else {
      prices.autoPrice = autoTotal;
      prices.autoDownPaymentDate = getDownPaymentDate(awsDateToStringDate(autoEffectiveDate));
    }

    if (isCondoMonthly && condoSchedule) {
      const [firstPayment, ...remainingPayments] = condoSchedule;

      prices.condoPrice = firstPayment.remainingMonthly;
      prices.condoRemainingPaymentsAmount = remainingPayments[0].remainingMonthly;
      prices.condoRemainingPayments = remainingPayments.length;
      prices.condoDownPaymentDate = firstPayment.date;
      prices.condoNextPayments = condoSchedule;
    } else {
      prices.condoPrice = condoTotal;
    }

    if (isRentersMonthly && rentersSchedule) {
      const [firstPayment, ...remainingPayments] = rentersSchedule;

      prices.rentersDownPaymentDate = firstPayment.date;
      prices.rentersRemainingPayments = remainingPayments.length;
      prices.rentersPrice = firstPayment.remainingMonthly;
      prices.rentersRemainingPaymentsAmount = remainingPayments[0].remainingMonthly;
      prices.rentersNextPayments = rentersSchedule;
    } else {
      prices.rentersPrice = rentersTotal;
      // use a fallback
      prices.rentersPaymentDate = getDownPaymentDate(awsDateToStringDate(rentersEffectiveDate));
    }

    if (policyType === 'AR' && autoCoverages) {
      const rentersAmount = autoCoverages
        .filter((c) => c.type === 'renters_total')
        .reduce((acc, c) => {
          return acc + c.amount;
        }, 0);
      const autoAmount = autoTotal - rentersAmount;
      prices.rentersTotalAR = isAutoMonthly ? rentersAmount / 6 : rentersAmount;
      prices.autoTotalAR = isAutoMonthly ? autoAmount / 6 : autoAmount;
    }

    return prices;
  }, [offer, policyType]);
};

export default useDetailedPrice;
