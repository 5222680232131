import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  form: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexBasis: 'auto'
  }
});

export default makeStyles(styles);
