import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import { getPolicyType } from 'core/helpers/policy-type';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { standaloneRentersStates } from '@ourbranch/lookups';
import useStyles from './attestations.styles';

const ownHomeWithoutProtectionText = `You assert that you or your spouse own this home, you are not running a business on the property, and there are no underground oil tanks on the property. You also assert and attest that this home, including its premises and any additional structures, does not have any existing damage, including, but not limited to, damage caused by a storm, flood, or fire.`;
const ownHomeWithProtectionText = `You assert that you or your spouse own this home, there are no underground oil tanks on the property, and its premises are not used to run a business except for home hosting or work from home activities. You also assert and attest that this home, including its premises and any additional structures, does not have any existing damage, including, but not limited to, damage caused by a storm, flood, or fire.`;
const ownCarstext = `You assert that your name or your spouse's name is on the title of the car(s) and you receive no payment for their use. You also attest that these cars do not have any existing damage, including, but not limited to, damage caused by accident, storm, flood or fire.`;
const ownCarsRideSharingtext = `You assert that your name or your spouse's name is on the title of the car(s) and you receive no payment for their use except for ride-sharing activities. You also attest that these cars do not have any existing damage, including, but not limited to, damage caused by accident, storm, flood or fire.`;
const rentersText =
  'You assert that you or your spouse legally rents the property listed as address and you are not running a business on the property.';
const condoText =
  'You assert that you or your spouse own this condo, and you are not running a business on its premises. You also assert and attest that this condo does not have any existing damage, including, but not limited to, damage caused by a storm, flood, or fire.';

const Attestation = observer(function Attestation() {
  const { offer: offerStore } = useStore();
  const classes = useStyles();
  const { offer } = offerStore;
  const selectedOption = getQuoteFriendlySelectedOption(offerStore?.selectedOption || 'HA');
  const { hasHome, hasAuto, hasCondo, isRenters } = getPolicyType(
    selectedOption,
    offer?.quote?.noBindHome,
    offer?.quote?.noBindAuto
  );
  const isStandaloneRenters = isRenters && standaloneRentersStates[offer.quote.correctedAddress.state];
  const hasRideSharing = offer?.quote?.cars?.some((c) => c.rideSharing);
  const hasHomeownerProtection = offer?.quote?.homeCoverage?.homeownerProtection;

  return (
    <Grid container>
      {hasHome && (
        <FormField
          name="attestationsHomeAccepted"
          type="checkbox"
          label={hasHomeownerProtection ? ownHomeWithProtectionText : ownHomeWithoutProtectionText}
          mode="light"
          className={classes.row}
          topAlignCheckbox
        />
      )}
      {hasCondo && (
        <FormField
          name="attestationsCondoAccepted"
          type="checkbox"
          label={condoText}
          mode="light"
          className={classes.row}
          topAlignCheckbox
        />
      )}
      {hasAuto && (
        <FormField
          name="attestationsAutoAccepted"
          type="checkbox"
          label={hasRideSharing ? ownCarsRideSharingtext : ownCarstext}
          mode="light"
          className={classes.row}
          topAlignCheckbox
        />
      )}
      {isStandaloneRenters && (
        <FormField
          name="attestationsRentersAccepted"
          type="checkbox"
          label={rentersText}
          mode="light"
          topAlignCheckbox
        />
      )}
    </Grid>
  );
});

Attestation.propTypes = {
  offer: PropTypes.object.isRequired
};

export default Attestation;
