import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLHomeLegalFinancialQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Legal, financial, and prior history</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Is your home owned by a corporation, estate, partnership, LLC, LP, or LLP?
        </Label>
        <FormField
          className={classes.formItem}
          id="home.isOwnedByCorpOrPartnershipEtc"
          name="home.isOwnedByCorpOrPartnershipEtc"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Is your home in foreclosure?</Label>

        <FormField
          className={classes.formItem}
          id="home.isInForeclosure"
          name="home.isInForeclosure"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Does your home have more than two mortgages?</Label>
        <FormField
          className={classes.formItem}
          id="home.hasMoreThan2Mortgages"
          name="home.hasMoreThan2Mortgages"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Have you filed a homeowner claim within the past 5 years?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Branch allows only one prior loss and the proof of repairs must be sent and received.
        </Label>
        <FormField
          className={classes.formItem}
          id="home.hadPriorLossIn5Years"
          name="home.hadPriorLossIn5Years"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          In the last 5 years, has Branch or any other insurance company canceled, declined, or non-renewed your home
          insurance?
        </Label>
        <FormField
          className={classes.formItem}
          id="home.isPriorPolicyCancelledOrNonRenewed"
          name="home.isPriorPolicyCancelledOrNonRenewed"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Do you currently have insurance on this property?</Label>
        <FormField
          className={classes.formItem}
          id="home.hasInsuranceCurrently"
          name="home.hasInsuranceCurrently"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
    </Grid>
  );
};
export default FLHomeLegalFinancialQuestions;
