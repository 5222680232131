import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { lookupsJson } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import {
  condoTypesWithBasement,
  condoTypesWithFloorLevel,
  condoTypesWithEntry,
  condoTypesWithUnitsInBuilding
} from 'core/helpers/constants';
import useStyles from './property.styles';

const Property = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { condo } = values;

  const showNumberOfUnits = condoTypesWithUnitsInBuilding.includes(values.condo.typeOfCondo);

  return (
    <>
      <Grid item xs={12}>
        <Label type="formSubTitleLight">Property</Label>
      </Grid>
      <FormField
        name="condo.typeOfCondo"
        type="select"
        label="Condo Type"
        mode="light"
        options={lookupsJson.typeOfCondo}
        xs={3}
      />
      <FormField
        name="condo.floor"
        type="select"
        label="Floor/Level"
        mode="light"
        options={lookupsJson.condoFloor}
        disabled={!condoTypesWithFloorLevel.includes(condo.typeOfCondo)}
        xs={3}
      />
      <Grid item xs={3}>
        <LabelTooltip
          mode="light"
          label="Type of entry"
          disabled={!condoTypesWithEntry.includes(condo.typeOfCondo)}
          tooltip={{
            label: 'More Info',
            onHoverText:
              'Does the main entrance to the condo unit exit to a shared interior hallway or to the exterior of the structure?'
          }}
        >
          <FormField
            type="select"
            options={lookupsJson.typeOfEntry}
            id="condo.typeOfEntry"
            name="condo.typeOfEntry"
            mode="light"
            disabled={!condoTypesWithEntry.includes(condo.typeOfCondo)}
          />
        </LabelTooltip>
      </Grid>
      <Grid item className={classes.checkboxContainer} xs={3}>
        <FormField
          type="checkbox"
          id="hasBasement"
          name="condo.hasBasement"
          mode="light"
          label="Has a basement"
          disabled={!condoTypesWithBasement.includes(condo.typeOfCondo)}
          fast={false}
        />
      </Grid>
      {showNumberOfUnits && (
        <Grid item className={classes.fullWidthCheckbox} xs={12}>
          <FormField
            type="checkbox"
            id="buildingHasMoreThanFiveUnits"
            name="condo.buildingHasMoreThanFiveUnits"
            mode="light"
            label="Building has 5 or more condo units"
            fast={false}
          />
        </Grid>
      )}
      <FormField
        name="condo.yearBuilt"
        type="numeric"
        label="Built in"
        mode="light"
        format={{
          allowNegative: false
        }}
        xs={3}
      />
      <FormField
        name="condo.condoQuality"
        type="select"
        label="Condo Quality"
        mode="light"
        options={lookupsJson.homeQuality}
        xs={3}
      />
      <FormField
        name="condo.sqFt"
        type="numeric"
        label="Condo unit sq ft"
        mode="light"
        format={{
          allowNegative: false
        }}
        xs={3}
      />
      <FormField
        name="condo.constructionType"
        type="select"
        label="Construction Type"
        mode="light"
        options={lookupsJson.constructionType}
        xs={3}
      />
      <FormField
        xs={3}
        type="numeric"
        id="condo.numHalfBathrooms"
        name="condo.numHalfBathrooms"
        mode="light"
        label="Half Bathrooms"
        format={{
          allowNegative: false
        }}
      />
      <FormField
        xs={3}
        type="numeric"
        id="condo.numFullBathrooms"
        name="condo.numFullBathrooms"
        mode="light"
        label="Full Bathrooms"
        format={{
          allowNegative: false
        }}
      />
      <FormField
        xs={6}
        type="select"
        id="condo.roofShape"
        options={lookupsJson.condoRoofShape}
        name="condo.roofShape"
        mode="light"
        label="Roof shape"
      />
      <FormField
        name="condo.fpc"
        type="value"
        label="Fire Protection Class (non-editable)"
        value={condo.fpc || 'N/A'}
        xs={6}
      />
      <FormField
        name="condo.wildfireHazardScore"
        type="value"
        label="Wildfire Hazard Score (non-editable)"
        value={condo.wildfireHazardScore || 'N/A'}
        xs={6}
      />
      <FormField
        name="condo.dtoName"
        type="value"
        label="Distance to Ocean (non-editable)"
        value={condo.dtoName || 'N/A'}
        xs={4}
      />
      <FormField
        name="condo.dtbowLabel"
        type="value"
        label="Nearest BOW (non-editable)"
        value={condo.dtbowLabel || 'N/A'}
        xs={4}
      />
      <FormField
        name="condo.dtbowName"
        type="value"
        label="Distance to BOW (non-editable)"
        value={condo.dtbowName || 'N/A'}
        xs={4}
      />
    </>
  );
};

export default Property;
