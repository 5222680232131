import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { useParams, Link } from 'react-router-dom';

import { AuthContext } from 'core/components/auth';
import { ViewSchedule } from 'common/components/payment/view-schedule';
import { FormField } from 'core/components/form';
import { ActionButton } from 'core/components/action-button';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';
import { LottieLoader } from 'core/components/loading';
import { useStore } from 'core/store';
import { awsDateToDateFormatter } from 'core/helpers/formatters';
import PolicyPreviewRefundNotification from './policy-preview-refund-notification/policy-preview-refund-notification';
import { validationSchema } from './preview.validation';
import {
  getPriceDetails,
  parsePreviewChanges,
  determineboxTwoText,
  canAddToInternalNote,
  getButtonLabel
} from './helpers';
import styles from './preview.styles';
import closeIcon from './close.svg';

function Preview({
  classes,
  open,
  preview,
  loading,
  onClose,
  onConfirm,
  errors,
  action,
  policyType,
  cancelReason,
  nonRenewReason,
  automatedFixesDescription,
  mismatchedDataDescription
}) {
  const { canViewClarionDoorData } = useContext(AuthContext);
  const {
    account: {
      policies: {
        policy: { billingDetails, totalBilled }
      }
    }
  } = useStore();
  const { arrow, label } = preview ? getPriceDetails(preview, totalBilled) : {};
  const { id: accountId, policyId } = useParams();

  const fullAutomatedFixesDescription = [automatedFixesDescription, preview?.feesChanged]
    .filter((desc) => desc)
    .join('.');

  const hasScheduleChanged = preview && !!preview.nextPayments.length;
  const hasSchedule = preview && (!!preview.nextPayments.length || !!billingDetails.nextPayments.length);

  const newEndDate = preview?.diff && JSON.parse(preview.diff).full?.addedChanges.endDate;

  const isRefund = preview?.billingDescription.toLowerCase().includes('refund');

  const isImmediatePayment =
    preview?.billingDescription.toLowerCase().includes('immediate') ||
    preview?.billingDescription.toLowerCase().includes('today') ||
    preview?.billingDescription.toLowerCase().includes('via check');

  const isChangingToOneTimePay = preview?.diff && JSON.parse(preview.diff)?.full?.addedChanges?.paymentType === 'F';

  const cancelBoxTwoText =
    action === 'cancel' && cancelReason && preview?.itemsChanged.includes('cancelReason')
      ? determineboxTwoText(cancelReason)
      : undefined;
  const nonRenewBoxTwoText =
    nonRenewReason && preview?.itemsChanged.includes('autoRenew') ? determineboxTwoText(nonRenewReason) : undefined;

  // some pre-determined notes based on cancel reasons cannot be edited
  const disableInternalNote = cancelBoxTwoText && !canAddToInternalNote(cancelReason);

  const clarionDoorPriceDiff = () => {
    return (
      canViewClarionDoorData && (
        <Label className={classes.priceDiff}>
          Price difference not what you are expecting?
          <Link
            rel="noreferrer noopener"
            target="_blank"
            to={`/customer/${accountId}/policy/${policyId}/${preview.id}`}
            className={classes.orangeLink}
          >
            &nbsp; View ClarionDoor data.
          </Link>
        </Label>
      )
    );
  };

  const priceDiff = () => {
    return (
      arrow && (
        <>
          <div className={classes.arrow}>
            <img alt="Add segment" src={arrow} className={classes.arrowIcon} />
          </div>

          <Label type="infoInnerTitle" className={classes.priceInfo}>
            Policy price will {label} from ${totalBilled.toFixed(2)} to
            <span className={classes.boldPrice}>${(preview.newTotalPrice || 0).toFixed(2)}</span>
          </Label>
        </>
      )
    );
  };

  return (
    <Dialog open={open} classes={MapClassesToElem(ElemType.Modal, classes)}>
      <DialogContent className={classNames([classes.mContent, { [classes.centered]: loading || errors }])}>
        <ActionButton type="edit" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        {loading && (
          <div className={classes.loadingContainer}>
            <LottieLoader />
            <Label style={{ justifyContent: 'center' }}>Calculating changes...</Label>
          </div>
        )}
        {preview && !loading && (
          <>
            <Label type="titleSecondary" className={classes.title}>
              {action === 'reinstate' && 'Reinstate Policy Preview'}
              {action === 'rescind' && 'Rescind Policy Cancellation Preview'}
              {action === 'cancel' && `Cancel ${policyType === 'A' ? 'Auto' : 'Home'} Policy Preview`}
              {!action && 'Policy Changes Preview'}
            </Label>
            {priceDiff()}
            {['cancel', 'reinstate', 'rescind'].includes(action) && (
              <div className={classes.fields}>
                <Label type="infoInnerTitle" className={classes.priceInfo}>
                  The policy will {action === 'cancel' ? 'be canceled as of ' : 'now have an end date of '}
                  <strong style={{ marginLeft: 6 }}>{awsDateToDateFormatter(newEndDate || preview.endDate)}</strong>
                </Label>
                <div>
                  {isRefund ? (
                    <PolicyPreviewRefundNotification preview={preview} />
                  ) : (
                    <ValueField
                      value={
                        <div className={classes.inline}>
                          {preview.billingDescription}{' '}
                          {hasSchedule && preview?.nextPayments?.length > 0 && !isImmediatePayment && (
                            <ViewSchedule
                              inline
                              nextPayments={hasScheduleChanged ? preview.nextPayments : billingDetails.nextPayments}
                            />
                          )}
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            )}

            {!action && (
              <div className={classes.fields}>
                <Grid className={classes.previewInfo}>
                  <ValueField label="Changes on Coverage" value={parsePreviewChanges(preview.coverageChange)} />
                </Grid>
                <Grid className={classes.previewInfo}>
                  <ValueField
                    label="The following items were changed"
                    value={parsePreviewChanges(preview.itemsChanged)}
                  />
                </Grid>
                {fullAutomatedFixesDescription.length > 0 && (
                  <Grid className={classes.previewInfo}>
                    <ValueField
                      label="The following items were automatically corrected"
                      value={fullAutomatedFixesDescription}
                    />
                  </Grid>
                )}
                {mismatchedDataDescription && (
                  <Grid className={classes.previewInfo}>
                    <ValueField label="There is a mismatch in the data" value={mismatchedDataDescription} />
                  </Grid>
                )}
                <div>
                  {isRefund ? (
                    <PolicyPreviewRefundNotification preview={preview} />
                  ) : (
                    <ValueField
                      label="Billing Description"
                      value={
                        <div className={classes.inline}>
                          {preview.billingDescription}{' '}
                          {hasSchedule &&
                            !(isImmediatePayment || isChangingToOneTimePay) &&
                            preview.nextPayments?.length && (
                              <ViewSchedule
                                inline
                                nextPayments={hasScheduleChanged ? preview.nextPayments : billingDetails.nextPayments}
                              />
                            )}
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            )}
            {clarionDoorPriceDiff()}
            <Formik
              onSubmit={(values) => {
                onConfirm(preview.id, values.notes);
              }}
              validationSchema={validationSchema(isRefund, preview?.skipImmediateBillOrRefund)}
              initialValues={{
                ...preview,
                notes: cancelBoxTwoText || nonRenewBoxTwoText || '',
                allowRefund: false
              }}
            >
              <Form>
                <Grid container justify="space-around" alignItems="flex-start" spacing={2}>
                  {isRefund && preview?.skipImmediateBillOrRefund && (
                    <FormField
                      id="allowRefund"
                      name="allowRefund"
                      type="checkbox"
                      label="This is an extenuating circumstance, and I want to process the refund manually"
                      mode="light"
                      ignoreGlobalDisabledState
                      xs={12}
                    />
                  )}
                  <FormField
                    id="notes"
                    name="notes"
                    type="string"
                    label="Add an internal note"
                    mode="light"
                    xs={9}
                    ignoreGlobalDisabledState
                    permissions={{ isLicensedAction: false }}
                    disabled={disableInternalNote}
                  />
                  <Button
                    id="submit"
                    name="submit"
                    type="submit"
                    variant="contained"
                    label="Add an internal note"
                    mode="light"
                    xs={3}
                    color="primary"
                    className={classes.submit}
                  >
                    {getButtonLabel(action)}
                  </Button>
                </Grid>
              </Form>
            </Formik>
            {cancelBoxTwoText && ( // pre-determined notes based on cancel reasons will always show up on docs
              <Label type="statusWarning" className={classes.submitWarning}>
                warning: this internal note will be included on the cancellation form sent to the customer
              </Label>
            )}
          </>
        )}
        {errors &&
          errors.map((error) => (
            <Label type="infoValue" key={error} className={classes.errors}>
              {error}
            </Label>
          ))}
      </DialogContent>
    </Dialog>
  );
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  action: PropTypes.oneOf(['cancel', 'reinstate', 'rescind', '']),
  preview: PropTypes.shape({
    coverageChange: PropTypes.string,
    itemsChanged: PropTypes.string,
    billingDescription: PropTypes.string,
    newPremium: PropTypes.number,
    newTotalPrice: PropTypes.number,
    id: PropTypes.string,
    endDate: PropTypes.string,
    nextPayments: PropTypes.array
  }),
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  policyType: PropTypes.string.isRequired,
  cancelReason: PropTypes.string
};

Preview.defaultProps = {
  preview: null,
  errors: null,
  action: undefined,
  cancelReason: undefined
};

export default withStyles(styles)(memo(Preview));
