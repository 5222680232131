import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  linkAddress: {
    color: theme.colorPalette.green_20
  },
  leftItem: {
    paddingRight: theme.spacing(5)
  },
  rightItem: {
    paddingLeft: theme.spacing(5)
  },
  streetMapLink: {
    color: theme.colorPalette.orange_10,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 12
  },
  links: {
    gap: theme.spacing(5)
  }
});

export default makeStyles(styles);
