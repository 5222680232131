import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { changeRejectionLangToNeedToTalkStates } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { getError } from 'quote/constants/error-helper';
import useStyles from './error.styles';

const ErrorDetail = ({ error, state }) => {
  const classes = useStyles();

  const errorObject = (err) => {
    if (err) {
      return getError(err);
    }
  };

  const { text, message, title, isRejection, agentScript, agentAction } = errorObject(error);

  return (
    <Grid item xs={12} className={classes.errorContent}>
      <Label className={classes.topInfo} type="infoLabel">
        {text} {error.code}
      </Label>
      {(title || message) && (
        <>
          {title && (
            <Label type="darkGreenSmall" className={classes.errorTitle}>
              {title}
            </Label>
          )}
          {(message || agentScript) && (
            <>
              <Label type="infoLabel" className={classes.errorMessageLabel}>
                {agentScript ? 'AGENT SCRIPT' : 'MESSAGE'}
              </Label>
              <Label type="infoLabel" className={classes.errorMessage}>
                {message || agentScript}
              </Label>
            </>
          )}
          {agentAction && (
            <>
              <Label type="infoLabel" className={classes.errorMessageLabel}>
                AGENT ACTION
              </Label>
              <Label type="infoLabel" className={classes.errorMessage}>
                {agentAction}
              </Label>
            </>
          )}

          {isRejection && changeRejectionLangToNeedToTalkStates[state] && (
            <Label type="infoLabel" className={classes.errorMessage}>
              This rejection is not permanent, but needs a a referral from underwriting before continuing.
            </Label>
          )}
        </>
      )}
    </Grid>
  );
};

ErrorDetail.propTypes = {
  error: PropTypes.object.isRequired,
  state: PropTypes.string.isRequired
};

export default ErrorDetail;
