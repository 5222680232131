/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter } from 'date-fns';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { canAddUmbrella, canHaveExcessUM } from 'core/helpers/quoter.service';

export const umbrellaValidationSchema = ({ policies, savedSegment, autoPolicyDetails }) =>
  Yup.object().shape({
    includeUmbrella: Yup.boolean()
      .required()
      .test(
        'includeUmbrella',
        'Error, you cannot activate umbrella with an excluded driver on your Auto policy',
        (value) => {
          if (value) {
            // auto policy with a future end date
            const policy = policies
              ? policies.find(
                  ({ policyType, endDate }) => policyType === PolicyType.Auto && isAfter(new Date(endDate), new Date())
                )
              : null;
            if (policy) {
              const driver = policy.policyDetails.drivers
                ? policy.policyDetails.drivers.find(({ excludeDriver }) => excludeDriver)
                : null;
              return !driver;
            }
          }
          return true;
        }
      )
      .test(
        'canAddUmbrella',
        "Policy does not meet requirements to add Umbrella: Auto BI and UM/UIM BI need to be at least 250/500 and Homeowner's Liability needs to be at least $300K",
        // eslint-disable-next-line func-names
        function (val, { options: { context } }) {
          if (val) {
            const { autoCoverage } = context;
            const { policyLimitBIPD, policyLimitUMBI } = autoCoverage;
            return canAddUmbrella({
              policyLimitBIPD,
              policyLimitUMBI,
              coverageX: context?.homeCoverage?.coverageX
            });
          }

          return true;
        }
      ),
    umbrellaCoverage: Yup.object()
      .shape({
        liabilityCoverageLimit: Yup.number()
          .test(
            'umbrella-limit-caped-at-2mil',
            'You cannot increase coverage over $2m',
            function (value, { options: { context } }) {
              // currently not allowing umbrella higher than 2mil
              const liabilityCoverageLimitChanged =
                context.includeUmbrella && savedSegment?.umbrellaCoverage?.liabilityCoverageLimit !== value;
              if (liabilityCoverageLimitChanged && value > 2000000) {
                return false;
              }
              return true;
            }
          )
          .nullable(),
        watercraftHullLengths: Yup.array()
          .of(Yup.number().max(30, 'We do not allow boats with hull lengths over 30 feet.'))
          .nullable(),
        uninsuredMotoristLimit: Yup.string()
          .test(
            'canHaveExcessUM',
            'You must have 250/500 UM/UIM to add excess UM to umbrella',
            function (value, { options: { context } }) {
              if (!value || value === '0') {
                return true;
              }

              const {
                autoCoverage: { policyLimitUMBI, policyLimitUIMBI },
                state,
                includeUmbrella
              } = context;

              return includeUmbrella ? canHaveExcessUM({ policyLimitUMBI, policyLimitUIMBI, state }) : true;
            }
          )
          .nullable(),
        numVehiclesTotal: Yup.number()
          .test(
            'minCount',
            'The total number of Umbrella vehicles cannot be less than the number of vehicles on the auto policy',
            function (val) {
              const numCars = autoPolicyDetails?.cars?.length;
              return this.options?.context?.includeUmbrella ? val >= numCars : true;
            }
          )
          .required('Number Of Vehicles is required'),
        numLicensedDriversUnder25: Yup.number().test(
          'minCountDrivers',
          'The total number of Umbrella drivers under age 25 cannot be less than the number of drivers under age 25 on the auto policy',
          function (val) {
            const driversUnder25 = autoPolicyDetails?.drivers.filter((d) => d.age < 25).length;
            return this.options?.context?.includeUmbrella ? val >= driversUnder25 : true;
          }
        )
      })
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null))
  });
