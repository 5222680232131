import React from 'react';
import { useFormikContext } from 'formik';
import { disableExcludeDriverStates } from '@ourbranch/lookups';
import { Grid } from '@material-ui/core';

import useSession from 'core/hooks/use-session';
import SwapButton from 'core/components/swap-button';
import { Tooltip } from 'core/components/tooltip';
import { FormField } from 'core/components/form';
import useStyles from './driver-actions.styles';

const DriverActions = ({
  driver,
  formikFieldPath,
  onRemove,
  removeFromList,
  index,
  allowLicensedActions,
  state,
  hasPermissionToExcludeDriver
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { canRemoveExcludedDriver, user } = useSession();

  const showSwapButton = driver.isPrimary && values.drivers?.some((driver) => driver.isCoApplicant);
  const coApplicant = values.drivers?.find((person) => person.isCoApplicant);

  const disableCoApplicantField = (coApplicant && !driver.isCoApplicant) || driver.excludeDriver;
  const disableExcludeDriverField =
    !hasPermissionToExcludeDriver || driver.isCoApplicant || driver.isPrimary || disableExcludeDriverStates[state];

  const fieldMode = driver.excludeDriver ? 'dark' : 'light';

  const excludedDriverTooltip = `${
    disableExcludeDriverStates[state] ? `${state} does not allow changing excluded driver status. ` : ''
  }An excluded driver has no coverage under this policy.`;

  return (
    <Grid container justify="flex-end" alignItems="center" spacing={4}>
      {showSwapButton && <SwapButton mode="big" allowLicensedActions={allowLicensedActions} formikPath="drivers" />}
      {!driver.isPrimary && (
        <Grid item>
          <FormField
            id={`${formikFieldPath}.isCoApplicant`}
            name={`${formikFieldPath}.isCoApplicant`}
            type="checkbox"
            label="Co Applicant?"
            mode={fieldMode}
            disabled={disableCoApplicantField}
          />
        </Grid>
      )}
      {!driver.isPrimary && (
        <Grid item>
          <FormField
            id={`${formikFieldPath}.excludeDriver`}
            name={`${formikFieldPath}.excludeDriver`}
            type="checkbox"
            label={
              <div className={classes.excluded}>
                Exclude Driver
                <Tooltip text={excludedDriverTooltip} placement="bottom" label="More Info" />
              </div>
            }
            onChange={(_, checked) => {
              setFieldValue(
                `${formikFieldPath}.exclusionReason`,
                checked ? `Manually excluded by ${user.username}` : null
              );
            }}
            mode={fieldMode}
            disabled={disableExcludeDriverField}
          />
        </Grid>
      )}
      <Grid item>
        <FormField
          type="remove"
          id={formikFieldPath}
          name={formikFieldPath}
          disabled={driver.isPrimary || (driver.excludeDriver && !canRemoveExcludedDriver)}
          mode="big"
          onClick={() => onRemove(index, removeFromList)}
          fast={false}
        />
      </Grid>
    </Grid>
  );
};

export default DriverActions;
