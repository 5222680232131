import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: `2px solid ${theme.colorPalette.white_10}`,
    backgroundColor: theme.colors.page__background_primary,
    padding: '18px 32px 24px 32px',
    marginBottom: theme.spacing(2)
  },
  'light-textFieldRoot': {
    marginBottom: 10
  },
  button: {
    width: 163,
    marginBottom: theme.spacing(3)
  },
  formContainer: {
    marginTop: 0,
    marginBottom: 0
  },
  addButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  error: {
    color: theme.colors.error
  },
  address: {
    width: `calc(70% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2)
  },
  carToolTip: {
    marginRight: '10px'
  },
  trailerTip: {
    marginRight: -10
  }
}));

export default useStyles;
