/* eslint-disable func-names */
import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { isBefore, isAfter } from 'date-fns';
import { paymentMethod as PaymentMethod, policyType as PolicyType, standaloneRentersStates } from '@ourbranch/lookups';

import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';
import { validUSState } from 'common/helpers/yup-helpers';
import preSaleChecklistSchema from './components/pre-sale-checklist/pre-sale-checklist.validation-schema';
import extraQuestionsSchema from './components/extra-questions/extra-questions.validation-schema';
import { hasPaymentMethod } from './helper';

Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

const addressValidator = (
  isRequired = false,
  allowPoBox = false,
  requiredMessage = 'Enter your address, city, state and zipcode'
) => {
  const regexZipCode = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
  return Yup.object()
    .nullable()
    .test('required-address', requiredMessage, (obj) => {
      if (obj && (!!isRequired || obj.address || obj.city || obj.state || obj.zip)) {
        return obj.address && obj.city && obj.state && !!obj.zip;
      }
      return true;
    })
    .test('invalid-zip-code', 'Invalid zip code', (obj) => {
      if (obj && obj.zip) {
        return regexZipCode.test(obj.zip);
      }
      return true;
    })
    .test('is-po-box', 'PO Box Addresses are invalid', function (value) {
      if (value?.address && !allowPoBox) {
        const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
        return !(pattern.test(value.address) || pattern.test(value.address2));
      }
      return true;
    });
};

const buyValidationSchema = Yup.object().shape({
  billingDayOfMonth: Yup.number().nullable().min(1).max(28).required(`Billing day of month is required`),
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string().trim().email().required(`Email is required`).nullable(),
  address: addressValidator(true, true),
  phone: Yup.string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Invalid phone number', (phone) => {
      try {
        return parsePhoneNumber(phone, { defaultCountry: 'US' }).isValid();
      } catch {
        return false;
      }
    })
    .nullable(),
  additionalPhoneNumbers: Yup.array()
    .of(
      Yup.object().shape({
        phoneNumber: Yup.string().required('Required or click trash to remove').min(10, 'Must be at least 10 digits'),
        note: Yup.string().max(50, 'Must be less than 50 characters').nullable()
      })
    )
    .nullable(),
  currentHomeownersCarrier: Yup.string().when('breakupWithHomeowners', {
    is: true,
    then: Yup.string().required('Please enter your current insurance company').nullable(),
    otherwise: Yup.string().nullable()
  }),
  currentAutoCarrier: Yup.string().when('breakupWithAuto', {
    is: true,
    then: Yup.string().required('Please enter your current insurance company').nullable(),
    otherwise: Yup.string().nullable()
  }),
  breakupESignature: Yup.string()
    .when('breakupWithAuto', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable()
    })
    .when('breakupWithHomeowners', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable()
    })
});

const autoAttestationsSchema = Yup.object().shape({
  attestationsAutoAccepted: Yup.boolean().required().oneOf([true], 'Must click on checkbox to confirm attestation')
});

const homeAttestationsSchema = Yup.object().shape({
  attestationsHomeAccepted: Yup.boolean().required().oneOf([true], 'Must click on checkbox to confirm attestation')
});

const rentersAttestationsSchema = Yup.object().shape({
  attestationsRentersAccepted: Yup.boolean().required().oneOf([true], 'Must click on checkbox to confirm attestation')
});

const condoAttestationsSchema = Yup.object().shape({
  attestationsCondoAccepted: Yup.boolean().required().oneOf([true], 'Must click on checkbox to confirm attestation')
});

const cardValidationSchema = Yup.object().shape({
  completeCardData: Yup.boolean().required().oneOf([true], 'Credit card information is required')
});

const minDate = new Date(new Date().setDate(new Date().getDate() + -1));
const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const autoValidationSchema = Yup.object().shape({
  autoEffectiveDate: Yup.string()
    .nullable()
    .test('required', 'Policy start date is required', (obj) => !!obj)
    .test('min', 'Policy start date cannot be in the past', (obj) => {
      const date = new Date(obj);
      return date > minDate;
    })
    .test('max', 'Policy start date must be within the next 60 days', (obj) => {
      const date = new Date(obj);
      return date < maxDate;
    }),
  autoPaymentType: Yup.string().required(`Payment type is required`),
  autoPaymentMethod: Yup.string().required(`Payment method is required`),
  drivers: Yup.array().of(
    Yup.object().shape({
      driversLicenseState: Yup.string().required("Driver's license state is required."),
      driversLicenseNumber: driversLicenseNumberSchema.required("Driver's license number is required.")
    })
  )
});

const homeValidationSchema = (minDateHome) => {
  return Yup.object().shape({
    homeEffectiveDate: Yup.string()
      .nullable()
      .test('required', 'Policy start date is required', (obj) => !!obj)
      .test('min', 'Policy start date cannot be in the past', (obj) => {
        const date = new Date(obj);
        return isBefore(minDateHome, date);
      })
      .test('max', 'Policy start date must be within the next 60 days', (obj) => {
        const date = new Date(obj);
        return date < maxDate;
      })
      .test('required', 'Mortgage lender name is required', function (obj) {
        return this.options.context.homeownersPaymentMethod !== 'W' || (!!obj && obj.length > 0);
      }),
    homeownersPaymentType: Yup.string().required(`Payment type is required`),
    homeownersPaymentMethod: Yup.string().required(`Payment method is required`),
    mortgageDetails: Yup.object().shape({
      mortgageHolderName: Yup.string()
        .nullable()
        .test('required', 'Mortgage lender name is required. Go back to edit offer to add a name', function (obj) {
          return (
            !this.options.context?.selectedOption?.includes('H') ||
            this.options.context.homeownersPaymentMethod !== 'W' ||
            (!!obj && obj.length > 0)
          );
        }),
      mortgageHolderAddress: Yup.object()
        .shape({
          address: Yup.string().nullable(),
          address2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().ensure().validUSState('checkout.mortgageHolderAddress.state'),
          zip: Yup.string().nullable()
        })
        .nullable(),
      loanNumber: Yup.string().nullable()
    }),
    loanOfficerEmail: Yup.string().email('Please use a valid email').nullable()
  });
};

const condoValidationSchema = (minDateHome) => {
  return Yup.object().shape({
    condoEffectiveDate: Yup.string()
      .nullable()
      .test('required', 'Policy start date is required', (obj) => !!obj)
      .test('min', 'Policy start date cannot be in the past', (obj) => {
        const date = new Date(obj);
        return isBefore(minDateHome, date);
      })
      .test('max', 'Policy start date must be within the next 60 days', (obj) => {
        const date = new Date(obj);
        return date < maxDate;
      })
      .test('required', 'Mortgage lender name is required', function (obj) {
        return this.options.context.homeownersPaymentMethod !== 'W' || (!!obj && obj.length > 0);
      }),
    condoPaymentType: Yup.string().required(`Payment type is required`),
    condoPaymentMethod: Yup.string().required(`Payment method is required`),
    mortgageDetails: Yup.object().shape({
      mortgageHolderName: Yup.string()
        .nullable()
        .test('required', 'Mortgage lender name is required. Go back to edit offer to add a name', function (obj) {
          return (
            !this.options.context?.selectedOption?.includes('H') ||
            this.options.context.homeownersPaymentMethod !== 'W' ||
            (!!obj && obj.length > 0)
          );
        }),
      mortgageHolderAddress: Yup.object()
        .shape({
          address: Yup.string().nullable(),
          address2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().ensure().validUSState('checkout.mortgageHolderAddress.state'),
          zip: Yup.string().nullable()
        })
        .nullable(),
      loanNumber: Yup.string().nullable()
    }),
    loanOfficerEmail: Yup.string().email('Please use a valid email').nullable()
  });
};

function getPaymentSchema(policyType, quote) {
  let schema = Yup.object().shape({});
  const { noBindHome, noBindAuto } = quote;

  if (policyType.includes(PolicyType.Home) && !noBindHome) {
    const { purchaseDate } = quote.home;
    const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
    const now = new Date();
    const minDateHome =
      purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
        ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
        : now.setDate(now.getDate() - 1);
    schema = schema.concat(homeValidationSchema(minDateHome));
  }

  if (policyType.includes(PolicyType.Condo)) {
    const { purchaseDate } = quote.condo;
    const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
    const now = new Date();
    const minDateCondo =
      purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
        ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
        : now.setDate(now.getDate() - 1);
    schema = schema.concat(condoValidationSchema(minDateCondo));
  }

  if (!noBindAuto && policyType.includes(PolicyType.Auto)) {
    schema = schema.concat(autoValidationSchema);
  }

  return schema;
}

export function getSchema(policyType, quote, values, isAgency) {
  const { noBindHome, noBindAuto, correctedAddress } = quote;
  let baseBuySchema = buyValidationSchema;
  let paymentSchema = getPaymentSchema(policyType, quote);
  if (
    hasPaymentMethod({
      paymentMethods: quote.global,
      paymentMethod: PaymentMethod.CreditCard,
      policyType,
      noBindHome,
      noBindAuto
    })
  ) {
    paymentSchema = paymentSchema.concat(cardValidationSchema);
  }

  if (!noBindHome && policyType === PolicyType.Home) {
    baseBuySchema = baseBuySchema.concat(homeAttestationsSchema);
  }

  if ([PolicyType.CABundle, PolicyType.Condo].includes(policyType)) {
    baseBuySchema = baseBuySchema.concat(condoAttestationsSchema);
  }

  if (!noBindAuto && policyType.includes(PolicyType.Auto)) {
    baseBuySchema = baseBuySchema.concat(autoAttestationsSchema);
  }

  if (
    policyType === PolicyType.Renters ||
    (policyType === PolicyType.ARBundle && standaloneRentersStates[correctedAddress.state])
  ) {
    baseBuySchema = baseBuySchema.concat(rentersAttestationsSchema);
  }

  if (policyType === PolicyType.HABundle && !noBindHome && !noBindAuto) {
    baseBuySchema = baseBuySchema.concat(homeAttestationsSchema).concat(autoAttestationsSchema);
  }

  // Pre-sale checklist validation
  baseBuySchema = baseBuySchema.concat(preSaleChecklistSchema(policyType, quote, values));

  // Extra questions validation
  baseBuySchema = baseBuySchema.concat(extraQuestionsSchema(quote, isAgency));

  return baseBuySchema.concat(paymentSchema);
}
