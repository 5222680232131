export default (theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    '& label:first-child': {
      marginBottom: theme.spacing(5)
    }
  },
  sideBySideContainer: {
    flexDirection: 'row',
    height: 39,
    '& label:first-child': {
      marginBottom: 0
    }
  },
  formControl: {
    minHeight: 0,
    marginBottom: 0
  },
  'light-radio': {
    color: theme.colors.text_secondary
  },
  'dark-radioRoot': {
    color: 'rgba(255,255,255,.5)',
    minHeight: 0
  },
  'dark-radioRootDisabled': {
    '&.MuiRadio-colorSecondary.Mui-disabled': {
      color: `${theme.colorPalette.beige_10_op_60}`
    }
  },
  controlLabel: {
    fontSize: 12
  },
  label: {
    fontSize: 14
  },
  'dark-radioLabel': {
    color: theme.colorPalette.beige_10,
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: 8,
    paddingRight: 16,
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: `${theme.colorPalette.beige_10_op_60}`
    }
  },
  'dark-radioLabelDisabled': {},
  helperText: {
    marginTop: 0
  },
  error: {
    color: theme.colors.red
  }
});
