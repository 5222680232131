import React from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useDisabledState } from 'common/disabled-context';
import { SchedulePP } from '../schedule-pp';
import { HomeCoverage, Additionals } from './coverages';
import { Umbrella } from '../umbrella';
import Detail from './detail';

function HomeOffer() {
  const { disabled } = useDisabledState();
  const { values } = useFormikContext();
  const {
    homeCoverage: { coverageX },
    home: {
      homeLocation: { state }
    }
  } = values;
  return (
    <>
      <Detail disabled={disabled} />
      <SchedulePP disabled={disabled} />
      <HomeCoverage disabled={disabled} />
      <Umbrella disabled={disabled} state={state} coverageX={coverageX} />
      <Additionals disabled={disabled} />
    </>
  );
}

export default observer(HomeOffer);
