import {
  rentersPolicyAvailable,
  standaloneRentersStates,
  condoStates,
  policyType,
  lookupsJson
} from '@ourbranch/lookups';

const useAvailablePolicies = (state) => {
  if (!state) {
    return [];
  }

  const isRentersAvailable = rentersPolicyAvailable[state];
  const isStandaloneRentersAvailable = standaloneRentersStates[state];
  const isCondoAvailable = condoStates[state];

  return lookupsJson.policyTypes.filter((opt) => {
    if (opt.id.includes(policyType.Condo)) {
      return isCondoAvailable;
    }
    if (opt.id === policyType.ARBundle) {
      return isRentersAvailable;
    }
    if (opt.id === policyType.Renters) {
      return isStandaloneRentersAvailable;
    }

    return true;
  });
};

export default useAvailablePolicies;
