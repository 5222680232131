import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: 450,
    backgroundColor: '#F8F4F4',
    padding: '5px 30px 5px 36px',
    margin: 'auto',
    position: 'relative',
    transform: 'translateY(50%)'
  },
  passwordInfo: {
    '& ul': {
      paddingLeft: '15px',
      margin: 0
    },
    '& p': {
      marginBottom: '1px',
      marginTop: '25px'
    }
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginTop: '10px'
  },
  button: {
    color: theme.colorPalette.white_10,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.colors.button__background_primary}`,

    outline: 'none',
    padding: '7px 16px 7px 16px',
    cursor: 'pointer',
    '&, &:hover': {
      background: theme.colors.button__background_primary
    }
  },
  confirm: {
    background: theme.colors.red_dark_90,
    color: theme.colorPalette.white_10,
    width: 380,
    borderRadius: '3px',
    padding: '15px 10px 15px 15px',
    marginBottom: '30px'
  },
  modalTitle: {
    color: theme.colors.page__title,
    fontWeight: 'normal',
    marginTop: theme.spacing(10),
    fontSize: 30
  },
  close: {
    fontSize: '32px',
    cursor: 'pointer',
    color: theme.colors.button__background_secondary,
    position: 'absolute',
    right: 0,
    top: 0
  },
  info: {
    fontSize: '18px'
  },
  setPasswordTrigger: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    padding: 0,
    height: 'auto',
    '&:hover': {
      background: 'transparent'
    }
  }
});

export default makeStyles(styles);
