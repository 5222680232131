import React from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';

import useStyles from '../home/no-home-modal.styles';

const CannotOfferModal = observer(({ setShowCannotOfferModal, state }) => {
  const { handleSubmit, setFieldValue, setFieldTouched, errors } = useFormikContext();

  const hasHomeError = errors?.home;
  const hasAutoError = errors?.auto;

  const classes = useStyles();

  const onClick = async () => {
    // set ineligibleForHomeDueToUWSelfReport/ineligibleForAutoDueToUWSelfReport if form has errors at this point
    // this will skip validation when we submit
    if (hasHomeError) {
      await setFieldValue('home.ineligibleForHomeDueToUWSelfReport', true);
      await setFieldTouched('home.ineligibleForHomeDueToUWSelfReport');
    }

    if (hasAutoError) {
      await setFieldValue('auto.ineligibleForAutoDueToUWSelfReport', true);
      await setFieldTouched('auto.ineligibleForAutoDueToUWSelfReport');
    }
    handleSubmit();
  };

  return (
    <BaseDialog onClose={() => setShowCannotOfferModal(false)} open>
      <Label className={classes.mainLabel} type="greenBig">
        Based on the provided answers, we are unable to offer
        {hasHomeError ? (hasAutoError ? null : " homeowner's ") : ' auto '} insurance at this time.
      </Label>
      <Label className={classes.notificationContent}>
        {state === 'FL'
          ? ' This property does not meet Branch underwriting requirements. If you have questions, please contact your manager or Agency Success Manager.'
          : 'If you need further support, contact a Branch underwriting representative. '}
      </Label>
      <Grid className={classes.buttonContainer}>
        <Button className={classes.buttons} variant="contained" color="primary" onClick={onClick}>
          Return to modify offer {hasHomeError ? (hasAutoError ? null : 'without home') : 'without auto'}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowCannotOfferModal(false)}
          className={classes.buttons}
        >
          Go back
        </Button>
      </Grid>
    </BaseDialog>
  );
});

export default CannotOfferModal;
