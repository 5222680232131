import Cookies from 'js-cookie';
import { values } from 'mobx';
import { omit, mapValues, isPlainObject, isEqual, get } from 'lodash-es';
import { flow, getEnv, types, getParent } from 'mobx-state-tree';
import { utcToZonedTime } from 'date-fns-tz';
import { isSameDay, parse } from 'date-fns';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';
import {
  bixStates,
  advancedConnectedHomeStates,
  discountInventoryScoreStates,
  discountPaperlessStates,
  leaseLoanStates,
  policyType,
  lapsedLicenseMonthsStates
} from '@ourbranch/lookups';

import awsExports from 'aws-exports';
import { formattedError, hasRejection, mapRevisedQuoteDetails, shouldReject } from 'core/helpers/quoter.service';
import {
  ABODE_PRO_PLAN_AFFINITY,
  ABODE_PRO_PLAN_PROVIDER_NAME,
  NEW_CUSTOMER_HOME_SECURITY
} from 'core/helpers/constants';
import Notification from 'core/helpers/notifications';
import { phoneNumberFormatter } from 'core/helpers/formatters';
import { withRetry } from 'core/helpers/with-retry';
import { determineIfOfferNeedsUWForm, getUWNotification } from 'core/helpers/underwriting-form-helpers';
import { emptyConnectedHome } from 'common/helpers/connected-home';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import {
  GET_OFFER,
  RECALCULATE_QUOTE_TO_CLUSTER,
  REQUEST_BIND,
  DOWNLOAD_QUOTE,
  ADD_DRIVERS_CARS_AND_RECALCULATE,
  DOWNLOAD_OFFER,
  GET_CAR_DRIVER_SUGGESTIONS,
  ADD_TO_PRE_BIND_UW_REJECTIONS,
  GET_PRE_BIND_UW_REJECTIONS_BY_WEB_USER_ID,
  CLEAR_REJECT_CODES,
  ADD_REFERRAL,
  SWAP_HOME_RENTERS
} from './offer-queries';
import { pollPolicyTable } from './offer-functions';

export const OfferPageTabs = {
  SETTINGS: 'SETTINGS',
  AUTO: 'AUTO',
  HOME: 'HOME',
  PEOPLE: 'PEOPLE',
  PIP: 'PIP',
  RENTERS: 'RENTERS',
  CONDO: 'CONDO'
};

export const CheckoutStatus = {
  Initial: 'Initial',
  Purchasing: 'Purchasing',
  PurchaseFailed: 'PurchaseFailed',
  DownloadingQuote: 'DownloadingQuote',
  DownloadingOffer: 'DownloadingOffer',
  FetchingPolicyFromOffer: 'FetchingPolicyFromOffer',
  FetchSuccessful: 'FetchSuccessful',
  FetchFailed: 'FetchFailed',
  MVRRetry: 'MVRRetry',
  MVRRejected: 'MVRRejected',
  MVRDriversChanged: 'MVRDriversChanged'
};

export const MVRErrors = {
  MVR_RETRY: 'MVRRetry',
  MVR_REJECTED: 'MVRRejected',
  MVR_DRIVERS_CHANGED: 'MVRDriversChanged'
};

export const FormAction = {
  Update: 'Update',
  Checkout: 'Checkout',
  UpdateEffectiveDates: 'UpdateEffectiveDates',
  PreBindUWValidation: 'PreBindUWValidation'
};

const FETCH_POLICY_ATTEMPTS = 30;

const Error = types.model({
  code: types.union(types.string, types.number),
  message: types.maybeNull(types.string),
  data: types.maybeNull(types.frozen())
});

const Driver = types
  .model({
    id: types.identifier,
    isPrimary: types.boolean,
    isCoApplicant: types.boolean,
    isSwappable: types.boolean,
    hasUDR: types.boolean,
    willRecheckDrivingRecord: false,
    excludeDriverDisabled: types.maybeNull(types.boolean)
  })
  .actions((self) => ({
    toggleCoApplicantStatus() {
      self.isCoApplicant = !self.isCoApplicant;
    },
    toggleWillRecheckDrivingRecord() {
      self.willRecheckDrivingRecord = !self.willRecheckDrivingRecord;
    }
  }));

const cleanInput = (input) => {
  return mapValues(omit(input, ['__typename']), (val, key) => {
    if (isPlainObject(val)) {
      return cleanInput(omit(val, ['__typename']));
    }
    return val;
  });
};

const mapBindRequestToInput = (bindRequest) => {
  return cleanInput(bindRequest);
};

const OfferStore = types
  .model({
    currentTab: types.optional(types.enumeration('OfferPageTabs', Object.values(OfferPageTabs)), OfferPageTabs.PEOPLE),
    errors: types.array(Error),
    loading: types.boolean,
    addingReferral: types.boolean,
    offer: types.maybeNull(types.frozen()),
    openDialog: types.boolean,
    drivers: types.map(Driver),
    mortgageOptions: types.maybeNull(types.frozen()),
    selectedOption: types.maybeNull(types.string),
    status: types.string,
    purchasedAccountId: types.maybeNull(types.string),
    formAction: types.string,
    includeConnectedHome: types.boolean,
    includeInventory: types.boolean,
    includePaperless: types.boolean,
    notifications: types.maybeNull(types.array(types.string)),
    vinDiscrepancies: types.maybeNull(types.array(types.frozen())),
    carAndDriverSuggestions: types.maybeNull(types.frozen()),
    checkoutFormData: types.maybeNull(types.frozen()),
    showConectedHomeModal: types.boolean,
    needsPreBindUWForm: types.boolean,
    priorQuoteWithPreBindUWRejections: types.model({
      ineligibleForHome: false,
      ineligibleForAuto: false
    }),
    areRejectCodesCleared: types.boolean
  })
  .views((self) => ({
    get shouldShowAutoTab() {
      return self?.selectedOption?.includes('A');
    },
    get shouldShowRentersTab() {
      return self?.selectedOption?.includes('R');
    },
    get shouldShowCondoTab() {
      return self?.selectedOption?.includes('C');
    },
    get shouldShowHomeTab() {
      return self?.selectedOption?.includes('H');
    },
    get shouldShowPIPTab() {
      return self?.selectedOption?.includes('A') && self.state === 'MI';
    },
    get shouldShowAdditionalPaymentsSection() {
      /* right now, the only additional price that can be added is for Abode Pro Plan, noted by the abode affinity code */
      const affinity = self.offer?.quote?.global?.affinity;
      const connectedHomeEnabled = self.offer?.quote?.connectedHome && self.offer?.quote?.connectedHome?.providerName;
      const providerIsAbodeProPlan = self.offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME;
      return affinity === ABODE_PRO_PLAN_AFFINITY && connectedHomeEnabled && providerIsAbodeProPlan;
    },
    get state() {
      return self.offer?.quote.correctedAddress.state;
    },
    get timezone() {
      return stateToTzMap[self.state];
    },
    get isBixOfferOrState() {
      return self.offer.quote.isBix || bixStates[self.state];
    },
    get isBix() {
      return self.offer?.quote?.isBix;
    },
    get showLeaseLoan() {
      return self.isBix ? leaseLoanStates[self.state]?.isBix : leaseLoanStates[self.state]?.isNotBix;
    },
    get isAdvancedConnectedHome() {
      return self.isBix
        ? advancedConnectedHomeStates[self.state]?.isBix
        : advancedConnectedHomeStates[self.state]?.isNotBix;
    },
    get allowInventoryDiscount() {
      return self.isBix
        ? discountInventoryScoreStates[self.state]?.isBix
        : discountInventoryScoreStates[self.state]?.isNotBix;
    },
    get allowPaperlessDiscount() {
      return self.isBix ? discountPaperlessStates[self.state]?.isBix : discountPaperlessStates[self.state]?.isNotBix;
    },
    get coApplicant() {
      return values(self.drivers).find((driver) => driver.isCoApplicant);
    },
    get excludeDriverDisabled() {
      return values(self.drivers).find((driver) => driver.excludeDriverDisabled);
    },
    isCoApplicantButtonDisabled(driverID, formValues) {
      /*
      if the driver is in the form values but not in the store,
      then the offer hasn't been updated to add the driver yet.
      updating needs to happen before swap is enabled
      */
      const driverInStore = self.offer?.quote.drivers.find((driver) => driver.id === driverID);
      const driverInFormValues = formValues?.drivers.find((driver) => driver.id === driverID);
      if (driverInFormValues && !driverInStore) {
        return true;
      }
      return false;
    },
    get defaultSelectedOption() {
      return self.offer?.options.find(({ name }) => name === 'Bundle')?.type || self.offer?.options[0]?.type;
    },
    get isStale() {
      if (self.offer) {
        const rateControlDate = utcToZonedTime(
          parse(self.offer?.quote.global.rateControlDate, 'yyyy-MM-dd', new Date())
        );
        const today = utcToZonedTime(new Date(), stateToTzMap[self.state]);
        return !isSameDay(rateControlDate, today);
      }
      return false;
    },
    get triggerDrivingRecordRecheck() {
      return values(self.drivers).some((driver) => driver.willRecheckDrivingRecord);
    },
    get driverIdsToRecheck() {
      return values(self.drivers)
        .filter((driver) => driver.willRecheckDrivingRecord)
        .map((driver) => driver.id);
    },
    get hasOneOrMoreUDRs() {
      return values(self.drivers).some((driver) => driver.hasUDR);
    }
  }))
  .actions((self) => ({
    setFormAction({ dirty, stale, fromHomeVerification = false }) {
      if (fromHomeVerification) {
        self.formAction = FormAction.PreBindUWValidation;
        return;
      }

      if (stale) {
        self.formAction = FormAction.UpdateEffectiveDates;
      } else if (dirty) {
        self.formAction = FormAction.Update;
      } else {
        self.formAction = FormAction.Checkout;
      }
    },
    setIncludeConnectedHome(include) {
      self.includeConnectedHome = include;
    },
    setIncludeInventory(include) {
      self.includeInventory = !!include;
    },
    setIncludePaperless(include) {
      self.includePaperless = !!include;
    },
    setDrivers(drivers) {
      self.drivers.clear();
      drivers.forEach((driver) => {
        const { id, isPrimary, isCoApplicant, excludeDriver: excludeDriverDisabled } = driver;
        const isSwappable = isPrimary || isCoApplicant;
        const hasUDR = driver.autoViolations?.UDR >= 1;
        self.drivers.set(id, { id, isPrimary, isCoApplicant, isSwappable, hasUDR, excludeDriverDisabled });
      });
    },
    getOffer: flow(function* getOffer(offerId) {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield client.query({
          query: GET_OFFER,
          variables: { offerId },
          fetchPolicy: 'no-cache'
        });

        if (response.data) {
          self.errors = [];
          const { offer } = response.data;

          // first try to sync with the URL selected option.
          // some refactor is needed here, because the rater is not updating the selected option with staff
          // since is not being added to the recalculate call.
          // Right now we have 3 sources of true that aren't synched, the store, the quote and the url
          const { pathname } = window.location;
          // offer/checkout/<offerId>/<option>/checkout
          const checkoutOption = pathname.split('/')[3];

          const selectedOption =
            offer.options.find((o) => o.type === checkoutOption)?.type ||
            // we need to make sure if default selected option is part of the options list
            offer.options.find((o) => o.type === offer.quote.selectedOption)?.type ||
            offer.options
              .map((option) => option.type)
              .sort((a, b) => {
                if (a === 'HA') {
                  return -1;
                }

                if (b === 'HA') {
                  return 1;
                }

                if (a === 'AR') {
                  return -1;
                }

                if (b === 'AR') {
                  return 1;
                }

                return 0;
              })[0];
          self.setSelectedOption(selectedOption);
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            self.rejectOffer(offer);
          } else {
            self.offer = offer;
            self.errors = [];
          }
          yield self.checkForPriorQuoteWithPreBindUWRejections();
          self.includeConnectedHome = !emptyConnectedHome(offer?.quote?.connectedHome);
          self.includeInventory = !!offer?.quote?.global?.discountInventoryScore;
          self.includePaperless = !!offer?.quote?.global?.discountPaperless;
          self.setDrivers(offer.quote.drivers);
          self.getDriverAndCarSuggestions(self.offer.id);
          self.setNeedsPreBindUWForm();
          self.setNotifications();
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    handleError(error) {
      const parsed = formattedError(error);
      if (parsed) {
        self.loading = false;
        const errors = [...self.errors, parsed];
        self.errors = errors;
        self.triggerOfferDialog(true);
      }
      return parsed;
    },
    addDriversAddCarsAndRecalculateCluster: flow(function* addDriversAddCarsAndRecalculateCluster({
      newDrivers,
      newVins,
      newTrailerVins,
      revisedQuoteDetails,
      offerId,
      history
    }) {
      const { client } = getEnv(self);
      self.loading = true;

      try {
        const response = yield withRetry(
          client.mutate(
            {
              mutation: ADD_DRIVERS_CARS_AND_RECALCULATE,
              variables: {
                offerId,
                newDrivers,
                newVins,
                newTrailerVins,
                revisedQuoteDetails
              }
            },
            'addDriversAddCarsAndRecalculateCluster'
          )
        );

        if (response.data) {
          self.errors = [];
          const { cluster } = response.data;
          const offer =
            cluster.offers.find(({ code }) => code === 'C') || cluster.offers.find(({ code }) => code === 'S'); // try C or fallback to S
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            self.rejectOffer(offer);
            history.push(`/offer/${offer.id}`);
          } else {
            self.offer = offer;
            self.setDrivers(offer.quote.drivers);
            self.setNotifications();
            self.setVINDiscrepancies(newVins);
            history.push(`/offer/${offer.id}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
        // Reset record recheck
      }
    }),
    recalculateQuoteToCluster: flow(function* recalculateQuoteToCluster(
      offerId,
      details,
      history,
      pushToCheckout = false
    ) {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield withRetry(
          client.query({
            query: RECALCULATE_QUOTE_TO_CLUSTER,
            variables: {
              offerId,
              revisedQuoteDetails: mapRevisedQuoteDetails(details)
            },
            fetchPolicy: 'no-cache'
          }),
          'recalculateQuoteToCluster'
        );

        if (response.data) {
          self.errors = [];
          const { cluster } = response.data;
          const offer =
            cluster.offers.find(({ code }) => code === 'C') || cluster.offers.find(({ code }) => code === 'S'); // try C or fallback to S
          yield self.checkForPriorQuoteWithPreBindUWRejections();
          if (shouldReject(offer.quote) || hasRejection(offer.quote)) {
            const { redirect } = self.rejectOffer(offer);
            if (redirect && !pushToCheckout) {
              const hasOption = !!offer.options.find((o) => o.type === details.selectedOption);
              const option = hasOption ? details.selectedOption : offer.options[0]?.type;
              self.setSelectedOption(option);
              if (!hasOption) {
                self.setCurrentTab(OfferPageTabs.PEOPLE);
              }
              history.push(`/offer/${offer.id}`);
            } else if (pushToCheckout) {
              history.push(`/offer/${offer.id}/${details.selectedOption}/checkout`);
            }
          } else {
            self.offer = offer;
            if (self.formAction === FormAction.PreBindUWValidation) {
              self.addToPreBindUWRejections(offer);
            }
            self.setDrivers(offer.quote.drivers);
            self.setNeedsPreBindUWForm();
            self.setNotifications();
            if (pushToCheckout) {
              history.push(`/offer/${offer.id}/${details.selectedOption}/checkout`);
            } else {
              history.push(`/offer/${offer.id}`);
            }
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    swapHomeRentersOptions: flow(function* swapHomeRentersOptions(history, selectedOption) {
      const { client } = getEnv(self);
      self.loading = true;

      const isRenters = self.offer.options.some((o) => o.type === policyType.ARBundle || o.type === policyType.Renters);

      try {
        const response = yield withRetry(
          client.query({
            query: SWAP_HOME_RENTERS,
            variables: {
              offerId: self.offer.id,
              revisedQuoteDetails: { isHomeOwner: !!isRenters, includeRenters: !isRenters, selectedOption }
            },
            fetchPolicy: 'no-cache'
          })
        );

        if (response.data) {
          self.errors = [];
          const { offer } = response.data;
          if (offer) {
            const bundle = offer.options.find(
              (o) => o.type === policyType.HABundle || o.type === policyType.CABundle || o.type === policyType.ARBundle
            )?.type;
            self.setSelectedOption(bundle);
            self.setCurrentTab(OfferPageTabs.PEOPLE);
            self.offer = offer;
            history.push(`/offer/${offer.id}`);
          }
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    rejectOffer(offer) {
      // const { offerings } = offer.quote;
      // const { autoRejectCode, homeownersRejectCode, condoRejectCode, monolineAutoRejectCode, rentersRejectCode } =
      //   offerings;
      // const filtered = [
      //   autoRejectCode,
      //   homeownersRejectCode,
      //   condoRejectCode,
      //   monolineAutoRejectCode,
      //   rentersRejectCode
      // ].filter((err) => !!err);

      const { rejections } = offer;
      const filtered = rejections.map((rejection) => rejection.value);
      self.loading = false;
      self.errors = filtered.map((code) => ({ code }));

      self.offer = offer;
      if (self.formAction === FormAction.PreBindUWValidation) {
        self.addToPreBindUWRejections(offer);
      }
      self.setNeedsPreBindUWForm();
      self.setNotifications();
      return { redirect: true };
    },
    setCurrentTab(newTab) {
      self.currentTab = newTab;
    },
    triggerOfferDialog(open = true) {
      self.openDialog = open;
    },
    setSelectedOption(option) {
      self.selectedOption = option;
    },
    bindRequest: flow(function* bindRequest(bindRequestInput) {
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.Purchasing;
      const cleanedInput = mapBindRequestToInput(bindRequestInput);
      self.triggerOfferDialog(true);

      try {
        const response = yield client.query({
          query: REQUEST_BIND,
          variables: { request: cleanedInput }
        });
        if (response.data) {
          const { id, systemId } = response.data.requestBind;
          return { id, systemId };
        }

        if (response.errors) {
          self.handleError(response.errors);
        }
      } catch (error) {
        self.triggerOfferDialog(false);
        const parsedError = self.handleError(error);

        self.status = MVRErrors[parsedError.code] || CheckoutStatus.PurchaseFailed;
        return { id: undefined, systemId: undefined };
      }
      self.checkoutFormData = null;
      self.status = CheckoutStatus.Initial;
      self.triggerOfferDialog(false);
    }),
    downloadQuote: flow(function* downloadQuote({ bypassCache }) {
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.DownloadingQuote;

      let formattedPhone = null;
      if (self.offer?.quote?.phone) {
        formattedPhone = phoneNumberFormatter({
          phoneNumber: self.offer.quote.phone.toString()
        });
      }

      const selectedOption = getQuoteFriendlySelectedOption(self.selectedOption);

      const quotePDFInput = {
        offerId: self.offer.id,
        optionId: self.offer.options.find((o) => o.type === selectedOption).id,
        bypassCache,
        bindDetails: {
          email: self.offer.quote.email,
          phoneNumbers: [{ number: formattedPhone }],
          mailingAddress: self.offer.quote.correctedAddress
        }
      };

      try {
        const response = yield client.query({
          query: DOWNLOAD_QUOTE,
          variables: { details: cleanInput(quotePDFInput) }
        });
        window.open(response.data.quotePdf, '_blank');
      } catch (error) {
        self.handleError(error);
      }

      self.status = CheckoutStatus.Initial;
    }),
    downloadOffer: flow(function* downloadOffer({ offerId, clusterId, policyType, bypassCache }) {
      const { protocol } = window.location;
      const { client } = getEnv(self);

      self.errors = [];
      self.status = CheckoutStatus.DownloadingOffer;

      const getClusterId = async (offerId, revisedQuoteDetails) => {
        const response = await client.query({
          query: RECALCULATE_QUOTE_TO_CLUSTER,
          variables: {
            offerId,
            revisedQuoteDetails
          },
          fetchPolicy: 'no-cache'
        });
        if (response?.data) {
          return response.data.cluster.id;
        }
      };

      let fetchedClusterId;
      try {
        fetchedClusterId = yield getClusterId(offerId, {});
      } catch {
        fetchedClusterId = null;
      }

      // have to give public url for docraptor to work properly so replace with dev environment url if localhost
      const host =
        awsExports.stackeryEnvironmentName === 'production'
          ? 'ourbranch.com'
          : `${awsExports.stackeryEnvironmentName}.ourbranch.dev`;

      try {
        const response = yield client.query({
          query: DOWNLOAD_OFFER,
          variables: {
            documentUrl: `${protocol}//${host}/review?cid=${
              fetchedClusterId || clusterId
            }&policyType=${policyType}&internal=true&printableVersion=true`,
            documentId: `offer_pdf_${offerId}`,
            bypassCache
          }
        });

        window.open(response.data.offerPdf, '_blank');
      } catch (error) {
        self.handleError(error);
      }

      self.status = CheckoutStatus.Initial;
    }),
    addReferral: flow(function* addReferral({ quoterEmail, ...referralDetails }) {
      const { client } = getEnv(self);
      self.addingReferral = true;
      try {
        if (referralDetails.id) {
          // sometimes the customerId comes in as id
          // (worth figuring out how)
          referralDetails.customerId ||= referralDetails.id;
          delete referralDetails.id;
        }
        const response = yield client.mutate({
          mutation: ADD_REFERRAL,
          variables: { referralDetails }
        });
        if (response.data.friendBuyLink) {
          Cookies.set(
            'offerData',
            {
              offerId: self.offer.id,
              option: self.selectedOption,
              email: quoterEmail
            },
            { expires: 24 } // expires within a day
          );
          window.open(response.data.friendBuyLink, '_self');
        } else {
          self.handleError({ message: 'Failed to generate referral link' });
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.addingReferral = false;
      }
    }),
    pollFetchPolicyFromOffer: flow(function* pollFetchPolicyFromOffer(offerId) {
      // waiting on brtoa to finish executing, usually takes around 2s
      const { client } = getEnv(self);
      self.errors = [];
      self.status = CheckoutStatus.FetchingPolicyFromOffer;

      try {
        const { data } = yield pollPolicyTable(client, offerId, {
          maxRetries: FETCH_POLICY_ATTEMPTS
        });
        if (data?.accountId) {
          self.status = CheckoutStatus.FetchSuccessful;
          self.purchasedAccountId = data.accountId;
          return data.accountId;
        }
      } catch (error) {
        self.status = CheckoutStatus.FetchFailed;
      }
    }),
    getDriverAndCarSuggestions: flow(function* getCarAndDriverSuggestions(offerId) {
      try {
        const { client } = getEnv(self);

        const { data } = yield client.query({
          query: GET_CAR_DRIVER_SUGGESTIONS,
          variables: {
            offerId
          }
        });
        if (data?.driverSuggestions || data?.carSuggestions) {
          self.carAndDriverSuggestions = data;
        }
      } catch (error) {
        self.handleError(error);
      }
    }),
    setNotifications: function setNotifications() {
      const { affinityLookups } = getParent(self);

      const notifications = [];
      const affinity = self.offer?.quote?.global?.affinity;
      const connectedHomeEnabled = self.offer?.quote?.connectedHome && self.offer?.quote?.connectedHome?.providerName;
      const providerIsAbodeProPlan = self.offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME;
      // abode pro plan
      if (affinity === ABODE_PRO_PLAN_AFFINITY && connectedHomeEnabled && providerIsAbodeProPlan) {
        notifications.push(Notification.Offer.AbodeProPlan);
      }

      // connected home partner, but connected home not on
      if (
        affinity &&
        affinityLookups.data.find((aff) => aff.affinity === affinity)?.data?.homeSecurity &&
        !connectedHomeEnabled
      ) {
        notifications.push(Notification.Offer.ConnectedHome);
      }

      // signing up for home security
      const newConnectedHomeSignUpCustomer =
        self.offer?.quote?.global?.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE;
      if (connectedHomeEnabled && newConnectedHomeSignUpCustomer) {
        notifications.push(Notification.Offer.NewConnectedHomeSignUpCustomer);
      }

      const UWNotifications = getUWNotification({
        offer: self.offer,
        priorQuoteWithPreBindUWRejections: self.priorQuoteWithPreBindUWRejections,
        needsPreBindUWForm: self.needsPreBindUWForm
      });

      if (UWNotifications.length) {
        notifications.push(...UWNotifications);
      }

      // if someone is interested in an auto policy in a state that is included in lapsedLicenseMonthsStates lookup.
      if (self.selectedOption.includes(policyType.Auto) && lapsedLicenseMonthsStates[self.state]) {
        notifications.push(Notification.Offer.LapsedDriversLicenses);
      }

      if (notifications.length) {
        self.notifications = notifications;
      } else {
        self.notifications = null;
      }
    },
    setNeedsPreBindUWForm: function setNeedsPreBindUWForm(setFormManually = false) {
      self.needsPreBindUWForm = determineIfOfferNeedsUWForm({
        offer: self.offer,
        priorQuoteWithPreBindUWRejections: self.priorQuoteWithPreBindUWRejections,
        setFormManually: false
      });
    },
    setVINDiscrepancies: function setVINDiscrepancies(newVins) {
      let vinDiscrepancies = [];

      let carsAfterUpdatingOffer = [];
      carsAfterUpdatingOffer = self?.offer?.quote?.cars;

      // filter the newVins array to remove successfully added vins
      vinDiscrepancies = newVins.filter((newCar) => {
        let i;
        let vinSuccessful = false;
        // loop through carsAfterUpdatingOffer array to see if each newVin object's VIN exists
        // in carsAfterUpdatingOffer
        // if the VIN does exist, do not add that car to vinDiscrepancies
        for (i = 0; i < carsAfterUpdatingOffer.length; i += 1) {
          if (newCar.VIN === carsAfterUpdatingOffer[i].VIN) {
            vinSuccessful = true;
          }
        }
        return !vinSuccessful;
      });

      // if vinDiscrepancies contains objects, map to the store
      if (vinDiscrepancies.length) {
        const discrepancyError = {
          code: 5009
        };

        const errors = [...self.errors, discrepancyError];

        self.errors = errors;
        self.vinDiscrepancies = vinDiscrepancies;
        self.triggerOfferDialog(true);
      } else {
        self.vinDiscrepancies = null;
      }
    },

    clearVinDiscrepancies: function clearVinDiscrepancies() {
      self.vinDiscrepancies = [];
    },

    recheckDrivingRecord() {
      self.triggerDrivingRecordRecheck = true;
    },

    saveCheckoutFormData(values) {
      if (values?.id && isEqual(values?.id, self.checkoutFormData?.id)) {
        self.checkoutFormData = { ...self.checkoutFormData, ...values };
      } else {
        self.checkoutFormData = { ...values };
      }
    },
    setShowConnectedHomeModal(open) {
      self.showConectedHomeModal = open;
    },
    addToPreBindUWRejections: flow(function* addToPreBindUWRejections(offer) {
      const { client } = getEnv(self);
      const ineligibleForHome =
        get(offer, 'quote.preBindUWCheck.homeVerification.ineligibleForHomeDueToUWSelfReport') ||
        self.priorQuoteWithPreBindUWRejections.ineligibleForHome ||
        false;
      const ineligibleForAuto =
        get(offer, 'quote.preBindUWCheck.autoVerification.ineligibleForAutoDueToUWSelfReport') ||
        self.priorQuoteWithPreBindUWRejections.ineligibleForAuto ||
        false;
      if ((ineligibleForHome || ineligibleForAuto) && awsExports.stackeryEnvironmentName === 'production') {
        try {
          const response = yield withRetry(
            client.mutate(
              {
                mutation: ADD_TO_PRE_BIND_UW_REJECTIONS,
                variables: {
                  webUserId: offer.webUserId,
                  ineligibleForHome,
                  ineligibleForAuto,
                  attestationAgent: get(offer, 'quote.preBindUWCheck.attestationAgent')
                }
              },
              'addToPreBindUWRejections'
            )
          );
          if (response.data) {
            self.setPriorQuoteWithPreBindUWRejectionsData(response.data?.addToPreBindUWRejections);
          }
        } catch (error) {
          self.handleError(error);
        } finally {
          self.loading = false;
        }
      }
    }),
    removeFromPreBindUWRejections: flow(function* removeFromPreBindUWRejections(
      homeOverride,
      autoOverride,
      teamLeader
    ) {
      const ineligibleForHome = !homeOverride ? self.priorQuoteWithPreBindUWRejections?.ineligibleForHome : false;
      const ineligibleForAuto = !autoOverride ? self.priorQuoteWithPreBindUWRejections?.ineligibleForAuto : false;

      const { client } = getEnv(self);
      try {
        const response = yield withRetry(
          client.mutate(
            {
              mutation: ADD_TO_PRE_BIND_UW_REJECTIONS,
              variables: {
                webUserId: self.offer.webUserId,
                ineligibleForHome,
                ineligibleForAuto,
                attestationAgent: teamLeader
              }
            },
            'addToPreBindUWRejections'
          )
        );
        if (response.data) {
          self.setPriorQuoteWithPreBindUWRejectionsData(response.data?.addToPreBindUWRejections);
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    clearRejectCodes: flow(function* clearRejectCodes() {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const response = yield client.query({
          query: CLEAR_REJECT_CODES,
          variables: { offerId: self.offer.id, invokedFromStaff: true }
        });
        if (response.data) {
          self.offer = response.data.offer;
          self.errors = [];
          self.areRejectCodesCleared = true;
        } else {
          self.errors = response.errors;
        }
      } catch (error) {
        self.handleError(error);
      } finally {
        self.loading = false;
      }
    }),
    getIsLicensedForState(session) {
      /*
      FormFields have this logic already built in via the permissions prop
      Therefore, only use this function if wanting to use this logic for items that are NOT form fields
      */
      const state = self.offer?.quote.correctedAddress.state;
      return session.allowedStates.includes(state) && !session.viewOnly;
    },
    setPriorQuoteWithPreBindUWRejectionsData(data) {
      if (data === null) {
        self.priorQuoteWithPreBindUWRejections = {
          ineligibleForHome: false,
          ineligibleForAuto: false
        };
      } else {
        self.priorQuoteWithPreBindUWRejections = {
          ineligibleForHome: data.ineligibleForHome,
          ineligibleForAuto: data.ineligibleForAuto
        };
      }
      self.setNotifications();
    },
    checkForPriorQuoteWithPreBindUWRejections: flow(function* checkForPriorQuoteWithPreBindUWRejections() {
      const { client } = getEnv(self);
      try {
        const response = yield withRetry(
          client.query(
            {
              query: GET_PRE_BIND_UW_REJECTIONS_BY_WEB_USER_ID,
              variables: {
                webUserId: self.offer.webUserId
              }
            },
            'getPreBindUWRejectionsByWebUserId'
          )
        );
        self.setPriorQuoteWithPreBindUWRejectionsData(
          response?.data?.getPreBindUWRejectionsByWebUserId ? response?.data?.getPreBindUWRejectionsByWebUserId : null
        );
      } catch (error) {
        self.handleError(error);
      } finally {
        self.setNotifications();
      }
    })
  }));

export default OfferStore;
