import React from 'react';
import { ConnectedHomeFormFields } from 'common/components/discounts/connected-home-form-fields';
import { usePolicyConnectedHomeSecurityProviders } from '../hooks/policy-connected-home.hooks';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';

/*
Read about the business logic behind Connected Home here:
https://www.notion.so/branch/All-About-Affinity-and-Connected-Home-Discounts-c4f5abad218049e0a37991cd60157f41
*/

const PolicyConnectedHomeSettings = observer(() => {
  const {
    account: {
      policies: { policy: policyStore }
    },
    affinityLookups: affinityLookupsStore
  } = useStore();

  const isAdvancedConnectedHome = policyStore?.isAdvancedConnectedHome;

  // Get policy specific providers and pass it as prop to avoid policy logic in common component
  const securityProviderOptions = usePolicyConnectedHomeSecurityProviders(
    { isAdvancedConnectedHome },
    affinityLookupsStore
  );

  return <ConnectedHomeFormFields mode="dark" securityProviderOptions={securityProviderOptions} />;
});

export default PolicyConnectedHomeSettings;
