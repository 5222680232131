import React, { useCallback, useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Formik, Form, yupToFormErrors } from 'formik';
import { v4 as uuid4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { useBasisTheoryToken } from 'common/hooks/use-basis-theory-token';
import { ABODE_PRO_PLAN_AFFINITY, ABODE_PRO_PLAN_PROVIDER_NAME } from 'core/helpers/constants';
import { AuthContext } from 'core/components/auth';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store';
import { CheckoutStatus } from 'core/store/offer-store';
import { awsDateTimeFormatter, phoneNumberFormatter } from 'core/helpers/formatters';
import { track } from 'core/helpers/analytics';
import { OfferDialog } from 'offer/components/dialog';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { useStripeToken } from '../../hooks/use-stripe-token';
import { getSchema } from '../../checkout.validation-schema';
import { getPaymentMethods } from '../../helper';
import { BillingInformation } from '../billing-information';
import { CancelCurrentPolicy } from '../cancel-current-policy';
import { PaymentsDisclosures } from '../payments-disclosures';
import { PreSaleChecklist } from '../pre-sale-checklist';
import { ExtraQuestions } from '../extra-questions';
import useFormInitialValues from './use-initial-form-values';
import useStyles from './checkout-form.styles';
import { getPolicyType } from 'core/helpers/policy-type';

const CheckoutForm = observer(({ toast, disabled }) => {
  const classes = useStyles();
  const { getStripeACHToken } = useStripeToken();
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();
  const session = useContext(AuthContext);
  const {
    offer: {
      saveCheckoutFormData,
      offer,
      bindRequest,
      status,
      openDialog,
      triggerOfferDialog,
      getIsLicensedForState,
      selectedOption: selectedOptionFromStore
    }
  } = useStore();

  const { getBasisTheoryCCToken, getBasisTheoryACHToken } = useBasisTheoryToken();

  const selectedOption = getQuoteFriendlySelectedOption(selectedOptionFromStore);

  const { isCondo } = getPolicyType(selectedOption, offer?.quote?.noBindHome, offer?.quote?.noBindAuto);

  const initialValues = useFormInitialValues(offer, selectedOption);
  const isLicensedToPurchase = getIsLicensedForState(session);

  const { hasACH, hasCreditCard } = useMemo(() => {
    const { hasACH, hasCreditCard } = getPaymentMethods({
      paymentMethods: offer.quote.global,
      policyType: selectedOption,
      noBindHome: offer?.quote?.noBindHome,
      noBindAuto: offer?.quote?.noBindAuto,
      hasAbodeProPlan:
        offer?.quote?.global?.affinity === ABODE_PRO_PLAN_AFFINITY &&
        offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME
    });
    return { hasACH, hasCreditCard };
  }, [offer, selectedOption]);

  const validate = useCallback(
    async (values) => {
      try {
        await getSchema(selectedOption, offer.quote, values, session.isAgency).validate(values, {
          abortEarly: false,
          context: values
        });
      } catch (error) {
        const formErrors = yupToFormErrors(error);
        if (showToast) {
          toast.notify({
            type: 'error',
            message: 'Please fix the form errors before continuing.'
          });
        }
        setShowToast(false);
        // disabling eslint for the console log so we can monitor validation errors in prod
        // eslint-disable-next-line
        console.log({ formErrors });
        return formErrors;
      }
    },
    [offer.quote, selectedOption, toast, showToast, session.isAgency]
  );

  const onSubmit = useCallback(
    async (values) => {
      let hasACHError = false;
      // Format input variables input to shape it's expecting
      const option = offer.options.find((o) => o.type === selectedOption);
      const phoneNumber = phoneNumberFormatter({ phoneNumber: values.phone.toString() });
      const formattedAdditionalPhoneNumbers = values.additionalPhoneNumbers
        ?.filter((phoneDetail) => phoneDetail.phoneNumber)
        .map((phoneDetail) => ({
          phoneNumber: phoneNumberFormatter({ phoneNumber: phoneDetail.phoneNumber }),
          note: phoneDetail.note,
          canText: phoneDetail.canText
        }));
      let creditCardInfo = null;
      if (hasCreditCard) {
        if (values.basisTheoryCardToken) {
          // we are restoring CC info
          creditCardInfo = {
            token: values.basisTheoryCardToken
          };
        } else if (values.completeCardData) {
          creditCardInfo = await getBasisTheoryCCToken();
          saveCheckoutFormData({
            id: values.address,
            creditCardInfo: creditCardInfo
              ? { ...creditCardInfo, card: { ...creditCardInfo.card, last4: values.cardLast4 } }
              : null,
            basisTheoryError: null
          });
        }
      }

      const basisTheoryBankAccountTokenInfo = hasACH
        ? await getBasisTheoryACHToken({
            routingNumberTextElementId: 'routingNumber',
            accountNumberTextElementId: 'accountNumber',
            accountHolderNameTextElementId: 'accountHolderName'
          }).catch((error) => {
            hasACHError = true;
            toast.notify({
              type: 'error',
              message: error.message
            });
          })
        : null;

      const email = values.email.trim();
      const bindRequestInput = {
        id: uuid4(),
        offerId: offer.id,
        optionId: option.id,
        bindDetails: {
          emails: [{ email }],
          phoneNumbers: [{ number: phoneNumber }],
          additionalPhoneNumbers: formattedAdditionalPhoneNumbers,
          mailingAddress: { ...values.address, address2: values.address.address2 || null },
          drivers: values.drivers?.length
            ? values.drivers.map((driver) => ({
                id: driver.id,
                driversLicenseState: driver.driversLicenseState,
                driversLicenseNumber: driver.driversLicenseNumber
              }))
            : undefined,
          billingDayOfMonth: values.billingDayOfMonth,
          homeBillingDayOfMonth: values.homeBillingDayOfMonth,
          autoBillingDayOfMonth: values.autoBillingDayOfMonth,
          rentersBillingDayOfMonth: values.autoBillingDayOfMonth, // should be renters?
          condoBillingDayOfMonth: values.condoBillingDayOfMonth,
          homeDownPayment: values.homeDownPayment,
          autoDownPayment: values.autoDownPayment,
          rentersDownPayment: values.rentersDownPayment,
          condoDownPayment: values.condoDownPayment,
          basisTheoryCardToken: creditCardInfo?.token,
          basisTheoryBankAccountToken: basisTheoryBankAccountTokenInfo?.id,
          salesRep: offer.quote.rep,
          bindRep: session.user.username,
          attestationsHomeAccepted: values.attestationsHomeAccepted ? awsDateTimeFormatter(new Date()) : null,
          attestationsAutoAccepted: values.attestationsAutoAccepted ? awsDateTimeFormatter(new Date()) : null,
          breakupWithAuto: values.breakupWithAuto,
          breakupWithHomeowners: values.breakupWithHomeowners,
          breakupWithRenters: values.breakupWithRenters,
          breakupWithUmbrella: offer.quote.includeUmbrella && values.breakupWithHomeowners,
          breakupESignature:
            values.breakupWithAuto || values.breakupWithHomeowners || values.breakupWithRenters
              ? values.breakupESignature
              : null,
          currentAutoCarrier: values.breakupWithAuto ? values.currentAutoCarrier : null,
          currentAutoCarrierPolicyNumber: values.breakupWithAuto ? values.currentAutoCarrierPolicyNumber : null,
          currentHomeownersCarrier: values.breakupWithHomeowners ? values.currentHomeownersCarrier : null,
          currentHomeownersCarrierPolicyNumber: values.breakupWithHomeowners
            ? values.currentHomeownersCarrierPolicyNumber
            : null,
          currentRentersCarrier: values.breakupWithRenters ? values.currentRentersCarrier : null,
          currentRentersCarrierPolicyNumber: values.breakupWithRenters
            ? values.currentRentersCarrierPolicyNumber
            : null,
          purchasedFromStaff: true,
          opposingPolicySoldByAgency: values.opposingPolicySoldByAgency,
          loanOfficerEmail: values.loanOfficerEmail,
          attestationsRentersAccepted: values.attestationsRentersAccepted ? awsDateTimeFormatter(new Date()) : null,
          attestationsCondoAccepted: values.attestationsCondoAccepted ? awsDateTimeFormatter(new Date()) : null,
          autoMegaDownPay: values.autoMegaDownPay,
          homeMegaDownPay: values.homeMegaDownPay,
          rentersMegaDownPay: values.rentersMegaDownPay,
          condoMegaDownPay: values.condoMegaDownPay
        }
      };
      track('Staff Purchase Offer', { bindRequestInput });
      if (!hasACHError) {
        const { id, systemId } = await bindRequest(bindRequestInput);
        if (id && systemId) {
          history.push({ pathname: `/offer/${offer.id}/purchased`, state: { email } });
        }
      }
    },
    [
      offer,
      getStripeACHToken,
      getBasisTheoryCCToken,
      hasCreditCard,
      hasACH,
      session,
      selectedOption,
      bindRequest,
      history
    ]
  );

  return (
    <Formik validateOnChange={false} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      <Form>
        <BillingInformation className={classes.content} />
        <PaymentsDisclosures policyType={selectedOption} offer={offer} />
        {!isCondo && <CancelCurrentPolicy className={classes.content} offer={offer} />}
        <PreSaleChecklist offer={offer} />
        <ExtraQuestions offer={offer} isAgency={session.isAgency} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.purchaseButton}
          loading={status === CheckoutStatus.Purchasing}
          onClick={() => {
            setShowToast(true);
          }}
          disabled={!isLicensedToPurchase || disabled}
        >
          Purchase
        </Button>
        {openDialog && <OfferDialog onClose={() => triggerOfferDialog(false)} fromCheckout />}
      </Form>
    </Formik>
  );
});

CheckoutForm.propTypes = {
  toast: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default withToast(CheckoutForm);
