import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import ScreenHeader from 'core/components/screen-header';
import FrontContact from '../front-contact';
import { AuthContext } from 'core/components/auth';

const CustomerHeader = ({ history }) => {
  const store = useStore();
  const { account: accountStore } = store;
  const { isExternalSales } = useContext(AuthContext);

  const titleText = () => {
    return accountStore.accountLoading ? 'Loading...' : `${accountStore.fname} ${accountStore.lname}`;
  };

  const title = titleText();

  const back = useCallback(() => {
    history.push(`/search/customers`);
  }, [history]);

  return (
    <div>
      <ScreenHeader title={title} onBack={back} />
      {!isExternalSales && <FrontContact />}
    </div>
  );
};

export default observer(CustomerHeader);
