import React from 'react';
import PropTypes from 'prop-types';
import MuiSwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';

import styles from './switch.styles';
import { Tooltip } from 'core/components/tooltip';
import { Label } from '../label';

function Switch({
  id,
  classes,
  label,
  value,
  onChange,
  mode,
  disabled,
  error,
  helperText,
  className,
  switchClassName,
  tooltipText,
  tooltipLabel,
  switchTooltipClassName: tooltipClassName,
  ...props
}) {
  return (
    <FormControl disabled={disabled} className={classNames(classes.container, className)} {...props}>
      <FormControlLabel
        classes={{ root: switchClassName, label: (mode && classes[`${mode}-label`]) || classes.label }}
        label={<Label>{label}</Label>}
        control={
          <MuiSwitch
            checked={value}
            onChange={onChange}
            value={id}
            color={mode === 'light' ? 'primary' : 'secondary'}
            disabled={disabled}
          />
        }
      />
      {error && (
        <FormHelperText classes={{ root: classes.helperText }} id={id} error={error}>
          {helperText}
        </FormHelperText>
      )}
      {tooltipText && (
        <Tooltip
          text={tooltipText}
          placement="bottom"
          label={tooltipLabel}
          className={classNames(classes.switchTooltip, tooltipClassName)}
        />
      )}
    </FormControl>
  );
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  switchClassName: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipLabel: PropTypes.string
};

Switch.defaultProps = {
  value: false,
  mode: 'light',
  disabled: false,
  helperText: undefined,
  error: undefined,
  className: '',
  switchClassName: '',
  tooltipText: '',
  tooltipLabel: ''
};

export default withStyles(styles)(Switch);
