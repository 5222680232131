import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { Formik, yupToFormErrors } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { policyTypes } from '@ourbranch/policy-types';

import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { AuthContext } from 'core/components/auth';
import { types } from 'core/helpers/sanitize';
import { UWPropertyValidationSchema } from './components/common/pre-bind-UW.validation';
import { FLValidationSchema } from './components/florida-UW/pre-bind-UW-FL.validation';
import VerifyPropertyContainer from './components/common/verify-property-container-continental';
import VerifyPropertyContainerFL from './components/florida-UW/verify-property-container-florida';
import { getUWPreBindInitialValues } from './components/common/get-UW-pre-bind-initial-values';

const PreBindUW = observer(() => {
  const history = useHistory();
  const { offer: offerStore } = useStore();
  const { offerId } = useParams();
  const { offer, getOffer } = offerStore;
  const session = useContext(AuthContext);
  const {
    user: { username }
  } = session;

  const UWPreBindHomeVerificationInitialValues = getUWPreBindInitialValues(
    offerStore?.offer?.quote,
    offerStore?.selectedOption,
    offerStore?.state
  );

  const validate = useCallback(
    async (values) => {
      // for the UW form, we use validation to determine if a user has submitted a wrong answer ( an answer that goes against underwriting rules)
      // when that happens, the user is given the option to drop home/auto, setting ineligibleForHomeDueToUWSelfReport/ineligibleForAutoDueToUWSelfReport
      // so don't validate if either variable is true, it means member has chosen to drop home/auto and is submitting values as is
      if (!values?.home?.ineligibleForHomeDueToUWSelfReport && !values?.auto?.ineligibleForAutoDueToUWSelfReport) {
        try {
          offerStore?.state !== 'FL'
            ? await UWPropertyValidationSchema().validate(values, {
                abortEarly: false,
                context: { ...values, UWConditions: UWPreBindHomeVerificationInitialValues.conditions }
              })
            : await FLValidationSchema(offerStore?.selectedOption).validate(values, {
                abortEarly: false,
                context: { ...values, UWConditions: UWPreBindHomeVerificationInitialValues.conditions }
              });
        } catch (error) {
          const formErrors = yupToFormErrors(error);

          // disabling eslint for the console log so we can monitor validation errors in prod
          // eslint-disable-next-line
          console.log({ formErrors });
          return formErrors;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const submitRecalculate = async (details) => {
    // bring in the offer values from the store and add the preBindUWCheck values
    let formattedDetails = {
      ...offerStore.offer.quote,
      preBindUWCheck: {
        homeVerification: details.home,
        autoVerification: details.auto,
        attestationAgent: username
      }
    };

    if (details.home.ineligibleForHomeDueToUWSelfReport && !details.auto.ineligibleForAutoDueToUWSelfReport) {
      formattedDetails.selectedOption = policyTypes.Auto;
    }

    if (!details.home.ineligibleForHomeDueToUWSelfReport && details.auto.ineligibleForAutoDueToUWSelfReport) {
      formattedDetails.selectedOption = policyTypes.Home;
    }

    // clean the values before submitting
    formattedDetails = types.QuoteDetailsInput(formattedDetails);

    // if still eligible, push to checkout after recalc
    const pushToCheckout =
      !details.home.ineligibleForHomeDueToUWSelfReport && !details.auto.ineligibleForAutoDueToUWSelfReport;
    await offerStore.recalculateQuoteToCluster(offerStore.offer.id, formattedDetails, history, pushToCheckout);
  };

  if (!offer) {
    getOffer(offerId);
    return <Loading noBackground />;
  }

  if (!UWPreBindHomeVerificationInitialValues?.initialValues || offerStore.loading) {
    return <Loading noBackground />;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={UWPreBindHomeVerificationInitialValues?.initialValues}
      validate={validate}
      onSubmit={submitRecalculate}
      validateOnBlur={false}
    >
      {(offerStore.state === 'FL' && <VerifyPropertyContainerFL />) || (
        <VerifyPropertyContainer conditions={UWPreBindHomeVerificationInitialValues?.conditions} />
      )}
    </Formik>
  );
});

export default PreBindUW;
