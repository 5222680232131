import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  errorContent: {
    paddingBottom: theme.spacing(6)
  },
  errorsLabel: {
    marginTop: theme.spacing(10),
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  errorCard: {
    minHeight: 215,
    padding: theme.spacing(8),
    display: 'flex',
    alignItems: 'stretch'
  },
  requoteButton: {
    backgroundColor: theme.colorPalette.orange_10,
    width: '100%'
  },
  overrideButton: {
    backgroundColor: theme.colorPalette.orange_10,
    width: '100%',
    marginTop: theme.spacing(4)
  },
  errorMessageLabel: {
    paddingTop: theme.spacing(4)
  },
  errorMessage: {
    textTransform: 'none'
  }
}));

export default useStyles;
