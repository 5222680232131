import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    marginBottom: theme.spacing(4)
  },
  titleSecondary: {
    maxWidth: 500
  },
  addressContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2)
  },
  noUnitNumber: {
    margin: 'auto',
    textAlign: 'start'
  },
  saveBtn: {
    position: 'relative',
    float: 'right',
    left: 325,
    width: 175
  }
}));

export default useStyles;
