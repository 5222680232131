/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter, isBefore, differenceInYears } from 'date-fns';
import { paymentMethod, paymentType, policyType } from '@ourbranch/lookups';

import { canAddUmbrella } from 'core/helpers/quoter.service';
import { FormAction } from 'core/store/offer-store';
import { connectedHomeSchema } from '../components/sidebar/components/discounts-card/connected-home-discount/connected-home-form/connected-home.validation-schema';
import { validUSState, requiredString, validAffinityCode } from 'common/helpers/yup-helpers';
import homeSchema from './validation-schemas/home.validation-schema';
import autoSchema from './validation-schemas/auto.validation-schema';
import effectiveDateSchema from './validation-schemas/effective-date.validation-schema';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);
Yup.addMethod(Yup.mixed, 'validAffinityCode', validAffinityCode);

const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const offerValidationSchema = (session, affinityLookups) =>
  Yup.object().shape({
    isBix: Yup.boolean().nullable(),
    global: Yup.object()
      .shape({
        affinity: Yup.string()
          .validAffinityCode('global.affinity', { affinityLookups, isAgency: session.isAgency })
          .nullable(),
        autoBillingDayOfMonth: Yup.number().nullable(),
        autoPaymentMethod: Yup.string().requiredString(),
        autoPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (session.isTeamLeader || !this.options.parent.priorCancelNonPay) {
                return true;
              }
              // if paying in full, or if policy type is home, pass validation
              if (value === paymentType.OneTime || this.options.context?.selectedOption === policyType.Home) {
                return true;
              }
              return false;
            }
          ),
        billingDayOfMonth: Yup.number().nullable(),
        currentAutoCarrier: Yup.string().nullable(),
        currentHomeownersCarrier: Yup.string().nullable(),
        currentlyAutoInsured: Yup.boolean(),
        discountInventoryScore: Yup.boolean(),
        discountPaperless: Yup.boolean(),
        homeBillingDayOfMonth: Yup.number().nullable(),
        homeownersPaymentMethod: Yup.string().requiredString(),
        homeownersPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (
                session.isTeamLeader ||
                !this.options.parent.priorCancelNonPay ||
                this.options.parent?.homeownersPaymentMethod === paymentMethod.Escrow
              ) {
                return true;
              }
              // if paying in full, or if policy type is not a home policy
              if (value === paymentType.OneTime || !this.options.context?.selectedOption.includes('H')) {
                return true;
              }
              return false;
            }
          ),
        personalPropertyProtection: Yup.boolean()
      })
      .nullable(),
    includeUmbrella: Yup.boolean()
      .test(
        'canAddUmbrella',
        "Policy does not meet requirements to add Umbrella: Auto BI and UM/UIM BI need to be at least 250/500 and Homeowner's Liability needs to be at least $300K. There also cannot be any excluded drivers.",
        function (val) {
          // if val is true return canaddumbrella
          // in every other case return true
          if (val) {
            const coverageX =
              this.options?.context?.homeCoverage?.coverageX || this.options?.context?.condoCoverage?.coverageX;
            return canAddUmbrella({
              policyLimitBIPD: this.options?.context?.autoCoverage?.policyLimitBIPD,
              policyLimitUMBI: this.options?.context?.autoCoverage?.policyLimitUMBI,
              coverageX
            });
          }

          return true;
        }
      )
      .nullable(),
    umbrellaCoverage: Yup.object()
      .shape({
        liabilityCoverageLimit: Yup.number().test(
          'umbrella-limit-caped-at-2mil',
          'You cannot increase coverage over $2m',
          function (value) {
            // currently not allowing umbrella higher than 2mil
            if (value > 2000000) {
              return false;
            }
            return true;
          }
        ),
        watercraftHullLengths: Yup.array().of(
          Yup.number().max(30, 'We do not allow boats with hull lengths over 30 feet.')
        ),
        numVehiclesTotal: Yup.number()
          .test(
            'minCount',
            'The total number of Umbrella vehicles cannot be less than the number of vehicles on the policy',
            function (val) {
              const numCars = this.options.context?.cars?.length;
              return this.options.context?.includeUmbrella ? val >= numCars : true;
            }
          )
          .required('Number Of Vehicles is required'),
        numLicensedDriversUnder25: Yup.number().test(
          'minCountDrivers',
          'The total number of Umbrella drivers under age 25 cannot be less than the number of drivers under age 25 on the auto policy',
          function (val) {
            const driversUnder25 = this.options.context?.drivers.filter((d) => {
              // not relying on d.age here because that node is only accurate after save
              // and we want to catch drivers under 25 in case they were added in the same update as adding umbrella
              const age = differenceInYears(new Date(), new Date(d.dateOfBirth));
              return age < 25;
            }).length;
            return this.options.context?.includeUmbrella ? val >= driversUnder25 : true;
          }
        )
      })
      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
    scheduledPersonalProperty: Yup.object()
      .shape({
        deductible: Yup.string()
          .test('sppDeductible', 'SPP deductible is required.', function (value, { options }) {
            if (options.context?.scheduledPersonalProperty?.items?.length) {
              return !!value;
            }
            return true;
          })
          .nullable(),
        items: Yup.array().nullable()
      })
      .nullable()
  });

// @TODO there is a way to programmatically update schemas, and this would having the more or less the same schema, but you need a workaround to support updating nested fields see  https://github.com/jquense/yup/issues/283#issuecomment-420279808
const checkoutValidationSchema = (session, affinityLookups) =>
  Yup.object().shape({
    isBix: Yup.boolean().nullable(),
    global: Yup.object().shape({
      affinity: Yup.string()
        .validAffinityCode('global.affinity', { affinityLookups, isAgency: session.isAgency })
        .nullable(),
      autoBillingDayOfMonth: Yup.number().nullable(),
      autoEffectiveDate: Yup.date().nullable(),
      autoPaymentMethod: Yup.string().requiredString(),
      autoPaymentType: Yup.string().requiredString(),
      billingDayOfMonth: Yup.number().nullable(),
      currentAutoCarrier: Yup.string().nullable(),
      currentHomeownersCarrier: Yup.string().nullable(),
      currentlyAutoInsured: Yup.boolean(),
      discountInventoryScore: Yup.boolean(),
      discountPaperless: Yup.boolean(),
      homeBillingDayOfMonth: Yup.number().nullable(),
      homeEffectiveDate: Yup.date()
        .test('min', 'Policy start date cannot be in the past or before the home purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateHome =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateHome, date);
          }
          return true;
        })

        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      homeownersPaymentMethod: Yup.string().requiredString(),
      homeownersPaymentType: Yup.string().requiredString(),
      personalPropertyProtection: Yup.boolean(),
      condoEffectiveDate: Yup.date()
        .nullable()
        .test('min', 'Policy start date cannot be in the past or before the condo purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const purchaseDate = this.options.context?.condo?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateCondo =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateCondo, date);
          }
          return true;
        })

        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('C')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
    }),
    primaryMortgageDetail: Yup.object()
      .shape({
        mortgageHolderName: Yup.string()
          .nullable()

          .test('required', 'Mortgage lender name is required to checkout', function (obj) {
            // the test passes if the payment method is not mortgage or if there's a value filled for mortgage holder name but we only need to check this in cases where the selected policy has a Home
            if (this.options.context?.selectedOption?.includes('H')) {
              return (
                this.options.context?.global?.homeownersPaymentMethod !== paymentMethod.Escrow ||
                this.options.context?.primaryMortgageDetail?.mortgageHolderName?.trim().length
              );
            }
            if (this.options.context?.selectedOption?.includes('C')) {
              return (
                this.options.context?.global?.condoPaymentMethod !== paymentMethod.Escrow ||
                this.options.context?.primaryMortgageDetail?.mortgageHolderName?.trim().length
              );
            }
            return true;
          }),
        loanNumber: Yup.string().nullable(),
        mortgageHolderAddress: Yup.object()
          .shape({
            address: Yup.string().nullable(),
            address2: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable().validUSState('offer.mortgageHolderAddress.state'),
            zip: Yup.string().nullable()
          })
          .nullable()
      })
      .nullable()
  });

export const buildSchema = ({
  formAction,
  includeConnectedHome,
  isAdvancedConnectedHome,
  needMVRs,
  session,
  affinityLookups,
  selectedOption,
  state
}) => {
  let schema;

  if (formAction === FormAction.Checkout && !needMVRs) {
    schema = checkoutValidationSchema(session, affinityLookups);
  } else {
    schema = offerValidationSchema(session, affinityLookups);
    if (selectedOption.includes('H')) {
      schema = schema.concat(homeSchema(state));
    }
    if (selectedOption.includes('A')) {
      schema = schema.concat(autoSchema(state));
    }
  }

  if (includeConnectedHome) {
    schema = schema.concat(connectedHomeSchema(isAdvancedConnectedHome));
  }
  // If the offer is out of date (meaning the effective dates are in the past)
  // we don't want the validation to enforce the effective date rules, since the form's submit function
  // will automatically update the effective dates to today
  // This is because the validation will stop the form from being submitted if there are validation errors
  if (formAction !== FormAction.UpdateEffectiveDates) {
    schema = schema.concat(effectiveDateSchema);
  }
  return schema;
};
