import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mContent: {
    minWidth: 720,
    minHeight: 290,
    padding: theme.spacing(12),
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  redBack: {
    backgroundColor: theme.colorPalette.red_20,
    '& *': {
      color: theme.colorPalette.white_10
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  link: {
    marginRight: theme.spacing(10)
  },
  copyLink: {
    marginRight: 22
  },
  btn: {
    minWidth: 288
  },
  btnSmall: {
    minWidth: 188
  },
  content: {
    marginTop: theme.spacing(8)
  },
  innerContent: {
    marginBottom: theme.spacing(5)
  },
  bottom: {
    marginTop: theme.spacing(8)
  },
  topInfo: {
    color: theme.colorPalette.red_20
  },
  errorTitle: {
    color: theme.colorPalette.red_20
  },
  errorMessage: {
    color: theme.colorPalette.red_20
  },
  errorContent: {
    marginTop: theme.spacing(2)
  },
  searchDialog: {
    minWidth: 720,
    minHeight: 290,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  searchFooter: {
    height: 125
  },
  linkButton: {
    backgroundColor: theme.colorPalette.orange_10,
    color: theme.colors.white,
    width: 296
  },
  buttonContainer: {
    marginRight: theme.spacing(12)
  },
  searchHeader: {
    padding: '28px 0 0 48px'
  },
  searchBar: {
    padding: theme.spacing(6, 12)
  },
  searchSubtitle: {
    paddingTop: theme.spacing(3)
  },
  contentWrapper: {
    padding: theme.spacing(5)
  },
  title: {
    padding: '30px 0px 20px 0px'
  },
  mvrDriversCard: {
    marginTop: 5,
    padding: theme.spacing(5, 5, 0),
    minHeight: 154
  },
  driverCard: {
    marginTop: 5,
    minHeight: 64
  }
}));

export default useStyles;
