import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  modalHeader: {
    marginBottom: theme.spacing(3)
  },
  modalContent: {
    lineHeight: 1,
    display: 'block'
  },
  infoContainer: {
    marginTop: theme.spacing(4)
  },
  link: {
    display: 'flex',
    margin: theme.spacing(4, 0),
    gap: theme.spacing(2)
  }
});

export default makeStyles(styles);
