import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import { getSnapshot } from 'mobx-state-tree';

import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { useErrors } from '../../helpers/error-hooks';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';
import { MoreInfoNeededFlagItem } from '../new-construction-flag-item';
import { Condo } from './condo';
import { Home } from './home';
import { propDetailsCode } from '../quote-forms/quote-forms.constants';
import { policyType } from '@ourbranch/lookups';

const propDetailsCondition = (code) => propDetailsCode.includes(code);

const PropertyDetail = ({ showPropertyDetailsCallout, sectionsToShow }) => {
  const { values } = useFormikContext();
  const {
    quote: { errors: quoteErrors }
  } = useStore();
  const errors = useErrors(getSnapshot(quoteErrors), propDetailsCondition);
  const propertyErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);
  const hasCondo = [policyType.CABundle, policyType.Condo].includes(values.policyType);

  useEffect(() => {
    if (errors.length && propertyErrors.current) {
      propertyErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    const shouldBeShown = propDetailsCode.every((element) => sectionsToShow.includes(element));
    setShouldShow(shouldBeShown);
  }, [sectionsToShow]);

  return (
    <Form>
      {values.isNewConstruction && <MoreInfoNeededFlagItem />}
      {(errors.length > 0 || values.isNewConstruction || shouldShow) && (
        <>
          <Errors errors={errors} ref={propertyErrors} />
          <Collapsible title="Property Detail" forceOpen={showPropertyDetailsCallout}>
            {hasCondo ? <Condo /> : <Home />}
          </Collapsible>
        </>
      )}
    </Form>
  );
};

PropertyDetail.propTypes = {
  sectionsToShow: PropTypes.array,
  showPropertyDetailsCallout: PropTypes.bool.isRequired
};

PropertyDetail.defaultProps = {
  sectionsToShow: []
};

export default withDatePicker(PropertyDetail);
