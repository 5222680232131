import React from 'react';
import { cancelCodeInformationalArray } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';

const NonRenewForm = () => {
  const nonRenewOptions = cancelCodeInformationalArray
    .filter((opt) => opt.nonRenew)
    .map((code) => ({
      id: code.cancelCode,
      value: code.cancelReasonPlainText
    }));

  return <FormField name="nonRenewReason" type="select" label="Reason" mode="light" options={nonRenewOptions} xs={6} />;
};

export default withDatePicker(NonRenewForm);
