import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    marginTop: theme.spacing(4)
  }
}));

export default useStyles;
