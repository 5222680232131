import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  accountCard: {
    padding: theme.spacing(8),
    position: 'relative',
    overflowWrap: 'anywhere'
  },
  buttonContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  inviterLink: {
    color: theme.colorPalette.orange_10
  },
  secondaryPhoneContainer: {
    border: `3px solid ${theme.colorPalette.white_10}`,
    borderRadius: 3,
    padding: '10px 20px 10px 20px',
    margin: '15px 0px'
  }
});

export default makeStyles(styles);
