import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { floridaHomeSoftRejectCodes, floridaAutoSoftRejectCodes } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { track } from 'core/helpers/analytics';
import { useStore } from 'core/store';
import useSession from 'core/hooks/use-session';
import { Card } from 'core/components/card';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { useErrors } from 'quote/helpers/error-hooks';
import ErrorDetail from './error-detail';
import { getError } from 'quote/constants/error-helper';
import useStyles from './error.styles';
import OverrideFLUWSoftErrorModal from '../../../pre-bind-UW/components/florida-UW/override-FL-UW-soft-error-modal';

const PolicyErrors = observer(() => {
  const router = useHistory();
  const classes = useStyles();
  const { offer: store, quote } = useStore();
  const { canClearUWFormRejections, viewOnly, isAgency } = useContext(AuthContext);
  const [errorToOverride, setErrorToOverride] = useState(null);
  const [showOverrideUWErrorModal, setShowOverrideUWErrorModal] = useState(false);
  const getErrorsAndRejections = (code) => code >= 4003;
  const errorsAndRejections = useErrors(store.errors, getErrorsAndRejections);
  const session = useSession();

  const onRequoteClick = (error) => {
    quote.setPrefillData(store.offer.quote);
    const errorDetails = getError(error);
    track('Re-run Offer', {
      errorCode: errorDetails.code,
      errorMessage: errorDetails.message,
      offerId: store.offer.id,
      staffUsername: session.user.username,
      isAgency,
      webuserId: store.offer.webUserId,
      offerType: store.offer.quote.selectedOption
    });
    router.push('/quote');
  };

  const onOverrideClick = (errorCode) => {
    setShowOverrideUWErrorModal(true);
    setErrorToOverride(errorCode);
  };

  return (
    <>
      {showOverrideUWErrorModal && (
        <OverrideFLUWSoftErrorModal
          errorToOverride={errorToOverride}
          setShowOverrideUWErrorModal={setShowOverrideUWErrorModal}
        />
      )}
      <Label type="darkGreenMedium" className={classes.errorsLabel} component="div">
        Policy Errors
      </Label>
      {errorsAndRejections.map((error) => (
        <Card key={`${store.offer.id}-${error}`} className={classes.errorCard} type="primaryEditable">
          <Grid container alignItems="stretch" alignContent="space-between" justify="center">
            <ErrorDetail error={error} state={store.state} />
            <Button
              className={classes.requoteButton}
              variant="contained"
              color="primary"
              disabled={viewOnly}
              onClick={() => onRequoteClick(error)}
            >
              <Label className={classes.btnLabel}>Re-run offer to Solve</Label>
            </Button>
            {canClearUWFormRejections &&
              store.state === 'FL' &&
              (floridaHomeSoftRejectCodes.includes(error.code) || floridaAutoSoftRejectCodes.includes(error.code)) && (
                <Button
                  className={classes.overrideButton}
                  variant="contained"
                  color="primary"
                  disabled={viewOnly}
                  onClick={() => onOverrideClick(error.code)}
                >
                  <Label className={classes.btnLabel}>Override error</Label>
                </Button>
              )}
          </Grid>
        </Card>
      ))}
    </>
  );
});

export default PolicyErrors;
