import { addCoveragesToPremiumBreakdown } from './lib/add-coverages-to-premium-breakdown';
import { assignClaimsToDrivers, assignPersonClaimsToDrivers } from './lib/assign-claims-to-drivers';
import { clean } from './lib/clean';
import { createBixConversionApplicationLink } from './lib/create-bix-conversion-application-link';
import { mergeAndRemoveDuplicates } from './lib/merge';
import { fixHomePremiums } from './lib/fix-home-premiums';
import { isFuturePayment } from './lib/payments';
import { policyDetailsFromSegments } from './lib/policy-details-from-segments';
import { samePolicyRoot, isPrecedingTerm } from './lib/policy-id-matching-utils';
import { willHaveCoverageRSExtended } from './lib/will-have-coverage-rs-extended';

export {
  assignClaimsToDrivers,
  assignPersonClaimsToDrivers,
  addCoveragesToPremiumBreakdown,
  clean,
  createBixConversionApplicationLink,
  fixHomePremiums,
  isFuturePayment,
  isPrecedingTerm,
  mergeAndRemoveDuplicates,
  policyDetailsFromSegments,
  samePolicyRoot,
  willHaveCoverageRSExtended
};
