export default (theme) => ({
  checkboxIcon: {
    marginLeft: theme.spacing(-3)
  },
  controlLabel: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(3)
  },
  checkbox: {
    marginTop: 0,
    display: 'flex',
    maxWidth: 240,
    alignItems: 'center'
  },
  tickIcon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2)
  },
  textarea: {
    padding: theme.spacing(2),
    fontSize: 16,
    lineHeight: '20px',
    minHeight: 56
  },
  button: {
    marginTop: theme.spacing(2)
  }
});
