import { policyType as PolicyType } from '@ourbranch/lookups';

export const getPolicyType = (policyType, noBindHome = false, noBindAuto = false) => {
  const hasHome = (policyType === PolicyType.HABundle && !noBindHome) || policyType === PolicyType.Home;
  const hasAuto =
    (policyType === PolicyType.HABundle && !noBindAuto) ||
    policyType === PolicyType.Auto ||
    policyType === PolicyType.ARBundle ||
    policyType === PolicyType.CABundle;
  const hasCondo = [PolicyType.Condo, PolicyType.CABundle].includes(policyType);

  const isHomeBundle = policyType === PolicyType.HABundle && !noBindHome && !noBindAuto;
  const isCondoBundle = policyType === PolicyType.CABundle;
  const isRenters = policyType === PolicyType.ARBundle || policyType === PolicyType.Renters;
  const isHome = policyType === PolicyType.Home;
  const isAuto = policyType === PolicyType.Auto;
  const isCondo = policyType === PolicyType.Condo;
  const isAnyBundle = isHomeBundle || isCondoBundle || isRenters;

  return { hasHome, hasAuto, isHomeBundle, isCondoBundle, isCondo, hasCondo, isRenters, isHome, isAuto, isAnyBundle };
};
