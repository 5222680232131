import { makeStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  checkboxContainer: {
    margin: 'auto'
  },
  fullWidthCheckbox: {
    display: 'flex',
    margin: 'auto',
    marginLeft: theme.spacing(2)
  },
  green: {
    opacity: 0.4
  }
});

export default makeStyles(useStyles);
