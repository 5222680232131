import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import { Table } from '../table';
import TableHeaderCell from './header-cell';
import { Label } from 'core/components/label';
import caret from 'core/assets/svg/caretOrange.svg';
import { Badge } from '../badge';
import styles from './sortable.styles';

const RowCollapser = ({ title, classes, setExpanded, expanded, count, mode, headers }) => {
  const columnCount = headers.length;

  return (
    <TableRow
      classes={mode && MapClassesToElem(`${mode}-${ElemType.TableRow}`, classes)}
      onClick={() => setExpanded(!expanded)}
    >
      <TableCell classes={mode && MapClassesToElem(`${mode}-${ElemType.TableCell}`, classes)} colSpan={columnCount - 1}>
        <div>
          {title} <Badge className={classes.badge}>{count}</Badge>
        </div>
      </TableCell>

      <TableCell colSpan={1}>
        <img
          align="right"
          className={expanded ? classes.icon : classes.iconFold}
          src={caret}
          alt={expanded ? <ExpandLess /> : <ExpandMore />}
        />
      </TableCell>
    </TableRow>
  );
};
const IndividualRow = ({ row, onClick, dataCy, headers, mode, classes, formatters }) => {
  const { rowMode } = row;
  const tableRowMode = rowMode || mode;
  return (
    <TableRow
      onClick={onClick ? () => onClick(row) : undefined}
      data-cy={`${dataCy}-${row.id}`}
      classes={tableRowMode && MapClassesToElem(`${tableRowMode}-${ElemType.TableRow}`, classes)}
    >
      {headers.map(([key]) => (
        <TableCell
          key={key}
          data-cy={`${dataCy}-${key}-${row.id}`}
          classes={mode && MapClassesToElem(`${mode}-${ElemType.TableCell}`, classes)}
        >
          {formatters[key] ? formatters[key](row[key], row) : row[key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

IndividualRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rowMode: PropTypes.PropTypes.oneOf([null, 'dark', 'red'])
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  dataCy: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  mode: PropTypes.oneOf([null, 'dark']),
  classes: PropTypes.object.isRequired,
  formatters: PropTypes.object.isRequired
};

IndividualRow.defaultProps = {
  mode: null
};

const CollapsibleTable = ({ onClick, dataCy, headers, classes, mode, formatters, rowCreator, data, ...rest }) => {
  const sections = data.map((d) => ({
    ...d,
    rows: d.rows.map((row) => ({
      row,
      onClick,
      dataCy,
      headers,
      mode,
      classes,
      formatters
    }))
  }));

  return (
    <Table
      dataCy="Sortable"
      {...rest}
      mode={mode}
      header={
        <>
          {headers.map(([key, value]) => (
            <TableHeaderCell key={key} columnId={key}>
              <Label className={classes.headerLabel}>{value}</Label>
            </TableHeaderCell>
          ))}
        </>
      }
      body={
        <>
          {sections.map((section) =>
            section.individual ? (
              section.rows.map((props) => <IndividualRow key={props.row.id} {...props} mode={mode} />)
            ) : (
              <>
                <RowCollapser
                  title={section.title}
                  classes={classes}
                  setExpanded={section.setExpanded}
                  count={section.rows.length}
                  expanded={section.expanded}
                  mode={section.mode}
                  headers={headers}
                />
                {section.expanded &&
                  section.rows.map((props) => <IndividualRow key={props.row.id} {...props} mode={section.mode} />)}
              </>
            )
          )}
        </>
      }
    />
  );
};

export default withStyles(styles)(CollapsibleTable);
