import { types } from 'mobx-state-tree';

const BillingDetails = types.model({
  transactions: types.maybeNull(types.array(types.frozen())),
  nextPaymentDate: types.maybeNull(types.string),
  nextPaymentAmount: types.maybeNull(types.number),
  nextPayments: types.optional(types.array(types.frozen()), []),
  totalInstallments: types.maybeNull(types.number),
  totalDue: types.maybeNull(types.number),
  totalRemaining: types.maybeNull(types.number),
  remainingPayments: types.maybeNull(types.number),
  modificationURL: types.maybeNull(types.string),
  activePaymentMethod: types.maybeNull(types.frozen()),
  allPaymentMethods: types.optional(types.array(types.frozen()), [], [null, undefined])
});

export default BillingDetails;
