import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { NotificationCard } from 'core/components/notification-card';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { useToast } from 'core/components/toast';
import { AuthContext } from 'core/components/auth';

import useStyles from './override-FL-UW-soft-error-modal.styles';

const OverrideFLUWSoftErrorModal = ({ errorToOverride, setShowOverrideUWErrorModal }) => {
  const classes = useStyles();
  const { handleSubmit, dirty, values, setFieldValue, setFieldTouched } = useFormikContext();
  const toast = useToast();
  const session = useContext(AuthContext);
  const {
    user: { username }
  } = session;

  const onOverride = async () => {
    if (dirty) {
      toast.notify({
        type: 'error',
        message: 'Please save your changes before overriding'
      });
    } else {
      const errorsList = values.overrides?.overridesForUW?.length ? [...values.overrides.overridesForUW] : [];
      errorsList.push({
        code: parseInt(errorToOverride, 10),
        overridingAgent: username
      });

      await setFieldValue('overrides.overridesForUW', errorsList);
      await setFieldTouched('overrides.overridesForUW', true);

      handleSubmit();
    }
  };

  return (
    <BaseDialog size="lg" onClose={() => setShowOverrideUWErrorModal(false)} open>
      <Label className={classes.mainLabel} type="greenBig">
        Are you sure you want to override this error?
      </Label>
      <Label className={classes.notificationContent}>
        Moving forward with this action would override an error that could enable Branch to insure assets that do not
        meet our Underwriting guidelines. Only override errors if you can provide proof of eligibility or you are sure
        Branch can cover these assets.
      </Label>
      <NotificationCard type="light">You will not be able to undo this action.</NotificationCard>
      <Grid className={classes.buttonContainer}>
        <Button className={classes.buttons} variant="contained" color="primary" onClick={onOverride}>
          Yes, override error
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowOverrideUWErrorModal(false)}
          className={classes.buttons}
        >
          No, keep
        </Button>
      </Grid>
    </BaseDialog>
  );
};

export default OverrideFLUWSoftErrorModal;
