import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { Button } from 'core/components/button';
import PropTypes from 'prop-types';

import AlertIconWhite from 'core/assets/svg/alert-white.svg';
import AlertIconOrange from 'core/assets/svg/alert-orange.svg';
import ProgressiveLogo from 'core/assets/svg/progressive-logo.svg';
import Label from 'core/components/label/label';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';

import styles from './quinstreet.styles';

const Quinstreet = observer(({ classes }) => {
  const { setFieldValue } = useFormikContext();
  const store = useStore();
  const {
    quote: { requestQuinstreetQuoteAction, loading, quinstreetOffer, setQuinstreetOffer, hasCarrierKnockout },
    offer: { offer }
  } = store;

  useEffect(() => {
    if (quinstreetOffer && !loading) {
      setQuinstreetOffer(null);
      setFieldValue('quoteQuinstreet', false);
    }
  }, [offer.id]);

  return (
    <Section title="External Offers" type="SubSection">
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <img src={AlertIconWhite} className={classes.icon} alt="Banner Icon" />
          <Label>Auto coverage purchased through Quinstreet is handled separately.</Label>
        </div>
        <div className={classes.containerInner}>
          <FormField
            id="quoteQuinstreet"
            name="quoteQuinstreet"
            mode="dark"
            label="Generate Progressive Quote"
            type="switch"
            onChange={(_, checked) => {
              if (checked) {
                requestQuinstreetQuoteAction(offer.quote, offer.webUserId);
              }
            }}
          />
          <img src={ProgressiveLogo} className={classes.icon} alt="Banner Icon" />
        </div>
        {hasCarrierKnockout && !loading && !quinstreetOffer && (
          <div className={classes.containerInner}>
            <Label className={classes.errorText}>Progressive rating incomplete, please check FAO</Label>
          </div>
        )}
        {loading && (
          <div className={classes.containerInner}>
            <Loading type="secondary" noBackground />
          </div>
        )}

        {quinstreetOffer && !loading && (
          <>
            <div className={classes.containerInner}>
              <div className={classes.flex}>
                <span className={classes.dollarIcon}>$</span>
                <h1 className={classes.header}>{quinstreetOffer.premium}</h1>
                <span className={classes.thinText}>PREMIUM</span>
              </div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.footerButton}
                onClick={() => {
                  window.open(quinstreetOffer.bridgeUrl, '_blank');
                }}
              >
                Continue to Progressive
              </Button>
            </div>
            <div className={classes.containerInner}>
              <div className={classes.disclosure}>
                <img src={AlertIconOrange} className={classes.smallIcon} alt="Banner Icon" />
                <Label className={classes.smallText}>
                  This quote may not reflect the final price and is subject to change prior to purchase.
                </Label>
              </div>
            </div>
          </>
        )}
      </div>
    </Section>
  );
});

Quinstreet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Quinstreet);
