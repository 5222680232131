/* eslint-disable */
const overrideCheckbox = (theme) => {
  theme.overrides.MuiCheckbox = {
    colorSecondary: {
      color: theme.colorPalette.green_10,
      '&$disabled': {
        color: theme.colorPalette.white_30,
        opacity: 0.6
      }
    },
    colorPrimary: {
      color: theme.colorPalette.orange_10,
      position: 'relative',
      '&::before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        float: 'left'
      },
      '& >span': {
        position: 'absolute'
      },
      '&$checked': {
        color: theme.colorPalette.orange_10
      },
      '&$checked::before': {
        backgroundColor: theme.colorPalette.white_10
      },
      '&$disabled': {
        color: theme.colorPalette.white_30,
        opacity: 0.6,
        '&$checked': {
          color: theme.colorPalette.orange_10
        }
      }
    },
    root: {
      marginTop: 0
    }
  };
};

export default overrideCheckbox;
