import { addDays, max, subDays, isFuture, isSameDay } from 'date-fns';
import { toDate, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { stateToTzMap } from '@ourbranch/state-to-tz-map';

/**
 * @deprecated since 2021-09-01
 * consider using `localToUtcTime` if you need to convert an iso string (eg: 2022-09-01) to a date object for comparison
 * */
export const dateFromPolicy = (date = new Date(), state = 'OH') => {
  return toDate(date);
};

/**
 *
 * @param {*} date ISO string date like '2021-04-16'
 * @param {*} state state the policy is in
 */
export const localToUtcTime = (date, state) => {
  return zonedTimeToUtc(date, stateToTzMap[state]);
};

export const getTimezone = (state) => {
  if (['WA', 'CA', 'OR', 'NV'].includes(state)) {
    return 'PT';
  }
  if (['AZ', 'NM', 'WY', 'UT', 'CO', 'MT', 'ID', 'ND', 'SD', 'NE'].includes(state)) {
    return 'MT';
  }
  if (['AL', 'AR', 'IL', 'IA', 'LA', 'MN', 'MO', 'MS', 'OK', 'WI', 'IN', 'KS', 'MI', 'TN', 'TX'].includes(state)) {
    return 'CT';
  }

  if (state === 'HI') {
    return 'HT';
  }
  if (state === 'AK') {
    return 'AKT';
  }
  // default to eastern
  return 'ET';
};

export const convertUTCToET = (date) => formatInTimeZone(date, 'America/New_York', 'MM/dd/yyyy @ h:mm a');

export function getStartMinMaxDate({
  policy,
  editCurrentSegment = false,
  currentSegment = undefined,
  canBackDate = false,
  editingPolicySettings = false
}) {
  const currentSegmentIndex = policy?.segments?.findIndex((segment) => segment.segmentId === currentSegment.segmentId);
  const isFirstSegment = currentSegmentIndex === 0;
  const isMostRecentSegment = currentSegmentIndex === policy?.segments?.length - 1;
  const within24HoursOfPolicyEffective =
    isSameDay(toDate(policy.effectiveDate), new Date()) ||
    isSameDay(subDays(toDate(policy.effectiveDate), 1), new Date());
  let startDate = toDate(currentSegment.startDate);
  const effectiveDate = toDate(policy.effectiveDate);
  let maxDate = toDate(policy.endDate);
  let minDate = new Date();
  const today = new Date();

  // editing effective date on policy settings tab
  if (editingPolicySettings) {
    if (isFuture(effectiveDate)) {
      return {
        startDate,
        minDate,
        maxDate: addDays(minDate, 60)
      };
    }
    return {
      startDate,
      minDate: isFuture(startDate) ? minDate : startDate,
      maxDate: addDays(startDate, 60)
    };
  }

  // editing segments on details tab
  if (canBackDate) {
    if (editCurrentSegment && currentSegment) {
      if (isFirstSegment) {
        // allow users who can back date to move policy effective date to ~2 months out from today if future effective, effective date if past the effective day
        minDate = isFuture(effectiveDate) ? today : startDate;
        maxDate = addDays(isFuture(effectiveDate) ? today : startDate, 60);
      } else {
        minDate = addDays(toDate(policy?.segments[currentSegmentIndex - 1]?.startDate), 1);
      }
      if (!isMostRecentSegment && !isFirstSegment) {
        maxDate = subDays(toDate(policy?.segments[currentSegmentIndex + 1]?.startDate), 1);
      }
    } else {
      // if creating a new segment, min date should be one day after the policy effective date
      // and start date should be tomorrow
      minDate = addDays(toDate(policy.effectiveDate), 1);
      startDate = addDays(new Date(), 1);
    }
  } else {
    // if a user can't back date, default minDate will be the later of one day after the first segment or tomorrow.
    minDate = max([addDays(toDate(policy.effectiveDate), 1), addDays(new Date(), 1)]);
    // a user without back dating permission will still be able to move dates of segments that start in the future (excluding the first)
    if (editCurrentSegment && currentSegment) {
      if (isFirstSegment) {
        minDate = startDate;
        maxDate = minDate;
      } else if (!isFirstSegment && isFuture(startDate)) {
        // if editing a segment that starts in the future, allow date to be changed to the later of one day after the preceding segment or today
        minDate = max([addDays(toDate(policy?.segments[currentSegmentIndex - 1]?.startDate), 1), new Date()]);
      }
      if (!isMostRecentSegment && !isFirstSegment) {
        maxDate = subDays(toDate(policy?.segments[currentSegmentIndex + 1]?.startDate), 1);
      }
    } else {
      startDate = minDate;
    }
  }
  // we don't want staff creating new changes less than 1 week out if policy effective date is tomorrow or today
  if (!editCurrentSegment && within24HoursOfPolicyEffective) {
    minDate = addDays(toDate(policy.effectiveDate), 7);
    startDate = minDate;
  }

  return { startDate, minDate, maxDate };
}
