import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { Label } from 'core/components/label';
import backIcon from 'core/components/screen-header/go-to.svg';
import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';
import { get, policyTypes } from 'core/helpers/lookups';
import { useStore } from 'core/store';
import FrontContact from 'customer/components/front-contact/front-contact';
import { AuthContext } from 'core/components/auth';

import useStyles from './policy-header.styles';

const getPolicyType = get(policyTypes);

const PolicyHeader = ({ history }) => {
  const store = useStore();
  const { account: accountStore } = store;
  const policy = accountStore?.policies?.policy?.policy;
  const accountId = accountStore.id;
  const { policyId } = useParams();
  const { isExternalSales } = useContext(AuthContext);
  const classes = useStyles();

  const titleText = () => {
    return policy?.id && policy?.id === policyId
      ? `${accountStore.fname} ${accountStore.lname}/${getPolicyType(policy.policyType)} Policy`
      : accountStore.accountLoading
      ? 'Loading...'
      : `${accountStore.fname} ${accountStore.lname}/Loading...`;
  };

  const title = titleText();

  const logRocketURL = policy?.offer?.logrocketSessionURL;

  const onBack = useCallback(() => {
    history.push(`/customer/${accountId}`);
  }, [accountId, history]);

  return (
    <Grid container className={classes.header} justify="space-between">
      <Grid item className={classes.container}>
        <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)} onClick={onBack}>
          <img src={backIcon} className={classes.icon} alt="back" />
        </IconButton>
        <Label type="title">{title}</Label>
        {!isExternalSales && <FrontContact />}
      </Grid>
      <Grid item>
        <div className={classes.rightContainer}>
          <Label type="infoLabel" className={classes.rightLabel}>
            PolicyID
          </Label>
          <Label type="infoValue" className={classes.rightValue}>
            {policyId}
          </Label>
          {policy && policy.fromStaff ? (
            <Label type="infoLabel" className={classes.madeByStaff}>
              Made by staff
            </Label>
          ) : null}
        </div>
        <div className={classes.additionalLink}>
          <Label type="infoLabel" className={classes.rightLabel}>
            Log Rocket
          </Label>
          <Label type="infoValue" className={classes.rightValue}>
            {logRocketURL ? (
              <a className={classes.link} href={logRocketURL} target="_blank" rel="noopener noreferrer">
                View purchase
              </a>
            ) : (
              'No session'
            )}
          </Label>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(PolicyHeader);
