import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  mainLabel: {
    marginTop: 40
  },
  boldedLetters: {
    fontWeight: 'bold'
  },
  notificationCard: {
    marginTop: 32
  },
  formContainer: {
    marginTop: 20
  },
  buttonContainer: {
    display: 'flex'
  },
  internalNotes: {
    marginRight: 8
  },
  buttons: {
    marginTop: 16,
    marginLeft: 16,
    paddingRight: 25,
    paddingLeft: 25
  }
});

export default makeStyles(styles);
