import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    padding: theme.spacing(6, 8),
    borderRadius: theme.sizes.roundCorners
  },
  coverages: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  }
});

export default makeStyles(styles);
