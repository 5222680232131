import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import Section from 'core/components/section';
import useStyles from './auto-information.styles';

const AutoInformation = ({ disabled }) => {
  const classes = useStyles();
  return (
    <Section title="Auto Information" type="SubSection">
      <Grid container justify="space-around" className={classes.container}>
        <Grid item xs={12}>
          <LabelTooltip
            mode="light"
            label="Number of cars owned"
            tooltip={{
              label: 'More Info',
              onHoverText: 'This information is required for an accurate Condo policy pricing.'
            }}
          >
            <FormField
              name="condo.numVehicles"
              type="numeric"
              mode="light"
              disabled={disabled}
              format={{
                allowNegative: false
              }}
            />
          </LabelTooltip>
        </Grid>
      </Grid>
    </Section>
  );
};

AutoInformation.propTypes = {
  disabled: PropTypes.bool
};

AutoInformation.defaultProps = {
  disabled: false
};

export default AutoInformation;
