import React from 'react';
import { Grid } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { awsDateToDateFormatter } from 'core/helpers/formatters';

import Section from 'core/components/section';
import CollapsibleCard from 'core/components/collapsible-card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { useStore } from 'core/store';
import useStyles from './prior-coverage.styles';

const PriorCoverage = () => {
  const classes = useStyles();

  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const id = offerStore?.offer?.id || policyStore?.policy?.id;
  const global = offerStore?.offer?.quote?.global || policyStore?.policy?.offer?.quote?.global;
  const date = global?.currentAutoCarrierStartDate;

  return (
    <Section title="Information from Prior Carrier" type="SubSection">
      <CollapsibleCard
        content={
          <Label type="infoSubtitle" style={{ display: 'inline', marginRight: 8 }}>
            Coverage Verifier Report
          </Label>
        }
        initialOpen={false}
      >
        <Grid container justify="space-around" className={classes.container}>
          <Grid container spacing={2} justify="space-between" alignItems="flex-start" className={classes.section}>
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Policy Information</Label>
            </Grid>
            <FormField
              name={`${id}${global?.currentAutoCarrier}`}
              type="value"
              label="Current Carrier"
              value={global?.currentAutoCarrier || 'N/A'}
              xs={16}
            />
            <FormField
              name={`${id}${date}`}
              type="value"
              label="Duration"
              value={
                (global?.currentAutoCarrierStartDate &&
                  typeof date === 'string' &&
                  formatDistance(new Date(), parseISO(date)).toUpperCase()) ||
                'N/A'
              }
              xs={16}
            />
            <FormField
              name={`${id}${global?.currentAutoCarrierEndDate}`}
              type="value"
              label="Policy Expiration Date"
              value={awsDateToDateFormatter(global?.currentAutoCarrierEndDate) || 'N/A'}
              xs={16}
            />
          </Grid>

          <Grid container className={classes.section}>
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Drivers on Current Policy</Label>
            </Grid>
            {global?.priorDrivers ? (
              global.priorDrivers.map((driver) => (
                <Grid container justify="flex-start" alignItems="flex-start" key={driver.firstName}>
                  <div className={classes.column}>
                    <FormField
                      name={`${id}${driver?.firstName}`}
                      type="value"
                      label="First Name"
                      value={driver?.firstName}
                      xs={16}
                    />
                  </div>
                  <FormField
                    name={`${id}${driver?.lastName}`}
                    type="value"
                    label="Last Name"
                    value={driver?.lastName}
                    xs={16}
                  />
                </Grid>
              ))
            ) : (
              <Grid container justify="flex-start" alignItems="flex-start">
                <div className={classes.column}>
                  <FormField name="firstName" type="value" label="First Name" value="N/A" xs={16} />
                </div>
                <FormField name="lastName" type="value" label="Last Name" value="N/A" xs={16} />
              </Grid>
            )}
          </Grid>

          <Grid container className={classes.section}>
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Cars on Current Policy</Label>
            </Grid>
            {global?.priorCars ? (
              global.priorCars.map((vehicle) => (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.carDetails}
                  key={vehicle.VIN}
                >
                  <FormField
                    name={`${id}${vehicle?.year}${vehicle?.make}${vehicle?.model}`}
                    type="value"
                    label="Car/Car Vin"
                    value={`${vehicle?.year} ${vehicle?.make} ${vehicle?.model} / ${vehicle?.VIN}`}
                    xs={16}
                  />
                  <Grid container item spacing={2} justify="space-between">
                    <FormField
                      name={`${id}${vehicle?.deductibleCollision}`}
                      type="value"
                      label="Collision Deductible"
                      value={vehicle?.deductibleCollision || 'N/A'}
                      xs={16}
                    />
                    <FormField
                      name={`${id}${vehicle?.deductibleComprehensive}`}
                      type="value"
                      label="Comprehensive Deductible"
                      value={vehicle?.deductibleComprehensive || 'N/A'}
                      xs={16}
                    />
                    <FormField
                      name={`${id}${vehicle?.roadsideAssistance}`}
                      type="value"
                      label="Towing and Labor Limit"
                      value={vehicle.roadsideAssistance || 'N/A'}
                      xs={16}
                    />
                    <FormField
                      name={`${id}${vehicle?.limitRental}`}
                      type="value"
                      label="Rental Reimbursement"
                      value={vehicle.limitRental !== null ? `$${vehicle.limitRental}` : 'N/A'}
                      xs={16}
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid container justify="flex-start" alignItems="flex-start">
                <div className={classes.column}>
                  <FormField name="Car" type="value" label="Car" value="N/A" xs={16} />
                </div>
                <FormField name="car vin" type="value" label="Car VIN" value="N/A" xs={16} />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} justify="space-between" className={classes.section}>
            <Grid item xs={12}>
              <Label type="formSubTitleLight">Auto Policy Coverage</Label>
            </Grid>
            <FormField
              name={`${id}${global?.priorIndividualBILimit}`}
              type="value"
              label="Bodily Injury Limit"
              value={
                global?.priorIndividualBILimit?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 'N/A'
              }
              xs={16}
            />
            <FormField
              name={`${id}${global?.priorOccurrenceBILimit}`}
              type="value"
              label="Property Damage Limit"
              value={
                global?.priorOccurrenceBILimit?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 'N/A'
              }
              xs={16}
            />
            <FormField
              name={`${id}${global?.priorUmIndividualBodilyInjury}`}
              type="value"
              label="Uninsured Motorist BI"
              value={
                global?.priorUmIndividualBodilyInjury && global?.priorUmOccurrenceBodilyInjury !== null
                  ? `${global.priorUmIndividualBodilyInjury?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })}/${global.priorUmOccurrenceBodilyInjury?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })}`
                  : 'N/A'
              }
              xs={16}
            />
            <FormField
              name={`${id}${global?.priorUmIndividualBodilyInjury}`}
              type="value"
              label="Underinsured Motorist BI"
              value={
                global?.priorUmIndividualBodilyInjury && global?.priorUmOccurrenceBodilyInjury !== null
                  ? `${global.priorUmIndividualBodilyInjury?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })}/${global.priorUmOccurrenceBodilyInjury?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })}`
                  : 'N/A'
              }
              xs={16}
            />
          </Grid>
        </Grid>
      </CollapsibleCard>
    </Section>
  );
};

export default PriorCoverage;
