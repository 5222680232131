import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../common.styles';

export default makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: `2px solid ${theme.colorPalette.white_10}`,
    backgroundColor: theme.colors.page__background_primary,
    padding: theme.spacing(6, 8),
    marginBottom: theme.spacing(2)
  },
  bottom: {
    justifyContent: 'space-between'
  },
  singleBottom: {
    justifyContent: 'flex-end'
  },
  breakage: {
    display: 'flex'
  },
  description: {
    height: 80
  },
  ...commonStyles(theme)
}));
