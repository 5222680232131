/* eslint-disable func-names */
import * as Yup from 'yup';

// for the UW form, we use validation to determine if a user has submitted a wrong answer (an answer that goes against underwriting rules).
// ex. user submits false for isPrimaryHome question, that will make the offer ineligible for home.
// the validation will run and return an error object with the node, which will be used in cannot-offer-modal.js to determine eligibility
// at that modal, the user is given the option to drop home/auto, setting ineligibleForHomeDueToUWSelfReport/ineligibleForAutoDueToUWSelfReport to true
// and skip validation to submit and recalc so that the backend UW rules can drop the ineligible policy type

const baseFLSchema = Yup.object().shape({
  signedPropertyUWAttestation: Yup.boolean().oneOf([true], `Agent must check attestation checkbox`).required()
});

const homeFLSchema = Yup.object().shape({
  home: Yup.object().shape({
    isPrimaryHome: Yup.string()
      .required()
      .test('isPrimaryHome', function (value) {
        return value === 'true';
      }),
    hasTrampolinesOrSkateBoardRampEtc: Yup.string()
      .required()
      .test('hasTrampolinesOrSkateBoardRampEtc', function (value) {
        return value === 'false';
      }),
    hadRentedMultTimesOrFullTimeOneTenant: Yup.string()
      .required()
      .test('hadRentedMultTimesOrFullTimeOneTenant', function (value) {
        return value === 'false';
      }),
    isOwnedByCorpOrPartnershipEtc: Yup.string()
      .required()
      .test('isOwnedByCorpOrPartnershipEtc', function (value) {
        return value === 'false';
      }),
    isInForeclosure: Yup.string()
      .required()
      .test('isInForeclosure', function (value) {
        return value === 'false';
      }),
    hasMoreThan2Mortgages: Yup.string()
      .required()
      .test('hasMoreThan2Mortgages', function (value) {
        return value === 'false';
      }),
    hadPriorLossIn5Years: Yup.string()
      .required()
      .test('hadPriorLossIn5Years', function (value) {
        return value === 'false';
      }),
    isPriorPolicyCancelledOrNonRenewed: Yup.string()
      .required()
      .test('isPriorPolicyCancelledOrNonRenewed', function (value) {
        return value === 'false';
      }),
    isIneligibleDogBreed: Yup.string()
      .required()
      .test('isIneligibleDogBreed', function (value) {
        return value === 'false';
      }),
    hasExoticOrNonDomesticatedEtcAnimals: Yup.string()
      .required()
      .test('hasExoticOrNonDomesticatedEtcAnimals', function (value) {
        return value === 'false';
      }),
    hasPetBiteHistoryOrViciousBehavior: Yup.string()
      .required()
      .test('hasPetBiteHistoryOrViciousBehavior', function (value) {
        return value === 'false';
      }),
    excludeWindCoverage: Yup.string()
      .required()
      .test('excludeWindCoverage', function (value) {
        return value === 'false';
      }),
    excludeContentCoverage: Yup.string()
      .required()
      .test('excludeContentCoverage', function (value) {
        return value === 'false';
      }),
    includeSinkholeCoverage: Yup.string()
      .required()
      .test('includeSinkholeCoverage', function (value) {
        return value === 'false';
      }),
    hadSinkholeActivityOrIsAdjacentToSinkholes: Yup.string()
      .required()
      .test('hadSinkholeActivityOrIsAdjacentToSinkholes', function (value) {
        return value === 'false';
      }),
    hasFlatRoofs: Yup.string()
      .required()
      .test('hasFlatRoofs', function (value) {
        return value === 'false';
      }),
    isMobileOrPrefabricatedEtc: Yup.string()
      .required()
      .test('isMobileOrPrefabricatedEtc', function (value) {
        return value === 'false';
      }),
    hasRecalledConstructionMaterials: Yup.string()
      .required()
      .test('hasRecalledConstructionMaterials', function (value) {
        return value === 'false';
      }),
    isWaterHeaterAgeGreaterThan15Years: Yup.string()
      .required()
      .test('isWaterHeaterAgeGreaterThan15Years', function (value) {
        return value === 'false';
      }),
    runDayCareOutOfMyHome: Yup.string()
      .required()
      .test('runDayCareOutOfMyHome', function (value) {
        return value === 'false';
      }),
    hasCommercialOccupation: Yup.string()
      .required()
      .test('hasCommercialOccupation', function (value) {
        return value === 'false';
      }),
    hasInsuranceCurrently: Yup.string()
      .required()
      .test('hasInsuranceCurrently', function (value) {
        return value === 'true';
      })
  })
});

const autoFLSchema = Yup.object().shape({
  auto: Yup.object().shape({
    needSR22: Yup.string()
      .required()
      .test('needSR22', function (value) {
        return value === 'false';
      }),
    excludeBICoverage: Yup.string()
      .required()
      .test('excludeBICoverage', function (value) {
        return value === 'false';
      })
  })
});

export const FLValidationSchema = (selectedOption) => {
  let schema = baseFLSchema;
  if (selectedOption.includes('H')) {
    schema = schema.concat(homeFLSchema);
  }
  if (selectedOption.includes('A')) {
    schema = schema.concat(autoFLSchema);
  }
  return schema;
};
