import { object, boolean } from 'yup';
import { getExtraQuestionsConditions } from '../../helper';

export default (quote, isAgency) => {
  const { opposingPolicySoldByAgency } = getExtraQuestionsConditions(quote, isAgency);

  if (!opposingPolicySoldByAgency) {
    return null;
  }

  return object().shape({
    opposingPolicySoldByAgency: boolean().nullable().required(' ')
  });
};
