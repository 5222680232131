import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    width: 720,
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(8)
  },
  sectionWithLine: {
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`,
    marginTop: theme.spacing(5),
    paddingTop: 25
  },
  paymentBlock: {
    paddingBottom: 25
  },
  secondaryPhoneContainer: {
    border: `3px solid ${theme.colorPalette.white_10}`,
    borderRadius: 3,
    padding: '10px 0px 10px 20px',
    margin: '15px 0px'
  },
  textCheckbox: {
    marginLeft: '5px'
  },
  loanOfficerField: {
    marginTop: theme.spacing(4)
  }
});

export default makeStyles(styles);
