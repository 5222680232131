import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Label } from 'core';
import editIcon from './edit.svg';
import styles from './label-with-button.styles';

const LabelWithButton = ({ classes, children, editing, type, onClick, ...props }) => {
  return (
    <Grid className={classes.container}>
      <Label type={type} {...props} className={classes.label}>
        {children}
      </Label>
      <IconButton className={classes.iconButtonRoot} onClick={onClick} data-cy="edit-button">
        <img className={classes.icon} src={editIcon} alt={'edit'} />
      </IconButton>
    </Grid>
  );
};

LabelWithButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(LabelWithButton);
