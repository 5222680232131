import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  gridItem: {
    paddingTop: theme.spacing(4)
  },
  field: {
    minHeight: 'unset'
  },
  stormShuttersTooltip: {
    top: 0
  },
  separator: {
    width: '100%',
    borderBottom: `1px solid ${theme.colorPalette.beige_10}`,
    margin: theme.spacing(6, 0, 4)
  }
});

export default makeStyles(styles);
