import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLHomeStructureMaterialQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Structure and materials</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Has your home had prior sinkhole damage or is it within a 1-mile proximity to a sinkhole?
        </Label>
        <FormField
          className={classes.formItem}
          id="home.hadSinkholeActivityOrIsAdjacentToSinkholes"
          name="home.hadSinkholeActivityOrIsAdjacentToSinkholes"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Does your home have a flat roof that covers an air-conditioned living area?
        </Label>

        <FormField
          className={classes.formItem}
          id="home.hasFlatRoofs"
          name="home.hasFlatRoofs"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Is your home any of the following? A mobile home, a prefabricated home, made of log, made of any
          unconventional construction material, or does it have a Landmark status.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.isMobileOrPrefabricatedEtc"
          name="home.isMobileOrPrefabricatedEtc"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Does your home have any recalled construction materials, including but not limited to: Chinese drywall, EIFS,
          asbestos material or polybutylene plumbing?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Homes built before 2006 might have recalled construction materials which Branch does not cover. Please confirm
          that the home uses accepted construction materials.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.hasRecalledConstructionMaterials"
          name="home.hasRecalledConstructionMaterials"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Is your water heater over 15 years old?</Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Homes that are older than 15 years are more likely to have old water heaters with Branch does not cover.
        </Label>

        <FormField
          className={classes.formItem}
          id="home.isWaterHeaterAgeGreaterThan15Years"
          name="home.isWaterHeaterAgeGreaterThan15Years"
          type="radio"
          mode="light"
          options={options}
          defaultValue={''}
        />
      </Card>
    </Grid>
  );
};
export default FLHomeStructureMaterialQuestions;
