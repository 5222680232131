import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  form: {
    width: '100%',
    alignItems: 'center',
    flexBasis: 'auto',
    gap: theme.spacing(0, 3)
  }
});

export default makeStyles(styles);
