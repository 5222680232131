import { useMemo } from 'react';
import { lookupsJson } from '@ourbranch/lookups';

const useOptions = (name, state) =>
  useMemo(() => {
    if (lookupsJson[`${name}State`] && lookupsJson[`${name}State`][state]) {
      return lookupsJson[`${name}State`][state];
    }
    return lookupsJson[name];
  }, [name, state]);

export default useOptions;
