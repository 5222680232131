import React, { useEffect, useContext, useState } from 'react';
import { CircularProgress, Tab, Tabs, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { rejectCodesToBeClearedInStaff, policyType } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import { OfferPageTabs } from 'core/store/offer-store';
import { useStore } from 'core/store/store.mobx';
import { useToast } from 'core/components/toast';
import Notification from 'core/helpers/notifications';
import { ValidationErrorsModal, useValidationErrorsModal } from 'core/components/validation-errors-modal';
import { OfferNotifications } from 'offer/components/notifications';
import ResetUWFormModal from './components/pre-bind-UW/components/common/reset-UW-form-modal';
import ConnectedHomeDiscountModal from './components/sidebar/components/discounts-card/connected-home-discount/connected-home-discount-modal';
import { AutoDetails } from './components/auto-details';
import { OfferDialog, CustomerSearchDialog, SearchReferralDialog } from './components/dialog';
import { Footer } from './components/footer';
import { HomeDetails } from './components/home-details';
import { PeopleDetails } from './components/people-details';
import { Settings } from './components/settings';
import PipMI from 'common/components/pip';
import { RentersOffer } from 'common/components/renters';
import { CondoOfferDetails } from 'common/components/condo';
import useStyles from './offer.styles';

const Offer = observer(() => {
  const classes = useStyles();
  const toast = useToast();
  const { offer: store } = useStore();
  const { dirty, values, validateForm, setFieldValue, setFieldTouched } = useFormikContext();
  const { showValidationErrorsModal, setShowValidationErrorsModal } = useValidationErrorsModal();
  const [showUWResetModal, setShowUWResetModal] = useState(false);
  const { viewOnly } = useContext(AuthContext);

  const notificationTypes = store?.notifications?.map((i) => i).reduce((curr, prev) => prev.concat(curr));
  const shouldClearRejectCodes = rejectCodesToBeClearedInStaff.includes(
    store.offer?.quote?.offerings?.homeownersRejectCode
  );

  useEffect(() => {
    store.setFormAction({ dirty, stale: store.isStale });
    // if the validation schema changes *after* the validation has run we have call validate again manually
    validateForm();
  }, [dirty, store, validateForm, store.isStale]);

  useEffect(() => {
    // set to auto here if ineligible for home but form hasn't updated yet
    if (store.priorQuoteWithPreBindUWRejections.ineligibleForHome && values.selectedOption.includes(policyType.Home)) {
      store.setSelectedOption(policyType.Auto);
      setFieldValue('selectedOption', policyType.Auto);
      setFieldTouched('selectedOption', true);
    }
  }, [values.selectedOption, store.selectedOption, store.priorQuoteWithPreBindUWRejections.ineligibleForHome]);

  useEffect(() => {
    // auto scroll up so notifications are in view
    if (
      store.notifications?.length &&
      notificationTypes !== Notification.Offer.NewConnectedHomeSignUpCustomer &&
      !store.loading
    ) {
      document.getElementById('notificationsContainer').scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [store.notifications, store.loading, notificationTypes]);

  // if a tab is selected and then the insurance type is changed to no longer show that tab, reset the current tab to people
  useEffect(() => {
    const shouldSwitchToPeopleTab =
      (store.currentTab === OfferPageTabs.HOME && !store.shouldShowHomeTab) ||
      (store.currentTab === OfferPageTabs.AUTO && !store.shouldShowAutoTab) ||
      (store.currentTab === OfferPageTabs.RENTERS && !store.shouldShowRentersTab) ||
      (store.currentTab === OfferPageTabs.PIP && !store.shouldShowPIPTab);

    if (shouldSwitchToPeopleTab) {
      store.setCurrentTab(OfferPageTabs.PEOPLE);
    }
  }, [
    store.currentTab,
    store.shouldShowHomeTab,
    store.shouldShowAutoTab,
    store.shouldShowRentersTab,
    store.shouldShowPIPTab,
    store.setCurrentTab
  ]);

  // Clear whitelisted reject codes if exists
  useEffect(() => {
    const clearRejectCodes = async () => {
      await store.clearRejectCodes();
      if (store.areRejectCodesCleared) {
        toast.notify({
          type: 'success',
          message:
            'This offer is for a home located in a wind/hail exclusion zone. Please ensure the member understands what this means for their coverage, and assist them with purchasing the excluded coverage through one of our partners, if applicable.',
          durationInSeconds: 15,
          label: 'X',
          action: () => {}
        });
      }
    };

    if (shouldClearRejectCodes) {
      clearRejectCodes();
    }
  }, [shouldClearRejectCodes, store.areRejectCodesCleared]);

  return (
    <>
      <div
        className={classNames(
          { [classes.container]: store.errors.length === 0 },
          { [classes.withErrors]: store.errors.length > 0 }
        )}
      >
        {store.notifications?.length && notificationTypes !== 'NewConnectedHomeSignUpCustomer' && (
          <OfferNotifications setShowUWResetModal={setShowUWResetModal} />
        )}
        {store.showConectedHomeModal && <ConnectedHomeDiscountModal />}
        {showUWResetModal && <ResetUWFormModal setShowUWResetModal={setShowUWResetModal} />}
        <Tabs value={store.currentTab} variant="fullWidth" onChange={(_, newTab) => store.setCurrentTab(newTab)}>
          <Tab value={OfferPageTabs.PEOPLE} label="People" className={classes.tab} />
          {store.shouldShowHomeTab && <Tab value={OfferPageTabs.HOME} label="Home Details" className={classes.tab} />}
          {store.shouldShowCondoTab && (
            <Tab value={OfferPageTabs.CONDO} label="Condo Details" className={classes.tab} />
          )}
          {store.shouldShowAutoTab && <Tab value={OfferPageTabs.AUTO} label="Auto Details" className={classes.tab} />}
          {store.shouldShowRentersTab && (
            <Tab value={OfferPageTabs.RENTERS} label="Renters Details" className={classes.tab} />
          )}
          {store.shouldShowPIPTab && <Tab value={OfferPageTabs.PIP} label="PIP" className={classes.tab} />}
          <Tab value={OfferPageTabs.SETTINGS} label="Settings" className={classes.tab} />
        </Tabs>
        {(store.loading || !store.offer) && (
          <Card className={classes.cardLoadingContainer}>
            <Grid container className={classes.loaderContainer} alignItems="center" justify="center">
              <CircularProgress className={classes.loader} />
            </Grid>
          </Card>
        )}
        {store.offer && !store.loading && (
          <>
            {store.currentTab === OfferPageTabs.SETTINGS && <Settings />}
            {store.currentTab === OfferPageTabs.PEOPLE && <PeopleDetails hasAuto={store.shouldShowAutoTab} />}
            {store.currentTab === OfferPageTabs.CONDO && <CondoOfferDetails hasAuto={store.shouldShowAutoTab} />}
            {store.currentTab === OfferPageTabs.AUTO && <AutoDetails />}
            {store.currentTab === OfferPageTabs.HOME && <HomeDetails />}
            {store.currentTab === OfferPageTabs.RENTERS && <RentersOffer />}
            {store.currentTab === OfferPageTabs.PIP && <PipMI />}
            {store.openDialog && <OfferDialog onClose={() => store.triggerOfferDialog(false)} />}
            <CustomerSearchDialog />
            {!store.offer.quote?.existingCustomer && <SearchReferralDialog />}
          </>
        )}
        {store.offer && !viewOnly && (
          <Footer
            showValidationErrorsModal={showValidationErrorsModal}
            setShowValidationErrorsModal={setShowValidationErrorsModal}
          />
        )}
        <ValidationErrorsModal open={showValidationErrorsModal} onClose={() => setShowValidationErrorsModal(false)} />
      </div>
    </>
  );
});

export default Offer;
