import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import home from 'core/assets/svg/home.svg';
import auto from 'core/assets/svg/auto.svg';
import renters from 'core/assets/svg/renters.svg';
import condo from 'core/assets/svg/condo.svg';
import { LabelTooltip } from 'core/components/label-tooltip';
import { dateFormatter, numberFormatter, awsDateToDateObject } from 'core/helpers/formatters';
import useStyles from './price-detail.styles';

const PaymentSchedulesTooltipTitle = ({ nextPayments }) => {
  const classes = useStyles();
  return (
    <>
      <Label type="titleDollarLabel" className={classes.paymentSchedules}>
        Payment schedule:
      </Label>
      <div>
        {nextPayments.map(({ date, remainingMonthly }, i) => (
          <div key={i}>
            <Label type="titleDollarLabel" className={classes.tooltip}>{`$${remainingMonthly} on ${date}`}</Label>
          </div>
        ))}
      </div>
    </>
  );
};

PaymentSchedulesTooltipTitle.propTypes = {
  nextPayments: PropTypes.array.isRequired
};

const CoverageItem = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div>
      <Label type="coverageLabel">{label}</Label>
      <Label type="coverageValue" className={classes.coverageValue}>
        {value}
      </Label>
    </div>
  );
};

const iconMap = {
  Home: { icon: home, height: 40 },
  Auto: { icon: auto, height: 28 },
  Renters: { icon: renters, height: 56 },
  Condo: { icon: condo, height: 64 }
};

const PriceDetail = ({
  name,
  type,
  isMonthly,
  downPaymentDate,
  remainingPayments,
  remainingPaymentsAmount,
  payment,
  paymentMethod,
  paymentType,
  termTotal,
  rentersTotalAR,
  autoTotalAR,
  stale,
  open,
  allowCollapsableContent,
  policyType,
  nextPayments,
  standaloneRenters
}) => {
  const classes = useStyles();
  const isBundleRenters = policyType === PolicyType.ARBundle && !standaloneRenters;
  const dateLabelFormatter = (date) => {
    return awsDateToDateObject(date).toDateString() === new Date().toDateString()
      ? 'DUE TODAY'
      : `DUE ${dateFormatter(awsDateToDateObject(date))}`;
  };

  return (
    <div>
      {name === 'Bundle' && (
        <Label type="policyLabel" className={classes.policy}>
          {type.toUpperCase()}
        </Label>
      )}
      <div className={classes.policyContainer}>
        <div className={classes.paymentContainer}>
          <Label type="titleDollarLabel">$</Label>
          <Label type="titleLight" className={stale ? classes.disabledPayment : classes.payment}>
            {numberFormatter(payment, 2)}
          </Label>
          <Label type="paymentLabel" className={classes.paymentDate}>
            {isMonthly ? `${dateLabelFormatter(downPaymentDate)}` : type === 'Auto' ? '6 MONTHS' : '12 MONTHS'}
          </Label>
        </div>
        <img src={iconMap[type].icon} alt={`${type}-icon`} height={iconMap[type].height} />
      </div>
      {isMonthly && nextPayments && (
        <div className={classes.remainingPayments}>
          <Label type="paymentLabel">
            NEXT {remainingPayments} PAYMENTS OF ${numberFormatter(remainingPaymentsAmount, 2)}
          </Label>
          <LabelTooltip
            tooltip={{
              label: 'View Schedule',
              onHoverText: (
                <PaymentSchedulesTooltipTitle
                  nextPayments={nextPayments.map(({ remainingMonthly, date }) => {
                    return {
                      date: dateFormatter(awsDateToDateObject(date)),
                      remainingMonthly: numberFormatter(remainingMonthly, 2)
                    };
                  })}
                />
              )
            }}
            placement="bottom"
          />
        </div>
      )}
      <Collapse in={open || !allowCollapsableContent}>
        <div className={classes.extendedInfo}>
          {isMonthly && nextPayments && (
            <>
              <CoverageItem
                label="Next bill day"
                value={nextPayments[0]?.date ? dateFormatter(awsDateToDateObject(nextPayments[0]?.date)) : ''}
              />
              <CoverageItem label="Total Term Price" value={`$${termTotal}`} />
            </>
          )}
          <CoverageItem label="Payment Method" value={paymentMethod} />
          <CoverageItem label="Payment Frequency" value={paymentType} />
          {isBundleRenters && (
            <>
              <CoverageItem label="Renters Cost" value={rentersTotalAR} />
              <CoverageItem label="Auto Cost" value={autoTotalAR} />
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

PriceDetail.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  payment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  downPaymentDate: PropTypes.string,
  remainingPayments: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remainingPaymentsAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentMethod: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  termTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  stale: PropTypes.bool,
  allowCollapsableContent: PropTypes.bool,
  rentersTotalAR: PropTypes.string,
  autoTotalAR: PropTypes.string,
  policyType: PropTypes.string.isRequired,
  nextPayments: PropTypes.array
};

PriceDetail.defaultProps = {
  downPaymentDate: null,
  remainingPayments: null,
  remainingPaymentsAmount: null,
  stale: false,
  allowCollapsableContent: false,
  open: false,
  rentersTotalAR: null,
  autoTotalAR: null,
  nextPayments: []
};
export default PriceDetail;
