import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.card_primary_separator,
    padding: theme.spacing(8)
  }
});

export default makeStyles(styles);
