import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getIn, useFormikContext } from 'formik';
import { useStore } from 'core/store/store.mobx';
import { AuthContext } from 'core/components/auth';

import Section from 'core/components/section';
import RepeatField from 'core/components/repeat-field';

function FormList({
  item: Item,
  addForm: AddForm,
  addLabel,
  title,
  disabled,
  id,
  singular,
  plural,
  children,
  type,
  classes,
  hasAuto,
  fromPolicy,
  ...rest
}) {
  const [folded, setFolded] = useState([]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const list = getIn(values, id) ?? [];

  const toggleFold = (i, foldedStatus) =>
    setFolded((prevFolded) => [...prevFolded.slice(0, i), foldedStatus, ...prevFolded.slice(i + 1, prevFolded.length)]);

  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const session = useContext(AuthContext);

  const allowLicensedActions = fromPolicy
    ? policyStore?.getAllowLicensedActions(session)
    : offerStore?.getIsLicensedForState(session);

  const pushItem = (val) => {
    const item = val;

    Object.keys(item).forEach((key) => {
      if (item[key] === '') {
        item[key] = null;
      }
    });
    setFieldValue(id, [...list, item]);
    setFieldTouched(id);
  };

  const removeItem = (i) => {
    const newList = list.slice();
    newList.splice(i, 1);
    setFieldValue(id, newList);
    setFieldTouched(id);
  };

  return (
    <Section
      classes={classes}
      title={title}
      type={type || 'SubSection'}
      rightLabel="Total"
      rightValue={`${list.length} ${list.size === 1 ? singular : plural}`}
    >
      {children}
      <RepeatField
        initialLength={list.length}
        key={list.length}
        maxSize={40}
        control={() => (
          <AddForm
            id={id}
            disabled={disabled}
            onAdd={pushItem}
            label={addLabel}
            currentPeopleOrDrivers={values.drivers || values.people}
          />
        )}
      >
        {({ onRemove, focus }, i) => {
          const p = list[i];
          return (
            <Item
              fieldName={`${id}[${i}]`}
              fieldId={id}
              key={p.id || i}
              index={i}
              item={p}
              onRemove={removeItem}
              removeFromList={onRemove}
              focus={focus}
              initialFold={!!folded[i]}
              onFold={toggleFold}
              disabled={disabled}
              previous={list[i - 1]}
              count={list.length}
              allowLicensedActions={allowLicensedActions}
              hasAuto={hasAuto}
              fromPolicy={fromPolicy}
              {...rest}
            />
          );
        }}
      </RepeatField>
    </Section>
  );
}

FormList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  addForm: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  addLabel: PropTypes.string,
  disabled: PropTypes.bool,
  singular: PropTypes.string,
  plural: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  classes: PropTypes.object,
  hasAuto: PropTypes.bool
};

FormList.defaultProps = {
  title: 'People',
  disabled: false,
  singular: 'person',
  plural: 'people',
  addLabel: undefined,
  children: undefined,
  classes: {},
  type: 'SubSection',
  hasAuto: false
};

export default observer(FormList);
