import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttons: {
    padding: theme.spacing(0, 7),
    marginLeft: theme.spacing(2)
  },
  notificationContent: {
    marginTop: theme.spacing(4),
    marginRight: 50,
    color: 'black',
    fontSize: 14
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(6),
    paddingBottom: -50
  },
  mainLabel: {
    marginTop: 10,
    fontSize: 38
  }
});

export default makeStyles(styles);
