import React from 'react';
import Grid from '@material-ui/core/Grid';

import FLHomeAnimalQuestions from './form-sections/animals-section';
import FLHomeLegalFinancialQuestions from './form-sections/legal-financial-prior-history-section';
import FLHomeCoverageQuestions from './form-sections/coverages-section';
import FLHomeUseQuestions from './form-sections/home-use-section';
import FLHomeStructureMaterialQuestions from './form-sections/structure-materials-section';
import FLAutoQuestions from './form-sections/auto-section';

const options = [
  {
    value: 'true',
    label: 'Yes'
  },
  {
    value: 'false',
    label: 'No'
  }
];

const VerifyPropertyFormFL = ({ needsHome, needsAuto }) => {
  return (
    <Grid>
      {needsHome && (
        <Grid>
          <FLHomeUseQuestions options={options} />
          <FLHomeLegalFinancialQuestions options={options} />
          <FLHomeCoverageQuestions options={options} />
          <FLHomeStructureMaterialQuestions options={options} />
          <FLHomeAnimalQuestions options={options} />
        </Grid>
      )}
      {needsAuto && <FLAutoQuestions options={options} />}
    </Grid>
  );
};
export default VerifyPropertyFormFL;
