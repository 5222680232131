import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  badge: {
    backgroundColor: theme.colorPalette.green_15,
    borderRadius: theme.spacing(5),
    color: theme.colorPalette.white_10,
    fontSize: 16,
    padding: theme.spacing(2, 4),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    maxWidth: 160,
    fontWeight: 600
  }
});

export default makeStyles(styles);
