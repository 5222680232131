import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import { useToast } from 'core/components/toast';
import { Button } from 'core/components/button';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyFooter = observer(({ setShowCannotOfferModal }) => {
  const classes = useStyles();

  const { handleSubmit, setErrors, setTouched, setFieldValue, validateForm, setFieldTouched, values } =
    useFormikContext();
  const toast = useToast();

  const onContinueToCheckout = useCallback(async () => {
    // run validation before submit so that we have the updated errors
    const updateErrors = await validateForm();
    if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);

      if (updateErrors?.signedPropertyUWAttestation) {
        toast.notify({
          type: 'error',
          message: 'You must sign off that you verified the property before continuing'
        });
      } else {
        const missingAField = Object.values(updateErrors).some((e) =>
          Object.values(e).some((err) => err.includes('required'))
        );

        if (missingAField) {
          toast.notify({
            type: 'error',
            message: 'Please answer all questions before continuing'
          });
        } else {
          // if not missing a required field, then errors at this point are for a wrong answer, meaning offer would be ineligible for home/auto
          setShowCannotOfferModal(true);
        }
      }
    } else {
      await handleSubmit();
    }
  }, [validateForm, setErrors, setTouched, handleSubmit, setShowCannotOfferModal]);

  const onClick = async () => {
    if (Object.keys(values.home).length) {
      await setFieldValue('home.ineligibleForHomeDueToUWSelfReport', false);
      await setFieldTouched('home.ineligibleForHomeDueToUWSelfReport');
      await setFieldValue('home.signedPropertyUWAttestation', values.signedPropertyUWAttestation);
      await setFieldTouched('home.signedPropertyUWAttestation');
    }
    if (Object.keys(values.auto).length) {
      await setFieldValue('auto.ineligibleForAutoDueToUWSelfReport', false);
      await setFieldTouched('auto.ineligibleForAutoDueToUWSelfReport');
      await setFieldValue('auto.signedPropertyUWAttestation', values.signedPropertyUWAttestation);
      await setFieldTouched('auto.signedPropertyUWAttestation');
    }
    await onContinueToCheckout();
  };

  return (
    <Grid className={classes.floatingContainer}>
      <Grid className={classes.footerContainer}>
        <Button variant="contained" color="primary" className={classes.footerBtn} onClick={onClick}>
          Continue to Checkout
        </Button>
      </Grid>
    </Grid>
  );
});

export default VerifyPropertyFooter;
