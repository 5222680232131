import {
  applicantCode,
  personalDetailsCode,
  priorAddressCode,
  autoDetailsCode,
  propDetailsCode,
  addAutoCode,
  sections
} from './quote-forms.constants';

export const cleanPersonalDetailsForm = (setFieldValue) => {
  setFieldValue('driversLicenseState', '', false);
  setFieldValue('driversLicense', '', false);
  setFieldValue('dateOfBirth', '', false);
};

export const cleanPriorAddressForm = (setFieldValue) => {
  setFieldValue('priorAddress.address', '', false);
  setFieldValue('priorAddress.state', '', false);
  setFieldValue('priorAddress.zip', '', false);
  setFieldValue('priorAddress.city', '', false);
  setFieldValue('priorAddress.unit', '', false);
};

export const cleanAutoDetailsForm = (setFieldValue) => {
  setFieldValue('policyLimitBIPD', '', false);
  setFieldValue('priorCoverageInYears', '', false);
  setFieldValue('insuranceInPast31Days', false, false);
};

export const cleanAddCarForm = (setFieldValue) => {
  setFieldValue('VIN', '', false);
};

export const cleanPropertyDetailForm = (setFieldValue) => {
  setFieldValue('home.yearBuilt', undefined, false);
  setFieldValue('home.purchaseDate', undefined, false);
  setFieldValue('home.typeOfHome', undefined, false);
  setFieldValue('home.numFamiliesInDwelling', undefined, false);
  setFieldValue('home.homeQuality', undefined, false);
  setFieldValue('home.sqFt', undefined, false);
  setFieldValue('home.constructionType', undefined, false);
  setFieldValue('home.numStories', undefined, false);
  setFieldValue('home.numHalfBathrooms', undefined, false);
  setFieldValue('home.numFullBathrooms', undefined, false);
  setFieldValue('home.numFireplaces', undefined, false);
  setFieldValue('home.numWoodBurningStoves', undefined, false);
  setFieldValue('home.basementType', undefined, false);
  setFieldValue('home.basementSqFt', undefined, false);
  setFieldValue('home.exteriorWallType', undefined, false);
  setFieldValue('home.garageType', undefined, false);
  setFieldValue('home.garageCarCapacity', undefined, false);
  setFieldValue('home.roofOriginal', undefined, false);
  setFieldValue('home.roofYear', undefined, false);
  setFieldValue('home.roofType', undefined, false);
  setFieldValue('home.roofShape', undefined, false);
  setFieldValue('home.latitude', undefined, false);
  setFieldValue('home.longitude', undefined, false);
  setFieldValue('condo.yearBuilt', undefined, false);
  setFieldValue('condo.purchaseDate', undefined, false);
  setFieldValue('condo.monthsRentedOut', undefined, false);
  setFieldValue('condo.floor', undefined, false);
  setFieldValue('condo.typeOfEntry', undefined, false);
  setFieldValue('condo.hasBasement', false, false);
  setFieldValue('condo.buildingHasMoreThanFiveUnits', false, false);
  setFieldValue('condo.condoQuality', undefined, false);
  setFieldValue('condo.constructionType', undefined, false);
  setFieldValue('condo.numHalfBathrooms', undefined, false);
  setFieldValue('condo.numFullBathrooms', undefined, false);
  setFieldValue('condo.roofShape', undefined, false);
  setFieldValue('condo.sqFt', undefined, false);
  setFieldValue('condo.latitude', undefined, false);
  setFieldValue('condo.longitude', undefined, false);
};

export const applicantCondition = (code) => applicantCode.includes(code);
const personalDetailsCondition = (code) => personalDetailsCode.includes(code);
const priorAddressCondition = (code) => priorAddressCode.includes(code);
const autoDetailsCondition = (code) => autoDetailsCode.includes(code);
const propDetailsCondition = (code) => propDetailsCode.includes(code);
const addAutoCondition = (code) => addAutoCode.includes(code);

const codeSectionMatch = (sectionCode) => {
  if (personalDetailsCondition(sectionCode)) {
    return personalDetailsCode;
  }
  if (priorAddressCondition(sectionCode)) {
    return priorAddressCode;
  }
  if (autoDetailsCondition(sectionCode)) {
    return autoDetailsCode;
  }
  if (propDetailsCondition(sectionCode)) {
    return propDetailsCode;
  }
  if (addAutoCondition(sectionCode)) {
    return addAutoCode;
  }
  return [sectionCode];
};

const hasUnknownErrors = (errors) => errors.some((error) => !sections.includes(Number(error.code)));

export const visibleSections = ({ errors, isNewConstruction, isNewPurchase, dirtySections, showAllFieldsClicked }) => {
  if (errors.length === 0 && !isNewConstruction && !showAllFieldsClicked) {
    return [];
  }

  if (errors.length === 0 && !isNewPurchase && !showAllFieldsClicked) {
    return [];
  }

  // If there are unknown errors, return all sections to show them.
  if (hasUnknownErrors(errors) || showAllFieldsClicked) {
    return sections;
  }

  return dirtySections;
};

export const handleCleanUp = (
  errors,
  currentVisibleSections,
  isNewConstruction,
  isNewPurchase,
  setFieldValue,
  setSectionsToShow,
  showAllFieldsClicked
) => {
  // The following sections will keep the values entered by user in the form.
  let keepValuesForSections = [];

  if (isNewConstruction) {
    keepValuesForSections = [...keepValuesForSections, ...priorAddressCode, ...propDetailsCode];
  }
  if (isNewPurchase) {
    keepValuesForSections = [...keepValuesForSections, ...priorAddressCode];
  }

  // If some sections have errors, they shouldn't be cleaned.
  if (Object.keys(errors).length) {
    errors.forEach((error) => {
      keepValuesForSections = [...keepValuesForSections, ...codeSectionMatch(Number(error.code))];
    });
  }

  // The current visible sections should keep the values entered by the user.
  keepValuesForSections = keepValuesForSections.concat(currentVisibleSections);
  keepValuesForSections = [...new Set([...keepValuesForSections, ...currentVisibleSections])];

  const visibles = visibleSections({
    errors,
    isNewConstruction,
    isNewPurchase,
    dirtySections: keepValuesForSections,
    showAllFieldsClicked
  });

  // Clean sections are not visible for the user.
  let dirtySections = sections.filter((section) => !visibles.includes(section));

  // Remove from dirtySections variable whose with errors or visible for user.
  // In order to keep the current values.
  dirtySections = dirtySections.filter((section) => !keepValuesForSections.includes(section));

  setSectionsToShow(visibles);

  // Sections to be cleaned.
  const sectionsToClean = sections.filter((section) => dirtySections.includes(section));

  if (sectionsToClean.length > 0) {
    sectionsToClean.forEach((sectionCode) => {
      if (personalDetailsCondition(Number(sectionCode))) {
        cleanPersonalDetailsForm(setFieldValue);
      }
      if (priorAddressCondition(Number(sectionCode))) {
        cleanPriorAddressForm(setFieldValue);
      }
      if (autoDetailsCondition(Number(sectionCode))) {
        cleanAutoDetailsForm(setFieldValue);
      }
      if (propDetailsCondition(Number(sectionCode))) {
        cleanPropertyDetailForm(setFieldValue);
      }
      if (addAutoCondition(Number(sectionCode))) {
        cleanAddCarForm(setFieldValue);
      }
    });
  }
};
