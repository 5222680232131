import { addMonths, isBefore } from 'date-fns';
import { normalizeDate } from '@ourbranch/date-helpers';
import { DynamoDbClaim, DynamoDbPersonClaim, DynamoDbPersonDetails } from '@ourbranch/schema-types/dynamodb';
import { USAStatesByAbbv } from '@ourbranch/utility-types';
import { CCWStates } from '@ourbranch/lookups';

function getBranchClaimClass(claim: DynamoDbClaim, state: USAStatesByAbbv) {
  let claimClass = 'NAF';
  if (claim.coverage) {
    if (claim.at_fault_ind === 'YES' || claim.at_fault_percentage > 50) {
      claimClass = 'AAF';
    } else if (claim.coverage.match(/THEFT|VAND/i)) {
      claimClass = 'theft_vandalism';
    } else if (claim.coverage.match(/COMP/i)) {
      if (claim.descr && claim.descr.match(/wind|hail|quake|storm|weather/i) && CCWStates.hasOwnProperty(state)) {
        claimClass = 'CCW';
      } else {
        claimClass = claim.indpaid >= 1000 ? 'CMP' : 'CMU';
      }
    }
  }
  return claimClass;
}

type assignClaimsToDriversArgs = {
  drivers: DynamoDbPersonDetails[];
  claims: DynamoDbClaim[];
  effectiveDate: Date | string;
  state: USAStatesByAbbv;
  assignZeroClaims: boolean;
};

export async function assignClaimsToDrivers({
  drivers,
  claims,
  effectiveDate,
  state,
  assignZeroClaims
}: assignClaimsToDriversArgs) {
  // constants we need
  const claimsLookBackInMonths = 35;
  const primary = drivers.find((d: DynamoDbPersonDetails) => d.isPrimary);
  if (!primary) {
    throw new Error('Could not find primary driver on policy');
  }

  if (Array.isArray(claims)) {
    const claimDates: any = {};
    for (const claim of claims) {
      if (claim.datereport?.value) {
        claim.datereport = claim.datereport.value;
      }
      const normalizedDate = normalizeDate(String(claim.datereport));
      console.log(`normalized date, ${normalizedDate}, using Branch claim: ${JSON.stringify(claim, null, 2)}`);

      const amountOK = assignZeroClaims ? true : (claim?.indpaid || 0) > 500;

      if (
        !claimDates[normalizedDate as keyof typeof claimDates] &&
        amountOK &&
        isBefore(new Date(normalizedDate || ''), new Date())
      ) {
        const normalizedDateLookBackDate = addMonths(new Date(normalizedDate || ''), claimsLookBackInMonths);
        isBefore(new Date(effectiveDate), normalizedDateLookBackDate);
        if (isBefore(new Date(effectiveDate), normalizedDateLookBackDate)) {
          const claimClass = getBranchClaimClass(claim, state);
          claimDates[normalizedDate as keyof typeof claimDates] = true;

          primary.autoViolationDates[normalizedDate as keyof typeof primary.autoViolationDates] = [
            {
              class: claimClass,
              miPoints: 0,
              points: claimClass === 'AAF' ? 4 : 2,
              source: 'claims',
              branch: true
            }
          ];

          // update counts on driver
          if (!primary.branchViolations) {
            primary.branchViolations = {};
          }
          const fc = claimClass as keyof typeof primary.branchViolations;
          primary.branchViolations[fc] = primary.branchViolations[fc] ? primary.branchViolations[fc] + 1 : 1;
        }
      }
    }
  }
} // assignClaimsToDrivers

type assignPersonClaimsToDriversArgs = {
  drivers: DynamoDbPersonDetails[];
  personClaims: DynamoDbPersonClaim[];
  effectiveDate: Date | string;
  assignZeroClaims: boolean;
};

export async function assignPersonClaimsToDrivers({
  drivers,
  personClaims,
  effectiveDate,
  assignZeroClaims
}: assignPersonClaimsToDriversArgs) {
  // constants we need
  const claimsLookBackInMonths = 35;
  const primary = drivers.find((d: DynamoDbPersonDetails) => d.isPrimary);
  if (!primary) {
    throw new Error('Could not find primary driver on policy');
  }

  if (Array.isArray(personClaims)) {
    for (const claim of personClaims) {
      const { faultCoding, lossDate, driverId, theftOrVandalism, indemnityPaid } = claim;
      const amountOK = assignZeroClaims ? true : (indemnityPaid || 0) > 500;
      const normalizedDateLookBackDate = addMonths(new Date(lossDate || ''), claimsLookBackInMonths);
      isBefore(new Date(effectiveDate || ''), normalizedDateLookBackDate);
      if (amountOK && isBefore(new Date(effectiveDate || ''), normalizedDateLookBackDate)) {
        const driver = drivers.find((d) => d.id === driverId) || primary;

        driver.autoViolationDates[lossDate as keyof typeof driver.autoViolationDates] = [
          {
            class: faultCoding,
            miPoints: 0,
            points: faultCoding === 'AAF' ? 4 : 2,
            source: 'claims',
            branch: true,
            tvFlag: String(theftOrVandalism).toUpperCase() === 'YES'
          }
        ];

        // update counts on driver
        // update counts on driver
        if (!driver.branchViolations) {
          driver.branchViolations = {};
        }
        const fc = faultCoding as keyof typeof driver.branchViolations;
        driver.branchViolations[fc] = driver.branchViolations[fc] ? driver.branchViolations[fc] + 1 : 1;
        if (String(theftOrVandalism).toUpperCase() === 'YES') {
          driver.branchViolations.tv = driver.branchViolations.tv ? driver.branchViolations.tv + 1 : 1;
        }
      }
    }
  }
} // assignPersonClaimsToDrivers
