import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    width: '100%'
  }
});

export default makeStyles(styles);
