import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { FormField } from 'core/components/form';
import SwapButton from 'core/components/swap-button';

const ApplicantActions = ({ index, fieldName, item = {}, removeFromList, onRemove, allowLicensedActions }) => {
  const { values } = useFormikContext();

  const showApplicantSwapButton = item.isPrimary && values.people?.some((person) => person.isCoApplicant);

  return (
    <>
      {item.isPrimary && showApplicantSwapButton && (
        <SwapButton mode="big" allowLicensedActions={allowLicensedActions} formikPath="people" />
      )}
      {!item.isPrimary && (
        <FormField
          type="remove"
          id={fieldName}
          name={fieldName}
          mode="big"
          onClick={() => onRemove(index, removeFromList)}
          fast={false}
        />
      )}
    </>
  );
};

ApplicantActions.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool
  }).isRequired,
  fieldName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  allowLicensedActions: PropTypes.bool.isRequired
};

export default ApplicantActions;
