/* eslint-disable func-names */
import * as Yup from 'yup';
import {
  UMPDOnlyWithUMBIStates,
  allow50100UMBIon2550BIStates,
  combinedUMUIM,
  doNotShowCarLevelUMPDStates,
  driverCarAssignmentStates,
  noUDRsAllowedStates,
  policyLevelUMPDStates,
  preBindMVRStates
} from '@ourbranch/lookups';

import { requiredString, validUSState } from 'common/helpers/yup-helpers';
import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';
import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';
import { getPLimitValue, getUMPDValue, isLimitACPEValid } from 'core/helpers/car-validations';
import { hasMakeModelStyleSymbols, haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

const michiganPIPSchema = Yup.object().shape({
  auto: Yup.object().shape({
    pipAllResidents: Yup.number()
      .test('requiredForMI', 'Required', function (value) {
        return value >= 0;
      })
      .nullable(),
    pipResidentsWithQHC: Yup.number()
      .test('requiredForMI', 'Required', function (value) {
        return value >= 0;
      })
      .test(
        'compareToAllPipEligibleResidents',
        'The amount of residents with QHC cannot exceed the amount of eligible pip residents',
        function (value) {
          const pipEligibleResidentsCount =
            this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
          if (value > pipEligibleResidentsCount) {
            // using this.createError to have a dynamic error message
            return this.createError({
              message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
            });
          }
          return true;
        }
      )
      .test('compareToHealthInsurance', 'Must be at least 1 because Medicare is selected', function (value) {
        if (
          this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
          !this.options.context.auto?.pipEveryoneOnSamePlan
        ) {
          return value >= 1;
        }
        return true;
      })
      .test(
        'compareToHealthInsurance2',
        'Must be the same as the number of residents in household because Medicare is selected and everyone is on the same plan',
        function (value) {
          if (
            this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
            this.options.context.auto?.pipEveryoneOnSamePlan
          ) {
            return value === this.options.context.auto?.pipAllResidents;
          }
          return true;
        }
      )
      .nullable(),
    pipExcludedResidents: Yup.number()
      .test('requiredForMI', 'Required', function (value) {
        return value >= 0;
      })
      .test(
        'checkIfMedicare',
        'The number of excluded residents must be 0 or the same as the number of residents in the household',
        function (value) {
          if (this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE) {
            return value === 0 || value === this.options.context.auto?.pipAllResidents;
          }
          return true;
        }
      )
      .test(
        'checkIfMedicare2',
        'The number of excluded residents must be 0 because residents with QHC is less than residents in household',
        function (value) {
          if (
            this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
            this.options.context.auto?.pipAllResidents > this.options.context.auto?.pipResidentsWithQHC
          ) {
            return value === 0;
          }
          return true;
        }
      )
      .test(
        'compareToAllPipEligibleResidents',
        'The amount of excluded residents cannot exceed the amount of eligible pip residents',
        function (value) {
          const pipEligibleResidentsCount =
            this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
          if (value > pipEligibleResidentsCount) {
            // using this.createError to have a dynamic error message
            return this.createError({
              message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
            });
          }
          return true;
        }
      )
      .nullable(),
    pipHealthInsuranceType: Yup.string()
      .test('requiredForMI', 'Required', function (value) {
        return value?.length;
      })
      .nullable(),
    pipAdditionalResidents: Yup.number()
      .test(
        'compareToAllResidents',
        'The amount of excluded residents cannot exceed the amount of residents living in the household',
        function (value) {
          return value <= this.options.context.auto?.pipAllResidents;
        }
      )
      .nullable()
  })
});

const autoSchema = (state) => {
  let schema = Yup.object().shape({
    autoCoverage: Yup.object().shape({
      policyLimitUMBI: Yup.string()
        .test('umbi', 'UMBI cannot be higher than BIPD', function (ob) {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
          if (
            !policyLimitUMBI ||
            !policyLimitBIPD ||
            (allow50100UMBIon2550BIStates[this.options.context?.correctedAddress?.state] &&
              policyLimitUMBI === '50/100')
          ) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);

          return valueBIPD >= valueUMBI;
        })
        .test('umbiCSL', 'BI cannot be CSL unless policy UM/UIM BI is also CSL.', function () {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};

          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (!combinedUMUIMState) {
            return true;
          }
          const umbiCSL = policyLimitUMBI.includes('CSL');
          const bipdCSL = policyLimitBIPD.includes('CSL');

          return policyLimitUMBI === '0/0' || umbiCSL === bipdCSL;
        }),
      policyLimitUIMBI: Yup.string()
        .test('uimbi', 'UIMBI cannot be higher than BIPD', function (obj) {
          const { policyLimitUIMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};

          if (combinedUMUIM[this.options.context?.correctedAddress?.state] || !policyLimitUIMBI || !policyLimitBIPD) {
            return true;
          }

          const valueUIMBI = policyLimitUIMBI.includes('/')
            ? Number(policyLimitUIMBI.split('/')[0])
            : Number(policyLimitUIMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);
          return valueBIPD >= valueUIMBI;
        })
        .test('uimbiCSL', 'UIM BI cannot be CSL unless policy UM BI and BI/PD are also CSL.', function () {
          const { policyLimitBIPD, policyLimitUIMBI } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (combinedUMUIMState || !hasAutoPolicy) {
            return true;
          }
          const bipdCSL = policyLimitBIPD.includes('CSL');
          const uimbiCSL = policyLimitUIMBI.includes('CSL');
          return policyLimitUIMBI === '0/0' || bipdCSL === uimbiCSL;
        }),
      policyLimitUMPD: Yup.string()
        .test('UMPDMustBeLowerThanPD', 'UMPD cannot be higher than PD', function (ob) {
          const { policyLimitBIPD, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          const state = this.options.context.correctedAddress?.state;

          if (!policyLimitBIPD || !policyLevelUMPDStates[state]) {
            return true;
          }

          if (this.options.context?.correctedAddress?.state === 'NM') {
            // in NM, we just have a UMPD = PD limit option, dont run validation
            return true;
          }

          const valuePD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[2])
            : Number(policyLimitBIPD.split(' ')[0]);

          const valueUMPD = parseFloat(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD.split('/')[0]) / 1000;

          return valuePD >= valueUMPD;
        })
        .test('UMPDMustHaveUMBIInThiState', 'UMPD cannot be chosen in this state without UMBI', function (value) {
          const { policyLimitUMBI, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          if (!UMPDOnlyWithUMBIStates[this.options.context?.correctedAddress?.state]) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);

          const valueUMPD = Number(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD) / 1000;

          return !(valueUMBI === 0 && valueUMPD > 0);
        }),

      policyLimitPIPME: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitPIPACR: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitMedicalPayments: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitComboFBP } = this.parent;
            if (policyLimitComboFBP && policyLimitComboFBP !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and combined first party benefits'
              });
            }
            if (policyLimitComboFBP && policyLimitComboFBP === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and PIP in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitPIP } = this.parent;
            if (value !== 'NONE' && policyLimitPIP && policyLimitPIP !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .nullable(),
      policyLimitComboFBP: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitMedicalPayments } = this.parent;
            if (policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and medical payments'
              });
            }
            if (policyLimitMedicalPayments && policyLimitMedicalPayments === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .nullable(),
      policyLimitPIP: Yup.string()
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and MedPay in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitMedicalPayments } = this.parent;
            if (value !== 'NONE' && policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .nullable()
    }),
    trailers: Yup.array(
      Yup.object().shape({
        VIN: Yup.string().requiredString('VIN is required'),
        type: Yup.string().requiredString('Trailer type is required'),
        year: Yup.number().typeError('Trailer year should be a number').required('Trailer year is required'),
        value: Yup.number()
          .typeError('Trailer value should be a number between $0 - $50000')
          .positive('Trailer value should be between $0 - $50000')
          .integer('Trailer value should be between $0 - $50000')
          .min(1, 'Trailer value should be greater than $0')
          .max(50000, 'Trailer value should be less than or equal to $50000')
          .when(
            ['deductibleCollision', 'deductibleComprehensive'],
            (deductibleCollision, deductibleComprehensive, schema) => {
              const validValues = [deductibleCollision.split('/')[0], deductibleComprehensive.split('/')[0]].filter(
                (value) => value !== 'NONE' && value
              );

              if (!validValues.length) return schema;
              const minValue = Math.min(...validValues.map((value) => value.split('/')[0]));

              return schema.min(500 + minValue, `Trailer value should be greater than ${500 + minValue}`);
            }
          )
          .required('Trailer value is required'),
        contents: Yup.number()
          .typeError('Content value should be a number between $0 - $5000')
          .positive('Content value should be between $0 - $5000')
          .integer('Content value should be between $0 - $5000')
          .min(0, 'Content value should be greater than $0')
          .max(5000, 'Content value should be less than or equal to $5000')
          .required('Trailer content value is required'),

        deductibleCollision: Yup.string()
          .requiredString('Collision deductible is required')
          .test(
            'minimumCollDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleCollision, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleCollision === 'NONE' && parent.deductibleComprehensive === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleCollision === currentTrailerDeductibleCollision &&
                  car.deductibleComprehensive.split('/')[0] === parent.deductibleComprehensive.split('/')[0]
                ) {
                  return true;
                }
              }
              return false;
            }
          ),

        deductibleComprehensive: Yup.string()
          .requiredString('Comprehensive deductible is required')
          .test(
            'minimumCompDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleComprehensive, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleComprehensive === 'NONE' && parent.deductibleCollision === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleComprehensive.split('/')[0] === currentTrailerDeductibleComprehensive.split('/')[0] &&
                  car.deductibleCollision === parent.deductibleCollision
                ) {
                  return true;
                }
              }
              return false;
            }
          ),
        garageLocation: Yup.object().shape({
          address: Yup.string()
            .requiredString('Address is required')
            .test('is-po-box', 'PO Box Addresses are invalid', (value) => {
              const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
              return !(pattern.test(value) || pattern.test(value));
            }),
          city: Yup.string().requiredString('City is required'),
          state: Yup.string().requiredString('State is required').validUSState('offer.trailers.garageLocation'),
          zip: Yup.string().requiredString('Zip code is required')
        })
      })
    ).nullable(),
    drivers: Yup.array().of(
      Yup.object().shape({
        driversLicenseNumber: Yup.string()
          .test(
            'driversLicenseRequired',
            'Drivers license is required for this state',
            function (val, { options: { context, parent } }) {
              if (preBindMVRStates.includes(context.correctedAddress.state) && parent.postBindMVR) {
                return !!val;
              }
              return true;
            }
          )
          .concat(driversLicenseNumberSchema)
          .nullable(),
        driversLicenseState: Yup.string().validUSState('offer.drivers.driverLicenseState'),
        schoolName: Yup.string()
          .when('fullTimeStudent', {
            is: (fullTimeStudent) => fullTimeStudent === true,
            then: Yup.string().requiredString('School Name is required')
          })
          .nullable(),

        schoolLocation: Yup.object()
          .when('fullTimeStudent', {
            is: (fullTimeStudent) => fullTimeStudent === true,
            then: Yup.object().shape({
              address: Yup.string().requiredString('School Address is required'),
              city: Yup.string().requiredString('School City is required'),
              state: Yup.string().requiredString('School State is required'),
              zip: Yup.string().requiredString('School Zip code is required')
            })
          })
          .nullable(),

        autoViolations: Yup.object().test(
          'hasUDR',
          "There are drivers on this offer that have an unverified driving record (UDR), and we do not allow that in this state. Please drop the drivers and re-add with Driver's License information to pull driving records.",
          function (obj) {
            if (noUDRsAllowedStates.includes(this.options.context?.correctedAddress.state)) {
              return obj.UDR === 0;
            }
            return true;
          }
        ),
        assignedCar: Yup.mixed().test('assignedCar', 'Each driver must be assigned to one car', function (value) {
          if (driverCarAssignmentStates[this.options.context?.correctedAddress?.state]) {
            if (!value) {
              return this.createError({
                message: 'All drivers must be assigned a primary vehicle'
              });
            }
            const cars = this.options.context.cars;
            if (value && !cars.some((car) => car.VIN === value)) {
              return this.createError({
                message: 'Driver assigned VIN that does not exist '
              });
            }
            const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
            const assignedDrivers = cars.map((car) => car.assignedDriver);

            if (drivers.length === cars.length) {
              const driversSet = new Set(assignedDrivers);
              // assert that each driver assigned exactly once
              return driversSet.size === assignedDrivers.length && drivers.length === driversSet.size
                ? true
                : this.createError({
                    message: 'Each driver must be assigned to one car'
                  });
            }

            return true;
          }
          return true;
        })
      })
    ),
    cars: Yup.array()
      .of(
        Yup.object().shape({
          garageLocation: Yup.object().shape({
            state: Yup.string().validUSState('offer.cars.garageLocation.state')
          }),
          limitUMPD: Yup.mixed()
            .nullable()
            .test(
              'limitUMPDMustBeLowerThanPLimitValue',
              "Error, this can't be higher than property damage limit",
              function (value) {
                const state = this.options.context?.correctedAddress?.state;

                if (state === 'NM' || doNotShowCarLevelUMPDStates[state]) {
                  // in NM, we just have a UMPD = PD limit option and shouldn't run this check.
                  // or we don't have UMPD in this state or car level UMPD in this state
                  // or not selecting auto
                  // so no need to validate
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
              }
            )
            .test(
              'noLimitUMPDWithoutUMBI',
              'Error, UMPD cannot be chosen in this state without UMBI',
              function (value) {
                const state = this.options.context?.correctedAddress.state;
                if (doNotShowCarLevelUMPDStates[state]) {
                  // we don't have UMPD in this state, or car level UMPD in this state, or not selecting Auto so don't run this check
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return !(
                  value !== 'NONE' &&
                  UMPDOnlyWithUMBIStates[state] &&
                  (autoCoverage.policyLimitUMBI?.startsWith('0/0') || autoCoverage.policyLimitUMBI === 'NONE')
                );
              }
            ),
          assignedDriver: Yup.mixed().test(
            'assignedDriver',
            'A driver may only be assigned to one car',
            function (value) {
              if (driverCarAssignmentStates[this.options.context?.correctedAddress?.state]) {
                if (!value) {
                  return this.createError({
                    message: 'All cars must be assigned a driver'
                  });
                }
                const cars = this.options.context.cars;
                const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
                const assignedDrivers = cars.map((car) => car.assignedDriver);
                const driversSet = new Set(assignedDrivers);

                if (drivers.length <= cars.length) {
                  return drivers.length === driversSet.size
                    ? true
                    : this.createError({
                        message: 'Every driver must be assigned to a car'
                      });
                }
                if (drivers.length > cars.length) {
                  return cars.length <= driversSet.size
                    ? true
                    : this.createError({
                        message: 'A driver may only be assigned to one car'
                      });
                }

                return true;
              }
              return true;
            }
          ),
          symbolMake: Yup.string()
            .test(
              'symbolMake-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolAux: Yup.string()
            .test(
              'symbolAux-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolModel: Yup.string()
            .test(
              'symbolModel-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolStyle: Yup.string()
            .test(
              'symbolStyle-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) {
                  return true;
                }
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) {
                  return !value || value?.length === 2;
                }
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          limitACPE: Yup.string().test(
            'Invalid',
            'To add Additional Custom Parts Limit, Collision and Comprehensive Deductibles are required',
            function (value) {
              return isLimitACPEValid(value, this.parent);
            }
          )
        })
      )
      .test(
        'garage-state-test',
        'Garage address must be in the same state as the home address for at least one vehicle',
        function (cars) {
          const state = this.options.context?.correctedAddress.state;
          if (cars.length > 0) {
            return cars.some((car) => car.garageLocation.state === state);
          }
          return true;
        }
      )
  });

  if (state === 'MI') {
    schema = schema.concat(michiganPIPSchema);
  }
  return schema;
};

export default autoSchema;
