const identity = (x) => x;
const arr = (typeDef) => (obj) => obj && obj.map(typeDef);

export const types = {
  String: identity,
  'String!': identity,
  ID: identity,
  'ID!': identity,
  Boolean: identity,
  Int: identity,
  '[Int]': arr(identity),
  AWSDate: identity,
  Float: identity,
  'AWSEmail!': identity,
  AWSPhone: identity
};

/**
 * defineType - creates a sanitizer function that receives the object to sanitize
 * and creates a new object containing only the properties in (typeDefs) the field type definition
 * by executing the type accessor function for each type. Storing the sanitizer function in the
 * types dictionary allow you to create composed types.
 *
 * @param {Object} typeDefs field type definitions
 */
const defineType = (typeDefs) => (obj) =>
  obj &&
  Object.entries(typeDefs).reduce((ret, [name, type]) => {
    let value;
    try {
      value = types[type](obj[name]);
    } catch (e) {
      throw Error(`The type [${type}] is failing at field [${name}] with the error:\n${e}`);
    }
    return {
      ...ret,
      [name]: value
    };
  }, {});

types.AddressDetailsInput = defineType({
  address: 'String!',
  address2: 'String',
  country: 'String',
  city: 'String',
  state: 'String',
  zip: 'String'
});

types['[AddressDetailsInput]'] = arr(types.AddressDetailsInput);

types.AdditionalPhoneNumber = defineType({
  phoneNumber: 'String',
  note: 'String',
  canText: 'Boolean'
});

types.AdditionalPhoneNumbers = arr(types.AdditionalPhoneNumber);

types.AccountDetailsInput = defineType({
  fname: 'String',
  lname: 'String',
  email: 'String',
  id: 'ID',
  phoneNumber: 'String',
  mailingAddress: 'AddressDetailsInput',
  additionalPhoneNumbers: 'AdditionalPhoneNumbers'
});

types['[AccountDetailsInput]'] = arr(types.AccountDetailsInput);

types.PersonDetailsInput = defineType({
  addDate: 'AWSDate',
  id: 'ID',
  isPrimary: 'Boolean',
  isCoApplicant: 'Boolean',
  firstName: 'String',
  middleName: 'String',
  lastName: 'String',
  suffix: 'String',
  dateOfBirth: 'AWSDate',
  gender: 'String',
  maritalStatus: 'String',
  highestEducation: 'String',
  driversLicenseState: 'String',
  driversLicenseNumber: 'String',
  excludeDriver: 'Boolean',
  ageFirstLicensed: 'Int',
  fullTimeStudent: 'Boolean',
  goodStudent: 'Boolean',
  takenCourse: 'Boolean',
  courseDiscountConditionCheck: 'Boolean',
  schoolName: 'String',
  schoolLocation: 'AddressDetailsInput',
  communityDriveParticipation: 'Boolean',
  waivedPIPWL: 'Boolean',
  pipEligible: 'Boolean',
  pipOptOut: 'Boolean',
  pipHasQHC: 'Boolean',
  assignedCar: 'String',
  userAssignedCar: 'String',
  gotDrivingRecord: 'Boolean',
  clearUDRViolation: 'Boolean',
  needSR22: 'Boolean',
  lapsedLicense: 'Boolean',
  exclusionReason: 'String'
});

types['[PersonDetailsInput]'] = arr(types.PersonDetailsInput);

types.CarDetailsInput = defineType({
  addDate: 'AWSDate',
  VIN: 'String',
  primaryUse: 'String',
  purchaseDate: 'AWSDate',
  garageLocation: 'AddressDetailsInput',
  deductibleCollision: 'String',
  deductibleComprehensive: 'String',
  coverageLoan: 'Boolean',
  limitUMPD: 'String',
  limitRental: 'String',
  limitACPE: 'String',
  limitIncomeLoss: 'String',
  roadsideAssistance: 'Boolean',
  rideSharing: 'Boolean',
  confirmed: 'Boolean',
  aebStatus: 'String',
  aebConfirmed: 'Boolean',
  antiTheftStatus: 'String',
  antiTheftConfirmed: 'Boolean',
  blindSpotStatus: 'String',
  blindSpotConfirmed: 'Boolean',
  singleAirbagStatus: 'String',
  singleAirbagConfirmed: 'Boolean',
  doubleAirbagStatus: 'String',
  doubleAirbagConfirmed: 'Boolean',
  assignedDriver: 'String',
  userAssignedDriver: 'String',
  luxury: 'Boolean',
  symbolMake: 'String',
  symbolModel: 'String',
  symbolStyle: 'String',
  symbolAux: 'String',
  manuallyAddedSymbols: 'Boolean',
  make: 'String',
  model: 'String',
  year: 'Int',
  annualMilesDriven: 'Int'
});

types.TrailerDetailsInput = defineType({
  addDate: 'AWSDate',
  VIN: 'String',
  type: 'String',
  purchaseDate: 'AWSDate',
  garageLocation: 'AddressDetailsInput',
  value: 'Int',
  contents: 'Int',
  year: 'Int',
  deductibleCollision: 'String',
  deductibleComprehensive: 'String',
  coverageLoan: 'Boolean',
  confirmed: 'Boolean'
});

types['[CarDetailsInput]'] = arr(types.CarDetailsInput);
types['[TrailerDetailsInput]'] = arr(types.TrailerDetailsInput);

types.AutoDetailsInput = defineType({
  householdMembers: 'Int',
  hasSetPip: 'Boolean',
  pipAllResidents: 'Int',
  pipEveryoneOnSamePlan: 'Boolean',
  pipResidentsWithQHC: 'Int',
  pipExcludedResidents: 'Int',
  pipAdditionalResidents: 'Int',
  pipHealthInsuranceType: 'String',
  residenceInsuranceType: 'String'
});

types.AutoCoverageDetailsInput = defineType({
  policyLimitBIPD: 'String',
  policyLimitUMBI: 'String',
  policyLimitUIMBI: 'String',
  policyLimitPIP: 'String',
  policyLimitUMPD: 'String',
  policyLimitMedicalPayments: 'String',
  policyLimitLPD: 'String',
  petInjury: 'Boolean',
  policyLimitPIPME: 'String',
  policyLimitPIPWL: 'String',
  policyLimitPIPACR: 'String',
  policyLimitPPI: 'String',
  policyLimitIncomeLoss: 'String',
  policyLimitAccidentalDeath: 'String',
  policyLimitFuneralBenefits: 'String',
  policyLimitExtraMedBenefits: 'String',
  policyLimitTortOption: 'String',
  policyLimitComboFBP: 'String',
  policyLimitGuestPIP: 'String',
  policyLimitNoFaultPIP: 'String',
  uimAddedOn: 'Boolean'
});

types.MortgageDetailsInput = defineType({
  loanNumber: 'String',
  mortgageHolderAddress: 'AddressDetailsInput',
  mortgageHolderName: 'String',
  primary: 'Boolean',
  mortgageHolderId: 'String'
});

types['[MortgageDetailsInput]'] = arr(types.MortgageDetailsInput);

types.HomeDetailsInput = defineType({
  basementSqFt: 'Int',
  basementType: 'String',
  constructionType: 'String',
  exteriorWallType: 'String',
  garageCarCapacity: 'Int',
  garageType: 'String',
  homeLocation: 'AddressDetailsInput',
  homeQuality: 'String',
  mortgageDetails: '[MortgageDetailsInput]',
  numFamiliesInDwelling: 'Int',
  numFireplaces: 'Int',
  numFullBathrooms: 'Int',
  numHalfBathrooms: 'Int',
  numOccupants: 'Int',
  numStories: 'Float',
  numWoodBurningStoves: 'Int',
  ownershipStatus: 'String',
  purchaseDate: 'AWSDate',
  roofOriginal: 'Boolean',
  roofShape: 'String',
  roofType: 'String',
  roofYear: 'Int',
  roofDeck: 'String',
  roofCover: 'String',
  roofDeckAttachment: 'String',
  roofToWallConnection: 'String',
  openingProtection: 'String',
  roofSWR: 'String',
  sqFt: 'Int',
  swimmingPool: 'String',
  typeOfHome: 'String',
  yearBuilt: 'Int',
  latitude: 'Float',
  longitude: 'Float',
  windstormMitigationLevel: 'Int',
  stormShutters: 'Boolean',
  fireHydrantWithin1000ft: 'Boolean'
});

types.HomeCoverageDetailsInput = defineType({
  coverageA: 'Float',
  coverageIBSC: 'Int',
  coverageBCPctOfA: 'Int',
  coverageBPctOfA: 'Int',
  coverageCPctOfA: 'Int',
  coverageDPctOfA: 'Int',
  coverageX: 'Int',
  coverageY: 'Int',
  coverageWaterBackup: 'Int',
  coverageBuildingMaterialsTheft: 'Boolean',
  coverageCourseOfConstruction: 'Boolean',
  coverageExtendedPremises: 'Boolean',
  coverageFairRental: 'Boolean',
  coveragePetBite: 'Boolean',
  coverageRSExtended: 'Boolean',
  coverageYardAndGarden: 'Boolean',
  coverageSinkhole: 'Boolean',
  coverageOilStorageTank: 'Boolean',
  coverageEBSL: 'Boolean',
  coverageBP: 'Int',
  coverageCA: 'Int',
  coverageDC: 'Boolean',
  coverageDR: 'Boolean',
  coverageF: 'Int',
  coverageG: 'Int',
  coverageJ: 'Int',
  coverageMI: 'Int',
  coverageMS: 'Boolean',
  coverageMSALE: 'Boolean',
  coverageSP: 'Int',
  coverageST: 'Int',
  homeownerProtection: 'Boolean',
  deductibleAllOther: 'Float',
  deductibleHurricane: 'Float',
  deductibleWindHail: 'Float',
  deductibleWaterBackup: 'Float',
  windHailExclusion: 'Boolean',
  interestedInSeparateWindHail: 'Boolean',
  externalPolicyId: 'String'
});

types.RentersCoverageDetailsInput = defineType({
  rentersLocation: 'AddressDetailsInput',
  coverageCD: 'String',
  coverageX: 'Int',
  coverageY: 'Int',
  deductible: 'Int'
});

types.UmbrellaCoverageDetailsInput = defineType({
  highRisk: 'Boolean',
  numVehiclesTotal: 'Int',
  numLicensedDriversUnder25: 'Int',
  numMotorcyclesScooters: 'Int',
  numATVs: 'Int',
  numRVs: 'Int',
  numPersonalWatercraft: 'Int',
  watercraftHullLengths: '[Int]',
  presenceOfRVs: 'Boolean',
  numRentalProperties: 'Int',
  rentalPropertyAddresses: '[AddressDetailsInput]',
  numOtherProperties: 'Int',
  otherPropertyAddresses: '[AddressDetailsInput]',
  presenceOfBusiness: 'Boolean',
  uninsuredMotoristLimit: 'String',
  limitBIOccurrence: 'String',
  liabilityCoverageLimit: 'Int'
});

types.EmailDetailsInput = defineType({
  label: 'String',
  email: 'AWSEmail!'
});

types['[EmailDetailsInput]'] = arr(types.EmailDetailsInput);

types.PhoneDetailsInput = defineType({
  phoneType: 'String',
  number: 'AWSPhone',
  extension: 'Int'
});

types['[PhoneDetailsInput]'] = arr(types.PhoneDetailsInput);

types.GlobalDetailsInput = defineType({
  autoEffectiveDate: 'AWSDate',
  homeEffectiveDate: 'AWSDate',
  currentlyHomeInsured: 'Boolean',
  manualInventorySubmission: 'Boolean',
  discountInventoryScore: 'Boolean',
  discountDriverScore: 'Boolean',
  discountPaperless: 'Boolean',
  homeownersPaymentType: 'String',
  homeownersPaymentMethod: 'String',
  autoPaymentType: 'String',
  autoPaymentMethod: 'String',
  currentlyAutoInsured: 'Boolean',
  currentAutoCarrier: 'String',
  currentAutoCarrierStartDate: 'AWSDate',
  currentAutoCarrierEndDate: 'AWSDate',
  currentAutoCarrierPolicyNumber: 'String',
  autoBillingDayOfMonth: 'Int',
  homeBillingDayOfMonth: 'Int',
  affinity: 'String',
  breakupWithAuto: 'Boolean',
  currentHomeownersCarrier: 'String',
  currentHomeownersCarrierPolicyNumber: 'String',
  breakupWithHomeowners: 'Boolean',
  personalPropertyProtection: 'Boolean',
  emails: '[EmailDetailsInput]',
  phoneNumbers: '[PhoneDetailsInput]',
  mailingAddress: 'AddressDetailsInput',
  homeSecurityPartnerCustomerType: 'String',
  discountInventoryScoreAddedDate: 'AWSDate',
  employeeDiscount: 'Boolean',
  rentersEffectiveDate: 'AWSDate',
  rentersPaymentType: 'String',
  rentersPaymentMethod: 'String',
  rentersBillingDayOfMonth: 'Int',
  currentRentersCarrier: 'String',
  currentRentersCarrierPolicyNumber: 'String',
  breakupWithRenters: 'Boolean',
  condoEffectiveDate: 'AWSDate',
  condoBillingDayOfMonth: 'Int',
  condoPaymentType: 'String',
  condoPaymentMethod: 'String',
  autoMegaDownPay: 'Boolean',
  homeMegaDownPay: 'Boolean',
  rentersMegaDownPay: 'Boolean',
  condoMegaDownPay: 'Boolean'
});

types.CondoDetailsInput = defineType({
  constructionType: 'String',
  condoLocation: 'AddressDetailsInput',
  hasUnitNumber: 'Boolean',
  condoQuality: 'String',
  mortgageDetails: '[MortgageDetailsInput]',
  numFullBathrooms: 'Int',
  numHalfBathrooms: 'Int',
  numOccupants: 'Int',
  ownershipStatus: 'String',
  purchaseDate: 'AWSDate',
  roofShape: 'String',
  sqFt: 'Int',
  typeOfCondo: 'String',
  typeOfEntry: 'String',
  hasBasement: 'Boolean',
  buildingHasMoreThanFiveUnits: 'Boolean',
  yearBuilt: 'Int',
  latitude: 'Float',
  longitude: 'Float',
  fpc: 'String',
  county: 'String',
  fireHydrantWithin1000ft: 'Boolean',
  windstormMitigationLevel: 'Int',
  fpcFromLocation: 'String',
  floor: 'String',
  weeksRentedOut: 'Int',
  numVehicles: 'Int',
  numStories: 'Float'
});

types.CondoCoverageDetailsInput = defineType({
  coverageC: 'Float',
  coverageA: 'Float',
  coverageBP: 'Int',
  coverageDPctOfC: 'Int',
  coverageEBSL: 'Boolean',
  coverageX: 'Int',
  coverageY: 'Int',
  coverageBCPctOfC: 'Int',
  coverageMSALE: 'Boolean',
  coverageMS: 'Boolean',
  coverageG: 'Int',
  coverageCA: 'Int',
  coverageDR: 'Boolean',
  coverageF: 'Int',
  coverageJ: 'Int',
  coverageMI: 'Int',
  coverageSP: 'Int',
  coverageST: 'Int',
  deductibleAllOther: 'Float',
  coverageWaterBackup: 'Int',
  personalPropReimbursement: 'Boolean',
  minimumDeductibleValue: 'Float'
});

types.scheduledPersonalPropertyItemDetailsInput = defineType({
  category: 'String',
  description: 'String',
  value: 'Int',
  breakageOption: 'Boolean',
  exTheftOption: 'Boolean'
});

types.PreBindUWCheckInput = defineType({
  homeVerification: 'HomeVerificationInput',
  autoVerification: 'AutoVerificationInput',
  attestationAgent: 'String',
  overridesForUW: '[Int]'
});

types.AutoVerificationInput = defineType({
  ineligibleForAutoDueToUWSelfReport: 'Boolean',
  signedPropertyUWAttestation: 'Boolean',
  // the nodes below are for FL only
  needSR22: 'Boolean',
  excludeBICoverage: 'Boolean'
});

types.HomeVerificationInput = defineType({
  signedPropertyUWAttestation: 'Boolean',
  ineligibleForHomeDueToUWSelfReport: 'Boolean',
  noAsbestosCheck: 'String',
  homeWiringCheck: 'String',
  homePlumbingCheck: 'String',
  noOpenFoundationCheck: 'String',
  // the below nodes are specific to the FL UW form
  hasTrampolinesOrSkateBoardRampEtc: 'Boolean',
  isMobileOrPrefabricatedEtc: 'Boolean',
  hadSinkholeActivityOrIsAdjacentToSinkholes: 'Boolean',
  isIneligibleDogBreed: 'Boolean',
  hasExoticOrNonDomesticatedEtcAnimals: 'Boolean',
  hasPetBiteHistoryOrViciousBehavior: 'Boolean',
  hasCommercialOccupation: 'Boolean',
  isOwnedByCorpOrPartnershipEtc: 'Boolean',
  hadRentedMultTimesOrFullTimeOneTenant: 'Boolean',
  isInForeclosure: 'Boolean',
  hasMoreThan2Mortgages: 'Boolean',
  runDayCareOutOfMyHome: 'Boolean',
  isPrimaryHome: 'Boolean',
  hadPriorLossIn5Years: 'Boolean',
  isWaterHeaterAgeGreaterThan15Years: 'Boolean',
  isPriorPolicyCancelledOrNonRenewed: 'Boolean',
  hasFlatRoofs: 'Boolean',
  excludeWindCoverage: 'Boolean',
  excludeContentCoverage: 'Boolean',
  includeSinkholeCoverage: 'Boolean',
  hasRecalledConstructionMaterials: 'Boolean',
  hasInsuranceCurrently: 'Boolean'
});

types['[scheduledPersonalPropertyItemDetailsInput]'] = arr(types.scheduledPersonalPropertyItemDetailsInput);
types.scheduledPersonalPropertyDetailsInput = defineType({
  deductible: 'Int',
  items: '[scheduledPersonalPropertyItemDetailsInput]'
});

types.EarthquakeCoverageDetailsInput = defineType({
  policyId: 'String'
});

types.FloodCoverageDetailsInput = defineType({
  policyId: 'String'
});

types.PolicyDetailsInput = defineType({
  correctedAddress: 'AddressDetailsInput',
  drivers: '[PersonDetailsInput]',
  nonDrivers: '[PersonDetailsInput]',
  people: '[PersonDetailsInput]',
  cars: '[CarDetailsInput]',
  trailers: '[TrailerDetailsInput]',
  auto: 'AutoDetailsInput',
  autoCoverage: 'AutoCoverageDetailsInput',
  home: 'HomeDetailsInput',
  attachedHomeowners: 'Boolean',
  attachedRenters: 'Boolean',
  attachedAuto: 'Boolean',
  homeCoverage: 'HomeCoverageDetailsInput',
  connectedHome: 'ConnectedHomeDetailsInput',
  includeUmbrella: 'Boolean',
  includeRenters: 'Boolean',
  includeEarthquake: 'Boolean',
  includeFlood: 'Boolean',
  earthquakeCoverage: 'EarthquakeCoverageDetailsInput',
  floodCoverage: 'FloodCoverageDetailsInput',
  rentersCoverage: 'RentersCoverageDetailsInput',
  umbrellaCoverage: 'UmbrellaCoverageDetailsInput',
  scheduledPersonalProperty: 'scheduledPersonalPropertyDetailsInput',
  global: 'GlobalDetailsInput',
  startDate: 'AWSDate',
  multiPolicyDiscount: 'String',
  underwritingChanges: 'Boolean',
  skipImmediateBillOrRefund: 'Boolean',
  applyFee: 'Boolean',
  condo: 'CondoDetailsInput',
  condoCoverage: 'CondoCoverageDetailsInput'
});

types.AdditionalPartyDetailsInput = defineType({
  id: 'ID',
  name: 'String',
  address: 'AddressDetailsInput',
  lossPayee: 'Boolean',
  relationship: 'String',
  loanNumber: 'String',
  VIN: 'String',
  addressActiveStatus: 'Boolean'
});

types['[AdditionalPartyDetailsInput]'] = arr(types.AdditionalPartyDetailsInput);

types.BankAchAccountDetailsInput = defineType({
  id: 'String',
  basisTheoryBankAccountToken: 'String'
});

types['[BankAchAccountDetailsInput]'] = arr(types.BankAchAccountDetailsInput);

types.PaymentMethodInput = defineType({
  id: 'String',
  basisTheoryCardToken: 'String'
});

types['[PaymentMethodInput]'] = arr(types.PaymentMethodInput);

types.FeeDetailsInput = defineType({
  type: 'String',
  description: 'String',
  amount: 'Float',
  appliedDate: 'String'
});

types['[FeeDetailsInput]'] = arr(types.FeeDetailsInput);

types.PolicyInput = defineType({
  id: 'String',
  billingAddInstallments: 'Int',
  billingDayOfMonth: 'Int',
  billingHoldUntil: 'AWSDate',
  effectiveDate: 'AWSDate',
  endDate: 'AWSDate',
  autoRenew: 'Boolean',
  renewalPaymentType: 'String',
  renewalPaymentMethod: 'String',
  renewalCreditPull: 'Boolean',
  rewriteReason: 'String',
  cancelReason: 'String',
  additionalParties: '[AdditionalPartyDetailsInput]',
  fees: '[FeeDetailsInput]',
  skipImmediateBillOrRefund: 'Boolean',
  applyFee: 'Boolean',
  sendPaperDocuments: 'Boolean',
  paymentMethod: 'String',
  paymentType: 'String',
  defaultBankAccount: 'BankAchAccountDetailsInput',
  defaultCreditCard: 'PaymentMethodInput',
  defaultEscrowAccount: 'MortgageDetailsInput',
  stripeCustomerId: 'String'
});

types.AccountInput = defineType({
  id: 'String',
  fname: 'String',
  lname: 'String',
  mailingAddress: 'AddressDetailsInput',
  phoneNumber: 'AWSPhone',
  email: 'AWSEmail'
});

types.ConnectedHomeDetailsInput = defineType({
  monitored: 'String',
  moistureDevices: 'Int',
  theftPreventionDevices: 'Int',
  motionDetectingDevices: 'Int',
  smokeDetectingDevices: 'Int',
  autoWaterShutoffDevices: 'Boolean',
  providerName: 'String',
  agreeToTerms: 'Boolean'
});

types.ExistingCustomerInput = defineType({
  id: 'String'
});

types.OverridesForUWInput = defineType({
  code: 'Int',
  overridingAgent: 'String'
});

types.OverridesInput = defineType({
  overridesForUW: '[OverridesForUWInput]'
});
types['[OverridesForUWInput]'] = arr(types.OverridesForUWInput);

types.QuoteDetailsInput = defineType({
  fname: 'String',
  lname: 'String',
  drivers: '[PersonDetailsInput]',
  cars: '[CarDetailsInput]',
  trailers: '[TrailerDetailsInput]',
  auto: 'AutoDetailsInput',
  autoCoverage: 'AutoCoverageDetailsInput',
  existingCustomer: 'ExistingCustomerInput',
  home: 'HomeDetailsInput',
  condo: 'CondoDetailsInput',
  condoCoverage: 'CondoCoverageDetailsInput',
  correctedAddress: 'AddressDetailsInput',
  homeCoverage: 'HomeCoverageDetailsInput',
  includeUmbrella: 'Boolean',
  includeRenters: 'Boolean',
  includeEarthquake: 'Boolean',
  includeFlood: 'Boolean',
  earthquakeCoverage: 'EarthquakeCoverageDetailsInput',
  floodCoverage: 'FloodCoverageDetailsInput',
  isHomeOwner: 'Boolean',
  rentersCoverage: 'RentersCoverageDetailsInput',
  scheduledPersonalProperty: 'scheduledPersonalPropertyDetailsInput',
  umbrellaCoverage: 'UmbrellaCoverageDetailsInput',
  global: 'GlobalDetailsInput',
  leadSource: 'String',
  selectedOption: 'String',
  connectedHome: 'ConnectedHomeDetailsInput',
  noBindHome: 'Boolean',
  noBindAuto: 'Boolean',
  fromStaff: 'Boolean',
  rep: 'String',
  preBindUWCheck: 'PreBindUWCheckInput',
  autoMegaDownPay: 'Boolean',
  homeMegaDownPay: 'Boolean',
  rentersMegaDownPay: 'Boolean',
  condoMegaDownPay: 'Boolean',
  overrides: 'OverridesInput'
});
