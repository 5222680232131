import { makeStyles } from '@material-ui/core/styles';

import commonStyles from './common.styles';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(8)
  },
  deductibleContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(0, 4),
    borderRadius: 6,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginBottom: theme.spacing(2),
    height: 76
  },
  selectRoot: {
    marginBottom: theme.spacing(4)
  },
  header: {
    paddingLeft: theme.spacing(4)
  },
  categoryIcon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2)
  },
  bottom: {
    justifyContent: 'flex-start'
  },
  exTheftContainer: {
    background: theme.colors.card_background_primary_editable,
    padding: theme.spacing(2, 5),
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '0 0 6px 6px',
    marginBottom: theme.spacing(2)
  },
  ...commonStyles(theme)
}));
