import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  formControl: {
    justifyContent: 'center'
  },
  controlLabel: {
    marginRight: theme.spacing(2)
  },
  container: {
    maxWidth: 450,
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  datePicker: {
    marginLeft: theme.spacing(8),
    marginBottom: 0,
    maxWidth: 150
  }
});

export default makeStyles(styles);
