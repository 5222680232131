import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLAutoQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Auto</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Do you require a SR-22 form for your policy?</Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          An SR-22 is a certificate of financial responsibility required for some drivers by their state or court order.
          This requirement is typically for drivers who have committed a serious traffic violation, like reckless
          driving or driving without insurance.
        </Label>
        <FormField
          className={classes.formItem}
          id="auto.needSR22"
          name="auto.needSR22"
          type="radio"
          mode="light"
          options={options}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Would you like to exclude bodily injury coverage from your auto policy?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Bodily Injury Liability Coverage (BI) pays for death or serious and permanent injury to others when you are
          legally liable for those damages. If you are sued, the insurer will provide legal representation until the
          insurer has paid the policy coverage limit.
        </Label>
        <FormField
          className={classes.formItem}
          id="auto.excludeBICoverage"
          name="auto.excludeBICoverage"
          type="radio"
          mode="light"
          options={options}
        />
      </Card>
    </Grid>
  );
};
export default FLAutoQuestions;
