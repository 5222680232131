import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import useStyles from './policy-preview-refund-notification.styles';
import NotificationIconOrange from 'core/assets/svg/info-orange.svg';

const PolicyPreviewRefundNotification = ({ preview }) => {
  const classes = useStyles();
  const { skipImmediateBillOrRefund } = preview;

  return (
    <Card className={classes.cardContainer}>
      <Grid className={classes.columnContainer}>
        <Grid className={classes.iconContainer}>
          <img src={NotificationIconOrange} className={classes.iconSmall} alt="Notification Icon" />
          <p className={classes.boldText}>This policy requires a refund</p>
        </Grid>
        <ValueField
          label="Billing Description"
          value={<div className={classes.inline}>{preview?.billingDescription}</div>}
        />

        {skipImmediateBillOrRefund && (
          <Label type="greenExtraSmall">
            Unless this is an extenuating circumstance, please go back and UNCHECK the &quot;Delay Payment/Refund&quot;
            option.
          </Label>
        )}
      </Grid>
    </Card>
  );
};

export default PolicyPreviewRefundNotification;
