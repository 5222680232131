export default (theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_secondary
  },
  // Price details
  firstRow: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  pastDueRow: {
    backgroundColor: theme.colorPalette.red_20
  },
  icon: {
    width: 27,
    height: 27,
    objectFit: 'contain'
  },
  field: {
    width: 145
  },
  valueField: {
    maxWidth: 118
  },
  fieldContainer: {
    marginBottom: 0
  },
  totalFeesContainer: {
    width: 166,
    padding: 0
  },
  totalFees: {
    marginRight: theme.spacing(4)
  },
  withMargin: {
    marginRight: 19
  },
  paymentBody: {
    padding: theme.spacing(4, 8, 8)
  },
  secondRow: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  thirdRow: {
    paddingTop: 14,
    paddingBottom: theme.spacing(4)
  },
  rowTitle: {
    paddingTop: theme.spacing(4),
    paddingBottom: 0,
    display: 'flex',
    justifyContent: 'space-between'
  },
  // Payment Details
  paymentsCard: {
    marginTop: {
      marginTop: theme.spacing(4)
    }
  },
  creditCard: {
    width: 250
  },
  tableBodyPaymentMethodLast4: {
    display: 'inline-flex',
    color: theme.colorPalette.beige_10_op_60,
    width: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
    '& > span': {
      margin: '0 4px'
    }
  },
  creditCardLast4: {
    composes: '$tableBodyPaymentMethodLast4',
    display: 'block',
    fontSize: 10,
    width: '50%'
  },
  tableRow: {
    height: 47,
    borderBottom: `1px solid ${theme.colorPalette.white_40_op_70}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  tableCell: {
    borderBottom: 'none'
  },
  sectionContainer: {
    padding: theme.spacing(0, 8)
  },
  cardComponent: {
    width: '100%'
  },
  // Fee Breakdown
  feeRowText: {
    color: `${theme.colorPalette.beige_10}`,
    width: 120
  },
  checkbox: {
    color: `${theme.colorPalette.beige_10}`
  },
  checkboxCell: {
    width: 80,
    paddingLeft: 0
  },
  // Add Fee
  feeSelectorContainer: {
    width: 260,
    display: 'flex',
    justifyContent: 'space-between'
  },
  feeSelector: {
    width: 200
  },
  feeAddButtonContainer: {
    paddingTop: 3
  },
  // manual charge
  submit: {
    width: 168,
    marginRight: theme.spacing(2),
    marginTop: 25
  },
  spaceBetweenRow: {
    justifyContent: 'space-between'
  },
  manualChargeContainer: {
    paddingTop: 10
  },
  loadingBar: {
    height: 2
  },
  stripeCustomerLink: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  addInstallment: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`
  },
  addInstallmentBtn: {
    width: 168,
    margin: '20px 10px'
  },
  warningText: {
    fontSize: 10,
    margin: 0,
    color: theme.colorPalette.white_10
  },
  manualChargeTitleContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
});
