import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { condoStates, policyType, rentersPolicyAvailable } from '@ourbranch/lookups';

import switchIcon from 'core/assets/svg/switch.svg';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { Button } from 'core/components/button';
import { CardLine } from '../../card-line';
import useStyles from './switch-policy.styles';
import { SwitchPolicyModal } from '../switch-policy-modal';

const policyName = {
  [policyType.Home]: 'Home',
  [policyType.Condo]: 'Condo',
  [policyType.Renters]: 'Renters'
};

const bundleForPolicy = {
  [policyType.Renters]: policyType.ARBundle,
  [policyType.Home]: policyType.HABundle,
  [policyType.Condo]: policyType.CABundle
};

const SwitchPolicy = () => {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const history = useHistory();
  const [showSwitchPolicyModal, setShowSwitchPolicyModal] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState({ policy: null, bundle: null });
  const { values, setValues, validateForm, setErrors } = useFormikContext();
  const {
    offer: { offer, swapHomeRentersOptions, getIsLicensedForState }
  } = useStore();
  const hasRenters = offer.options.some((opt) => opt.type === policyType.ARBundle);

  const allowLicensedActions = getIsLicensedForState(session);
  const { state } = offer?.quote?.correctedAddress || {};

  const togglePolicyType = useCallback(
    async (type) => {
      const canSwitchToCondo = hasRenters && type === policyType.Condo && !!offer.quote.condo;
      const canSwitchToHome = hasRenters && type === policyType.Home && !offer.quote.condo;
      const canSwap = type === policyType.Renters || canSwitchToHome || canSwitchToCondo;

      if (canSwap) {
        const selectedOption = bundleForPolicy[type];
        const updatedValues = {
          ...values,
          global: { ...values.global, homeownersPaymentMethod: 'C', condoPaymentMethod: 'C' },
          includeRenters: type === policyType.Renters,
          isHomeOwner: type !== policyType.Renters,
          selectedOption
        };

        try {
          await validateForm(updatedValues);
          setValues(updatedValues);
          await swapHomeRentersOptions(history, selectedOption);
        } catch (errors) {
          setErrors(errors);
          throw errors;
        }
      } else {
        setSelectedPolicy({ policy: policyName[type].toLowerCase(), bundle: bundleForPolicy[type] });
        setShowSwitchPolicyModal(true);
      }
    },
    [offer.options, values]
  );

  const { activePolicy, availablePolicies } = useMemo(() => {
    let activePolicy = '';
    const availablePolicies = [];
    const hasRenters = offer.options.some((o) => [policyType.Renters, policyType.ARBundle].includes(o.type));
    if (hasRenters) {
      activePolicy = 'RENTERS';
      availablePolicies.push(policyType.Home);
      if (condoStates[state]) {
        availablePolicies.push(policyType.Condo);
      }
    } else {
      const hasHome = offer.options.some((o) => [policyType.Home, policyType.HABundle].includes(o.type));
      const hasCondo = offer.options.some((o) => [policyType.Condo, policyType.CABundle].includes(o.type));
      activePolicy = hasHome ? 'HOME' : hasCondo ? 'CONDO' : null;
      if (!hasHome) {
        availablePolicies.push(policyType.Home);
      } else if (condoStates[state]) {
        availablePolicies.push(policyType.Condo);
      }
      if (rentersPolicyAvailable[state]) {
        availablePolicies.push(policyType.Renters);
      }
    }
    return { activePolicy, availablePolicies };
  }, [offer.options, state]);

  return (
    activePolicy && (
      <CardLine className={classes.container}>
        <Label className={classes.switchLabel} type="status">
          <img src={switchIcon} alt="arrows" />
          SWITCH {activePolicy} TO{' '}
        </Label>
        {availablePolicies.map((type, ix) => (
          <Button
            key={ix}
            className={classes.button}
            color="secondary"
            variant="text"
            onClick={() => togglePolicyType(type)}
            disabled={!allowLicensedActions}
          >
            {policyName[type]}
          </Button>
        ))}
        {availablePolicies.length === 0 && (
          <Label type="policyLabel" className={classes.disabled}>
            There are no available policies to switch to
          </Label>
        )}
        {showSwitchPolicyModal && (
          <SwitchPolicyModal
            open={showSwitchPolicyModal}
            onClose={() => setShowSwitchPolicyModal(false)}
            policyToSwitch={selectedPolicy.policy}
            bundle={selectedPolicy.bundle}
          />
        )}
      </CardLine>
    )
  );
};

export default SwitchPolicy;
