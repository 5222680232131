import { CondoType, condoTypesWithUnitsInBuilding } from 'core/helpers/constants';

export const formatCondoValues = (condo) => {
  if (!condo) {
    return null;
  }

  const { monthsRentedOut, noUnitNumber, ...rest } = condo;
  delete condo.oldestResident;
  delete condo.highestEducation;
  if (!condoTypesWithUnitsInBuilding.includes(rest.typeOfCondo)) {
    rest.buildingHasMoreThanFiveUnits = rest.typeOfCondo !== CondoType.DETACHED;
  }

  return {
    ...rest,
    hasUnitNumber: !noUnitNumber,
    weeksRentedOut: monthsRentedOut * 4
  };
};
