import React, { useContext } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormikContext, Form, Formik } from 'formik';
import { lookupsJson, noTrailerCoverageStates } from '@ourbranch/lookups';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import { awsDateFormatter } from 'core/helpers/formatters';
import { Tooltip } from 'core/components/tooltip';
import useStyles from 'common/components/people/add-form.styles';

const initialValues = {
  trailerVIN: '',
  initialOpen: true
};

const findGarageLocation = (trailers) => {
  if (!Array.isArray(trailers) || !trailers.length) {
    return undefined;
  }
  const [trailer] = trailers;
  if (trailer) {
    return trailer.garageLocation;
  }
};

const handleSubmit =
  (onAdd, trailers) =>
  ({ trailerVIN, initialOpen, type }) => {
    onAdd({
      VIN: trailerVIN.toUpperCase(),
      initialOpen,
      type,
      purchaseDate: awsDateFormatter(new Date()),
      garageLocation: findGarageLocation(trailers)
    });
  };

const validationSchema = (trailers) =>
  Yup.object().shape({
    trailerVIN: Yup.string()
      .required('VIN is required')
      .ensure()
      .test(
        'is-new-vin',
        'This VIN is already included in your offer',
        (val) => !trailers?.find((trailer) => trailer.VIN === val)
      )
  });

function AddTrailer({ onAdd, id }) {
  const { viewOnly } = useContext(AuthContext);
  const { values } = useFormikContext();
  const { [id]: trailers } = values || {};

  const classes = useStyles();
  const store = useStore();

  const state = values?.correctedAddress?.state || store?.account?.policies?.policy?.geographicState;
  const noTrailerCoverageInThisState = noTrailerCoverageStates?.includes(state);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema(trailers)}
      onSubmit={handleSubmit(onAdd, trailers)}
      disabled
    >
      {() => (
        <Form className={classes.container}>
          <Grid key="container" container justify="space-between" alignItems="center" spacing={2}>
            <FormField
              id="trailerVIN"
              name="trailerVIN"
              type="string"
              label={`Trailer's VIN Number`}
              mode="light"
              disabled={noTrailerCoverageInThisState}
              xs={4}
            />
            <FormField
              id="type"
              name="type"
              type="select"
              label="Type"
              mode="light"
              xs={4}
              disabled={noTrailerCoverageInThisState}
              options={lookupsJson.trailerType}
            />
            {noTrailerCoverageInThisState && (
              <Tooltip
                text={'We are not offering trailer coverage in this state'}
                placement="top"
                className={classes.trailerTip}
              />
            )}
            <Button
              type="submit"
              key="addButton"
              mode="big"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={noTrailerCoverageInThisState || viewOnly}
            >
              Add Trailer
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

AddTrailer.propTypes = {
  onAdd: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default AddTrailer;
