import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  lastField: {
    width: 154
  },
  addLabel: {
    margin: theme.spacing(4, 0)
  },
  itemContainer: {
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 48,
    paddingBottom: theme.spacing(2)
  },
  identifier: {
    paddingBottom: theme.spacing(2)
  },
  buttonContainer: {
    paddingTop: theme.spacing(2)
  },
  button: {
    width: 36,
    height: 36
  },
  line: {
    borderBottom: `1px solid ${theme.colorPalette.white_10}`,
    width: 'calc(100% - 8px)'
  }
});

export default makeStyles(styles);
