import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  getOptions,
  lookupsJson,
  noMedPayStates,
  uimAddedOnStates,
  incomeLossStates,
  UMBIEqualsBIStates,
  UIMBIEqualsUMBIUnlessRejectStates,
  accidentalDeathStates
} from '@ourbranch/lookups';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useMemo, useEffect } from 'react';

import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { useCurrentState } from '../../../hooks/useCurrentState';
import SplitLiabilityLimit from './split-liability-limit';
import * as umbiResolvers from './limit-by-state';
import { UIMCoverageType } from './uim-coverage-type';
import styles from './coverage.styles';

const id = 'autoCoverage';

const Coverage = ({ classes, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const state = useCurrentState(values);

  const UMBIByState = useMemo(() => {
    if (state) {
      return lazy(umbiResolvers[`um${state.toLowerCase()}`]);
    }
  }, [state]);

  const { policyLimitBIPD } = values[id];
  let { policyLimitUMBI, policyLimitUIMBI, policyLimitUMPD } = values[id];

  // if null UMBI, then UMBI = BIPD
  if (!policyLimitUMBI) {
    policyLimitUMBI = policyLimitBIPD.includes('/')
      ? policyLimitBIPD.split('/').slice(0, -1).join('/')
      : policyLimitBIPD;
  }

  // if null UIMBI = UMBI
  if (!policyLimitUIMBI) {
    policyLimitUIMBI = policyLimitUMBI;
  }

  if (!policyLimitUMPD) {
    policyLimitUMPD = 'NONE';
  }

  const valueBIPD = policyLimitBIPD.includes('/')
    ? Number(policyLimitBIPD.split('/')[0])
    : Number(policyLimitBIPD.split(' ')[0]);

  const valuePD = policyLimitBIPD.includes('/')
    ? Number(policyLimitBIPD.split('/')[2])
    : Number(policyLimitBIPD.split(' ')[0]);

  const isSplitLimit = policyLimitBIPD.includes('/');

  const valueUM = policyLimitUMBI.includes('/')
    ? Number(policyLimitUMBI.split('/')[0])
    : Number(policyLimitUMBI.split(' ')[0]);

  const valueUIMBI = policyLimitUIMBI.includes('/')
    ? Number(policyLimitUIMBI.split('/')[0])
    : Number(policyLimitUIMBI.split(' ')[0]);

  const [UIMBIOptions, UMBIOptions, UMPDOptions] = useMemo(() => {
    const validOption = (option, limit, isSL) => {
      if (option.id?.startsWith('0/0')) return true;
      if (option.id.includes('/') && !isSL) return false;
      if (option.id.includes('CSL') && isSL) return false;
      if (UMBIEqualsBIStates[state]) {
        // NH requires BI to equal UMBI
        if (option.id.includes('/')) {
          return Number(option.id.split('/')[0]) === limit;
        }
        return Number(option.id.split(' ')[0]) === limit;
      }
      if (option.id.includes('/')) {
        return Number(option.id.split('/')[0]) <= limit;
      }
      return Number(option.id.split(' ')[0]) <= limit;
    };

    const validUMPDOption = (option, limit, isSL) => {
      const optionValue = option.id.replace(/\/[0-9]+DED/i, '');
      if (optionValue?.startsWith('0/0') || option.id === 'NONE') return true;
      if (valueUM === 0) return false;

      if (optionValue.includes('/') && !isSL) return false;

      // rules where UMPD has to be <= individual limit and also <= PD

      if (['AR'].includes(state)) {
        if (optionValue.includes('CSL') && isSL) return false; // no CSL when SL
        const toCompare = Number(optionValue.split(' ')[0]);
        if (!isSL) {
          // CSL check
          return toCompare <= valueUM && toCompare <= limit;
        }
        return toCompare <= valueUM * 1000 && toCompare <= limit * 1000;
      }

      if (state === 'IN') {
        return parseInt(optionValue, 10) <= valueUM * 1000;
      }

      // rules where UMPD has to be equal to CSL or <= PD
      if (!isSL) {
        // CSL check -- no coverage or CSL is the same
        return Number(optionValue.split(' ')[0]) === valueUM;
      }
      return Number(optionValue) <= limit * 1000;
    };

    let uimbiOptions = getOptions('policyLimitUIMBI', state).map((option) => ({
      ...option,
      disabled: !validOption(option, valueBIPD, isSplitLimit)
    }));
    const umbiOptions = getOptions('policyLimitUMBI', state).map((option) => ({
      ...option,
      disabled: !validOption(option, valueBIPD, isSplitLimit)
    }));

    if (state === 'VT') {
      // Vermont has 50/100 UMBI for 25/50 BI
      if (valueBIPD === 25) {
        const theOption = umbiOptions.find((opt) => opt.id === '50/100');
        if (!theOption) {
          umbiOptions.push({
            id: '50/100',
            value: '$50K | $100K',
            disabled: false
          });
        } else {
          // enable the option:
          theOption.disabled = false;
        }
      }
    }

    if (state === 'WI') {
      // Wisconsin is different for UIMBI; must be limited to UMBI or 50/100
      // if UMBI is 25/50
      if (valueUM === 25 && !uimbiOptions.find((opt) => opt.id === '50/100')) {
        uimbiOptions.push({
          id: '50/100',
          value: '$50K | $100K',
          disabled: false
        });
      }
      // otherwise uimbi must equal um
      uimbiOptions = uimbiOptions.map((opt) => ({
        ...opt,
        disabled:
          !opt.id?.startsWith('0/0') &&
          Number(opt.id.split('/')[0]) !== valueUM &&
          (valueUM !== 25 || opt.id !== '50/100') &&
          opt.id.replace(' CSL', '') !== String(valueBIPD)
      }));
    } else if (UIMBIEqualsUMBIUnlessRejectStates[state]) {
      // UIMBI must be UMBI or rejected
      uimbiOptions = uimbiOptions.map((opt) => ({
        ...opt,
        disabled:
          !opt.id?.startsWith('0/0') &&
          Number(opt.id.split('/')[0]) !== valueUM &&
          Number(opt.id.split(' ')[0]) !== valueUM
      }));
    }

    const umpdOptions = getOptions('policyLimitUMPD', state).map((option) => ({
      ...option,
      disabled: !validUMPDOption(option, valuePD, isSplitLimit)
    }));

    return [
      // how do we change this over to lookupsJson?
      uimbiOptions,
      // how do we change this over to lookupsJson?
      umbiOptions,
      umpdOptions
    ];
  }, [state, valueBIPD, valueUM, isSplitLimit, valuePD]);

  // // don't allow UM/UIM to be higher than BIPD
  useEffect(() => {
    if (policyLimitUMBI && valueUM > valueBIPD) {
      // reverse options so we get highest value allowed
      const lowestPossibleUMBI = [...UMBIOptions].reverse().find((option) => {
        return !option.disabled;
      });
      setFieldValue(`${id}.policyLimitUMBI`, lowestPossibleUMBI?.id, false);
    }

    // in WI, 25/50 BI allows for 50/100 UIM, and otherwise UIM must be UM or rejected:
    const uimValueBIPD = state === 'WI' && valueBIPD === 25 ? 50 : valueBIPD;

    // UIMBI Can't be higher than UMBI which can't be higher than the uim BIPD set above
    // and also can't be currently disabled
    if (
      policyLimitUIMBI &&
      (
        valueUIMBI > uimValueBIPD ||
        UIMBIOptions.find((option) => {
          const testValue = option.id.includes('CSL')
            ? Number(option.id.replace(/ CSL/i, ''))
            : Number(option.id.split('/')[0]);
          return testValue === valueUIMBI;
        })
      )?.disabled
    ) {
      const lowestPossibleUIMBI = [...UIMBIOptions].reverse().find((option) => {
        return !option.disabled;
      });
      // UIMBI is optional
      setFieldValue(`${id}.policyLimitUIMBI`, lowestPossibleUIMBI?.id || '0/0', false);
    }

    // check and change policyLimitUIMBI if it's not right
    if (policyLimitUIMBI && UIMBIOptions.find((opt) => opt.id === policyLimitUIMBI)?.disabled) {
      const lowestPossibleUIMBI = [...UIMBIOptions].reverse().find((option) => {
        return !option.disabled;
      });
      setFieldValue(`${id}.policyLimitUIMBI`, lowestPossibleUIMBI?.id || '0/0', false);
    }

    // check and change policyLimitUMPD if it's not right
    if (policyLimitUMPD && UMPDOptions.find((opt) => opt.id === policyLimitUMPD)?.disabled) {
      const lowestPossibleUMPD = [...UMPDOptions].reverse().find((option) => {
        return !option.disabled;
      });
      setFieldValue(`${id}.policyLimitUMPD`, lowestPossibleUMPD?.id || 'NONE', false);
    }
  }, [
    valueBIPD,
    policyLimitUMBI,
    policyLimitUIMBI,
    policyLimitUMPD,
    valueUM,
    valueUIMBI,
    setFieldValue,
    UIMBIOptions,
    UMBIOptions,
    UMPDOptions,
    state
  ]);

  const paTortOptionsToolTip = () => {
    return (
      <>
        <div>
          <strong>Tort Options</strong> - Provides two options to an insured for pursuing financial compensation for
          injuries caused by other drivers:
        </div>
        <br />
        <div>
          <strong> Limited Tort </strong> - allows for an insured to seek recovery for all medical bills and lost wages
          but not for pain and suffering or other non-monetary damages except in the case of a serious injury.
        </div>
        <br />
        <div>
          <strong> Full Tort</strong> - allows for unrestricted recovery by the insured for any financial compensation
          including medical bills, lost wages, and pain and suffering.
        </div>
        <br />
      </>
    );
  };

  return (
    <Section title="Auto Policy Coverage" type="SubSection">
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="flex-start" className={classes.containerDark} spacing={4}>
          {state === 'MI' ? (
            <SplitLiabilityLimit disabled={disabled} />
          ) : (
            <Grid item xs={6}>
              <LabelTooltip
                mode="dark"
                label="Bodily Injury/Property Damage Limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitBIPD }}
              >
                <FormField
                  mode="dark"
                  name={`${id}.policyLimitBIPD`}
                  id={`${id}.policyLimitBIPD`}
                  // how do we change this over to lookupsJson?
                  options={getOptions('policyLimitBIPD', state)}
                  type="select"
                  optional
                  disabled={disabled}
                />
              </LabelTooltip>
            </Grid>
          )}

          {!noMedPayStates[state] && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Medical Payments"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitMedicalPayments }}
              >
                <FormField
                  mode="dark"
                  name={`${id}.policyLimitMedicalPayments`}
                  id={`${id}.policyLimitMedicalPayments`}
                  // how do we change this over to lookupsJson?
                  options={getOptions('policyLimitMedicalPayments', state)}
                  type="select"
                  optional
                  disabled={disabled}
                />
              </LabelTooltip>
            </Grid>
          )}

          <Suspense fallback={null}>
            {UMBIByState && (
              <UMBIByState
                id={id}
                disabled={disabled}
                UMBIOptions={UMBIOptions}
                UIMBIOptions={UIMBIOptions}
                UMPDOptions={UMPDOptions}
              />
            )}
          </Suspense>
          {uimAddedOnStates.includes(state) && <UIMCoverageType id={id} disabled={disabled} />}
          {state === 'MI' && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Limited Property Damage Coverage"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.limitedPropertyDamage }}
              >
                <FormField
                  id={`${id}.policyLimitLPD`}
                  name={`${id}.policyLimitLPD`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={lookupsJson.policyLimitLPD}
                />
              </LabelTooltip>
            </Grid>
          )}
          {incomeLossStates[state] && (
            <Grid item xs={6}>
              <LabelTooltip
                label={state === 'DC' ? 'Work Loss' : 'Income Loss/Total Disability, Loss of Income Coverage'}
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.incomeLossCoverage }}
              >
                <FormField
                  id={`${id}.policyLimitIncomeLoss`}
                  name={`${id}.policyLimitIncomeLoss`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={getOptions('policyLimitIncomeLoss', state)}
                />
              </LabelTooltip>
            </Grid>
          )}
          {accidentalDeathStates[state] && (
            <FormField
              id={`${id}.policyLimitAccidentalDeath`}
              name={`${id}.policyLimitAccidentalDeath`}
              type="select"
              label="Accidental Death Coverage"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={getOptions('policyLimitAccidentalDeath', state)}
            />
          )}
          {['DC', 'PA'].includes(state) && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Funeral Benefits Coverage"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.funeralBenefitsCoverage }}
              >
                <FormField
                  id={`${id}.policyLimitFuneralBenefits`}
                  name={`${id}.policyLimitFuneralBenefits`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={getOptions('policyLimitFuneralBenefits', state)}
                />
              </LabelTooltip>
            </Grid>
          )}
          {state === 'PA' && (
            <Grid item xs={6}>
              <LabelTooltip label="Tort Option" tooltip={{ label: 'More Info', onHoverText: paTortOptionsToolTip() }}>
                <FormField
                  id={`${id}.policyLimitTortOption`}
                  name={`${id}.policyLimitTortOption`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={lookupsJson.policyLimitTortOption}
                />
              </LabelTooltip>
            </Grid>
          )}
          {['DC', 'PA'].includes(state) && (
            <Grid item xs={6}>
              <LabelTooltip
                label={state === 'PA' ? 'Extraordinary Medical Benefits Coverage' : 'Medical Expenses'}
                tooltip={{
                  label: 'More Info',
                  onHoverText:
                    state === 'PA'
                      ? tooltipHoverTexts.extraordinaryMedicalBenefitsCoverage
                      : `This provides coverage for the member regardless of whether or not they caused an accident, within their policy limits. It covers the member and any relatives living in their home, passengers in the insured vehicle, and certain licensed drivers who drive their insured vehicle with the member's permission.`
                }}
              >
                <FormField
                  id={`${id}.policyLimitExtraMedBenefits`}
                  name={`${id}.policyLimitExtraMedBenefits`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={getOptions('policyLimitExtraMedBenefits', state)}
                />
              </LabelTooltip>
            </Grid>
          )}
          {state === 'PA' && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Combined First Party Benefits"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.combinedFirstPartyBenefits }}
              >
                <FormField
                  id={`${id}.policyLimitComboFBP`}
                  name={`${id}.policyLimitComboFBP`}
                  type="select"
                  mode="dark"
                  xs={12}
                  disabled={disabled}
                  options={lookupsJson.policyLimitComboFBP}
                />
              </LabelTooltip>
            </Grid>
          )}
        </Grid>
      </div>
    </Section>
  );
};

Coverage.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

Coverage.defaultProps = {
  disabled: false
};

export default withStyles(styles)(Coverage);
