import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  label: {
    color: theme.colors.light_text,
    display: 'inline',
    '&.Mui-disabled': {
      color: theme.colorPalette.white_30,
      opacity: 0.6
    }
  },
  container: {
    height: 39,
    minHeight: 0,
    cursor: 'pointer',
    marginBottom: 0
  },
  'light-label': {
    color: theme.colors.text_secondary,
    display: 'inline'
  },
  'light-checkboxColorPrimary': {
    color: theme.colors.text_secondary
  },
  'dark-label': {
    color: `${theme.colors.light_text} !important`,
    '&.Mui-disabled': {
      color: theme.colorPalette.white_30,
      opacity: 0.6
    }
  },
  'dark-checkboxRoot': {
    height: 'auto'
  },
  lightCheckboxLongLabel: {
    height: 'auto',
    alignSelf: 'baseline',
    paddingTop: 6,
    color: theme.colors.text_secondary
  },
  darkCheckboxLongLabel: {
    height: 'auto',
    alignSelf: 'baseline',
    paddingTop: 6,
    color: theme.colors.text_primary
  },
  error: {
    color: `${theme.colorPalette.red_10} !important`
  },
  checkboxLongLabelError: {
    height: 'auto',
    alignSelf: 'baseline',
    paddingTop: 6,
    color: `${theme.colorPalette.red_10} !important`
  },
  helperText: {
    display: 'none'
  }, // For overriding
  customControlLabel: {
    alignSelf: 'flex-start',
    flex: 'auto'
  },
  checkboxTooltip: {
    position: 'relative',
    left: theme.spacing(6),
    top: '-10px'
  }
});

export default makeStyles(styles);
