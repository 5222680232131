import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import { Grid } from '@material-ui/core';
import { lookupsJson } from '@ourbranch/lookups';

import Field from 'core/components/form/form.v2';
import { LabelTooltip } from 'core/components/label-tooltip';
import {
  condoTypesWithBasement,
  condoTypesWithFloorLevel,
  condoTypesWithEntry,
  condoTypesWithUnitsInBuilding
} from 'core/helpers/constants';
import useStyles from './condo.styles';

const Condo = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const {
    monthsRentedOutOptions,
    typeOfCondoOptions,
    typeOfEntryOptions,
    constructionTypeOptions,
    roofShapeOptions,
    condoQualityOptions,
    floorOptions
  } = useMemo(() => {
    const monthsRentedOutOptions = new Array(13).fill(null).map((_, ix) => ({
      id: ix,
      value: `${ix}`
    }));

    return {
      monthsRentedOutOptions,
      typeOfEntryOptions: lookupsJson.typeOfEntry,
      condoQualityOptions: lookupsJson.homeQuality,
      typeOfCondoOptions: lookupsJson.typeOfCondo,
      constructionTypeOptions: lookupsJson.constructionType,
      roofShapeOptions: lookupsJson.condoRoofShape,
      floorOptions: lookupsJson.condoFloor
    };
  }, []);

  const showNumberOfUnits = condoTypesWithUnitsInBuilding.includes(values.condo.typeOfCondo);

  return (
    <>
      <Field xs={4} type="numeric" id="condo.yearBuilt" name="condo.yearBuilt" mode="light" label="Year Built" />
      <Field
        xs={4}
        type="date"
        id="condo.purchaseDate"
        name="condo.purchaseDate"
        mode="light"
        label="Purchase Date"
        defaultToEmpty
        disableFuture={false}
        maxDate={addDays(new Date(), 60)}
      />
      <Grid item xs={4}>
        <LabelTooltip
          mode="light"
          label="Months rented out"
          tooltip={{
            label: 'More Info',
            onHoverText: 'How many months on average this condo is rented out to others per year.'
          }}
        >
          <Field
            type="select"
            options={monthsRentedOutOptions}
            id="condo.monthsRentedOut"
            name="condo.monthsRentedOut"
            mode="light"
          />
        </LabelTooltip>
      </Grid>
      <Field
        xs={3}
        type="select"
        options={typeOfCondoOptions}
        id="condo.typeOfCondo"
        name="condo.typeOfCondo"
        mode="light"
        label="Type of condo"
      />
      <Field
        xs={3}
        type="select"
        id="condo.floor"
        options={floorOptions}
        name="condo.floor"
        mode="light"
        label="Floor/Level"
        disabled={!condoTypesWithFloorLevel.includes(values.condo.typeOfCondo)}
      />
      <Grid item xs={3}>
        <LabelTooltip
          mode="light"
          label="Type of entry"
          disabled={!condoTypesWithEntry.includes(values.condo.typeOfCondo)}
          tooltip={{
            label: 'More Info',
            onHoverText:
              'Does the main entrance to the condo unit exit to a shared interior hallway or to the exterior of the structure?'
          }}
        >
          <Field
            type="select"
            options={typeOfEntryOptions}
            id="condo.typeOfEntry"
            name="condo.typeOfEntry"
            mode="light"
            disabled={!condoTypesWithEntry.includes(values.condo.typeOfCondo)}
          />
        </LabelTooltip>
      </Grid>

      <Grid item className={classes.checkboxContainer} xs={3}>
        <Field
          type="checkbox"
          id="hasBasement"
          name="condo.hasBasement"
          mode="light"
          label="Has a basement"
          disabled={!condoTypesWithBasement.includes(values.condo.typeOfCondo)}
          fast={false}
        />
      </Grid>

      {showNumberOfUnits && (
        <Grid item className={classes.fullWidthCheckbox} xs={12}>
          <Field
            type="checkbox"
            id="buildingHasMoreThanFiveUnits"
            name="condo.buildingHasMoreThanFiveUnits"
            mode="light"
            label="Building has 5 or more condo units"
            fast={false}
          />
        </Grid>
      )}
      <Field
        xs={4}
        type="select"
        options={condoQualityOptions}
        id="condo.condoQuality"
        name="condo.condoQuality"
        mode="light"
        label="Condo unit quality"
      />
      <Field xs={4} type="numeric" id="sqFt" name="condo.sqFt" mode="light" label="Condo unit sq ft" />
      <Field
        xs={4}
        type="select"
        id="condo.constructionType"
        name="condo.constructionType"
        options={constructionTypeOptions}
        mode="light"
        label="Construction"
      />
      <Field
        xs={4}
        type="numeric"
        id="condo.numHalfBathrooms"
        name="condo.numHalfBathrooms"
        mode="light"
        label="Half Bathrooms"
      />
      <Field
        xs={4}
        type="numeric"
        id="condo.numFullBathrooms"
        name="condo.numFullBathrooms"
        mode="light"
        label="Full Bathrooms"
      />
      <Field
        xs={4}
        type="select"
        id="condo.roofShape"
        options={roofShapeOptions}
        name="condo.roofShape"
        mode="light"
        label="Roof shape"
      />
      <Field xs={6} type="numeric" id="condo.latitude" name="condo.latitude" mode="light" label="Latitude" />
      <Field xs={6} type="numeric" id="condo.longitude" name="condo.longitude" mode="light" label="Longitude" />
    </>
  );
};

export default Condo;
