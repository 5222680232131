import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import openIcon from './open-icon.svg';
import useStyles from './icon-text-link.styles';

const IconTextLink = ({ href, text, newTab, altIcon }) => {
  const classes = useStyles();

  return (
    <Label className={classes.link} type="action" onClick={() => window.open(href, newTab ? '_blank' : '_self')}>
      <img src={openIcon} alt={altIcon} />
      {text}
    </Label>
  );
};

IconTextLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  altIcon: PropTypes.string
};

IconTextLink.defaultProps = {
  newTab: true,
  altIcon: 'icon'
};

export default IconTextLink;
