import { paymentType as PaymentType } from '@ourbranch/lookups';
import { differenceInCalendarDays, getDate, addMonths, setDate } from 'date-fns';
import { awsDateToDateObject } from 'core/helpers/formatters';

const firstInstallmentBillingDate = (effectiveDate, billingDayOfMonth) => {
  return setDate(addMonths(effectiveDate, getDate(effectiveDate) > billingDayOfMonth ? 1 : 0), billingDayOfMonth);
};

export const canOfferMegaDownPay = (effectiveDateIn, billingDayOfMonth, paymentType) => {
  if (effectiveDateIn && billingDayOfMonth && paymentType) {
    const effectiveDate = awsDateToDateObject(effectiveDateIn);
    return (
      paymentType === PaymentType.Monthly &&
      differenceInCalendarDays(firstInstallmentBillingDate(effectiveDate, billingDayOfMonth), effectiveDate) < 20 &&
      getDate(effectiveDate) !== billingDayOfMonth
    );
  }
  return false;
};

export const getMegaDownPayOnChange = (paymentData, setFieldValue, type) => {
  const typeFormatted = { H: 'home', A: 'auto', R: 'renters', C: 'condo' }[type];
  const effectiveDate = paymentData[`${typeFormatted}EffectiveDate`];
  const billingDayOfMonth = paymentData[`${typeFormatted}BillingDayOfMonth`];
  const paymentType = paymentData[`${typeFormatted}PaymentType`];
  const megaDownPayEnabled = paymentData[`${typeFormatted}MegaDownPay`];

  return (e, fieldName) => {
    const afterChangeCanOfferMegaDownPay = canOfferMegaDownPay(
      fieldName.includes('EffectiveDate') ? e.value.toISOString().split('T')[0] : effectiveDate,
      fieldName.includes('BillingDayOfMonth') ? e : billingDayOfMonth,
      fieldName.includes('PaymentType') ? e : paymentType
    );

    if (!afterChangeCanOfferMegaDownPay && megaDownPayEnabled) {
      setFieldValue(`global.${typeFormatted}MegaDownPay`, false);
      // handle when  becomes offered due to selection
    } else if (afterChangeCanOfferMegaDownPay && !megaDownPayEnabled) {
      setFieldValue(`global.${typeFormatted}MegaDownPay`, true);
    }
  };
};
