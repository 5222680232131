import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useStore } from 'core/store';
import useStyles from './switch-policy-modal.styles';

const SwitchPolicyModal = ({ open, onClose, policyToSwitch, bundle }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    offer: { offer },
    quote: { setPrefillData }
  } = useStore();

  const onCreateNewQuoteClick = () => {
    setPrefillData({ ...offer.quote, policyType: bundle });
    history.push('/quote');
  };

  return (
    <BaseDialog open={open} onClose={onClose} className={classes.modal} size="md">
      <div className={classes.container}>
        <Label type="greenBig" className={classes.title}>
          Switch to {policyToSwitch}
        </Label>
        <Label type="modalBody" className={classes.body}>
          Because we need to pull different information on the potential member, you will need to requote to see the{' '}
          {policyToSwitch} options. We will copy to the new quote all the information possible.
        </Label>
        <div className={classes.actions}>
          <Button className={classes.cancel} onClick={onClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button className={classes.newQuote} onClick={onCreateNewQuoteClick} variant="contained" color="primary">
            Create new quote
          </Button>
        </div>
      </div>
    </BaseDialog>
  );
};

SwitchPolicyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  policyToSwitch: PropTypes.string.isRequired,
  bundle: PropTypes.string.isRequired
};

export default SwitchPolicyModal;
