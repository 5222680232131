import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from '../../common/verify-property-container.styles.js';

const FLHomeCoverageQuestions = ({ options }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Label className={classes.sectionTitle}>Coverages</Label>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Would you like to exclude wind coverage from your home policy?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Wind coverage includes losses caused by wind, wind gusts, tornadoes, cyclones, hurricanes or hail. Please have
          the potential member fill out the content exclusion form and submit it so that we may be able to allow policy
          binding. Form has to be hand-written, not typed to be acceptable
        </Label>
        <FormField
          className={classes.formItem}
          id="home.excludeWindCoverage"
          name="home.excludeWindCoverage"
          type="radio"
          mode="light"
          options={options}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>
          Would you like to exclude content coverage from your policy?
        </Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Content coverage includes losses to your personal property due to a covered loss. Please have the potential
          member fill out the content exclusion form and submit so that we may be able to allow policy binding. Form has
          to be hand-written, not typed to be acceptable
        </Label>

        <FormField
          className={classes.formItem}
          id="home.excludeContentCoverage"
          name="home.excludeContentCoverage"
          type="radio"
          mode="light"
          options={options}
        />
      </Card>
      <Card className={classes.propertyFormQuestionCard}>
        <Label className={classes.propertyFormQuestion}>Do you want to include sinkhole coverage on your policy?</Label>
        <Label type="formTitle" className={classes.propertyFormExplanation}>
          Sinkhole coverage includes losses due to structural damage to your home caused by sinkhole activity. Sinkhole
          activity means settlement or systematic weakening of the earth supporting your home. Please note that
          Catastrophic Ground Collapse coverage is automatically included in your policy. In order to continue to bind
          coverage with the sinkhole endorsement, we need a sinkhole inspection completed by a qualified inspection
          company. Please note, these inspections are typically $300 paid by the potential member. Your policy does
          cover you for catastrophic ground collapse coverage. Branch must have UW manager AND product approval prior to
          allowing this coverage.
        </Label>
        <FormField
          className={classes.formItem}
          id="home.includeSinkholeCoverage"
          name="home.includeSinkholeCoverage"
          type="radio"
          mode="light"
          options={options}
        />
      </Card>
    </Grid>
  );
};
export default FLHomeCoverageQuestions;
