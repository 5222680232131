import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import chat from 'core/assets/svg/chat-icon.svg';
import useStyles from './front-contact.styles';

const FrontContact = () => {
  const store = useStore();
  const { account } = store;
  const { email, fetchFrontContactURL, accountLoading, getFrontContactURL } = account;
  useEffect(() => {
    if (!accountLoading) {
      fetchFrontContactURL(email);
    }
  }, [email, getFrontContactURL]);
  const frontContact = account.frontContactURL;
  const classes = useStyles();

  if (accountLoading) {
    return (
      <>
        <Label type="infoLabel" className={classes.rightLabel}>
          Front Contact
        </Label>
        <Label type="infoValue" className={classes.rightValue}>
          Loading...
        </Label>
      </>
    );
  }

  return (
    <Grid>
      {frontContact ? (
        <>
          <IconButton>
            <a href={frontContact} target="_blank" rel="noopener noreferrer">
              <img src={chat} className={classes.linkButton} alt="front contact" />
            </a>
          </IconButton>
          <Label type="infoValue" className={classes.rightValue}>
            <a className={classes.link} href={frontContact} target="_blank" rel="noopener noreferrer">
              Open Front Contact
            </a>
          </Label>
        </>
      ) : (
        <>
          <Label type="infoLabel" className={classes.rightLabel}>
            Front Contact
          </Label>
          <Label type="infoValue" className={classes.rightValue}>
            Not Created
          </Label>
        </>
      )}
    </Grid>
  );
};

export default observer(FrontContact);
