import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import { withStyles } from '@material-ui/core/styles';
import { errorCodes, rejectCodes, changeRejectionLangToNeedToTalkStates } from '@ourbranch/lookups';

import { withStore } from 'core/store';
import { Label } from '../../../core';
import { getError } from '../../constants/error-helper';
import styles from './errors.styles';

const ErrorItem = ({ classes, error, isRejection, store }) => {
  const {
    quote: { offer }
  } = store;

  const errorText = useMemo(() => {
    const codes = isRejection ? rejectCodes : errorCodes;
    return Object.keys(codes).reduce((prev, key) => {
      if (prev === '' && codes[key] === Number(error.code)) {
        return key;
      }
      return prev;
    }, '');
  }, [error, isRejection]);

  const errorObject = useMemo(() => {
    if (error) {
      return getError(error);
    }
  }, [error]);

  return (
    <div
      className={classNames({
        [classes.errorContainer]: !isRejection,
        [classes.rejectionContainer]: isRejection,
        [classes.nonFcraContainer]: isRejection && !errorObject.fcra
      })}
    >
      {!isRejection && <div className={classes.triangle} />}
      <div className={isRejection ? classes.rejectionContent : classes.errorContent}>
        <Label
          className={classNames({
            [classes.topInfo]: !isRejection || errorObject.fcra,
            [classes.nonFcraTopInfo]: isRejection && !errorObject.fcra
          })}
          type="infoLabel"
        >
          {errorText}: {error.code}
        </Label>
        {errorObject && (
          <>
            {errorObject.title && (
              <Label
                type="infoSubtitle"
                className={classNames({
                  [classes.title]: !isRejection || errorObject.fcra,
                  [classes.nonFcraTitle]: isRejection && !errorObject.fcra
                })}
              >
                {errorObject.title}
              </Label>
            )}
            {errorObject?.message ||
              (errorObject?.agentAction && (
                <>
                  <Label
                    type="infoValue"
                    className={classNames(classes.message, {
                      [classes.errorMessage]: !isRejection,
                      [classes.fcraMessage]: isRejection && errorObject.fcra,
                      [classes.nonFcraMessage]: isRejection && !errorObject.fcra,
                      [classes.rejectionMessage]: isRejection
                    })}
                  >
                    <Label type="infoLabel">{errorObject?.message ? 'MESSAGE' : 'AGENT SCRIPT'}</Label>

                    {errorObject?.message || errorObject?.agentScript}
                    {isRejection && changeRejectionLangToNeedToTalkStates[offer?.quote?.correctedAddress?.state] && (
                      <Label
                        type="infoValue"
                        className={classNames(classes.message, {
                          [classes.errorMessage]: !isRejection,
                          [classes.fcraMessage]: isRejection && errorObject.fcra,
                          [classes.nonFcraMessage]: isRejection && !errorObject.fcra
                        })}
                      >
                        This rejection is not permanent, but needs a a referral from underwriting before continuing.
                      </Label>
                    )}
                  </Label>
                  {errorObject?.agentAction && (
                    <Label
                      type="infoValue"
                      className={classNames(classes.message, {
                        [classes.errorMessage]: !isRejection,
                        [classes.fcraMessage]: isRejection && errorObject.fcra,
                        [classes.nonFcraMessage]: isRejection && !errorObject.fcra,
                        [classes.rejectionMessage]: isRejection
                      })}
                    >
                      <Label type="infoLabel">AGENT ACTION</Label>
                      {errorObject?.agentAction}
                    </Label>
                  )}
                </>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

ErrorItem.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  isRejection: PropTypes.bool.isRequired
};

export default flowRight(withStyles(styles), withStore)(ErrorItem);
