import { makeStyles } from '@material-ui/core/styles';

export const styles = (theme) => ({
  details: {
    marginTop: theme.spacing(4)
  },
  segmentForm: {
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(2)
  },
  withFooter: {
    marginBottom: theme.spacing(32)
  },
  tab: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: 'unset',
    minWidth: 152
  }
});

export default makeStyles(styles);
