import { useFormikContext } from 'formik';

/**
 *  formikPath is either "people" or "drivers"
 *      offer will always be "drivers" no matter if its home/auto offer type
 *      auto policy will be "drivers"
 *      home policy will be "people"
 */
export const useSwapAppCoApp = ({ formikPath, allowLicensedActions }) => {
  const { values, setFieldTouched, setFieldValue, initialValues } = useFormikContext();

  const currentApplicantId = values[formikPath].find((person) => person.isPrimary)?.id;
  const initialCoAppId = initialValues[formikPath].find((initialPerson) => initialPerson.isCoApplicant)?.id;
  const isSwapping = currentApplicantId === initialCoAppId;

  const onSwap = () => {
    const newPeople = values[formikPath].map((item) => {
      const { isPrimary, isCoApplicant } = item;
      const isApplicant = isPrimary || isCoApplicant;

      return {
        ...item,
        isPrimary: isApplicant && !isPrimary,
        isCoApplicant: isApplicant && !isCoApplicant
      };
    });

    setFieldValue(formikPath, newPeople);
    setFieldTouched(formikPath);
  };

  const someNameChange = values[formikPath]?.find((person) => {
    const initialPerson = initialValues[formikPath]?.find((initialPerson) => initialPerson.id === person.id);
    return (
      initialPerson?.firstName !== person?.firstName ||
      initialPerson?.lastName !== person?.lastName ||
      initialPerson?.middleName !== person?.middleName
    );
  });

  const coApplicant = values[formikPath]?.find((person) => person.isCoApplicant);
  const initialVersionOfCurrentCoapp = initialValues[formikPath]?.find((person) => person.id === coApplicant?.id);
  const coApplicantIsNewlyCoapplicant = !(
    (initialVersionOfCurrentCoapp?.isCoApplicant || initialVersionOfCurrentCoapp?.isPrimary) &&
    coApplicant?.isCoApplicant
  );
  const disableSwapButton = !allowLicensedActions || !!coApplicantIsNewlyCoapplicant || !!someNameChange;

  return { onSwap, disableSwapButton, isSwapping };
};
