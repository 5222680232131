import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  secondary: {
    padding: theme.spacing(6, 8),
    margin: 0,
    overflow: 'visible'
  },
  innerCheckbox: {
    paddingLeft: 50
  }
}));

export default useStyles;
