import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import FormList from 'core/components/form-list';
import AddPerson from 'common/components/people/add-form';
import { OfferDriver, OfferApplicant } from './person';
import Suggestions from 'common/components/people/suggestions/suggestions';
import { useStore } from 'core/store/store.mobx';
import useStyles from './people-details.styles';

const PeopleDetails = observer(({ disabled, hasAuto }) => {
  const classes = useStyles();
  const { offer: store } = useStore();

  const {
    values: { drivers }
  } = useFormikContext();

  const session = useContext(AuthContext);
  const agentIsLicensedForState = store.getIsLicensedForState(session);

  return (
    <div className={classes.container}>
      <FormList
        disabled={disabled}
        id="drivers" // this is confusing but no matter the policy type (e.g. home only) the people data will always be under the "drivers" node for offers
        item={hasAuto ? OfferDriver : OfferApplicant}
        addForm={(props) => (
          <AddPerson
            {...props}
            drivers={drivers}
            hasAuto={hasAuto}
            addContent={<Suggestions isCoapplicant={!hasAuto} isDriver drivers={drivers} />}
            disabled={!agentIsLicensedForState}
          />
        )}
        addLabel={hasAuto ? 'Add Drivers' : 'Add Co-applicants'}
        title={hasAuto ? 'Drivers' : 'Applicants'}
        singular="person"
        plural="people"
        hasAuto={hasAuto}
      />
    </div>
  );
});

export default PeopleDetails;

PeopleDetails.propTypes = {
  disabled: PropTypes.bool,
  hasAuto: PropTypes.bool
};

PeopleDetails.defaultProps = {
  disabled: false,
  hasAuto: false
};
