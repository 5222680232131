import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(8),
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  coApplicantContainer: {
    paddingBottom: theme.spacing(7)
  },
  driverContainer: {
    paddingBottom: theme.spacing(7)
  },
  cardFooter: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: theme.spacing(4, 8),
    borderTop: `1px solid ${theme.colorPalette.beige_10}`,
    borderBottomRightRadius: theme.sizes.roundCorners,
    borderBottomLeftRadius: theme.sizes.roundCorners
  },
  driverExcluded: {
    backgroundColor: theme.colorPalette.red_20
  },
  driverExcludedLabel: {
    color: theme.colorPalette.white_10,
    textDecoration: 'line-through'
  },
  driverExcludedReason: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.light_text
  }
}));

export default useStyles;
