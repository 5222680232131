import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MUICheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormHelperText } from '@material-ui/core';

import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';
import { Tooltip } from 'core/components/tooltip';
import { Label } from 'core/components/label';
import useStyles from './checkbox.styles';

function Checkbox({
  id,
  label,
  value,
  onChange,
  style,
  mode,
  disabled,
  error,
  helperText,
  className,
  topAlignCheckbox,
  tooltipText,
  tooltipLabel,
  checkboxTooltipClassName: tooltipClassName,
  inlineDisplay,
  ...props
}) {
  const classes = useStyles();

  const getCheckboxClass = useCallback(() => {
    if (error) {
      if (topAlignCheckbox) {
        return { root: classes.checkboxLongLabelError };
      }
      return { root: classes.error };
    }
    if (topAlignCheckbox && mode) {
      if (mode === 'light') {
        return { root: classes.lightCheckboxLongLabel };
      }
      return { root: classes.darkCheckboxLongLabel };
    }
    if (mode) {
      return MapClassesToElem(`${mode}-${ElemType.Checkbox}`, classes);
    }
  }, [error, topAlignCheckbox, classes, mode]);

  const controlClasses = error
    ? { label: classes.error, root: classes.customControlLabel }
    : { label: (mode && classes[`${mode}-label`]) || classes.label, root: classes.customControlLabel };

  return (
    <>
      <FormControl className={classNames(classes.container, className)} disabled={disabled} style={style} {...props}>
        <FormControlLabel
          classes={controlClasses}
          label={
            <Label inlineDisplay={inlineDisplay} id={id}>
              {label}
            </Label>
          }
          control={
            <MUICheckbox
              disabled={disabled}
              id={id}
              checked={value}
              onChange={onChange}
              value={id}
              color="primary"
              classes={getCheckboxClass}
            />
          }
        />
        {error && (
          <FormHelperText classes={{ root: classes.helperText }} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      {tooltipText && (
        <Tooltip
          text={tooltipText}
          placement="bottom"
          label={tooltipLabel}
          className={classNames(classes.checkboxTooltip, tooltipClassName)}
        />
      )}
    </>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  mode: PropTypes.oneOf([null, 'light', 'dark']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  topAlignCheckbox: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipLabel: PropTypes.string,
  checkboxTooltipClassName: PropTypes.string,
  inlineDisplay: PropTypes.bool
};
Checkbox.defaultProps = {
  value: undefined,
  style: {},
  mode: null,
  disabled: false,
  error: false,
  helperText: undefined,
  className: '',
  topAlignCheckbox: false,
  tooltipText: '',
  tooltipLabel: '',
  checkboxTooltipClassName: null,
  inlineDisplay: false
};

export default Checkbox;
