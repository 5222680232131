const styles = (theme) => ({
  label: {
    // Set defaults
    display: 'flex',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  title: {
    composes: '$label',
    fontSize: 42,
    fontWeight: 100,
    textAlign: 'left',
    color: theme.colors.page__subtitle,
    [`@media (max-width: ${theme.screens.mobile}px)`]: {
      fontSize: 32
    }
  },
  titleSecondary: {
    composes: '$title',
    color: theme.colors.text_secondary
  },
  titleLight: {
    fontSize: 40,
    composes: '$title',
    color: theme.colorPalette.white_10,
    fontWeight: 200
  },
  subtitle: {
    composes: '$label',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 500,
    color: theme.colors.page__subtitle
  },
  subtitleLight: {
    composes: '$label',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 300,
    color: theme.colorPalette.white_10
  },
  smallSubtitleLight: {
    composes: '$label',
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 300,
    color: theme.colorPalette.white_10
  },
  formTitle: {
    composes: '$label',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 300,
    color: theme.colors.text_secondary
  },
  formSubTitle: {
    composes: '$label',
    fontSize: 16,
    color: theme.colors.light_text
  },
  formButton: {
    composes: '$label',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.colors.loading_bar
  },
  formSubTitleLight: {
    composes: '$label',
    fontSize: 16,
    color: theme.colors.info__label_title
  },
  infoLabel: {
    composes: '$label',
    lineHeight: 1.4,
    fontSize: 10,
    color: theme.colors.info__label_secondary,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  infoValue: {
    composes: '$label',
    lineHeight: 1.25,
    fontSize: 16,
    color: theme.colors.info__label_primary
  },
  infoValueFormatted: {
    composes: '$infoValue',
    'white-space': 'pre-line'
  },
  infoSubtitle: {
    composes: '$label',
    lineHeight: 1.2,
    fontSize: 20,
    color: theme.colors.page__subtitle
  },
  infoSubtitleWhite: {
    composes: '$label',
    lineHeight: 1.2,
    fontSize: 20,
    color: theme.colorPalette.white_10
  },
  infoSubtitleOrange: {
    composes: '$infoSubtitle',
    color: theme.colorPalette.orange_10
  },
  infoSubtitleBold: {
    composes: '$infoSubtitle',
    fontWeight: 500,
    display: 'contents'
  },
  infoInnerTitle: {
    composes: '$label',
    fontSize: 20,
    display: 'inline-flex',
    lineHeight: 1.2,
    color: theme.colors.info__label_title
  },
  infoInnerTitleBold: {
    composes: '$label',
    fontSize: 20,
    display: 'inline-flex',
    lineHeight: 1.2,
    color: theme.colors.info__label_title,
    fontWeight: 600
  },
  infoCardTitle: {
    composes: '$label',
    display: 'inline',
    fontSize: 14,
    lineHeight: 1,
    color: theme.colors.info__label_tertiary,
    fontWeight: 600
  },
  coverageLabel: {
    composes: '$label',
    lineHeight: 1.4,
    fontSize: 10,
    color: theme.colors.coverage__label_secondary,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  coverageValue: {
    composes: '$label',
    lineHeight: 1.25,
    fontSize: 16,
    color: theme.colors.coverage__label_primary
  },
  bundleLabel: {
    composes: '$coverageLabel',
    color: theme.colors.planPicker_label_secondary
  },
  bundleValue: {
    composes: '$coverageValue',
    color: theme.colors.planPicker_label_primary
  },
  coverageInnerTitle: {
    composes: '$label',
    fontSize: 20,
    display: 'inline-flex',
    lineHeight: 1.2,
    color: theme.colors.coverage__label_title
  },
  coverageSubtitle: {
    composes: '$label',
    marginBottom: 8,
    fontSize: 14,
    color: theme.colors.coverage__label_subtitle
  },
  coverageParagraph: {
    composes: '$label',
    fontWeight: 300,
    display: 'block',
    fontSize: 14,
    color: theme.colors.coverage__label_paragraph
  },
  coverageAmount: {
    composes: '$label',
    fontWeight: 'normal',
    display: 'inline-flex',
    fontSize: 24,
    color: theme.colors.coverage__label_primary,
    paddingRight: 8
  },
  addTitle: {
    composes: '$label',
    fontSize: 32,
    fontWeight: 100,
    color: theme.colors.page__title
  },
  action: {
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.colorPalette.orange_10
  },
  toast: {
    fontSize: 14,
    color: theme.colorPalette.beige_10
  },
  greenSmall: {
    composes: '$label',
    fontSize: 16,
    lineHeight: 1,
    color: theme.colorPalette.green_10
  },
  greenExtraSmall: {
    composes: '$label',
    display: 'inline',
    fontSize: 14,
    lineHeight: 1,
    color: theme.colorPalette.green_10,
    fontWeight: 500
  },
  darkGreenSmall: {
    composes: '$greenSmall',
    color: theme.colorPalette.green_20
  },
  greenBig: {
    composes: '$label',
    fontWeight: 100,
    fontSize: 42,
    lineHeight: 1,
    color: theme.colorPalette.green_10
  },
  darkGreenMedium: {
    composes: '$label',
    display: 'inline',
    fontSize: 20,
    lineHeight: 1,
    color: theme.colorPalette.green_20
  },
  whiteSmall: {
    composes: '$label',
    fontSize: 12,
    lineHeight: 1,
    color: theme.colorPalette.white_10
  },
  whiteMedium: {
    composes: '$label',
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 500,
    color: theme.colorPalette.white_10
  },
  darkSmall: {
    composes: '$label',
    display: 'inline',
    fontSize: 14,
    lineHeight: 1,
    color: theme.colorPalette.green_20,
    fontWeight: 500
  },
  noteSmall: {
    fontSize: 10,
    fontWeight: 300,
    lineHeight: 1.4,
    color: theme.colorPalette.beige_10
  },
  paymentLabel: {
    composes: '$label',
    fontWeight: 300,
    fontSize: 10,
    color: theme.colorPalette.white_10
  },
  policyLabel: {
    composes: '$label',
    fontWeight: 500,
    fontSize: 12,
    color: theme.colorPalette.beige_10
  },
  titleDollarLabel: {
    composes: '$label',
    textAlign: 'right',
    color: theme.colorPalette.white_10,
    fontSize: 16
  },
  formLabel: {
    composes: '$label',
    fontWeight: 500,
    fontSize: 9,
    color: theme.colorPalette.beige_10_op_75,
    textTransform: 'uppercase',
    lineHeight: 1
  },
  formLabelLight: {
    composes: '$label',
    fontWeight: 500,
    fontSize: 9,
    color: theme.colorPalette.green_10,
    textTransform: 'uppercase',
    lineHeight: 1,
    paddingBottom: 5
  },
  formLabelLightLarge: {
    composes: '$label',
    fontWeight: 500,
    fontSize: 12,
    color: theme.colorPalette.green_10,
    textTransform: 'uppercase',
    lineHeight: 1,
    paddingBottom: 5
  },
  smallLightLabel: {
    composes: '$label',
    fontWeight: 400,
    fontSize: 14,
    color: theme.colorPalette.white_10,
    opacity: 0.2,
    marginLeft: 44,
    marginTop: -36
  },
  status: {
    composes: '$label',
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600
  },
  statusClosed: {
    composes: '$status',
    color: theme.colorPalette.green_20
  },
  statusOpen: {
    composes: '$status',
    color: theme.colorPalette.green_50
  },
  statusWarning: {
    composes: '$status',
    color: theme.colorPalette.orange_20
  },
  statusClosedMedium: {
    composes: '$label $statusClosed',
    fontSize: 14
  },
  statusOpenMedium: { composes: '$statusOpen', fontSize: 14 },
  statusWarningMedium: { composes: '$statusWarning', fontSize: 14 },
  smallWarning: {
    composes: '$label',
    fontWeight: 'normal',
    fontSize: 10,
    color: '#df2b26',
    lineHeight: 1.2
  },
  largeWarning: {
    composes: '$label',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#df2b26',
    lineHeight: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  discount: {
    fontWeight: 'normal',
    fontSize: 16,
    color: theme.colorPalette.beige_10,
    lineHeight: 1
  },
  discountPromo: {
    composes: '$label',
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.colorPalette.white_10
  },
  smallWhiteBold: {
    composes: '$label',
    color: theme.colorPalette.white_10,
    fontSize: 14,
    fontWeight: 600
  },
  smallWhite: {
    composes: '$label',
    color: theme.colorPalette.white_10,
    fontSize: 14
  },
  modalBody: {
    color: theme.colorPalette.green_15,
    fontSize: 14,
    lineHeight: '20px'
  }
});

export default styles;
