import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    justifyContent: 'unset',
    gap: theme.spacing(4)
  },
  button: {
    fontSize: 12,
    padding: 0,
    fontWeight: 'normal',
    minWidth: 'unset',
    height: 'unset'
  },
  switchLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: theme.colorPalette.white_10
  },
  disabled: {
    color: theme.colorPalette.beige_10_op_60
  }
});

export default makeStyles(styles);
