import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Field from 'core/components/form/form.v2';
import { IconTextLink } from 'core/components/icon-text-link';
import useStyles from './storm-shutters.styles';

const StormShutters = ({ className, link, label, tooltipText, checkboxTooltipClassName }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, className)}>
      <Field
        name="home.stormShutters"
        type="checkbox"
        label={label}
        tooltipText={tooltipText}
        tooltipLabel={tooltipText ? 'More Info' : null}
        mode="light"
        xs={link ? 7 : 12}
        checkboxTooltipClassName={checkboxTooltipClassName}
      />
      {link && <IconTextLink href={link} text="Go to device verification form" altIcon="Device verification form" />}
    </div>
  );
};

StormShutters.propTypes = {
  className: PropTypes.string,
  checkboxTooltipClassName: PropTypes.string,
  label: PropTypes.string,
  tooltipText: PropTypes.string
};

StormShutters.defaultProps = {
  className: null,
  checkboxTooltipClassName: null,
  label: 'Do all windows and glass doors have storm shutters available in the event of a windstorm?',
  tooltipText: null
};

export default StormShutters;
