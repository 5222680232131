import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import { Grid } from '@material-ui/core';
import { lookupsJson, hideHighestEducationStates, HighestEducation } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';

const Residents = ({ state }) => {
  const { values } = useFormikContext();
  const monthsRentedOutOptions = new Array(13).fill(null).map((_, ix) => ({
    id: ix,
    value: `${ix}`
  }));
  return (
    <>
      <Grid item xs={12}>
        <Label type="formSubTitleLight">Residents</Label>
      </Grid>
      <FormField
        name="condo.ownershipStatus"
        type="select"
        label="Ownership"
        mode="light"
        options={lookupsJson.ownershipStatus}
        xs={3}
      />
      <FormField
        type="select"
        options={monthsRentedOutOptions}
        label="Months rented out"
        id="condo.monthsRentedOut"
        name="condo.monthsRentedOut"
        mode="light"
        xs={3}
      />
      <FormField
        name="condo.purchaseDate"
        type="date"
        label="Purchase date"
        mode="light"
        xs={3}
        disableFuture={false}
        maxDate={addDays(new Date(), 60)}
      />
      <FormField
        name="condo.numOccupants"
        type="numeric"
        label="Residents"
        mode="light"
        format={{
          allowNegative: false
        }}
        xs={3}
      />
      {!hideHighestEducationStates.includes(state) && (
        <FormField
          name="highestEducation"
          type="value"
          label="Highest Education of Residents (non-editable)"
          value={HighestEducation[values.highestEducation] || 'N/A'}
          xs={6}
        />
      )}
      <FormField
        name="oldestResident"
        type="value"
        label="Age of Oldest resident (non-editable)"
        value={values.oldestResident || 'N/A'}
        xs={hideHighestEducationStates.includes(state) ? 12 : 6}
      />
    </>
  );
};

Residents.propTypes = {
  state: PropTypes.string.isRequired
};

export default Residents;
